import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import { API, APIKEY, ISurveyItem, ITemplateSurvey } from './IVesta';


import {
  DetailsList,  IColumn, SelectionMode,
  Stack, IIconProps,
  Panel, PanelType,
  Dropdown, IDropdownStyles, IDropdownOption,
  ActionButton, IconButton,
} from 'office-ui-fabric-react';

import TemplateSurvey from './TemplateSurvey';


interface IState {
    selected: ITemplateSurvey,
    selectedindex: number,
    availablesurveys: ISurveyItem[],
    surveys: ISurveyItem[],
    templatesurveys: ITemplateSurvey[],
    isOpen: boolean,
    addSurveyID: string,
    addSurveyName: string,
    addSurveyDescription: string,
  }

interface IProps extends WrappedComponentProps {
  user: string,
  surveys: ITemplateSurvey[],
  onChange: any,
}
  
class TemplateSurveys extends Component<IProps,IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
          selected:{  surveyName: "", surveyDescription: "" } as ITemplateSurvey  ,
          selectedindex: -1,
          availablesurveys: [],
          surveys: [],
          templatesurveys: this.props.surveys,
          isOpen: false,
          addSurveyID: "",
          addSurveyName: "",
          addSurveyDescription: "",
        };

        this.dismissPanel = this.dismissPanel.bind(this);
        this._ItemChanged = this._ItemChanged.bind(this);
        this.setaddsurvey = this.setaddsurvey.bind(this);
        this.addsurvey = this.addsurvey.bind(this);
        this.setaddsurvey = this.setaddsurvey.bind(this);
        this.templateSurveyChanged = this.templateSurveyChanged.bind(this);
        this.deleteTemplateSurvey = this.deleteTemplateSurvey.bind(this);
        this.editTemplateSurvey = this.editTemplateSurvey.bind(this);
    }


    componentDidMount()
    {
      this.loadallsurveys();
      this.refresh();
    }
  
    componentDidUpdate(prevProps: IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.surveys !== prevProps.surveys) {
        this.setState({templatesurveys: this.props.surveys});
      }
    }
    
  
    public filterval: string = "";

   

    loadallsurveys()
    {
      
      const post = {
        security: {
          APIKey: APIKEY,
          User: this.props.user
        },
      }
      axios.post(API + "/survey/search",post)
        .then(response => {
          this.setState({ availablesurveys: response.data.surveys });
          this.setsurveys();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
   


    setsurveys()
    {
      const templates = this.state.availablesurveys;
      function notUsed(element:ISurveyItem) { 
          return !templates.some(function (item:ISurveyItem) {return item.id === element.id});
      } 
      this.setState({ surveys: this.state.availablesurveys.filter(notUsed) })
    }

    refresh()
    {
      
    }

    private _onColumnClick = (): void => {    };            
    private _ItemChanged = (item: any): void => { this.setState({isOpen: true, selected: item}); };     
    private dismissPanel()
    {
        this.setState({isOpen: false});
    }  

    setaddsurvey(event: any,option?: any,index?: number)
    {
      this.setState({
          addSurveyID: this.state.availablesurveys[index!].id || "",
          addSurveyName: this.state.availablesurveys[index!].name || "",
          addSurveyDescription: this.state.availablesurveys[index!].description || "",
        });
    }

    addsurvey()
    {
      var ts = this.state.templatesurveys;
      if(ts === undefined)
      {
        ts = []; 
      }
      ts.push(
        {
          id: "",
          unitpercent: 0,
          surveyID:  this.state.addSurveyID,
          surveyName: this.state.addSurveyName,
          surveyDescription: this.state.addSurveyDescription,
        } as ITemplateSurvey
      )
      this.setState({templatesurveys: ts});
      this.props.onChange(this.state.templatesurveys);
    }

    templateSurveyChanged(item: ITemplateSurvey)
    {
      var s = this.state.templatesurveys;
      s[this.state.selectedindex] = item;
      this.setState({templatesurveys: s, isOpen: false}); 
      this.props.onChange(this.state.templatesurveys);
    }

    private editTemplateSurvey(item: ITemplateSurvey,index: number)
    {
      this.setState({isOpen: true, selected: item, selectedindex: index  });
    }  
  
    private deleteTemplateSurvey(item: ITemplateSurvey,index: number)
    {
      var ts = this.state.templatesurveys;
      ts.splice(index,1);
      this.setState({templatesurveys: ts});
      this.props.onChange(this.state.templatesurveys);  
    }
  

    render() {

      const allsurveys: IDropdownOption[] = this.state.availablesurveys.map(function(element: ISurveyItem)  {
          return({ key: element.id, text: element.name });
      });
      const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
      };


      const addFriendIcon: IIconProps = { iconName: 'Add' };
      const columns: IColumn[] = [
        {
          key: 'name',
          name: this.props.intl.formatMessage({ id: 'survey.survey' }),
          fieldName: 'surveyName',
          minWidth: 210,
          maxWidth: 350,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'description',
          name: this.props.intl.formatMessage({ id: 'template.description' }),
          fieldName: 'surveyDescription',
          minWidth: 0,
          maxWidth: 0,
          currentWidth: 0,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
        },
      {
          key: 'unitpercent',
          name: this.props.intl.formatMessage({ id: 'template.unitpc' }),
          fieldName: 'unitpercent',
          minWidth: 100,
          maxWidth: 100,
          isRowHeader: true,
          isResizable: true,
          data: 'string',
          isPadded: true,
          onRender: (item: any) => {
            return item.unitpercent === 0 ? "For the building" : item.unitpercent.toString() + "%";
          },
        },
        {
          key: 'edit',
          name: 'Edit',
          isIconOnly: true,
          fieldName: 'id',
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: any,index?:number) => {
            return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.editTemplateSurvey(item,index || 0)} title="Edit" ariaLabel="Edit"  />
          },
        },
        {
          key: 'delete',
          name: 'Delete',
          ariaLabel: '',
          isIconOnly: true,
          fieldName: 'id',
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: any,index?:number) => {
            return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteTemplateSurvey(item,index || 0)} title="Delete" ariaLabel="Delete"  />
          },
        }
  
      ];



      var surveys;
      if(this.state.templatesurveys !== undefined )
      {
        surveys = <DetailsList  items={this.state.templatesurveys} 
            columns={columns} 
            selectionMode={SelectionMode.none}
            />;
      }

      return (
        <Stack grow styles={{root: {width: "500"}}}>
          <Stack horizontal verticalAlign="end" >
            <Dropdown
                      placeholder={this.props.intl.formatMessage({ id: 'survey.placeholder' })}
                      label={this.props.intl.formatMessage({ id: 'survey.surveys' })}
                      options={allsurveys}
                      styles={dropdownStyles}
                      defaultSelectedKey={this.state.addSurveyID}
                      onChange={this.setaddsurvey}
                  />
            <ActionButton iconProps={addFriendIcon} allowDisabledFocus onClick={this.addsurvey} >Add</ActionButton>
          </Stack>
          {surveys}

          <Panel
              isOpen={this.state.isOpen}
              onDismiss={this.dismissPanel}
              headerText={this.props.intl.formatMessage({ id: 'survey.requirement' })}
              closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
              type={PanelType.large}

          >
            <TemplateSurvey 
                user={this.props.user} 
                surveys={this.state.availablesurveys}
                onClose={this.dismissPanel}
                onChange={this.templateSurveyChanged}
                templateSurvey={this.state.selected}
            />
          </Panel>
        </Stack>
      );
    };      
}

export default injectIntl(TemplateSurveys);