import { StringUtils } from "@azure/msal-common";
import { IDatePickerStrings } from "office-ui-fabric-react";

export const { API, APIKEY } = require("./IVestaEndpoint");
// export const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;
// export const API = process.env.REACT_APP_API_URL;
// export const APIKEY = process.env.REACT_APP_API_KEY;

export const MAPSKEY = "AIzaSyCvIfcCWgOEnXCn_jqJY0fV_TnzforO7L4";
export const stdDayPickerStrings: IDatePickerStrings = {
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],

    shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],

    days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ],

    shortDays: ["S", "M", "T", "W", "T", "F", "S"],

    goToToday: "Go to today",
    prevMonthAriaLabel: "Go to previous month",
    nextMonthAriaLabel: "Go to next month",
    prevYearAriaLabel: "Go to previous year",
    nextYearAriaLabel: "Go to next year",
    closeButtonAriaLabel: "Close date picker",
    monthPickerHeaderAriaLabel: "{0}, select to change the year",
    yearPickerHeaderAriaLabel: "{0}, select to change the month",
};

export interface IPagination {
    current: number;
    pageSize: number;
    sortField: string;
    sortOrder: string;
}

export interface IUD {
    id: string;
    value: string;
}

export interface IMessage {
    id: string;
    responseToID: string;
    clientID: string;

    contactIDFrom: string;
    contactFrom: any;

    contactIDTo: string;
    contactTo: any;

    userIDTo: string;
    userTo: IUser;
    userIDFrom: string;
    userFrom: IUser;
    certificateID: string;
    certificate: ICertificate;
    surveyID: string;
    questionID: string;
    subject: string;
    message: string;
    fromMessage: string;
    read: boolean;

    // url: string,
    // logitude: number,
    // latitude: number,
    // takenat: Date,
    // blobname: string,

    dateSent: Date;
    dateRead: Date;
    messageHistory: {
        message: string;
        dateSent: Date;
        isReceived: boolean;
    }[];
}

export interface ITranslation {
    language: string;
    description: string;
    assessorguidance: string;
    operatorguidance: string;
    evidenceprompt: string;
    evidencename: string;
    evidenceguidance: string;
    options: string;
}

export interface ICondition {
    left: string;
    right: string;
    condition: string;
}

export interface IQuestion {
    id?: string;
    type: number;
    description: string;
    guidance: string;
    operatorGuidance: string;
    helpURL: string;
    options: string;

    mustApprove?: boolean;

    mandatory: boolean;
    mandatoryGracePeriod: number;

    validate?: boolean;
    validationFailMessage: string;
    validationMin: number;
    validationMax: number;
    validationExtra: string;

    evidencerequired: number;
    evidencetype: number;
    evidenceprompt: string;
    evidenceguidance: string;
    evidenceHelpURL: string;
    evidencename: string;
    requiredafter: number;
    gpsTagged: boolean;
    gpsRingfenced: boolean;
    recentPicture: boolean;
    recentleaway?: number;
    answer?: IAnswer;
    translations: ITranslation[];

    conditional: boolean;
    condition?: ICondition;
    conditionalSurveyID: string;
    conditionalQuestionID: string;
    conditionalValue: string;

    conditionalSurveyName?: string;
    conditionalSectionName?: string;
    conditionalQuestionName?: string;

    externalRef: string;
    conditionalDisabled: boolean;
    premium?: boolean;
    validationError?: string;
    videoDescription?: string;
    videoURL?: string;
    isDisplayMyoCorporate: boolean;
    isAnswersYes: boolean;
    section: string;
    subSection: string;
    positiveDisplay: string;
    negativeDisplay: string;
    introductionToTextAnswer: string;
    imageMyoDescription: string;
    videoMyoDescription: string;
    numberMyoDescription: string;
    dateMyoDescription: string;
    manyOfMyoDescription: string;
    documentMyoDescription: string;
    oneOfMyoDescription: { [key: string]: string }[];
    color: string;
    negativeColor: string;
}

export interface ISecurity {
    APIKey: string;
    Token: string;
}

export interface IAttachment {
    ID: string;
    contentType: string;
    contentUrl: string;
    content: string;
    name: string;
    thumbnailUrl: string;
}

export interface IObjectAttachment extends IAttachment {
    expires: boolean;
    expiresOn?: Date;
    category: string;
    filesize: number;
    blobname: string;
    originalname: string;
    mimetype: string;
}

export interface IAttachmentCategory {
    name: string;
    mandatory: boolean;
}

export interface IClientSettings {
    clientTypes: string[];
    propertyTypes?: string[];
    completionTypes?: string[];
    myAttachmentCategories: IAttachmentCategory[];
    myClientAttachmentCategories: IAttachmentCategory[];
    myBuildingAttachmentCategories: IAttachmentCategory[];
}

export interface IClient {
    id: string;
    parentID: string;
    contactID: string;
    contactInfo: IContactInfo;
    name: string;
    contact: string;
    phone: string;
    email: string;
    externalID: string;
    type: string;
    assessor: string;
    managedExternally: boolean;
    attachments?: IObjectAttachment[];
    settings: IClientSettings;
    ud: IUD[];
}

export interface IContactInfo {
    id: string;
    parentID: string;
    name: string;
    email: string;
    pin?: IPin;
    telephones?: IPhone[];
    numberOfTries?: number;
    notifyPinDetails?: number;
    dateAssigned?: any;
}

export interface IPin {
    number: string;
    pinHash: string;
    issuedOn?: any;
    expiresOn?: any;
    verified?: boolean;
}

export interface IPhone {
    type: number;
    countryCode: string;
    areaCode: string;
    number: string;
    ext: string;
}

export interface ISurveySection {
    id?: string;
    name: string;
    description: string;
    approvalsRequiredPercent: number;
    questions: IQuestion[];
    validationError?: string;
    qaPass: boolean;
    qaaccepted?: boolean;
}

export interface IBuilding {
    id?: string;
    clientID: string;
    contactID: string;
    contactInfo: IContactInfo;
    client: IClient | null;
    clientName: string;
    certificate: ICertificate | null;
    certificateName: string;
    manager: string;
    managerName: string;
    assessor: string;
    assessorInfo: IUser;
    telephone: string;
    buildingName: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    postcode: string;
    latitude: number;
    longitude: number;
    unitCount: number;
    externalID: string;
    managedExternally: boolean;
    attachments?: IObjectAttachment[];
    ud: IUD[];
    propertyType: string;
    configuration: ConfigurationState;
}

export interface ConfigurationState {
    oneBedroomUnit: string;
    twoBedroomUnit: string;
    threeBedroomUnit: string;
    moreThanThreeBedroomUnit: string;
    studioApartmentUnit: string;
}

export interface ICertificate {
    id: string;
    buildingID: string;
    building: IBuilding;
    name: string;
    hasExpectedCompletedDate: boolean;
    expectedCompletedDate: string;
    expiresOn: Date;
    inviteExpiresOn: Date;
    surveys: ICertificateSurvey[];
    active: boolean;
    issued: boolean;
    issuedBy: string;
    issuedUser: IUser;
    issuedOn: Date;
    inviteIssued: boolean;
    inviteIssuedBy: string;
    inviteIssuedUser: IUser;
    inviteIssuedOn: Date;
    premium: boolean;
    external: boolean;
    ud: IUD[];
}

export interface ICertificateSurvey {
    id: string;
    key: string;
    surveyID: string;
    certificateID?: string;
    survey: ISurvey;
    unit: string;
    building: IBuilding;
    notes: string;
    hasExpectedCompletedDate: boolean;
    expectedCompletedDate: string;
    completedOn: Date;
    approvedOn: Date;
    approvedByID: any;
    approvedBy: IUser;
    active: boolean;
    answers: IAnswer[];
    isCompleted: boolean;
    isApproved: boolean;
    isStarted: boolean;
}

export interface IAnswer {
    id: string;
    questionID: string;
    answer: string;
    answerLocal: string;
    na?: boolean;

    approved: boolean;
    approvedBy: string;
    approvedOn: Date;
    expires: boolean;
    expiresOn: Date;

    evidencesupplied: boolean;
    evidence: IEvidence;
}

export interface IEvidence {
    id: string;
    mimetype: string;
    url: string;
    longitude: number;
    latitude: number;
    takenat: Date;
    acceptedBy: string;
    blobname: string;
    originalname: string;
    acceptedOn: Date;
    expires: boolean;
    expiresOn: string;
}

export interface ISurveyItem {
    id: string;
    name: string;
    description: string;
}

export interface ITemplateItem {
    id: string;
    name: string;
    description: string;
}

export interface ICertificateTemplate {
    id: string;
    name: string;
    description: string;
    useExpectedCompletedDate?: boolean;
    expectedCompletionPeriod?: number;
    external?: boolean;
    surveys: ITemplateSurvey[];
}

export interface ITemplateSurvey {
    unitpercent: number;
    surveyID: string;
    surveyName: string;
    surveyDescription: string;
}

export interface ISurvey {
    id?: string;
    name: string;
    description: string;
    validFor: number;
    useExpectedCompletionPeriod?: boolean;
    expectedCompletionPeriod?: number;
    completionMethod: number;
    sections: ISurveySection[];
    autoComplete?: boolean;
    autoCompleteSurveyID?: string;
    certificateCount: number;
    templateCount: number;
    canDelete: boolean;
    propertyType: string;
    surveyType: string,
    isDisplayMyoCorporate: boolean,
    completionType: string;
}

export interface ISection {
    id: string;
    name: string;
    subsection: ISubSection[];
    isProperty: boolean;
}

export interface ISubSection {
    id: string;
    name: string;
}

export interface IReportReference {
    id: string;
    name: string;
}

export interface IObjectReference {
    name: string;
    id: string;
}

export interface IReport {
    id?: string;
    name: string;
    description: string;
    sections: IReportSection[];
}

export interface IReportSection {
    id?: string;
    name: string;
    description: string;
    lines: IReportLine[];
}

export interface IFont {
    typeface: string;
    color: string;
    size: number;
    bold: boolean;
    italic: boolean;
    underline: boolean;
}

export interface IReportLine {
    description: string;
    type: number;
    options: string;
    surveyID: string;
    questionID: string;
    font: IFont;
    question?: IQuestion;
    answer?: IAnswer;
}

export interface IUser {
    id?: string;
    clientID?: string;
    email?: string;
    role?: string;
    active?: boolean;
}

export const getEmailErrorMessage = (
    value: string,
    message?: string
): string => {
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value
        )
    )
        return "";

    return message ? message : `Please enter a valid email address.`;
};

export const getMandatoryErrorMessage = (
    value: string,
    fieldName: string,
    message?: string
): string => {
    if (value !== "") return "";
    return message ? message : `Please enter a value for ${fieldName}.`;
};

export const formatEmail = (email: string): string => {
    if (!email) return "";

    var b = email.indexOf("(");
    if (b >= 0) {
        email = email.substr(0, b - 1).trim();
    }

    return email;
};

export const getPhone = (phones: any): string => {
    if (!phones) return "";
    if (phones.length === 0) return "";
    if (!phones[0]) return "";
    return phones[0].number;
};
