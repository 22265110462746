import React, { Component } from "react";
import axios from "axios";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {
    API,
    APIKEY,
    getEmailErrorMessage,
    getMandatoryErrorMessage,
    IUser,
    IBuilding,
    formatEmail,
    getPhone,
    IContactInfo,
    ConfigurationState
} from "./IVesta";

import {
    Stack,
    IStackStyles,
    IStackTokens,
    TextField,
    ITextFieldStyles,
    DefaultButton,
    PrimaryButton,
    SpinButton,
    ISpinButtonStyles,
    Separator,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
} from "office-ui-fabric-react";
import LocationIcon from "./LocationIcon";

import { Position } from "office-ui-fabric-react/lib/utilities/positioning";
import ContactPicker from "./components/ContactPicker";
import {
    BasePicker,
    ITag,
    IInputProps,
    IBasePickerSuggestionsProps,
    IBasePickerProps,
} from "@fluentui/react/lib/Pickers";

const stackStyles: IStackStyles = {
    root: {
        width: "500",
    },
};

/* picker */

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {};
var picker: BasePicker<ITag, IBasePickerProps<ITag>>;

/* picker */

type IState = {
    selected: string;
    isOpen: boolean;
    id: string;
    buildingname: string;
    address1: string;
    address2: string;
    city: string;
    postcode: string;
    latitude: number;
    longitude: number;
    unitcount: number;
    manager: string;
    managername: string;
    assessor: string;
    assessorInfo: IUser;
    shouldSave: boolean;
    users: IDropdownOption[];
    contactList: ITag[];
    contactid: string;
    contactInfo: IContactInfo;
    telephone: string;
    propertytype: string;
    configuration: ConfigurationState;
};

interface IProps extends WrappedComponentProps {
    user: string;
    building: IBuilding;
    buildingId: string;
    onClose?: any;
    onSave?: any;
    contactData: any;
}

// interface ConfigurationState {
//   oneBedroomUnit: string;
//   twoBedroomUnit: string;
//   threeBedroomUnit: string;
//   moreThanThreeBedroomUnit: string;
//   studioApartmentUnit: string;
// }

class Building extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            selected: "Undefined",
            isOpen: false,
            manager: "",
            managername: "",
            contactid: "",
            contactInfo: { name: "", email: "" } as IContactInfo,
            assessor: "",
            assessorInfo: { email: "" } as IUser,
            unitcount: 0,
            id: this.props.buildingId,
            buildingname: "",
            address1: "",
            address2: "",
            city: "",
            postcode: "",
            propertytype: "",
            configuration: {
                oneBedroomUnit: "0",
                twoBedroomUnit: "0",
                threeBedroomUnit: "0",
                moreThanThreeBedroomUnit: "0",
                studioApartmentUnit: "0",
            } as ConfigurationState,
            longitude: this.props.building.latitude,
            latitude: this.props.building.longitude,
            shouldSave: false,
            users: [],
            contactList: [],
            telephone: this.props.building.telephone,
        };

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.handleUnitChange = this.handleUnitChange.bind(this);
        this.setassessor = this.setassessor.bind(this);
        this.onSetLoc = this.onSetLoc.bind(this);
        this.bindContactList = this.bindContactList.bind(this);

        this.handlePickerChange = this.handlePickerChange.bind(this);
        this.handlePickerBlur = this.handlePickerBlur.bind(this);
        this.getDefaultItem = this.getDefaultItem.bind(this);
        this.filterSelectedTags = this.filterSelectedTags.bind(this);
    }

    private handleUnitChange(value: string) {
        const unit = parseInt(value);
        if (unit < 1) {
            this.setState({ unitcount: unit, shouldSave: false });
        } else if (isNaN(unit)) {
            this.setState({ unitcount: unit, shouldSave: false });
        } else {
            this.setState({ unitcount: parseInt(value), shouldSave: true });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.building !== this.props.building) {
            this.setState({
                manager: this.props.building.manager,
                unitcount: this.props.building.unitCount,
                address1: this.props.building.address1,
                address2: this.props.building.address2,
                city: this.props.building.city,
                postcode: this.props.building.postcode,
                longitude: this.props.building.latitude,
                latitude: this.props.building.longitude,
            });
        }
    }

    // picker
    listContainsTagList = (tag: ITag | undefined, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tag
            ? tagList.some((compareTag) => compareTag.key === tag.key)
            : null;
    };

    filterSuggestedTags = (
        filterText: string,
        tagList: ITag[] | undefined
    ): ITag[] => {
        return filterText
            ? this.state.contactList.filter(
                (tag) =>
                    tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 &&
                    !this.listContainsTagList(tag, tagList)
            )
            : [];
    };

    filterSelectedTags = (filterText: string, tagList: ITag[]): ITag[] => {
        return filterText
            ? this.state.contactList.filter(
                (tag) =>
                    tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
            )
            : [];
    };

    getTextFromItem = (item: ITag) => {
        return item.name;
    };

    onItemSelected(item: ITag | undefined): ITag | PromiseLike<ITag> | null {
        if (picker && item && this.listContainsTagList(item, picker.items)) {
            return null;
        }
        return item ? item : null;
    }

    getDefaultItem(): ITag[] | undefined {
        var ret: ITag[] = [];
        if (this.state.manager !== "") {
            if (this.state.contactInfo) {
                ret.push({
                    key: this.state.contactInfo.email,
                    name: this.state.contactInfo.email,
                });
            }
        }
        return ret;
    }

    inputProps: IInputProps = {
        onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
            this.handlePickerBlur(ev),
    };

    handlePickerBlur(html: any) {
        var email = html.target.defaultValue;

        if (!email) return;

        email = formatEmail(email ? email : "");

        var contact = this.findConttact(email);

        var manager = email;

        var managerName = "";
        var contactID = "";
        var phone = "";

        if (contact) {
            contactID = contact.id;
            phone = getPhone(contact.telephones);
            managerName = contact.name;
        } else {
            contact = {
                name: "",
                email: email,
            };
        }

        this.setState({
            contactid: contactID,
            contactInfo: contact,
            manager: manager,
            managername: managerName,
            telephone: phone,
            shouldSave: true,
        });
    }

    findConttact(email: string) {
        for (var i = 0; i < this.props.contactData.contacts.length; i++) {
            if (email === this.props.contactData.contacts[i].email) {
                return this.props.contactData.contacts[i];
            }
        }

        return null;
    }

    handlePickerChange(items?: ITag[]) {
        const len = items ? items.length : 0;
        if (len === 0) {
            this.setState({ manager: "", shouldSave: true });
            return;
        }

        var contact = this.state.contactInfo;

        var manager = formatEmail(items ? items[0].name : "");
        const key = items ? items[0].key : 0;
        var _opt = this.props.contactData.contacts[key];

        if (!contact) {
            contact = {
                parentID: "",
                id: "",
                name: "",
                email: "",
            };
        }

        contact.name = _opt.name;
        contact.email = _opt.email;

        var managerName = _opt.name;

        var phone = getPhone(_opt.telephones);
        var contactID = _opt.id;

        this.setState({
            contactid: contactID,
            contactInfo: contact,
            manager: manager,
            managername: managerName,
            telephone: phone,
            shouldSave: true,
        });
    }
    // picker

    handleChange(event: any) {
        const target = event.target;
        const value: string =
            target.type === "checkbox" ? target.checked : target.value;
        const name: string = target.name;
        var contact = this.state.contactInfo;
        switch (name) {
            case "unitcount":
                this.setState({ unitcount: parseInt(value), shouldSave: true });
                break;
            case "buildingname":
                this.setState({ buildingname: value, shouldSave: true });
                break;
            case "address1":
                this.setState({ address1: value, shouldSave: true });
                break;
            case "address2":
                this.setState({ address2: value, shouldSave: true });
                break;
            case "city":
                this.setState({ city: value, shouldSave: true });
                break;
            case "postcode":
                this.setState({ postcode: value, shouldSave: true });
                break;
            case "email":
                contact.email = value;
                this.setState({
                    manager: value,
                    contactInfo: contact,
                    shouldSave: true,
                });
                break;
            case "managername":
                contact.name = value;
                this.setState({
                    managername: value,
                    contactInfo: contact,
                    shouldSave: true,
                });
                break;
            case "telephone":
                this.setState({ telephone: value, shouldSave: true });
                break;
        }
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
        };

        this.authenticatedrefresh(post);

        axios
            .post(API + "/user/Search", post)
            .then((response) => {
                const items = response.data.users;
                var table: any = [];
                items.forEach(function (element: any) {
                    table.push({
                        key: element.id,
                        text: element.email,
                    });
                });

                this.setState({ users: table });

                this.bindContactList();
            })
            .catch(function (error) { });
    }

    // authenticatedrefresh is used to fill input value.
    authenticatedrefresh(request: object) {
        const post = {
            ...request,
            id: this.props.building.id,
        };

        axios
            .post(API + "/building/get", post)
            .then((response) => {
                if (response.data.status.errorCode > 0) {
                    console.log(response.data.status.message);
                } else {
                    const convertedResponse = Object.keys(response.data.building).reduce(
                        (acc: any, key: string) => {
                            const lowercaseKey = key.toLowerCase();
                            if (key === "contactInfo" || key === "assessorInfo") {
                                if (key === "contactInfo") {
                                    acc["manager"] = response.data.building.contactInfo.email;
                                    acc["managername"] = response.data.building.contactInfo.name;
                                }
                                acc[key] = response.data.building[key];
                            }
                            acc[lowercaseKey] = response.data.building[key];
                            return acc;
                        },
                        {}
                    );
                    this.setState({ ...this.state, ...convertedResponse });
                    this.forceUpdate();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    bindContactList() {
        const _options: ITag[] = [];
        for (var i = 0; i < this.props.contactData.contacts.length; i++) {
            _options.push({
                key: `${i}`,
                name: `${this.props.contactData.contacts[i].email} (${this.props.contactData.contacts[i].name})`,
            });
        }

        this.setState({ ...this.state, contactList: _options });
    }

    onSetLoc(lat: number, lng: number) {
        this.setState({ latitude: lat, longitude: lng, shouldSave: true });
    }

    save() {
        if (this.state.unitcount >= 1 && this.state.unitcount <= 10) {
            var b: IBuilding = {
                client: null,
                clientName: "",
                certificate: null,
                certificateName: "",
                contactID: this.state.contactid,
                contactInfo: this.state.contactInfo,
                id: this.props.building.id,
                buildingName: this.state.buildingname,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                postcode: this.state.postcode,
                manager: this.state.manager,
                managerName: this.state.managername,
                assessor: this.state.assessor,
                assessorInfo: this.state.assessorInfo,
                unitCount: this.state.unitcount,
                country: "",
                telephone: this.state.telephone,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                clientID: "",
                externalID: "",
                managedExternally: false,
                ud: [],
                propertyType: this.state.propertytype,
                configuration: this.state.configuration
            };
            this.props.onSave(b);
        } else {
            console.log("Error");
        }
    }

    close() {
        this.props.onClose();
    }

    setassessor(ev: any, option?: any) {
        this.setState({ assessor: option.key || "", shouldSave: true });
    }
    render() {
        const stackToks: IStackTokens = {
            childrenGap: "6",
            padding: "16px",
        };

        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
        };
        const narrow: Partial<ITextFieldStyles> = { fieldGroup: { width: 250 } };
        const narrowzip: Partial<ITextFieldStyles> = { fieldGroup: { width: 100 } };
        const narrownum: Partial<ISpinButtonStyles> = { root: { width: "100px" } };

        return (
            <Stack grow styles={stackStyles}>
                <Stack>
                    <TextField
                        label={this.props.intl.formatMessage({ id: "building.name" })}
                        value={this.state.buildingname}
                        name="buildingname"
                        onChange={this.handleChange}
                    />
                    <TextField
                        label={this.props.intl.formatMessage({ id: "building.address1" })}
                        value={this.state.address1}
                        name="address1"
                        onChange={this.handleChange}
                    />
                    <TextField
                        label={this.props.intl.formatMessage({ id: "building.address2" })}
                        value={this.state.address2}
                        name="address2"
                        onChange={this.handleChange}
                    />
                    <TextField
                        label={this.props.intl.formatMessage({ id: "building.city" })}
                        value={this.state.city}
                        name="city"
                        styles={narrow}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label={this.props.intl.formatMessage({ id: "building.postcode" })}
                        value={this.state.postcode}
                        name="postcode"
                        styles={narrowzip}
                        onChange={this.handleChange}
                    />
                    <Stack horizontal styles={stackStyles} tokens={stackToks}>
                        <LocationIcon
                            user={this.props.user}
                            latitude={this.state.latitude}
                            longitude={this.state.longitude}
                            onChange={this.onSetLoc}
                        />
                    </Stack>
                    {/*<TextField label={this.props.intl.formatMessage({ id: 'building.manager.email' })} value={this.state.manager} name="email" onGetErrorMessage={getEmailErrorMessage} onChange={this.handleChange}/>

                <VirtualizedComboBox 
                        text={this.state.manager} 
                        label={this.props.intl.formatMessage({ id: 'client.email' })} 
                        defaultSelectedKey={this.state.manager}
                        allowFreeform useComboBoxAsMenuWidth 
                        options={this.state.contactList}
                        onChange={this.handleEmailChange} 
                        required={true}
                        errorMessage={getEmailErrorMessage(this.state.manager, this.props.intl.formatMessage({ id: 'validation.error.email' }))}
                /> */}

                    <ContactPicker
                        inputProps={{ id: "email" }}
                        label={this.props.intl.formatMessage({ id: "client.email" })}
                        removeButtonAriaLabel="Remove"
                        onResolveSuggestions={this.filterSuggestedTags}
                        getTextFromItem={this.getTextFromItem}
                        pickerSuggestionsProps={pickerSuggestionsProps}
                        itemLimit={1}
                        disabled={false}
                        onItemSelected={this.onItemSelected}
                        selectedItems={this.getDefaultItem()}
                        onChange={this.handlePickerChange}
                        onBlur={this.handlePickerBlur}
                        required={true}
                        pickerValue={picker}
                        onGetErrorMessage={getEmailErrorMessage}
                        errorMessage={this.props.intl.formatMessage({
                            id: "validation.error.email",
                        })}
                    />
                    <TextField
                        name="managername"
                        label={this.props.intl.formatMessage({
                            id: "building.manager.name",
                        })}
                        value={this.state.managername}
                        onChange={this.handleChange}
                        required={true}
                        onGetErrorMessage={() =>
                            getMandatoryErrorMessage(
                                this.state.managername,
                                "Manager Name",
                                this.props.intl.formatMessage({
                                    id: "validation.error.building.managername",
                                })
                            )
                        }
                    />

                    <TextField
                        name="telephone"
                        label={this.props.intl.formatMessage({
                            id: "building.manager.phone",
                        })}
                        value={this.state.telephone}
                        onChange={this.handleChange}
                    />

                    <Dropdown
                        label={this.props.intl.formatMessage({ id: "building.assessor" })}
                        placeholder={this.props.intl.formatMessage({
                            id: "building.select.user",
                        })}
                        required={true}
                        options={this.state.users}
                        styles={dropdownStyles}
                        selectedKey={this.state.assessor}
                        onChange={this.setassessor}
                        errorMessage={getMandatoryErrorMessage(
                            this.state.assessor,
                            "Assessor",
                            this.props.intl.formatMessage({
                                id: "validation.error.building.assessor",
                            })
                        )}
                    />

                    <SpinButton
                        label={this.props.intl.formatMessage({ id: "building.units" })}
                        labelPosition={Position.top}
                        value={this.state.unitcount.toString()}
                        styles={narrownum}
                        onValidate={this.handleUnitChange}
                        onIncrement={(value: string) => {
                            let unit = Number(value) + 1;
                            if (unit > 10) {
                                // unit = 10;
                                this.setState({ unitcount: unit, shouldSave: false });
                            } else if (isNaN(unit)) {
                                this.setState({ unitcount: unit, shouldSave: false });
                            } else {
                                this.setState({ unitcount: unit, shouldSave: true });
                            }
                        }}
                        onDecrement={(value: string) => {
                            let unit = Number(value) - 1;
                            if (unit < 1) {
                                // unit = 1;
                                this.setState({ unitcount: unit, shouldSave: false });
                            } else if (isNaN(unit)) {
                                this.setState({ unitcount: unit, shouldSave: false });
                            } else {
                                this.setState({ unitcount: unit, shouldSave: true });
                            }
                        }}
                        min={1}
                        max={10}
                    />
                    <div style={{ color: "rgb(164, 38, 44)", fontSize: "12px" }}>
                        {isNaN(this.state.unitcount)
                            ? this.props.intl.formatMessage({
                                id: "validation.error.building.unit",
                            })
                            : this.state.unitcount < 1
                                ? this.props.intl.formatMessage({
                                    id: "validation.error.building.unit.min",
                                })
                                : this.state.unitcount > 10
                                    ? this.props.intl.formatMessage({
                                        id: "validation.error.building.unit.max",
                                    })
                                    : null}
                    </div>
                    <TextField
                        name="propertyType"
                        label={this.props.intl.formatMessage({
                            id: "building.building.type",
                        })}
                        styles={narrow}
                        disabled={true}
                        value={this.state.propertytype}
                    />
                    <Stack>
                        {[
                            {
                                name: "oneBedroomUnit",
                                value: this.state.configuration
                                    ? this.state.configuration.oneBedroomUnit
                                    : "0",
                                labelId: "building.building.configuration.oneBedroomUnit",
                            },
                            {
                                name: "twoBedroomUnit",
                                value: this.state.configuration
                                    ? this.state.configuration.twoBedroomUnit
                                    : "0",
                                labelId: "building.building.configuration.twoBedroomUnit",
                            },
                            {
                                name: "threeBedroomUnit",
                                value: this.state.configuration
                                    ? this.state.configuration.threeBedroomUnit
                                    : "0",
                                labelId: "building.building.configuration.threeBedroomUnit",
                            },
                            {
                                name: "moreThanThreeBedroomUnit",
                                value: this.state.configuration
                                    ? this.state.configuration.moreThanThreeBedroomUnit
                                    : "0",
                                labelId:
                                    "building.building.configuration.moreThanThreeBedroomUnit",
                            },
                            {
                                name: "studioApartmentUnit",
                                value: this.state.configuration
                                    ? this.state.configuration.studioApartmentUnit
                                    : "0",
                                labelId: "building.building.configuration.studioApartmentUnit",
                            },
                        ].map((unit, index) => (
                            <Stack key={index}>
                                <TextField
                                    name={`configuration.${unit.name}`}
                                    label={this.props.intl.formatMessage({ id: unit.labelId })}
                                    styles={narrow}
                                    disabled={true}
                                    value={unit.value}
                                />
                            </Stack>
                        ))}
                    </Stack>
                    <Stack horizontal styles={stackStyles} tokens={stackToks}></Stack>
                    <Stack horizontal tokens={stackToks}>
                        <PrimaryButton
                            onClick={this.save}
                            text={this.props.intl.formatMessage({ id: "std.save" })}
                            disabled={!this.state.shouldSave}
                        />
                        <DefaultButton
                            onClick={this.close}
                            text={this.props.intl.formatMessage({ id: "std.cancel" })}
                        />
                    </Stack>
                </Stack>
                <Separator />
            </Stack>
        );
    }
}

export default injectIntl(Building);
