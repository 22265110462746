import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps } from 'react-intl';
import { PieChart, Pie, Cell,  Legend } from 'recharts';

import { API, APIKEY, } from './IVesta';


import {
  Stack, IStackStyles,
} from 'office-ui-fabric-react';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

type IState = {
  total: number,
  attachments: number,
  evidence: number,
}

interface IProps extends WrappedComponentProps {
  clientid: string,
  user: string,
  super?: boolean,
}
  

class ClientStorage extends Component<IProps,IState> {

    constructor(props: IProps) {
        super(props);


        this.state = {
          attachments: 0,
          evidence: 0,
          total: 10000000,
        };

    }


    componentDidMount()
    {
      this.refresh();
    }
  

   
    refresh()
    {
      
      const post  = { 
          security: {
            APIKey: APIKEY,
            User: this.props.user
          },
          ID: this.props.clientid,
      }
      var url = "/client/storage";
      if(this.props.super)
      {
        url = "/client/totalstorage";
      }

      axios.post(API + url,post)
        .then(response => {
            this.setState({ 
              attachments: response.data.attachments,
              evidence: response.data.evidence,
             });
        })
        .catch(function (error) {
          console.log(error);
        });
}





    render() {

        var divisor = 1024;
        var divisorI = "Kb";
        var max = this.state.evidence;
        if(this.state.attachments > max)  max = this.state.attachments;
        if(max > (1024*1024) )
        {
          if(max > (1024*1024*1024) )
          {
            divisor = 1024*1024*1024;
            divisorI = "Gb";
          }
          else
          {
            divisor = 1024*1024;
            divisorI = "Mb";
          }
        }

        const data = [
          { name: 'Evidence (' + divisorI + ')', value: Math.floor((this.state.evidence / divisor) * 100) / 100  },
          { name: 'Attachments (' + divisorI + ')', value: Math.floor((this.state.attachments / divisor) * 100) / 100 },
        ];
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
                


      return (
        <Stack grow styles={stackStyles}>

          <PieChart width={800} height={400} >
                  <Pie
                    data={data}
                    cx={320}
                    cy={200}
                    innerRadius={40}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend />
          </PieChart>

        </Stack>
      );
    };      
}

export default injectIntl(ClientStorage);