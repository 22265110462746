import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { API, APIKEY, IClient, IPagination } from "./IVesta";
import { Spinner } from "@fluentui/react/lib/Spinner";
import Client from "./Client";
import { Pagination } from "@uifabric/experiments";
import {
  DetailsList,
  IColumn,
  SelectionMode,
  Stack,
  IStackStyles,
  CommandBar,
  ICommandBarItemProps,
  Panel,
  PanelType,
  SearchBox,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Dropdown,
  IDropdownStyles,
  IDialogContentProps,
} from "office-ui-fabric-react";

const stackStyles: IStackStyles = {
  root: {
    width: "500",
  },
};

interface IState {
  user: string;
  selected: string;
  clients: IClient[];
  types: string[];
  type: number;
  typefind: string;
  isOpen: boolean;
  isCertOpen: boolean;
  selectedID: string;
  filter: string;
  showSuccess: boolean;
  undo: boolean;
  undoData: any;
  showError: boolean;
  message: string;
  pagination: IPagination;
  totalCount: number;
  contactData: any;
  showDialog: boolean;
  dialogContent: IDialogContentProps;
  dialogContext: string;
  isBusy: boolean;
  currentItem: any;
}

interface IProps extends WrappedComponentProps {
  client?: string;
}

class Clients extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      user: "",
      selected: "Undefined",
      clients: [],
      types: ["(Any)"],
      type: -1,
      typefind: "",
      isOpen: false,
      isCertOpen: false,
      selectedID: "",
      filter: "",
      showSuccess: false,
      undo: false,
      undoData: null,
      showError: false,
      message: "",
      pagination: {
        current: 0,
        pageSize: 10,
        sortField: "",
        sortOrder: "asc",
      },
      totalCount: 0,
      contactData: [],
      showDialog: false,
      dialogContent: {
        type: DialogType.largeHeader,
        title: "Confirm",
        subText: "put your sub text here...",
      },
      dialogContext: "delete",
      currentItem: null,
      isBusy: false,
    };

    this.dismissPanel = this.dismissPanel.bind(this);
    this.filter = this.filter.bind(this);
    this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    this.addNewClient = this.addNewClient.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.editClient = this.editClient.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.settype = this.settype.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.loadContactList = this.loadContactList.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.onDialogOkClick = this.onDialogOkClick.bind(this);
    this.mapContacts = this.mapContacts.bind(this);
    this.mapContact = this.mapContact.bind(this);
  }

  onPageChange(page: number) {
    var p: IPagination = Object.assign(this.state.pagination);
    p.current = page;
    this.setState({ pagination: p });
    this.authenticatedrefresh();
  }

  private editClient(item: IClient, index?: number) {
    this.setState({ isOpen: true, selectedID: item.id });
  }

  showDialog(context: string, item: any) {
    var content: IDialogContentProps;

    switch (context) {
      case "delete": {
        content = {
          type: DialogType.largeHeader,
          title: "Confirm Delete",
          subText: `Are you sure you want to delete ${item.name}`,
        };
        break;
      }

      default: {
        content = {
          type: DialogType.largeHeader,
          title: "Confirm",
          subText: "put your sub text here...",
        };
      }
    }

    this.setState({
      showDialog: true,
      currentItem: item,
      dialogContext: context,
      dialogContent: content,
    });
  }

  onDialogOkClick() {
    const context = this.state.dialogContext;

    switch (context) {
      case "delete": {
        this.deleteClient(this.state.currentItem.id);
        break;
      }

      default: {
        return;
      }
    }
  }

  deleteClient(id: string) {
    var $this = this;
    this.setState({ isBusy: true });

    const post = {
      security: {
        APIKey: APIKEY,
        User: this.state.user,
      },
      clientID: id,
    };

    axios
      .post(API + "/client/Recycle", post)
      .then((response) => {
        if (response.data.status.errorCode > 0) {
          this.showMessage(
            true,
            this.props.intl.formatMessage({ id: "client.delete.error" })
          );
        } else {
          this.showMessage(
            false,
            this.props.intl.formatMessage({ id: "client.delete.ok" })
          );
        }

        this.authenticatedrefresh();
        this.setState({ showDialog: false, isBusy: false });
      })
      .catch(function (error) {
        $this.authenticatedrefresh();
        $this.showMessage(
          true,
          $this.props.intl.formatMessage({ id: "client.delete.error" })
        );
        $this.setState({ showDialog: false, isBusy: false });
      });
  }

  private filter(event: any) {
    this.setState({ filter: event ? event.target.value : "" });
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    Auth.currentSession()
      .then((session: any) => {
        this.setState({ user: session.idToken.payload.email });
        this.authenticatedrefresh();
        this.loadContactList(this.state.user);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  authenticatedrefresh() {
    const params = this.state.pagination;

    const post = {
      security: {
        APIKey: APIKEY,
        User: this.state.user,
      },
      type: this.state.typefind,
      current: params.current + 1,
      pagesize: params.pageSize,
      sortfield: params.sortField,
      sortorder: params.sortOrder,
      name: this.state.filter,
    };

    axios
      .post(API + "/client/Search", post)
      .then((response) => {
        if (response.data.status.errorCode > 0) {
          this.showMessage(true, response.data.status.message);
        } else {
          this.setState({
            clients: response.data.clients,
            totalCount: response.data.totalCount,
            types: ["(Any)", ...response.data.clientTypes],
          });
          this.loadContactList(this.state.user);
          this.mapContacts(this.state.clients);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  mapContacts(clients: IClient[]) {
    for (var i = 0; i < clients.length; i++) {
      if (clients[i].contactInfo) {
        this.mapContact(clients[i]);
      }
    }
    this.setState({ clients: clients });
  }

  mapContact(client: IClient) {
    client.email = client.contactInfo.email;
    client.contact = client.contactInfo.name;
  }

  loadContactList(user: string) {
    const post = {
      security: {
        APIKey: APIKEY,
        User: user,
      },
      ParentID: "",
    };

    axios
      .post(API + "/contact/ContactList", post)
      .then((response) => {
        if (response.data.status.errorCode > 0) {
          this.showMessage(true, response.data.status.message);
        } else {
          this.setState({ ...this.state, contactData: response.data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {};

  private dismissPanel() {
    this.setState({ isOpen: false });
    this.authenticatedrefresh();
  }

  private addNewClient() {
    this.setState({ isOpen: true, selectedID: "" });
  }

  private showMessage(error: boolean, message: string) {
    if (error) this.setState({ showError: true, message: message });
    else this.setState({ showSuccess: true, message: message });
  }

  settype(ev: any, option?: any) {
    var index = parseInt(option.key || "0");
    this.setState({
      type: index - 1,
      typefind: index === 0 ? "" : option.text,
    });
  }

  private managedExternally(managed?: boolean): boolean {
    if (managed === null) return false;
    return managed ? managed : false;
  }

  render() {
    const { clients } = this.state;
    const _items: ICommandBarItemProps[] = [
      {
        key: "newItem",
        text: this.props.intl.formatMessage({ id: "client.new" }),
        cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
        iconProps: { iconName: "Add" },
        onClick: this.addNewClient,
      },
    ];
    const columns: IColumn[] = [
      {
        key: "name",
        name: this.props.intl.formatMessage({ id: "client.name" }),
        fieldName: "name",
        minWidth: 0,
        maxWidth: 0,
        currentWidth: 0,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "type",
        name: this.props.intl.formatMessage({ id: "client.type" }),
        fieldName: "type",
        minWidth: 150,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "contact",
        name: this.props.intl.formatMessage({ id: "client.contact" }),
        fieldName: "contact",
        minWidth: 150,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "phone",
        name: this.props.intl.formatMessage({ id: "client.phone" }),
        fieldName: "phone",
        minWidth: 150,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "email",
        name: this.props.intl.formatMessage({ id: "client.email" }),
        fieldName: "email",
        minWidth: 150,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "edit",
        name: "Edit",
        ariaLabel: "",
        isIconOnly: true,
        fieldName: "id",
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: any, index?: number) => {
          return (
            <IconButton
              iconProps={{ iconName: "Edit" }}
              onClick={() => this.editClient(item, index)}
              title="Edit"
              ariaLabel="Edit"
              disabled={this.managedExternally(item.managedExternally)}
            />
          );
        },
      },
      {
        key: "delete",
        name: "Delete",
        ariaLabel: "",
        isIconOnly: true,
        fieldName: "id",
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: any) => {
          // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
          return (
            <IconButton
              iconProps={{ iconName: "Delete" }}
              onClick={() => this.showDialog("delete", item)}
              title="Delete"
              ariaLabel="Delete"
              disabled={this.managedExternally(item.managedExternally)}
            />
          );
        },
      },
    ];

    var msg;
    if (this.state.showSuccess) {
      msg = (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => {
            this.setState({ showSuccess: false });
          }}
          dismissButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
        >
          {this.state.message}
        </MessageBar>
      );
      setTimeout(() => this.setState({ showSuccess: false }), 3000);
    }
    if (this.state.showError) {
      msg = (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => {
            this.setState({ showError: false });
          }}
          dismissButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
        >
          {this.state.message}
        </MessageBar>
      );
    }
    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdown: { width: 300 },
    };

    const types = this.state.types.map(function (
      element: string,
      index: number
    ) {
      return { key: index, text: element };
    });

    var pageCount =
      Math.floor(this.state.totalCount / this.state.pagination.pageSize) + 1;

    return (
      <Stack grow styles={stackStyles}>
        <Dialog
          hidden={!this.state.showDialog}
          dialogContentProps={this.state.dialogContent}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={this.onDialogOkClick}
              text={this.state.isBusy ? "" : "Yes"}
              disabled={this.state.isBusy}
            >
              <Spinner hidden={!this.state.isBusy} />
            </PrimaryButton>
            <DefaultButton
              onClick={() => this.setState({ showDialog: false })}
              text="No"
              disabled={this.state.isBusy}
            />
          </DialogFooter>
        </Dialog>
        {msg}
        <CommandBar
          items={_items}
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
        <Stack horizontal tokens={{ childrenGap: "6" }}>
          <Stack grow>
            <SearchBox
              placeholder="Filter Clients"
              iconProps={{ iconName: "Filter" }}
              onChange={this.filter}
              value={this.state.filter}
            />
            <Dropdown
              label={this.props.intl.formatMessage({ id: "client.type" })}
              options={types}
              styles={dropdownStyles}
              selectedKey={this.state.type + 1}
              onChange={this.settype}
            />
          </Stack>
          <Stack>
            <DefaultButton
              toggle
              text="Search"
              iconProps={{ iconName: "Search" }}
              onClick={this.authenticatedrefresh}
            />
          </Stack>
        </Stack>
        <Pagination
          selectedPageIndex={this.state.pagination.current}
          pageCount={pageCount}
          onPageChange={this.onPageChange}
          format="buttons"
          firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
          previousPageIconProps={{ iconName: "ChevronLeft" }}
          nextPageIconProps={{ iconName: "ChevronRight" }}
          lastPageIconProps={{ iconName: "DoubleChevronRight" }}
        />

        <DetailsList
          items={clients}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Panel
          isOpen={this.state.isOpen}
          onDismiss={this.dismissPanel}
          headerText={this.props.intl.formatMessage({ id: "client.client" })}
          closeButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
          type={PanelType.extraLarge}
        >
          <Client
            id={this.state.selectedID}
            onClose={this.dismissPanel}
            showMessage={this.showMessage}
            contactData={this.state.contactData}
          />
        </Panel>
      </Stack>
    );
  }
}

export default injectIntl(Clients);
