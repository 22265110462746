import React, { Component } from "react";
import axios from "axios";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles/certificate.css";

import {
    API,
    APIKEY,
    ICertificate,
    ICertificateTemplate,
    IClient,
} from "./IVesta";

import {
    DetailsList,
    IColumn,
    SelectionMode,
    Stack,
    IStackStyles,
    CommandBar,
    ICommandBarItemProps,
    DefaultButton,
    PrimaryButton,
    IconButton,
    Panel,
    PanelType,
    Dialog,
    DialogType,
    DialogFooter,
    Dropdown,
    IDropdownStyles,
    IDropdownOption,
    Toggle,
    MessageBar,
    MessageBarType,
} from "office-ui-fabric-react";

import Certificate from "./Certificate";
import ClientList from "./ClientList";
import Moment from "react-moment";

const stackStyles: IStackStyles = { root: { width: "500" } };

interface IState {
    selected: ICertificate;
    certificates: ICertificate[];
    isOpen: boolean;
    isClientOpen: boolean;
    isCertOpen: boolean;
    selectedID: string;
    dialogOpen: boolean;
    deleteOpen: boolean;
    moveOpen: boolean;
    deleteID: string;
    newTemplateID: string;
    newSourceID: string;
    templates: ICertificateTemplate[];
    answerCount: string;
    showExternal: boolean;
    showSuccess: boolean;
    showError: boolean;
    message: string;
    selectedClient?: IClient;
}

interface IProps extends WrappedComponentProps {
    unitCount?: number;
    buildingID?: string;
    clientID?: string;
    user: string;
    buildingName?: string;
}

class CertificatesList extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            selected: {} as ICertificate,
            certificates: [],
            isOpen: false,
            isClientOpen: false,
            isCertOpen: false,
            dialogOpen: false,
            deleteOpen: false,
            moveOpen: false,
            deleteID: "",
            selectedID: "",
            newTemplateID: "",
            newSourceID: "",
            answerCount: "UNKNOWN",
            templates: [],
            showExternal: false,
            showSuccess: false,
            showError: false,
            message: "",
        };

        this.dismissPanel = this.dismissPanel.bind(this);
        this._ItemChanged = this._ItemChanged.bind(this);
        this._onToggleDialog = this._onToggleDialog.bind(this);
        this.setnewtemplate = this.setnewtemplate.bind(this);
        this.setnewsource = this.setnewsource.bind(this);
        this.addNewCertificate = this.addNewCertificate.bind(this);
        this.onCreateNewCertificate = this.onCreateNewCertificate.bind(this);
        this.refreshtemplates = this.refreshtemplates.bind(this);
        this.deleteCertificate = this.deleteCertificate.bind(this);
        this.onDeleteCertificate = this.onDeleteCertificate.bind(this);
        this._onToggleDelete = this._onToggleDelete.bind(this);
        this._onToggleMove = this._onToggleMove.bind(this);
        this.doMove = this.doMove.bind(this);
        this.onMoveCertificate = this.onMoveCertificate.bind(this);
        this.selectDestClient = this.selectDestClient.bind(this);
        this.openSelectDestClient = this.openSelectDestClient.bind(this);
        this.toggleExternal = this.toggleExternal.bind(this);
        this.sendEmailForIssue = this.sendEmailForIssue.bind(this);
        this.showMessage = this.showMessage.bind(this);
    }

    public toggleExternal() {
        var val = !this.state.showExternal;
        this.setState({ showExternal: val });
        this.refresh(val);
    }

    public sendEmailForIssue(certificateId: string) {
        var post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            clientId: this.props.clientID as string | null,
            buildingId: this.props.buildingID as string | null,
            certificateId: certificateId as string | null,
        };

        axios
            .post(API + "/certificate/SendEmailForIssue", post)
            .then(() => {
                this.refresh(this.state.showExternal);
                this.showMessage(
                    false,
                    this.props.intl.formatMessage({
                        id: "certificate.issue.sent.email.success",
                    })
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    private showMessage(error: boolean, message: string) {
        if (error) this.setState({ showError: true, message: message });
        else this.setState({ showSuccess: true, message: message });
    }

    public setnewsource(event: any, option?: any, index?: number) {
        this.setState({ newSourceID: this.state.certificates[index!].id || "" });
    }

    public setnewtemplate(event: any, option?: any, index?: number) {
        this.setState({ newTemplateID: this.state.templates[index!].id || "" });
    }

    public addNewCertificate() {
        this.setState({ dialogOpen: true, newTemplateID: "" });
    }

    public onCreateNewCertificate() {
        var post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            templateID: this.state.newTemplateID,
            buildingID: "" as string | null,
            clientID: "" as string | null,
            sourceID: this.state.newSourceID,
        };
        if (this.props.buildingID != null) {
            post.buildingID = this.props.buildingID || "";
            post.clientID = null;
        } else {
            post.buildingID = null;
            post.clientID = this.props.clientID || "";
        }
        axios
            .post(API + "/certificate/create", post)
            .then(() => {
                this.setState({ dialogOpen: false, newTemplateID: "" });
                this.refresh(this.state.showExternal);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _onToggleDialog() {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    }

    _onToggleDelete() {
        this.setState({ deleteOpen: !this.state.deleteOpen });
    }

    componentDidMount() {
        this.refresh(this.state.showExternal);
        this.refreshtemplates();
    }

    refresh(showExternal: boolean) {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            buildingID: this.props.buildingID != null ? this.props.buildingID : null,
            clientID: !this.props.buildingID != null ? this.props.clientID : null,
            showExternal: showExternal,
        };
        axios
            .post(API + "/certificate/search", post)
            .then((response) => {
                this.setState({ certificates: response.data.certificates });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    refreshtemplates() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
        };
        axios
            .post(API + "/certificate/template/search", post)
            .then((response) => {
                this.setState({ templates: response.data.templates });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    private _onColumnClick = (): void => { };

    private _ItemChanged = (item: any): void => {
        const id = item.id;
        this.setState({
            isOpen: true,
            selectedID: id,
        });
    };

    private dismissPanel() {
        this.setState({ isOpen: false, isCertOpen: false });
    }

    private viewCertificate(item: ICertificate) {
        this.setState({ isOpen: true, selected: item });
    }

    private doMove(item: ICertificate) {
        this.setState({ moveOpen: true, selected: item });
    }

    _onToggleMove() {
        this.setState({ moveOpen: !this.state.moveOpen });
    }

    public openSelectDestClient() {
        this.setState({ isClientOpen: true });
    }

    public selectDestClient(client: IClient) {
        this.setState({ isClientOpen: false, selectedClient: client });
    }

    onMoveCertificate() {
        if (this.state.selected == null) return;
        if (this.state.selectedClient === undefined) return;

        var post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ID: this.state.selected.id,
            ClientID: this.state.selectedClient.id,
        };
        axios
            .post(API + "/certificate/reassign", post)
            .then(() => {
                this.setState({ moveOpen: false, selectedClient: undefined });
                this.refresh(this.state.showExternal);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    deleteCertificate(i: ICertificate) {
        var answerCount: number = 0;
        var questionCount: number = 0;

        i.surveys.forEach((survey) => {
            survey.survey.sections.forEach((section) => {
                questionCount += section.questions.length;
            });
            if (survey.answers != null) {
                answerCount += survey.answers.length;
            }
        });

        var a =
            "You will be deleting " + answerCount + "/" + questionCount + " answers";
        if (answerCount === 0)
            a = "None of the " + questionCount + " questions has been answered.";

        this.setState({ deleteOpen: true, answerCount: a, deleteID: i.id });
    }

    onDeleteCertificate() {
        var post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ID: this.state.deleteID,
        };
        axios
            .post(API + "/certificate/delete", post)
            .then(() => {
                this.setState({ deleteOpen: false, deleteID: "" });
                this.refresh(this.state.showExternal);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const cCertificateColumns: IColumn[] = [
            {
                key: "name",
                name: this.props.intl.formatMessage({ id: "certificate.name" }),
                fieldName: "name",
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                // onColumnClick: this._onColumnClick,
                onRender: (item: ICertificate) => {
                    return (
                        <div className="certificate-list-name">
                            <div>{item.name}</div>
                            {item.issued ? null : (
                                <div>
                                    <DefaultButton
                                        onClick={() => this.sendEmailForIssue(item.id)}
                                        text={item.inviteIssued ?
                                            this.props.intl.formatMessage({ id: "certificate.resend" }) :
                                            this.props.intl.formatMessage({ id: "certificate.issue" })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
                data: "string",
                isPadded: true,
            },
            {
                key: "approvedon",
                name: this.props.intl.formatMessage({ id: "certificate.issuedon" }),
                fieldName: "approvedOn",
                minWidth: 140,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: ICertificate) => {
                    if (item.inviteIssued)
                        return (
                            <Moment format="MMM DD YYYY h:mm A">{item.inviteIssuedOn}</Moment>
                        );
                    return <>NA</>;
                },
            },
            {
                key: "expireson",
                name: this.props.intl.formatMessage({ id: "certificate.expireson" }),
                fieldName: "expiresOn",
                minWidth: 140,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: ICertificate) => {
                    if (item.inviteIssued && item.inviteExpiresOn)
                        return (
                            <Moment format="MMM DD YYYY h:mm A">
                                {item.inviteExpiresOn}
                            </Moment>
                        );
                    return <>NA</>;
                },
            },
            {
                key: "view",
                name: "View",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                    return (
                        <IconButton
                            iconProps={{ iconName: "View" }}
                            onClick={() => this.viewCertificate(item)}
                            title="Edit"
                            ariaLabel="Edit"
                        />
                    );
                },
            },
            {
                key: "reassign",
                name: "Reassign",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                    if (item.issued) return null;
                    return (
                        <IconButton
                            iconProps={{ iconName: "Tag" }}
                            onClick={() => this.doMove(item)}
                            title="Reassign"
                            ariaLabel="Reassign"
                        />
                    );
                },
            },
            {
                key: "delete",
                name: "Delete",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                    if (item.issued) return null;
                    return (
                        <IconButton
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => this.deleteCertificate(item)}
                            title="Delete"
                            ariaLabel="Delete"
                        />
                    );
                },
            },
        ];

        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
        };

        const alltemplates: IDropdownOption[] = this.state.templates.map(function (
            element: any
        ) {
            return { key: element.id, text: element.name };
        });

        const allcerts: IDropdownOption[] = this.state.certificates.map(function (
            element: any
        ) {
            return { key: element.id, text: element.name };
        });

        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: this.props.intl.formatMessage({
                id: "certificate.add.dialog.title",
            }),
            subText: this.props.intl.formatMessage({
                id: "certificate.add.dialog.text",
            }),
        };

        const deleteContentProps = {
            type: DialogType.largeHeader,
            title: this.props.intl.formatMessage({
                id: "certificate.delete.dialog.title",
            }),
            subText: this.props.intl.formatMessage({
                id: "certificate.delete.dialog.text",
            }),
        };

        const moveContentProps = {
            type: DialogType.largeHeader,
            title: this.props.intl.formatMessage({
                id: "certificate.move.dialog.title",
            }),
            subText: this.props.intl.formatMessage({
                id: "certificate.move.dialog.text",
            }),
        };

        const modalProps = {
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        };

        var msg;
        if (this.state.showSuccess) {
            msg = (
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => {
                        this.setState({ showSuccess: false });
                    }}
                    dismissButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                >
                    {this.state.message}
                </MessageBar>
            );
            setTimeout(() => this.setState({ showSuccess: false }), 3000);
        }
        if (this.state.showError) {
            msg = (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={() => {
                        this.setState({ showError: false });
                    }}
                    dismissButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                >
                    {this.state.message}
                </MessageBar>
            );
        }

        return (
            <>
                {(this.props.unitCount !== undefined && this.props.unitCount < 1 && (
                    <Stack horizontal styles={stackStyles}>
                        <Stack>
                            <p className="lead">
                                Please indicate the number of units required for this
                                certificate
                            </p>
                        </Stack>
                    </Stack>
                )) || (
                        <Stack grow styles={stackStyles}>
                            {msg}
                            <Stack
                                style={{ paddingTop: 5 }}
                                tokens={{ childrenGap: 10 }}
                                horizontal
                                grow
                                verticalAlign="center"
                            >
                                <Toggle
                                    onClick={this.toggleExternal}
                                    checked={this.state.showExternal}
                                    inlineLabel
                                    label={this.props.intl.formatMessage({
                                        id: "certificate.showexternal",
                                    })}
                                />
                                <DefaultButton
                                    onClick={() => {
                                        this.addNewCertificate();
                                    }}
                                    iconProps={{ iconName: "add" }}
                                    text={this.props.intl.formatMessage({
                                        id: "certificate.add.certificate",
                                    })}
                                />
                            </Stack>
                            <DetailsList
                                items={this.state.certificates}
                                columns={cCertificateColumns}
                                selectionMode={SelectionMode.none}
                            />
                            <Dialog
                                hidden={!this.state.dialogOpen}
                                onDismiss={this._onToggleDialog}
                                dialogContentProps={dialogContentProps}
                                modalProps={modalProps}
                            >
                                <Dropdown
                                    placeholder={this.props.intl.formatMessage({
                                        id: "certificate.select.template",
                                    })}
                                    label={this.props.intl.formatMessage({
                                        id: "certificate.templates",
                                    })}
                                    options={alltemplates}
                                    styles={dropdownStyles}
                                    selectedKey={this.state.newTemplateID}
                                    onChange={this.setnewtemplate}
                                />

                                <Dropdown
                                    placeholder={this.props.intl.formatMessage({
                                        id: "certificate.copyfrom.placeholder",
                                    })}
                                    label={this.props.intl.formatMessage({
                                        id: "certificate.copyfrom",
                                    })}
                                    options={allcerts}
                                    styles={dropdownStyles}
                                    selectedKey={this.state.newSourceID}
                                    onChange={this.setnewsource}
                                />

                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={this.onCreateNewCertificate}
                                        text={this.props.intl.formatMessage({ id: "std.add" })}
                                    />
                                    <DefaultButton
                                        onClick={this._onToggleDialog}
                                        text={this.props.intl.formatMessage({ id: "std.cancel" })}
                                    />
                                </DialogFooter>
                            </Dialog>
                            <Dialog
                                hidden={!this.state.deleteOpen}
                                onDismiss={this._onToggleDelete}
                                dialogContentProps={deleteContentProps}
                                modalProps={modalProps}
                            >
                                {this.state.answerCount}
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onDeleteCertificate();
                                        }}
                                        text={this.props.intl.formatMessage({ id: "std.delete" })}
                                    />
                                    <DefaultButton
                                        onClick={this._onToggleDelete}
                                        text={this.props.intl.formatMessage({ id: "std.cancel" })}
                                    />
                                </DialogFooter>
                            </Dialog>

                            <Dialog
                                hidden={!this.state.moveOpen}
                                onDismiss={this._onToggleMove}
                                dialogContentProps={moveContentProps}
                                modalProps={modalProps}
                            >
                                <PrimaryButton
                                    onClick={this.openSelectDestClient}
                                    text={this.props.intl.formatMessage({
                                        id: "certificate.pick.client",
                                    })}
                                />
                                <br />
                                <br />
                                {this.state.selectedClient == null
                                    ? "Please select..."
                                    : this.state.selectedClient.name}
                                <DialogFooter>
                                    <PrimaryButton
                                        disabled={this.state.selectedClient == null}
                                        onClick={() => {
                                            this.onMoveCertificate();
                                        }}
                                        text={this.props.intl.formatMessage({ id: "std.move" })}
                                    />
                                    <DefaultButton
                                        onClick={this._onToggleMove}
                                        text={this.props.intl.formatMessage({ id: "std.cancel" })}
                                    />
                                </DialogFooter>
                            </Dialog>

                            <Panel
                                isOpen={this.state.isOpen}
                                onDismiss={this.dismissPanel}
                                headerText={`${this.props.buildingName} / ${this.state.selected.name}`}
                                closeButtonAriaLabel={this.props.intl.formatMessage({
                                    id: "aria.close",
                                })}
                                type={PanelType.large}
                            >
                                <Certificate id={this.state.selected.id} user={this.props.user} buildingName={this.props.buildingName} />
                            </Panel>

                            <Panel
                                isOpen={this.state.isClientOpen}
                                onDismiss={this.dismissPanel}
                                headerText={`${this.props.buildingName} / ${this.state.selected.name}`}
                                closeButtonAriaLabel={this.props.intl.formatMessage({
                                    id: "aria.close",
                                })}
                                type={PanelType.large}
                            >
                                <ClientList
                                    clientSelected={this.selectDestClient}
                                    user={this.props.user}
                                />
                            </Panel>
                        </Stack>
                    )}
            </>
        );
    }
}

export default injectIntl(CertificatesList);
