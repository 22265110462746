import React, { Component } from "react";
import axios from "axios";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { API, APIKEY, IBuilding, IPagination } from "./IVesta";
import Building from "./Building";
import CertificatesList from "./CertificatesList";
import UD from "./Ud";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { Pagination } from "@uifabric/experiments";
import {
    DetailsList,
    IColumn,
    SelectionMode,
    Stack,
    IStackStyles,
    CommandBar,
    ICommandBarItemProps,
    IconButton,
    PrimaryButton,
    DefaultButton,
    Text,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    IDialogContentProps,
    MessageBar,
    MessageBarType,
    SearchBox,
} from "office-ui-fabric-react";
import Moment from "react-moment";

const stackStyles: IStackStyles = {
    root: {
        width: "500",
    },
};

type IState = {
    selected: IBuilding;
    buildings: IBuilding[];
    isOpen: boolean;
    isCertOpen: boolean;
    filter: string;
    showDialog: boolean;
    dialogContent: IDialogContentProps;
    dialogContext: string;
    isBusy: boolean;
    isLoading: boolean;
    currentItem: any;
    showSuccess: boolean;
    showError: boolean;
    message: string;
    pagination: IPagination;
};

interface IProps extends WrappedComponentProps {
    client: string;
    user: string;
    addBuilding: any;
    showMessage: any;
    forUser?: boolean;
    contactData: any;
}

class BuildingsList extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            selected: {} as IBuilding,
            buildings: [],
            isOpen: false,
            isCertOpen: false,
            filter: "",
            showDialog: false,
            dialogContent: {
                type: DialogType.largeHeader,
                title: "Confirm",
                subText: "put your sub text here...",
            },
            dialogContext: "delete",
            currentItem: null,
            isBusy: false,
            isLoading: false,
            showSuccess: false,
            showError: false,
            message: "",
            pagination: {
                current: 0,
                pageSize: 10,
                sortField: "",
                sortOrder: "asc",
            },
        };

        this.dismissPanel = this.dismissPanel.bind(this);
        this.addNewBuilding = this.addNewBuilding.bind(this);
        this.deleteBuilding = this.deleteBuilding.bind(this);
        this.editBuilding = this.editBuilding.bind(this);
        this.savebuilding = this.savebuilding.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.onDialogOkClick = this.onDialogOkClick.bind(this);
        this.mapContacts = this.mapContacts.bind(this);
        this.mapContact = this.mapContact.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.filter = this.filter.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        // this.mapAssessors = this.mapAssessors.bind(this);
        // this.mapAssessor = this.mapAssessor.bind(this);
    }


    componentDidMount() {
        this.refresh();
    }

    onPageChange(page: number) {
        var p: IPagination = Object.assign(this.state.pagination);
        p.current = page;
        this.setState({ pagination: p });
        // this.refresh();
    }

    public filterval: string = "";

    private filter(event: any) {
        this.setState({ filter: event ? event.target.value : "" });
    }

    private showMessage(error: boolean, message: string) {
        if (error) this.setState({ showError: true, message: message });
        else this.setState({ showSuccess: true, message: message });
    }

    refresh() {
        const params = this.state.pagination;
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ClientID: this.props.client,
            filter: this.state.filter,
            // current: params.current + 1,
            // pagesize: params.pageSize,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
        };

        var url = "/building/Search";
        if (this.props.forUser) {
            url = "/building/forUser";
        }

        this.setState({
            isLoading: true,
        });

        axios
            .post(API + url, post)
            .then((response) => {
                this.setState({
                    buildings: response.data.buildings,
                    isLoading: false,
                });
                this.mapContacts(this.state.buildings);
                // this.mapAssessors(this.state.buildings);
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }

    mapContacts(buildings: IBuilding[]) {
        for (var i = 0; i < buildings.length; i++) {
            if (buildings[i].contactInfo) {
                this.mapContact(buildings[i]);
            }
        }
        this.setState({ buildings: buildings });
    }

    mapContact(building: IBuilding) {
        building.manager = building.contactInfo.email;
        building.managerName = building.contactInfo.name;
    }

    // mapAssessors(buildings: IBuilding[]) {
    //   for (var i = 0; i < buildings.length; i++) {
    //     if (buildings[i].assessorInfo) {
    //       this.mapAssessor(buildings[i]);
    //     }
    //   }
    //   this.setState({ buildings: buildings });
    // }

    // mapAssessor(building: IBuilding) {
    //   building.assessorInfo = building.assessorInfo;
    // }

    onDialogOkClick() {
        const context = this.state.dialogContext;

        switch (context) {
            case "delete": {
                this.deleteBuilding(this.state.currentItem.id);
                break;
            }

            default: {
                return;
            }
        }
    }

    showDialog(context: string, item: any) {
        var content: IDialogContentProps;

        switch (context) {
            case "delete": {
                content = {
                    type: DialogType.largeHeader,
                    title: "Confirm Delete",
                    subText: `Are you sure you want to delete ${item.buildingName}`,
                };
                break;
            }

            default: {
                content = {
                    type: DialogType.largeHeader,
                    title: "Confirm",
                    subText: "put your sub text here...",
                };
            }
        }

        this.setState({
            showDialog: true,
            currentItem: item,
            dialogContext: context,
            dialogContent: content,
        });
    }

    deleteBuilding(id: string) {
        var $this = this;
        this.setState({ isBusy: true });

        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },

            buildingID: id,
        };

        axios
            .post(API + "/building/Recycle", post)
            .then((response) => {
                if (response.data.status.errorCode > 0) {
                    this.props.showMessage(
                        true,
                        this.props.intl.formatMessage({ id: "client.delete.error" })
                    );
                } else {
                    this.props.showMessage(
                        false,
                        this.props.intl.formatMessage({ id: "client.delete.ok" })
                    );
                }

                this.refresh();
                this.setState({ showDialog: false, isBusy: false });
            })
            .catch(function (error) {
                $this.refresh();
                $this.props.showMessage(
                    true,
                    $this.props.intl.formatMessage({ id: "client.delete.error" })
                );
                $this.setState({ showDialog: false, isBusy: false });
            });
    }

    private editBuilding(item: IBuilding) {
        this.setState({ isOpen: true, selected: item });
    }

    private _onColumnClick = (): void => { };
    private dismissPanel() {
        this.setState({ isOpen: false });
    }

    addNewBuilding() {
        this.setState({
            selected: {
                address1: "",
                address2: "",
                city: "",
                postcode: "",
                manager: "",
                unitCount: 0,
                clientID: this.props.client,
            } as IBuilding,
            isOpen: true,
        });
    }

    savebuilding(b: IBuilding) {
        b.clientID = this.props.client;
        const post = {
            Security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            building: b,
            clientid: this.props.client,
        };
        var func = "update";
        if (b.id === "") func = "create";
        if (b.id == null) func = "create";

        if (func === "create") {
            b.id = "new";
        }

        axios
            .post(API + "/building/" + func, post)
            .then((response) => {
                if (response.data.status.errorCode !== 0) {
                    this.props.showMessage(true, response.data.status.message);
                    return;
                }
                this.refresh();
                // this.setState({ isOpen: false });
                // this.props.showMessage(
                //   false,
                //   this.props.intl.formatMessage({ id: "building.saved" })
                // );
                this.showMessage(
                    false,
                    this.props.intl.formatMessage({
                        id: "building.saved",
                    })
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    private managedExternally(managed?: boolean): boolean {
        if (managed === null) return false;
        return managed ? managed : false;
    }

    render() {
        const _items: ICommandBarItemProps[] = [
            {
                key: "newItem",
                text: this.props.intl.formatMessage({ id: "building.add" }),
                cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
                iconProps: { iconName: "Add" },
                onClick: this.addNewBuilding,
            },
        ];

        const additionalColumn: IColumn[] = this.props.forUser
            ? [
                {
                    key: "clientName",
                    name: this.props.intl.formatMessage({ id: "building.client.name" }),
                    fieldName: "clientName",
                    minWidth: 100,
                    maxWidth: 250,
                    isRowHeader: true,
                    isResizable: true,
                    data: "string",
                    isPadded: true,
                    onRender: (item: any) => {
                        if (!this.props.forUser) return "";
                        return item.client == null ? "" : item.client.name;
                    },
                },
            ]
            : [];

        const baseColumns: IColumn[] = [
            {
                key: "address",
                name: this.props.intl.formatMessage({ id: "building.building.name" }),
                fieldName: "address",
                minWidth: 150,
                maxWidth: 400,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IBuilding) => {
                    var r = item.buildingName;
                    if (item.city !== "") {
                        r += (r !== "" ? ", " : "") + item.city;
                    }
                    if (item.country !== "") {
                        r += (r !== "" ? ", " : "") + item.country;
                    }
                    return r;
                },
            },
            {
                key: "certificateName",
                name: this.props.intl.formatMessage({
                    id: "building.certificate.name",
                }),
                fieldName: "certificateName",
                minWidth: 100,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: any) => {
                    return item.certificate == null ? "" : item.certificate.name;
                },
            },
            {
                key: "status",
                name: this.props.intl.formatMessage({
                    id: "building.certificate.status",
                }),
                fieldName: "status",
                minWidth: 0,
                maxWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IBuilding) => {
                    return getStatus(item);

                },
            },
            {
                key: "newissueddate",
                name: this.props.intl.formatMessage({
                    id: "building.certificate.newissueddate",
                }),
                fieldName: "newissueddate",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IBuilding) => {
                    if (item.certificate && item.certificate.inviteIssued)
                        return (
                            <Moment format="MMM DD YYYY h:mm A">
                                {item.certificate.inviteIssuedOn}
                            </Moment>
                        );
                    return <>NA</>;
                },
            },
            {
                key: "certifieddate",
                name: this.props.intl.formatMessage({
                    id: "building.certificate.certifieddate",
                }),
                fieldName: "certifieddate",
                minWidth: 150,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IBuilding) => {
                    if (item.certificate && item.certificate.issued)
                        return (
                            <Moment format="MMM DD YYYY h:mm A">
                                {item.certificate.issuedOn}
                            </Moment>
                        );
                    return <>NA</>;
                },
            },
            {
                key: "assessorInfo",
                name: this.props.intl.formatMessage({ id: "building.assessor" }),
                fieldName: "assessorInfo",
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: any) => {
                    var assInfo = item.assessorInfo;
                    if (assInfo !== null && assInfo !== undefined) {
                        return assInfo.email;
                    } else {
                        return "N/A";
                    }
                },
            },
            {
                key: "units",
                name: this.props.intl.formatMessage({ id: "building.units" }),
                fieldName: "unitCount",
                minWidth: 50,
                maxWidth: 50,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
            },

            {
                key: "myocorporate",
                name: this.props.intl.formatMessage({ id: "building.myocorporate" }),
                fieldName: "myocorporate",
                minWidth: 80,
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: any) => {
                    if (item.certificate && item.certificate.issued)
                        return (
                            <IconButton
                                iconProps={{ iconName: "CheckMark" }}
                                title="myocorporate"
                                ariaLabel="myocorporate"
                                className="tick"
                            />
                        )
                },
            },
            {
                key: "edit",
                name: "Edit",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                    return (
                        <IconButton
                            iconProps={{ iconName: "Edit" }}
                            onClick={() => this.editBuilding(item)}
                            title="Edit"
                            ariaLabel="Edit"
                            disabled={this.managedExternally(item.managedExternally)}
                        />
                    );
                },
            },
            {
                key: "delete",
                name: "Delete",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                    if (this.props.forUser) return "";
                    else if (item.certificate && item.certificate.issued) return null
                    return (
                        <IconButton
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => this.showDialog("delete", item)}
                            title="Delete"
                            ariaLabel="Delete"
                            disabled={this.managedExternally(item.managedExternally)}
                        />
                    );
                },
            },
        ];

        const columns: IColumn[] = [...additionalColumn, ...baseColumns];

        var title;
        if (this.props.forUser)
            title = this.props.intl.formatMessage({ id: "building.my.buildings" });
        else title = "";

        var commandBar = <></>;
        // Add Property button hide
        // if (!this.props.forUser) {
        //   commandBar = <CommandBar items={_items} />;
        // }

        var msg;
        if (this.state.showSuccess) {
            msg = (
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => {
                        this.setState({ showSuccess: false });
                    }}
                    dismissButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                >
                    {this.state.message}
                </MessageBar>
            );
            setTimeout(() => this.setState({ showSuccess: false }), 3000);
        }
        if (this.state.showError) {
            msg = (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={() => {
                        this.setState({ showError: false });
                    }}
                    dismissButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                >
                    {this.state.message}
                </MessageBar>
            );
        }

        const totalPages = Math.ceil(
            this.state.buildings.length / this.state.pagination.pageSize
        );
        const statusOrder: { [key: string]: number } = {
            "Submitted": 1,
            "In Progress": 2,
            "Certified": 3,
            "Unknown": 4,
        };
        const getStatus = (item: IBuilding) => {
            const certificate = item && item.certificate ? item.certificate : null;
            const surveys = certificate && certificate.surveys ? certificate.surveys : null;
            const isIssued = certificate && certificate.issued === true;
            const isInviteIssued = certificate && certificate.inviteIssued == true;


            const allSurveysApprovedOrCompleted = surveys && surveys.every(
                survey => survey.isApproved === true || survey.isCompleted === true
            );

            if (isIssued) {
                return "Certified";
            } else if (allSurveysApprovedOrCompleted) {
                return "Submitted";
            } else if (isInviteIssued) {
                return "In Progress";
            }

        };

        const sortByStatus = (a: IBuilding, b: IBuilding) => {


            const statusA = getStatus(a) || "Unknown";
            const statusB = getStatus(b) || "Unknown";

            return statusOrder[statusA] - statusOrder[statusB];
        };

        const sortedBuildings = [...this.state.buildings].sort(sortByStatus);

        const paginateData = () => {
            const startIndex =
                this.state.pagination.current * this.state.pagination.pageSize;
            const endIndex = startIndex + this.state.pagination.pageSize;

            //  return this.state.buildings.slice(startIndex, endIndex);
            return sortedBuildings.slice(startIndex, endIndex);
        };

        const paginatedData = paginateData();


        return (
            <Stack grow styles={stackStyles}>
                <Dialog
                    hidden={!this.state.showDialog}
                    dialogContentProps={this.state.dialogContent}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } },
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={this.onDialogOkClick}
                            text={this.state.isBusy ? "" : "Yes"}
                            disabled={this.state.isBusy}
                        >
                            <Spinner hidden={!this.state.isBusy} />
                        </PrimaryButton>
                        <DefaultButton
                            onClick={() => this.setState({ showDialog: false })}
                            text="No"
                            disabled={this.state.isBusy}
                        />
                    </DialogFooter>
                </Dialog>

                <Stack horizontal grow verticalAlign="center">
                    <Text variant="xLarge">{title}</Text>
                    <Stack grow>{commandBar}</Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: "6" }}>
                    <Stack grow>
                        <SearchBox
                            placeholder="Filter Client, Property, City, or Country"
                            iconProps={{ iconName: "Filter" }}
                            onChange={this.filter}
                            value={this.state.filter}
                        />
                    </Stack>
                    <Stack>
                        <DefaultButton
                            toggle
                            text="Search"
                            iconProps={{ iconName: "Search" }}
                            onClick={this.refresh}
                        />
                    </Stack>
                </Stack>

                <>
                    {this.state.isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <Pagination
                                selectedPageIndex={this.state.pagination.current}
                                pageCount={totalPages}
                                onPageChange={this.onPageChange}
                                format="buttons"
                                firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
                                previousPageIconProps={{ iconName: "ChevronLeft" }}
                                nextPageIconProps={{ iconName: "ChevronRight" }}
                                lastPageIconProps={{ iconName: "DoubleChevronRight" }}
                            />
                            <DetailsList
                                // items={this.state.buildings}
                                items={paginatedData}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                            />
                        </>
                    )}
                </>

                <Panel
                    isOpen={this.state.isOpen}
                    onDismiss={this.dismissPanel}
                    headerText={this.state.selected.buildingName}
                    closeButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                    type={PanelType.large}
                >
                    <Pivot>
                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "building.building",
                            })}
                        >
                            {msg}
                            <Building
                                user={this.props.user}
                                building={this.state.selected}
                                buildingId={
                                    (this.state.selected && this.state.selected.id) || ""
                                }
                                contactData={this.props.contactData}
                                onSave={this.savebuilding}
                                onClose={this.dismissPanel}
                            />
                        </PivotItem>

                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "building.certificates",
                            })}
                        >
                            <CertificatesList
                                user={this.props.user}
                                unitCount={this.state.selected.unitCount || 0}
                                buildingID={this.state.selected.id || ""}
                                buildingName={this.state.selected.buildingName || ""}
                            />
                        </PivotItem>

                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "building.attachments",
                            })}
                        ></PivotItem>

                        <PivotItem
                            headerText={this.props.intl.formatMessage({ id: "building.ud" })}
                        >
                            <UD user={this.props.user} ud={this.state.selected.ud} />
                        </PivotItem>
                    </Pivot>
                </Panel>
            </Stack>
        );
    }
}

export default injectIntl(BuildingsList);
