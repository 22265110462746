import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Moment from 'react-moment';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';


import { API, APIKEY, ICertificateSurvey, IMessage, ICertificate } from './IVesta'

import { initializeIcons } from '@uifabric/icons';

import {
    DetailsList,
    SelectionMode,
    IColumn,
    Stack, IStackStyles, Text,
    Panel, PanelType,
    IconButton,
} from 'office-ui-fabric-react';


import Survey from './Survey';
import Message from './Message';
import Certificate from './Certificate';


const stackStyles: IStackStyles = {
    root: {
        width: "500"
    },
};


interface Survey {
    name: string,
    description: string,
    validfor: bigint
}

interface IState {
    user: string,
    selected: any,
    surveys: ICertificateSurvey[],
    certificates: ICertificate[],
    messages: IMessage[],
    message: IMessage,
    isEditOpen: boolean,
    isOpen: boolean,
    canCreate: boolean,
    open: boolean,
}

interface IProps extends WrappedComponentProps {
    client: string
}

class ToDo extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        initializeIcons();


        this.state = {
            user: "",
            selected: "Undefined",
            surveys: [],
            certificates: [],
            messages: [],
            message: {} as IMessage,
            isEditOpen: false,
            isOpen: false,
            open: false,
            canCreate: true,
        };

        this.onEdit = this.onEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.onClose = this.onClose.bind(this);
        this._MessageChanged = this._MessageChanged.bind(this);
        this.viewCertificate = this.viewCertificate.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
    }

    private dismissPanel() {
        this.setState({ isOpen: false });
    }

    private viewCertificate(item: ICertificate) {
        this.setState({ isOpen: true, selected: item });
    }


    private onClose() {
        this.setState({
            open: false,
        })
    }

    private onEdit() {
        this.setState({
            isEditOpen: true,
        })
    }

    private _ItemChanged = (item: any): void => {
        this.setState({
            isEditOpen: true,
            selected: item
        });
    };

    private _MessageChanged = (item: IMessage): void => {
        this.setState({
            open: true,
            message: item
        });
    };

    private closeEdit() {

        this.setState({
            isEditOpen: false,
        })
    }


    componentDidMount() {
        this.refresh();
    }

    refresh() {
        Auth.currentSession()
            .then((session: any) => {
                this.setState({ user: session.idToken.payload.email });
                this.authenticatedrefresh(session.idToken.payload.email);
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    authenticatedrefresh(u: string) {

        const post = {
            security: {
                APIKey: APIKEY,
                User: u
            },
        }
        axios.post(API + "/todo/user", post)
            .then(response => {
                this.setState({
                    user: u,
                    surveys: response.data.surveys,
                    messages: response.data.messages,
                    certificates: response.data.certificates,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    private _onColumnClick = (): void => {
    };

    onUpdate() {

    }

    render() {

        const msgColumns: IColumn[] = [
            {
                key: 'column1',
                name: 'From',
                fieldName: 'managerFrom',
                minWidth: 100,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: this.props.intl.formatMessage({ id: 'message.subject' }),
                fieldName: 'subject',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: this.props.intl.formatMessage({ id: 'message.datesent' }),
                fieldName: 'dateSent',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IMessage) => {
                    return <Moment format="ddd DD MMM YYYY HH:mm">{item.dateSent}</Moment>;
                },
            },
        ];

        const cCertificateColumns: IColumn[] = [
            {
                key: 'name',
                name: this.props.intl.formatMessage({ id: 'certificate.name' }),
                fieldName: 'name',
                minWidth: 150,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'building',
                name: this.props.intl.formatMessage({ id: 'certificate.building' }),
                fieldName: 'building',
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                isPadded: true,
                onRender: (item: ICertificate) => {
                    if (item && item.building && item.building.address1 && item.building.address2) {
                        return item.building.address1 + ", " + item.building.address2;
                    } else if (item && item.building && item.building.address1) {
                        return item.building.address1;
                    } else if (item && item.building && item.building.address2) {
                        return item.building.address2;
                    } else {
                        return "";
                    }
                },
            },
            {
                key: 'expireson',
                name: this.props.intl.formatMessage({ id: 'certificate.expireson' }),
                fieldName: 'expiresOn',
                minWidth: 110,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: any) => {
                    return <Moment format="ddd DD MMM YYYY">{item.expiresOn}</Moment>
                },
            },
            {
                key: 'view',
                name: this.props.intl.formatMessage({ id: 'column.view' }),
                ariaLabel: '',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                    return <IconButton iconProps={{ iconName: 'View' }} onClick={() => this.viewCertificate(item)} title="Edit" ariaLabel="Delete" />
                },
            }
        ];


        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Unit',
                fieldName: 'unit',
                minWidth: 100,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Address',
                fieldName: 'building',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: ICertificateSurvey) => {
                    var r = item.building.address1;
                    if (item.building.address2 !== "") { r += (r !== "" ? "," : "") + item.building.address2; }
                    if (item.building.city !== "") { r += (r !== "" ? "," : "") + item.building.city; }
                    if (item.building.postcode !== "") { r += (r !== "" ? "," : "") + item.building.postcode; }
                    return r;
                },

            },
            {
                key: 'column4',
                name: 'Completed On',
                fieldName: 'completedOn',
                minWidth: 120,
                maxWidth: 150,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'Date',
                isPadded: true,
                onRender: (item: ICertificateSurvey) => {
                    return <Moment format="ddd DD MMM YYYY HH:mm">{item.completedOn}</Moment>;
                },
            },
            {
                key: 'column5',
                name: 'Survey',
                fieldName: 'survey',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: ICertificateSurvey) => {
                    return item.survey.name;
                },
            },
        ];

        var msg;
        if (this.state.messages === undefined) {
            return "Internal Error";
        }
        if (this.state.messages.length === 0) {
            msg = <Text variant="large" ><FormattedMessage id="message.nomessages" /></Text>;
        }
        else {
            msg = <DetailsList selectionMode={SelectionMode.none} items={this.state.messages} onActiveItemChanged={this._MessageChanged} columns={msgColumns} />;
        }

        var surveys;
        if (this.state.surveys.length === 0) {
            surveys = <Text variant="large" ><FormattedMessage id="survey.nosurveys" /></Text>;
        }
        else {
            surveys = <DetailsList selectionMode={SelectionMode.none} items={this.state.surveys} onActiveItemChanged={this._ItemChanged} columns={columns} />;
        }

        var certificates;
        if (this.state.certificates.length === 0) {
            certificates = <Text variant="large" ><FormattedMessage id="certificate.noexpiring" /></Text>;
        }
        else {
            certificates = <DetailsList items={this.state.certificates} columns={cCertificateColumns} selectionMode={SelectionMode.none} />
        }

        return (
            <Stack grow styles={stackStyles}>
                <Text variant="xLarge" ><FormattedMessage id="message.unread" /></Text>
                {msg}
                <br />
                <br />
                <Text variant="xLarge" ><FormattedMessage id="survey.awaitingapproval" /></Text>
                {surveys}
                <br />
                <br />
                <Text variant="xLarge" ><FormattedMessage id="certificate.expiring" /></Text>
                {certificates}
                <Panel
                    isOpen={this.state.isEditOpen}
                    onDismiss={this.closeEdit}
                    headerText={this.props.intl.formatMessage({ id: 'survey.reviewsurvey' })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                    type={PanelType.extraLarge}
                // Stretch panel content to fill the available height so the footer is positioned
                // at the bottom of the page
                //  isFooterAtBottom={true}
                >
                    <Survey user={this.state.user} userrole="review" onUpdate={this.onUpdate} survey={this.state.selected} />
                </Panel>
                <Message user={this.state.user} visible={this.state.open} onClose={this.onClose} message={this.state.message} />
                <Panel
                    isOpen={this.state.isOpen}
                    onDismiss={this.dismissPanel}
                    headerText={this.props.intl.formatMessage({ id: 'certificate.certificate' })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                    type={PanelType.large}
                >
                    <Certificate id={this.state.selected.id} user={this.state.user} />
                </Panel>

            </Stack>

        );
    };

}

export default injectIntl(ToDo)