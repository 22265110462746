import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import { API, APIKEY, ICertificateTemplate } from './IVesta';

import {
  DetailsList,  IColumn,
  SelectionMode,
  Stack, IStackStyles,
  CommandBar, ICommandBarItemProps,
  Panel, PanelType,
  IconButton,
  Text,
  Dialog, DialogFooter, DialogType, PrimaryButton, DefaultButton,
} from 'office-ui-fabric-react';

import CertificateTemplate from './CertificateTemplate';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

interface IBuilding  {
  name: string,
  description: string,
  validfor: bigint
}


interface IState  {
    user: string,
    selected: string,
    templates: IBuilding[],
    isOpen: boolean,
    isCertOpen: boolean,
    selectedID: string,
    panelTitle: string,
    deleteOpen: boolean,
  }

  interface IProps extends WrappedComponentProps {
  }
  
  
  
class CertificateTemplates extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
          user: "",
          selected:"Undefined"  ,
          templates: []       ,
          isOpen: false,
          isCertOpen: false,
          selectedID: "",
          panelTitle: "",    
          deleteOpen: false, 
        };

        this.dismissPanel = this.dismissPanel.bind(this);
        this._ItemChanged = this._ItemChanged.bind(this);
        this.addnewtemplate = this.addnewtemplate.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.onDeleteTemplate = this.onDeleteTemplate.bind(this);
        this._onToggleDelete = this._onToggleDelete.bind(this);
    }


    componentDidMount()
    {
      this.refresh();
    }
  
    refresh()
    {
      Auth.currentSession()
          .then((session: any) => { 
            this.setState({ user: session.idToken.payload.email});
            this.authenticatedrefresh();
          })
          .catch( function(err) {
            console.log(err)
      });
    }

    authenticatedrefresh()
    {

      if(this.state.user === "")   return;
      
      const post  = { 
          security: {
            APIKey: APIKEY,
            User: this.state.user
          }
      }

      axios.post(API + "/certificate/template/search",post)
        .then(response => {
            this.setState({ templates: response.data.templates });

        })
        .catch(function (error) {
          console.log(error);
        });
    }

    addnewtemplate()
    {
      this.setState({isOpen: true, panelTitle: this.props.intl.formatMessage({ id: 'template.new' }), selectedID: ""});
    }

    private editTemplate(item: ICertificateTemplate,index?: number)
    {
        this.setState({isOpen: true, panelTitle: this.props.intl.formatMessage({ id: 'template.edit' }),selectedID: item.id || ""  });
    }  
  
    private deleteTemplate(id: string)
    {
      this.setState({ deleteOpen: true, selectedID: id })
  
    }

    _onToggleDelete() {
      this.setState({ deleteOpen: !this.state.deleteOpen })
    }

  
    onDeleteTemplate()
    {
            const post = {
                Security: {
                  APIKey: APIKEY,
                  User: this.state.user
                },
                id: this.state.selectedID,
              }
              axios.post(API + "/certificate/template/delete",post)
                .then(() => {
                    this.setState({ deleteOpen: false });
                    this.authenticatedrefresh();
                })
                .catch(function (error) {
                      console.log(error);
                });                  
        }

  
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    };      
      
    private _ItemChanged = (item: any, index: any, ev: any): void => {
      const id = item.id;
        this.setState({isOpen: true,
                        selectedID: id});
    };      

    private dismissPanel()
    {
        this.setState({isOpen: false});
        this.authenticatedrefresh();
    }  

    render() {

      const _items: ICommandBarItemProps[] = [
          {
            key: 'newItem',
            text: this.props.intl.formatMessage({ id: 'template.new' }),
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            onClick: this.addnewtemplate,
          },
        ];
        const columns: IColumn[] = [
            {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'template.name' }),
              fieldName: 'name',
              minWidth: 210,
              maxWidth: 350,
              isRowHeader: true,
              isResizable: true,
              isSorted: true,
              isSortedDescending: false,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'description',
              name: this.props.intl.formatMessage({ id: 'template.description' }),
              fieldName: 'description',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSorted: true,
              isSortedDescending: false,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'edit',
              name: 'Edit',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any,index?:number) => {
                return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.editTemplate(item,index)} title="Edit" ariaLabel="Edit"  />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
                return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteTemplate(item.id)} title="Delete" ariaLabel="Delete"  />
              },
            }
        ];

        const deleteContentProps = {
          type: DialogType.largeHeader,
          title: this.props.intl.formatMessage({ id: 'template.delete.dialog.title' }),
          subText: this.props.intl.formatMessage({ id: 'template.delete.dialog.text' }),
        };
        const modalProps = {
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
      };

  
        return (
          <Stack grow styles={stackStyles}>
            <Text variant="xLarge" >Certificate Templates</Text>
            <CommandBar
              items={_items}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <DetailsList  items={this.state.templates} 
                          columns={columns} 
                          selectionMode={SelectionMode.none}
            />

            <Panel
                isOpen={this.state.isOpen}
                onDismiss={this.dismissPanel}
                headerText={this.state.panelTitle}
                closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                type={PanelType.extraLarge}
            >
              <CertificateTemplate user={this.state.user} id={this.state.selectedID} onClose={this.dismissPanel}/>
            </Panel>
            <Dialog
                    hidden={!this.state.deleteOpen}
                    onDismiss={this._onToggleDelete}
                    dialogContentProps={deleteContentProps}
                    modalProps={modalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => {this.onDeleteTemplate()}} text={this.props.intl.formatMessage({ id: 'std.delete' })} />
                        <DefaultButton onClick={this._onToggleDelete} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
                    </DialogFooter>
                </Dialog>            
          </Stack>
        );
      };
      
}

export default injectIntl(CertificateTemplates);