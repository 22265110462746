import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { injectIntl, WrappedComponentProps, } from 'react-intl';

import { API, APIKEY, IClient, IClientSettings, IAttachmentCategory } from './IVesta';

import {
    TextField,
    PrimaryButton,
    Pivot, PivotItem,
    Stack, IStackStyles,
} from 'office-ui-fabric-react';

import ClientStorage from './ClientStorage';
import CategoryListEdit from './CategoryListEdit';
import StringListEdit from './StringListEdit';
import CertificateListEdit from './CertificateListEdit';

type IState = {
    client: IClient,
    user: string,
    shouldSave: boolean,
}

interface IProps extends WrappedComponentProps {
}

class Profile extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            user: "",
            shouldSave: false,
            client: {} as IClient,
        };

        this.handleChange = this.handleChange.bind(this);
        this.catUpdated = this.catUpdated.bind(this);
        this.catClientUpdated = this.catClientUpdated.bind(this);
        this.catBuildingUpdated = this.catBuildingUpdated.bind(this);
        this.typesUpdated = this.typesUpdated.bind(this);
        this.propertyTypesUpdated = this.propertyTypesUpdated.bind(this);
        this.completionTypesUpdated = this.completionTypesUpdated.bind(this);
        this.save = this.save.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const value: string = target.type === 'checkbox' ? target.checked : target.value;
        const name: string = target.name;

        var s = this.state.client;
        switch (name) {
            case "name":
                s.name = value;
                break;
            case "contact":
                s.contactInfo.name = value;
                break;
            case "email":
                s.contactInfo.email = value;
                break;
            case "phone":
                s.phone = value;
                break;
        }
        this.setState({ client: s, shouldSave: true, });
    }


    componentDidMount() {
        this.refresh();
    }

    refresh() {
        Auth.currentSession()
            .then((session: any) => {
                this.setState({ user: session.idToken.payload.email });
                this.authenticatedrefresh();
            })
            .catch(function (err) {
                console.log(err)
            });
    }

    authenticatedrefresh() {

        const post = {
            security: {
                APIKey: APIKEY,
                User: this.state.user
            },
        }

        var url = "/client/profile";
        axios.post(API + url, post)
            .then(response => {
                var client: IClient = response.data.client;
                if (client.settings == null) {
                    client.settings = {
                        clientTypes: [],
                        propertyTypes: [],
                        completionTypes: [],
                        myAttachmentCategories: [],
                        myClientAttachmentCategories: [],
                        myBuildingAttachmentCategories: [],
                    } as IClientSettings
                }
                this.setState({ client: client });
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    save() {
        const post = {
            Security: {
                APIKey: APIKEY,
                User: this.state.user
            },
            client: this.state.client,
        }
        axios.post(API + "/client/updateprofile", post)
            .then(response => {
                this.setState({ shouldSave: false })
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    catUpdated(items: IAttachmentCategory[]) {
        var c: IClient = Object.assign(this.state.client);
        if (c.settings == null) c.settings = {} as IClientSettings;
        c.settings.myAttachmentCategories = Object.assign(items);
        this.setState({ client: c, shouldSave: true });
    }

    catClientUpdated(items: IAttachmentCategory[]) {
        var c: IClient = Object.assign(this.state.client);
        if (c.settings == null) c.settings = {} as IClientSettings;
        c.settings.myClientAttachmentCategories = items;
        this.setState({ client: c, shouldSave: true });
    }

    catBuildingUpdated(items: IAttachmentCategory[]) {
        var c: IClient = Object.assign(this.state.client);
        if (c.settings == null) c.settings = {} as IClientSettings;
        c.settings.myBuildingAttachmentCategories = items;
        this.setState({ client: c, shouldSave: true });
    }

    typesUpdated(items: string[]) {
        var c: IClient = Object.assign(this.state.client);
        if (c.settings == null) c.settings = {} as IClientSettings;
        c.settings.clientTypes = items;
        this.setState({ client: c, shouldSave: true });
    }
    propertyTypesUpdated(items: string[]) {
        var c: IClient = Object.assign(this.state.client);
        if (c.settings == null) c.settings = {} as IClientSettings;
        c.settings.propertyTypes = items;
        this.setState({ client: c, shouldSave: true });
    }
    completionTypesUpdated(items: string[]) {
        var c: IClient = Object.assign(this.state.client);
        if (c.settings == null) c.settings = {} as IClientSettings;
        c.settings.completionTypes = items;
        this.setState({ client: c, shouldSave: true });
    }

    render() {
        if ((this.state.client.id || "") === "") {
            return <></>
        }

        const stackStyles: IStackStyles = {
            root: {
                width: "500"
            },
        };

        return (<>
            <Stack grow styles={stackStyles}>
                <Stack>
                    <TextField label={this.props.intl.formatMessage({ id: 'client.name' })} value={this.state.client.name} name="name" onChange={this.handleChange} />
                    <TextField label={this.props.intl.formatMessage({ id: 'client.contact' })} value={this.state.client.contactInfo.name} name="contact" onChange={this.handleChange} />
                    <TextField label={this.props.intl.formatMessage({ id: 'client.email' })} value={this.state.client.contactInfo.email} name="email" onChange={this.handleChange} />
                    <TextField label={this.props.intl.formatMessage({ id: 'client.phone' })} value={this.state.client.phone} name="phone" onChange={this.handleChange} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 6 }}>
                    <PrimaryButton onClick={this.save} text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} />
                </Stack>
            </Stack>
            <Pivot>
                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.options' })}>
                    <Stack horizontal>
                        <StringListEdit sort title={this.props.intl.formatMessage({ id: 'client.types' })} onChange={this.typesUpdated} list={this.state.client.settings.clientTypes} />
                    </Stack>
                </PivotItem>

                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.autocert' })}>
                    <Stack horizontal>
                        <CertificateListEdit onChange={this.typesUpdated} list={this.state.client.settings.clientTypes} />
                        <CertificateListEdit onChange={this.typesUpdated} list={this.state.client.settings.clientTypes} />
                    </Stack>
                </PivotItem>

                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.attachment.categories' })}>
                    <CategoryListEdit onChange={this.catUpdated} list={this.state.client.settings.myAttachmentCategories} />
                </PivotItem>

                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.attachment.client.categories' })}>
                    <CategoryListEdit onChange={this.catClientUpdated} list={this.state.client.settings.myClientAttachmentCategories} />
                </PivotItem>

                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.attachment.building.categories' })}>
                    <CategoryListEdit onChange={this.catBuildingUpdated} list={this.state.client.settings.myBuildingAttachmentCategories} />
                </PivotItem>

                <PivotItem headerText={this.props.intl.formatMessage({ id: 'client.storage' })}>
                    <ClientStorage super user={this.state.user} clientid={this.state.client.id} />
                </PivotItem>

                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.property.types' })}>
                    <Stack horizontal>
                        <StringListEdit sort title={this.props.intl.formatMessage({ id: 'profile.property.types' })} onChange={this.propertyTypesUpdated} list={this.state.client.settings.propertyTypes ? this.state.client.settings.propertyTypes : []} />
                    </Stack>
                </PivotItem>
                <PivotItem headerText={this.props.intl.formatMessage({ id: 'profile.completion.types' })}>
                    <Stack horizontal>
                        <StringListEdit sort title={this.props.intl.formatMessage({ id: 'profile.completion.types' })} onChange={this.completionTypesUpdated} list={this.state.client.settings.completionTypes ? this.state.client.settings.completionTypes : []} />
                    </Stack>
                </PivotItem>
            </Pivot>
        </>);
    };
}

export default injectIntl(Profile);