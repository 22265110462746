import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {
  Stack,
  IStackStyles,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";

import BuildingsList from "./BuildingsList";

interface IProps extends WrappedComponentProps {
  contactData: any;
}

type IState = {
  user: string;
  selected: string;
  isOpen: boolean;
  id: any;
  shouldSave: boolean;
  showSuccess: boolean;
  showError: boolean;
  message: string;
};

class Buildings extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      user: "",
      selected: "Undefined",
      isOpen: false,
      id: props.id,
      shouldSave: false,
      showSuccess: false,
      showError: false,
      message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    Auth.currentSession()
      .then((session: any) => {
        this.setState({ user: session.idToken.payload.email });
        this.authenticatedrefresh(session.idToken.payload.email);
      })
      .catch(function (err) {});
  }

  authenticatedrefresh(user: string) {
    return;
  }

  handleChange(event: any) {
    this.setState({ shouldSave: true });
  }

  private showMessage(error: boolean, message: string) {
    if (error) this.setState({ showError: true, message: message });
    else this.setState({ showSuccess: true, message: message });
  }

  render() {
    const stackStyles: IStackStyles = {
      root: {
        width: "500",
      },
    };

    if (this.state.user === "") {
      return <h3>Loading...</h3>;
    }

    var msg;
    if (this.state.showSuccess) {
      msg = (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => {
            this.setState({ showSuccess: false });
          }}
          dismissButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
        >
          {this.state.message}
        </MessageBar>
      );
      setTimeout(() => this.setState({ showSuccess: false }), 3000);
    }
    if (this.state.showError) {
      msg = (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => {
            this.setState({ showError: false });
          }}
          dismissButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
        >
          {this.state.message}
        </MessageBar>
      );
    }

    return (
      <Stack grow styles={stackStyles}>
        {msg}
        <BuildingsList
          forUser
          showMessage={this.showMessage}
          contactData={this.props.contactData}
          user={this.state.user}
          client=""
          addBuilding=""
        />
      </Stack>
    );
  }
}

export default injectIntl(Buildings);
