import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import { withAuthenticator } from "@aws-amplify/ui-react";
import Amplify, { Auth } from "aws-amplify";

import NavBar from "./NavBar";
import Welcome from "./Welcome";
import "bootstrap/dist/css/bootstrap.css";

import ToDo from "./ToDo";
import Messages from "./Messages";
import Buildings from "./Buildings";
import Clients from "./Clients";
import MCP from "./MCP";
import UserCertificates from "./UserCertificates";
import CertificateTemplates from "./CertificateTemplates";
import Surveys from "./Surveys";
import Reports from "./Reports";
import Client0Attachments from "./Client0Attachments";
import Profile from "./Profile";

import SignUpContainer from "./SignUpContainer";
import LoginContainer from "./LoginContainer";
import Users from "./Users";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: "eu-west-2",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "eu-west-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    //Old on for MYo// userPoolId: 'eu-west-2_0nfjsm3az',
    // userPoolId: "eu-west-2_K943ZsKc5", // dev
    // userPoolId: "eu-west-2_a8d0Q517F", // stag
    userPoolId: "eu-west-2_xWUPnIEBt", // prod

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //Old on for MYo// userPoolWebClientId: '3lbscpkdu7sca4ud577oua8mi1',
    // userPoolWebClientId: "68uo10l7h141ej0rhq8ecn6vqs", //dev
    // userPoolWebClientId: "2gl76hma5ud076lu7ce8ov7gs8", // stag
    userPoolWebClientId: "1teedkpc6od7g18bevjultga8n", // prod

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    //cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //    domain: '.yourdomain.com',
    // OPTIONAL - Cookie path
    //    path: '/',
    // OPTIONAL - Cookie expiration in days
    //    expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //    sameSite:  "lax",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //    secure: true
    //},

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { myCustomKey: "myCustomValue" },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "your_cognito_domain",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

Auth.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: "eu-west-2",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: "eu-west-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    //Old on for MYo// userPoolId: 'eu-west-2_0nfjsm3az',
    // userPoolId: "eu-west-2_K943ZsKc5", // dev
    // userPoolId: "eu-west-2_a8d0Q517F", // stag
    userPoolId: "eu-west-2_xWUPnIEBt", // prod

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //Old on for MYo// userPoolWebClientId: '3lbscpkdu7sca4ud577oua8mi1',
    // userPoolWebClientId: "68uo10l7h141ej0rhq8ecn6vqs", //dev
    // userPoolWebClientId: "2gl76hma5ud076lu7ce8ov7gs8", // stag
    userPoolWebClientId: "1teedkpc6od7g18bevjultga8n", // prod

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    //cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //    domain: '.yourdomain.com',
    // OPTIONAL - Cookie path
    //    path: '/',
    // OPTIONAL - Cookie expiration in days
    //    expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //    sameSite:  "lax",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //    secure: true
    //},

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata: { myCustomKey: "myCustomValue" },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "your_cognito_domain",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

interface IProps {}

type TParams = { id: string };

class App extends Component<IProps> {
  render() {
    let error = null;

    return (
      <Router>
        <div>
          <NavBar isAuthenticated={false} authButtonMethod="" user="" />
          <Container
            style={{
              paddingTop: "100px",
              paddingLeft: "20px",
              paddingRight: "20px",
              maxWidth: "none",
            }}
          >
            {error}
            <Route
              exact
              path="/"
              render={(props) => (
                <Welcome
                  {...props}
                  isAuthenticated={false}
                  user=""
                  authButtonMethod=""
                />
              )}
            />
            <Route
              exact
              path="/ToDo"
              render={() => (true ? <ToDo client="" /> : <Redirect to="/" />)}
            />
            <Route
              exact
              path="/Messages"
              render={() =>
                true ? <Messages client="" /> : <Redirect to="/" />
              }
            />
            <Route
              exact
              path="/Certificates"
              render={() => (true ? <UserCertificates /> : <Redirect to="/" />)}
            />
            <Route
              exact
              path="/Buildings"
              render={() =>
                true ? <Buildings contactData={{}} /> : <Redirect to="/" />
              }
            />
            <Route
              exact
              path="/Client"
              render={() =>
                true ? <Clients client="" /> : <Redirect to="/" />
              }
            />
            <Route
              exact
              path="/MCP"
              render={() => (true ? <MCP client="" /> : <Redirect to="/" />)}
            />
            <Route exact path="/User" render={() => <Users />} />
            <Route
              exact
              path="/CertificateTemplate"
              render={() => <CertificateTemplates />}
            />
            <Route exact path="/Survey" render={() => <Surveys />} />
            <Route exact path="/Report" render={() => <Reports />} />
            <Route
              exact
              path="/Attachments"
              render={() => <Client0Attachments />}
            />
            <Route exact path="/Profile" render={() => <Profile />} />
            <Route
              exact
              path="/SignIn"
              render={() => (false ? <LoginContainer /> : <Redirect to="/" />)}
            />
            <Route
              exact
              path="/SignUp"
              render={() => (false ? <SignUpContainer /> : <Redirect to="/" />)}
            />
          </Container>
        </div>
      </Router>
    );
  }
}

export default withAuthenticator(App);
