import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { API, APIKEY, ISurvey, ISurveySection, IObjectReference, IQuestion, IObjectAttachment, } from './IVesta';
import {
    Label, TextField, Slider, Dropdown, IDropdownStyles,
    DefaultButton, PrimaryButton, IconButton, ActionButton,
    CommandButton, IIconProps,
    Dialog, DialogType, DialogFooter,
    Image, ImageFit, Panel, PanelType,
    Spinner, SpinnerSize,
    Stack, IStackStyles, IStackTokens, Separator,
    getRTL, Icon, mergeStyleSets, List, Toggle,
    Text, ITheme, getTheme, getFocusStyle,
} from 'office-ui-fabric-react';
import QuestionEdit from './QuestionEdit';
import InfoIcon from './InfoIcon';

const stackStyles: IStackStyles = {
    root: {
        width: "500"
    },
};

type IState = {
    isopen: boolean,
    selected: any,
    isNewSectionOpen: boolean,
    survey: ISurvey,
    currentSection: ISurveySection,
    currentSectionIndex: number,

    newSectionName: string,
    newSectionDescription: string,

    currentQuestion: any,
    currentQuestionIndex: number,

    attachments: IObjectAttachment[],

    shouldSave: boolean,
    questionAdd: boolean,

    surveys: IObjectReference[],
    propertyTypes: string[],
    completionTypes: string[],
    prePropertyType: string; // Manage the section question based on property type.
}

interface IProps extends WrappedComponentProps {
    user: string,
    surveyID: string,
    onClose: any,
}

class SurveyEdit extends Component<IProps, IState> {
    private dragdrop: number = 0;
    constructor(props: any) {
        super(props);
        this.state = {
            isopen: false,
            selected: null,
            isNewSectionOpen: false,
            newSectionName: "",
            newSectionDescription: "",

            currentSection: { name: "", description: "", approvalsRequiredPercent: 0, qaPass: false, questions: [] },
            currentSectionIndex: -1,

            currentQuestion: null,
            currentQuestionIndex: -1,

            survey: { name: "", description: "", sections: [], validFor: 365, completionMethod: 0, expectedCompletionPeriod: 30, certificateCount: 0, templateCount: 0, canDelete: false, propertyType: "", surveyType: "", isDisplayMyoCorporate: false, completionType: "" },

            attachments: [],

            surveys: [],

            shouldSave: false,
            propertyTypes: [],
            completionTypes: [],
            prePropertyType: "",
            questionAdd: true
        };

        this.createNewSection = this.createNewSection.bind(this);
        this.closeNewSection = this.closeNewSection.bind(this);
        this.onCreateNewSection = this.onCreateNewSection.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectSection = this.selectSection.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.selectQuestion = this.selectQuestion.bind(this);
        this.deleteQuestion = this.deleteQuestion.bind(this);
        this.createNewQuestion = this.createNewQuestion.bind(this);
        this.setpc = this.setpc.bind(this);
        this.onSave = this.onSave.bind(this);
        this.savequestion = this.savequestion.bind(this);
        this.handleValidForChange = this.handleValidForChange.bind(this);
        this.handleCompletionMethod = this.handleCompletionMethod.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.deleteSection = this.deleteSection.bind(this);
        this.handleNavDrop = this.handleNavDrop.bind(this);
        this.handleAutofillMethod = this.handleAutofillMethod.bind(this);
        this.handleSurveyTypeChange = this.handleSurveyTypeChange.bind(this);
        this.handlePropertyTypeChange = this.handlePropertyTypeChange.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
    }

    private deleteSection() {
        var survey = JSON.parse(JSON.stringify(this.state.survey));
        survey.sections.splice(this.state.currentSectionIndex, 1);
        this.setState({
            survey: survey,
            shouldSave: true,
            currentSection:
            {
                name: "",
                description: "",
                approvalsRequiredPercent: 0,
                qaPass: false,
                questions: []
            },
            currentSectionIndex: -1,
        });
    }

    private handleCompletionMethod(event: any, option?: any, index?: number) {
        var s = this.state.survey;
        s.completionMethod = index || 0;
        this.setState({ survey: s, shouldSave: true });
    }

    private handleAutofillMethod(event: any, option?: any, index?: number) {
        var s = this.state.survey;
        s.autoCompleteSurveyID = this.state.surveys[index || 0].id;
        this.setState({ survey: s, shouldSave: true });
    }

    private handleValidForChange(event: any, option?: any, index?: number) {
        var value: number = 0;
        switch (index || 0) {
            case 1:
                value = 7; break;
            case 2:
                value = 31; break;
            case 3:
                value = 90; break;
            case 4:
                value = 180; break;
            case 5:
                value = 365; break;
            case 6:
                value = 730; break;
        }
        var s = this.state.survey;
        s.validFor = value;
        this.setState({ survey: s, shouldSave: true });
    }

    handleSurveyTypeChange = (event: any, option: any) => {
        var s = this.state.survey;
        s.surveyType = option.text;
        if (s.surveyType == "Brand") {
            s.propertyType = "";
            s.completionType = "";
        }
        this.setState({ survey: s, shouldSave: true, });
    }

    handlePropertyTypeChange = (event: any, option: any) => {
        var s = this.state.survey;
        this.setState({ prePropertyType: s.propertyType });
        s.propertyType = option.text;
        this.setState({ survey: s, shouldSave: true, });
    }

    handleCompletionTypeChange = (event: any, option: any) => {
        var s = this.state.survey;
        s.completionType = option.text;
        this.setState({ survey: s, shouldSave: true, });
    }

    handleToggleChange = (event: any, checked: any) => {
        var s = this.state.survey;
        s.isDisplayMyoCorporate = checked;
        this.setState({ survey: s, shouldSave: true, });
    }

    private dismissPanel() {
        this.setState({ isopen: false });
        var survey = this.state.survey;
        if (this.state.questionAdd) {
            var survey = this.state.survey;
            survey.sections[this.state.currentSectionIndex].questions.splice(this.state.currentQuestionIndex, 1);
            this.setState({ survey: survey, shouldSave: true, questionAdd: false });
        }
    }

    private handleChange(event: any) {
        const target = event.target;
        const value: string = target.type === 'checkbox' ? target.checked : target.value;
        const name: string = target.name;
        var s;
        switch (name) {
            case "expected":
                if (isNaN(parseInt(value))) return;
                s = this.state.survey;
                s.expectedCompletionPeriod = parseInt(value);
                this.setState({ survey: s, shouldSave: true });
                break;
            case "description":
                s = this.state.survey;
                s.description = value;
                this.setState({ survey: s, shouldSave: true });
                break;
            case "name":
                s = this.state.survey;
                s.name = value;
                this.setState({ survey: s, shouldSave: true });
                break;
            case "newSectionName":
                this.setState({
                    newSectionName: value
                });
                break;
            case "newSectionDescription":
                this.setState({
                    newSectionDescription: value
                });
                break;
            case "currentSectionName":
                s = this.state.currentSection;
                s.name = value;
                this.setState({
                    currentSection: s,
                    shouldSave: true,
                });
                break;
            case "currentSectionDescription":
                s = this.state.currentSection;
                s.description = value;
                this.setState({
                    currentSection: s,
                    shouldSave: true,
                });
                break;
        }
    }

    private setpc(value: number) {
        var sec = this.state.currentSection;
        sec.approvalsRequiredPercent = value;
        this.setState({
            currentSection: sec,
            shouldSave: true,
        });
    }

    componentDidMount() {
        this.refresh();
    }

    componentWillUnmount() {
        this.setState({ prePropertyType: "" });
    }

    private deleteQuestion(index: number) {
        if (window.confirm("Are you sure you wish to delete this question?")) {
            var survey = this.state.survey;
            survey.sections[this.state.currentSectionIndex].questions.splice(index, 1);
            this.setState({ survey: survey, shouldSave: true, questionAdd: false });
        }
    }

    private createNewQuestion() {
        if (this.state.currentSectionIndex < 0) return;
        var survey = this.state.survey;
        var q: IQuestion = {
            description: "",
            guidance: "",
            operatorGuidance: "",
            helpURL: "",
            options: "",
            type: 0,
            mandatory: true,
            mandatoryGracePeriod: 0,
            validate: false,
            validationFailMessage: "",
            validationMin: 0,
            validationMax: 0,
            validationExtra: "",
            evidenceprompt: "",
            evidencerequired: 0,
            requiredafter: 0,
            evidencetype: 0,
            evidencename: "",
            evidenceguidance: "",
            evidenceHelpURL: "",
            gpsTagged: false,
            gpsRingfenced: false,
            recentPicture: false,
            recentleaway: 7,
            translations: [],
            conditional: false,
            conditionalSurveyID: "",
            conditionalQuestionID: "",
            conditionalValue: "",
            externalRef: "",
            conditionalDisabled: false,
            isDisplayMyoCorporate: this.state.survey.isDisplayMyoCorporate,
            isAnswersYes: false,
            section: "",
            subSection: "",
            positiveDisplay: "",
            negativeDisplay: "",
            introductionToTextAnswer: "",
            imageMyoDescription: "",
            videoMyoDescription: "",
            numberMyoDescription: "",
            dateMyoDescription: "",
            manyOfMyoDescription: "",
            documentMyoDescription: "",
            oneOfMyoDescription: [],
            color: "",
            negativeColor: "",
        }
        var i = survey.sections[this.state.currentSectionIndex].questions.length;
        survey.sections[this.state.currentSectionIndex].questions.push(q);

        this.setState({
            survey: survey,
            currentSection: survey.sections[this.state.currentSectionIndex],
            currentQuestion: q,
            currentQuestionIndex: i,
            isopen: true,
            shouldSave: true,
            questionAdd: true,
        });
    }

    private createNewSection() {
        this.setState({ isNewSectionOpen: true });
    }

    private savequestion(q: IQuestion) {
        if (this.state.currentSectionIndex < 0) return;
        if (this.state.currentQuestionIndex < 0) return;

        var survey = Object.assign(this.state.survey);
        survey.sections[this.state.currentSectionIndex].questions[this.state.currentQuestionIndex] = q;

        this.setState({
            survey: survey,
            currentSection: survey.sections[this.state.currentSectionIndex],
            shouldSave: true,
        });
        this.forceUpdate();
        this.setState({ isopen: false });
    }

    private onCreateNewSection() {
        var section: ISurveySection = {
            name: this.state.newSectionName,
            description: this.state.newSectionDescription,
            approvalsRequiredPercent: 50,
            qaPass: false,
            questions: []
        }
        var survey = JSON.parse(JSON.stringify(this.state.survey));
        survey.sections.push(section);
        this.setState({
            survey: survey,
            isNewSectionOpen: false,
            newSectionName: "",
            newSectionDescription: "",
            shouldSave: true,
        })
    }

    private closeNewSection() {
        this.setState({ isNewSectionOpen: false });
    }

    private refresh() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            id: this.props.surveyID
        }
        axios.post(API + "/survey/EditSurvey", post)
            .then(response => {
                var sec = response.data.survey.sections[0];
                this.setState({
                    survey: response.data.survey,
                    surveys: response.data.allSurveys,
                    attachments: response.data.attachments,
                    currentSection: sec,
                    propertyTypes: response.data.propertyTypes,
                    completionTypes: response.data.completionTypes,
                    currentSectionIndex: 0
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    private selectQuestion(ev: number) {
        this.setState({
            currentQuestion: this.state.currentSection.questions[ev],
            currentQuestionIndex: ev,
            isopen: true,
            questionAdd: false
        });

    }

    private selectSection(event: any, item?: ISurveySection, index?: number) {
        const n: number = index || 0;
        var i = (item !== undefined) ? n : 0;
        var sec = this.state.survey.sections[i];
        this.setState({
            currentSection: sec,
            currentSectionIndex: i,
        });
    }

    private onSave() {
        var s = this.state.survey;
        if (this.state.prePropertyType !== 'Single Contained' && s.propertyType === 'Single Contained') {
            s.sections.forEach(section => {
                section.questions.forEach(question => {
                    question.subSection = '';
                });
            });

            this.setState({ survey: s, shouldSave: true, });
        }
        const post = {
            Security: {
                APIKey: APIKEY,
                User: this.props.user
            },
            Survey: this.state.survey,
        }
        axios.post(API + "/survey/Save", post)
            .then(() => {
                this.setState({ prePropertyType: "", shouldSave: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleDragEnter(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragLeave(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragStart(e: any) {
        this.dragdrop = parseInt(e.currentTarget.id.substring(1));
        e.stopPropagation();
    }

    handleDragOver(e: any) {
        e.dataTransfer.dropEffect = 'move';
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop(e: any) {
        var i = parseInt(e.currentTarget.id.substring(1));
        if (i !== this.dragdrop) {
            var survey = Object.assign(this.state.survey);
            var questions = survey.sections[this.state.currentSectionIndex].questions;
            var q;
            if (i === 0) {
                //    Move to the first item
                q = questions.splice(this.dragdrop, 1)[0];
                questions.splice(0, 0, q);
                survey.sections[this.state.currentSectionIndex].questions = questions;
            }
            else {
                //    Move to the first item
                q = questions.splice(this.dragdrop, 1)[0];
                if (this.dragdrop > i) questions.splice(i, 0, q);
                else questions.splice(i - 1, 0, q);
                survey.sections[this.state.currentSectionIndex].questions = questions;
            }
            this.setState({
                survey: survey,
                currentSection: survey.sections[this.state.currentSectionIndex],
                shouldSave: true,
            });
        }
        e.preventDefault();
        e.stopPropagation();
    };

    handleNavDrop(e: any) {
        var i = parseInt(e.currentTarget.id.substring(1));
        if (i !== this.dragdrop) {
            var survey = this.state.survey;
            var sections = this.state.survey.sections;
            var s;
            if (i === 0) {
                s = sections.splice(this.dragdrop, 1)[0];
                sections.splice(0, 0, s);

            }
            else {
                s = sections.splice(this.dragdrop, 1)[0];
                if (this.dragdrop > i) sections.splice(i, 0, s);
                else sections.splice(i - 1, 0, s);
            }
            var tmp: any = [];
            for (i = 0; i < sections.length; i++) {
                tmp.push(sections[i]);
            }
            survey.sections = tmp;
            this.setState({
                survey: survey,
                currentSection: survey.sections[this.state.currentSectionIndex],
                shouldSave: true,
            });
        }
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        if (this.state.survey == null) {
            return (
                <Stack grow styles={stackStyles}>
                    <Spinner size={SpinnerSize.large} label="Loading Survey" />
                </Stack>
            );
        }

        const theme: ITheme = getTheme();
        const { palette, semanticColors, fonts } = theme;

        const classNames = mergeStyleSets({
            itemCell: [
                getFocusStyle(theme, { inset: -1 }),
                {
                    minHeight: 54,
                    padding: 10,
                    width: "100%",
                    boxSizing: 'border-box',
                    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                    display: 'flex',
                    selectors: {
                        '&:hover': { background: palette.neutralLight },
                    },
                },
            ],
            itemImage: {
                flexShrink: 0,
            },
            itemContent: {
                marginLeft: 10,
                overflow: 'hidden',
                flexGrow: 1,
            },
            itemName: [
                fonts.large,
                {
                    whiteSpace: 'break-spaces',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            ],
            itemIndex: {
                fontSize: fonts.small.fontSize,
                color: palette.neutralTertiary,
                marginBottom: 10,
            },
            chevron: {
                alignSelf: 'center',
                marginLeft: 10,
                color: palette.neutralTertiary,
                fontSize: fonts.large.fontSize,
                flexShrink: 0,
            },
        });

        const deleteIcon: IIconProps = { iconName: 'Delete' };

        const onRenderCell = (item?: IQuestion, index?: number | undefined): JSX.Element => {
            var i: IQuestion = item || {} as IQuestion;
            var cond = "";
            if (i.conditional) {
                cond = this.props.intl.formatMessage({ id: 'question.isconditional' });
                cond += " ";
                if (i.conditionalSurveyID === this.state.survey.id) {
                    cond += "this survey : ";
                }
                else {
                    cond += i.conditionalSurveyName + " : ";
                }
                if (i.conditionalSurveyID === this.state.survey.id) {
                    cond += "this section : ";
                }
                else {
                    cond += i.conditionalSectionName + " : ";
                }
                cond += " " + i.conditionalQuestionName;
            }
            var approveimage = i.mustApprove ? "approve.png" : "noapprove.png";
            var external = <></>;
            if ((i.externalRef || "") !== "") {
                external = <Text>External Reference: {i.externalRef}</Text>;
            }
            return (
                <div className={classNames.itemCell}
                    id={"I" + index}
                    onDrop={this.handleDrop}
                    onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver}
                    onDragEnter={this.handleDragEnter}
                    onDragLeave={this.handleDragLeave}
                    draggable={true} data-is-focusable={true}
                >
                    <Image className={classNames.itemImage} src={"/images/icons/questiontypes/" + i.type + ".png"} width={50} height={50} imageFit={ImageFit.cover} />
                    <div className={classNames.itemContent}>
                        <div className={classNames.itemName}>{i.description}</div>
                        <div className={classNames.itemIndex}>
                            <Stack>
                                <Text>{i.mandatory ? this.props.intl.formatMessage({ id: 'question.mandatory' }) : i.premium ? this.props.intl.formatMessage({ id: 'question.premium' }) : this.props.intl.formatMessage({ id: 'question.optional' })}</Text>
                                <Text>{cond}</Text>
                                {external}
                            </Stack>
                        </div>
                    </div>
                    <Image className={classNames.itemImage} src={"/images/icons/" + approveimage} width={50} height={50} imageFit={ImageFit.cover} />
                    <IconButton className={classNames.chevron} iconProps={deleteIcon} onClick={() => { this.deleteQuestion(index || 0); }} />
                    <Icon onClick={() => { this.selectQuestion(index || 0); }} className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
                </div>
            );
        };

        const onRenderNavCell = (item?: ISurveySection, index?: number | undefined): JSX.Element => {
            var i: ISurveySection = item || {} as ISurveySection

            const icon = i.qaPass
                ? <Icon iconName="Completed" />
                : <Icon iconName="ErrorBadge" />

            return (
                <div className="SectionNav"
                    id={"I" + index}
                    onDrop={this.handleNavDrop}
                    onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver}
                    onDragEnter={this.handleDragEnter}
                    onDragLeave={this.handleDragLeave}
                    draggable={true} data-is-focusable={true}
                    onClick={() => this.selectSection(null, item, index)}
                >
                    <div className="Icon">{icon}</div>
                    <div className="Text">{i.name}</div>
                </div>
            );
        }

        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: this.props.intl.formatMessage({ id: 'survey.section.new.title' }),
            subText: this.props.intl.formatMessage({ id: 'survey.section.new.help' }),
        };

        const modelProps = {
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        };

        const stackToks: IStackTokens = {
            childrenGap: '16'
        };

        const addIcon: IIconProps = { iconName: 'Add' };
        const sliderValueFormat = (value: number) => `${value}%`;

        var questions = <Stack></Stack>;

        if (this.state.currentSection != null) {
            var q = JSON.parse(JSON.stringify(this.state.currentSection.questions));
            questions =
                <Stack>
                    <CommandButton iconProps={addIcon} text={this.props.intl.formatMessage({ id: 'question.new' })} onClick={() => this.createNewQuestion()} />
                    <List items={q} onRenderCell={onRenderCell} />
                    <Panel
                        isOpen={this.state.isopen}
                        onDismiss={this.dismissPanel}
                        headerText={this.props.intl.formatMessage({ id: 'question.edit' })}
                        closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                        type={PanelType.large}
                    // Stretch panel content to fill the available height so the footer is positioned
                    // at the bottom of the page
                    //  isFooterAtBottom={true}
                    >
                        <QuestionEdit
                            user={this.props.user}
                            onSave={this.savequestion}
                            question={this.state.currentQuestion}
                            attachments={this.state.attachments}
                            currentSurvey={this.state.survey}
                            surveySectionID={this.state.currentSection.id}
                            onClose={this.dismissPanel}
                        />
                    </Panel>
                </Stack>;
        }

        var completedate;
        if (this.state.survey.useExpectedCompletionPeriod) {
            completedate = <TextField
                label={this.props.intl.formatMessage({ id: 'survey.completion.days' })}
                name="expected"
                value={(this.state.survey.expectedCompletionPeriod || 30).toString()}
                onChange={this.handleChange} />;
        }

        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
        };
        return (
            <Stack grow styles={stackStyles} tokens={stackToks}>
                <Stack>
                    <Stack tokens={{ childrenGap: 20 }} horizontal grow verticalAlign="start">
                        <Stack grow >
                            <TextField label={this.props.intl.formatMessage({ id: 'survey.name' })} name="name" value={this.state.survey.name} onChange={this.handleChange} />
                            <TextField label={this.props.intl.formatMessage({ id: 'survey.description' })} name="description" value={this.state.survey.description} onChange={this.handleChange} />
                        </Stack>
                        <Stack>
                            <Dropdown
                                onChange={this.handleCompletionMethod}
                                label={this.props.intl.formatMessage({ id: 'survey.completion.method' })}
                                placeholder={this.props.intl.formatMessage({ id: 'survey.completion.method.placeholder' })}
                                ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                options={[
                                    { key: 0, text: this.props.intl.formatMessage({ id: 'not.specified' }), title: 'Not Specified' },
                                    { key: 1, text: 'On Site', title: this.props.intl.formatMessage({ id: 'survey.completion.site' }) },
                                    { key: 2, text: 'Desk based', title: this.props.intl.formatMessage({ id: 'survey.completion.desk' }) },
                                ]}
                                selectedKey={this.state.survey.completionMethod}
                                styles={dropdownStyles}
                            />
                            <Dropdown
                                onChange={this.handleValidForChange}
                                label={this.props.intl.formatMessage({ id: 'survey.valid.for' })}
                                placeholder={this.props.intl.formatMessage({ id: 'survey.valid.for.placeholder' })}
                                ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                options={[
                                    { key: 1, text: 'Never expires', title: this.props.intl.formatMessage({ id: 'survey.valid.for.eternal' }) },
                                    { key: 7, text: '1 Week', title: this.props.intl.formatMessage({ id: 'survey.valid.for.week' }) },
                                    { key: 31, text: '1 Month', title: this.props.intl.formatMessage({ id: 'survey.valid.for.1month' }) },
                                    { key: 90, text: '3 Months', title: this.props.intl.formatMessage({ id: 'survey.valid.for.3months' }) },
                                    { key: 180, text: '6 Months', title: this.props.intl.formatMessage({ id: 'survey.valid.for.6months' }) },
                                    { key: 365, text: '1 Year', title: this.props.intl.formatMessage({ id: 'survey.valid.for.1year' }) },
                                    { key: 730, text: '2 Year', title: this.props.intl.formatMessage({ id: 'survey.valid.for.2year' }) },
                                ]}
                                selectedKey={this.state.survey.validFor}
                                required={true}
                                styles={dropdownStyles}
                            />
                        </Stack>
                        <Stack>
                            <Dropdown
                                onChange={this.handleSurveyTypeChange}
                                label={this.props.intl.formatMessage({ id: 'survey.type' })}
                                placeholder={this.props.intl.formatMessage({ id: 'survey.type.placeholder' })}
                                ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                options={[
                                    { key: 'Brand', text: 'Brand', title: this.props.intl.formatMessage({ id: 'survey.type.brand' }) },
                                    { key: 'Property', text: 'Property', title: this.props.intl.formatMessage({ id: 'survey.type.property' }) },
                                ]}
                                selectedKey={this.state.survey.surveyType}
                                styles={dropdownStyles}
                                required={true}
                            />
                            {this.state.survey.surveyType === 'Property' && (
                                <Stack>
                                    <Dropdown
                                        onChange={this.handlePropertyTypeChange}
                                        label={this.props.intl.formatMessage({ id: 'survey.type.property.type' })}
                                        placeholder={this.props.intl.formatMessage({ id: 'survey.type.placeholder' })}
                                        ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                        options={this.state.propertyTypes.map(type => ({
                                            key: type,
                                            text: type,
                                            title: this.props.intl.formatMessage({ id: 'survey.type.property' }),
                                        }))}
                                        selectedKey={this.state.survey.propertyType}
                                        styles={dropdownStyles}
                                        required={true}
                                    />

                                    <Dropdown
                                        onChange={this.handleCompletionTypeChange}
                                        label={this.props.intl.formatMessage({ id: 'survey.property.completion.type' })}
                                        placeholder={this.props.intl.formatMessage({ id: 'survey.type.placeholder' })}
                                        ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                        options={this.state.completionTypes.map(type => ({
                                            key: type,
                                            text: type,
                                            title: this.props.intl.formatMessage({ id: 'survey.type.property' }),
                                        }))}
                                        selectedKey={this.state.survey.completionType}
                                        styles={dropdownStyles}
                                        required={true}
                                    />
                                </Stack>
                            )}
                        </Stack>
                        <InfoIcon id={this.state.survey.id || "UNKNOWN"} />
                    </Stack>
                    <Stack>
                        <Stack style={{ paddingTop: 35 }} horizontal tokens={{ childrenGap: 20 }}>
                            <Stack>
                                <Toggle checked={this.state.survey.useExpectedCompletionPeriod} onText={this.props.intl.formatMessage({ id: 'survey.use.completion.date' })} offText={this.props.intl.formatMessage({ id: 'survey.dontuse.completion.date' })} onChange={() => { var s = this.state.survey; s.useExpectedCompletionPeriod = !s.useExpectedCompletionPeriod; this.setState({ survey: s, shouldSave: true }) }} />
                                {completedate}
                            </Stack>
                            <Stack>
                                <Toggle checked={this.state.survey.autoComplete} onText={this.props.intl.formatMessage({ id: 'survey.autocomplete.on' })} offText={this.props.intl.formatMessage({ id: 'survey.autocomplete.off' })} onChange={() => { var s = this.state.survey; s.autoComplete = !s.autoComplete; this.setState({ survey: s, shouldSave: true }) }} />
                                <Dropdown
                                    onChange={this.handleAutofillMethod}
                                    label={this.props.intl.formatMessage({ id: 'survey.autocomplete.source' })}
                                    placeholder={this.props.intl.formatMessage({ id: 'survey.completion.method.placeholder' })}
                                    ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                    options={
                                        this.state.surveys.map(s => { return { key: s.id, text: s.name } })
                                    }
                                    selectedKey={this.state.survey.autoCompleteSurveyID}
                                    styles={dropdownStyles}
                                />
                            </Stack>
                            <Stack>
                                <Toggle checked={this.state.survey.isDisplayMyoCorporate}
                                    onText={this.props.intl.formatMessage({ id: 'survey.isdisplay.myocorporate.yes' })}
                                    offText={this.props.intl.formatMessage({ id: 'survey.isdisplay.myocorporate.no' })}
                                    onChange={this.handleToggleChange} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack tokens={stackToks} horizontal >
                    <PrimaryButton onClick={this.onSave} disabled={!this.state.shouldSave} text={this.props.intl.formatMessage({ id: 'std.save' })} />
                    <DefaultButton onClick={this.props.onClose} text={this.state.shouldSave ? this.props.intl.formatMessage({ id: 'std.cancel' }) : this.props.intl.formatMessage({ id: 'std.close' })} />
                </Stack>
                <Separator />

                <Stack horizontal tokens={stackToks}>
                    <Stack>
                        <CommandButton iconProps={addIcon} text={this.props.intl.formatMessage({ id: 'survey.section.new' })} onClick={this.createNewSection} />
                        {/* <Nav
                          onLinkClick={this.selectSection}
                          selectedKey=""
                          ariaLabel={this.props.intl.formatMessage({ id: 'survey.sections' })}
                          styles={navStyles}
                          groups={navLinkGroups}
                        /> */}
                        <List className="SectionList" items={this.state.survey.sections} onRenderCell={onRenderNavCell} />
                    </Stack>
                    <Separator vertical />
                    <Stack grow>
                        <Stack horizontal grow tokens={stackToks}>
                            <Stack grow>
                                <TextField name="currentSectionName" label={this.props.intl.formatMessage({ id: 'survey.section.name' })} onChange={this.handleChange} value={this.state.currentSection.name} />
                                <TextField name="currentSectionDescription" label={this.props.intl.formatMessage({ id: 'survey.section.description' })} onChange={this.handleChange} multiline value={this.state.currentSection.description} />
                                <Slider
                                    label={this.props.intl.formatMessage({ id: 'survey.section.qa' })}
                                    max={100}
                                    value={this.state.currentSection.approvalsRequiredPercent}
                                    ariaValueText={() => '${value} ' + this.props.intl.formatMessage({ id: 'std.percent' })}
                                    valueFormat={sliderValueFormat}
                                    showValue
                                    onChange={this.setpc}
                                />
                                <Text variant="small"><FormattedMessage id="survey.section.qa.help" /></Text>
                                <ActionButton onClick={this.deleteSection} disabled={this.state.currentSection.questions.length > 0} iconProps={deleteIcon}>Delete Section</ActionButton>
                            </Stack>
                        </Stack>
                        <Separator />
                        <Label>Questions</Label>
                        {questions}
                    </Stack>
                </Stack>

                <Dialog
                    hidden={!this.state.isNewSectionOpen}
                    onDismiss={this.closeNewSection}
                    dialogContentProps={dialogContentProps}
                    modalProps={modelProps}
                >
                    <TextField name="newSectionName" label={this.props.intl.formatMessage({ id: 'survey.section.name' })} onChange={this.handleChange} value={this.state.newSectionName} />
                    <TextField name="newSectionDescription" label={this.props.intl.formatMessage({ id: 'survey.section.description' })} onChange={this.handleChange} multiline value={this.state.newSectionDescription} />
                    <DialogFooter>
                        <PrimaryButton onClick={this.onCreateNewSection} text={this.props.intl.formatMessage({ id: 'std.save' })} />
                        <DefaultButton onClick={this.closeNewSection} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
                    </DialogFooter>
                </Dialog>
            </Stack>
        );
    };

}

export default injectIntl(SurveyEdit);