import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import {  
          TextField,
           PrimaryButton, IconButton, 
           IIconProps,
          Spinner, SpinnerSize,
          Stack, IStackStyles, IStackTokens, Label,
          mergeStyleSets,
          List,
          ITheme, getTheme, getFocusStyle,
} from 'office-ui-fabric-react';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


type IState = {
    newString: string,
    list: string[],
}

interface IProps  extends WrappedComponentProps {
  list: string[],
  title: string,
  onChange: (arg0: string[]) => void,
  sort?: boolean
}


class StringListEdit extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);
        

        this.state = {
          newString: "",
          list: [...this.props.list],
        };

        this.handleChange = this.handleChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.createNewItem = this.createNewItem.bind(this);
    }



    private handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
        switch(name)
        {
          case "newString":
            this.setState({
                newString: value
            });
            break;
        }
    }
  
    private deleteItem(index: number)
    {
      var l = Object.assign(this.state.list);
      l.splice(index,1);
      this.props.onChange(l);
    }
  
    private createNewItem()
    {
      var l = Object.assign(this.state.list);
      l.push(this.state.newString);
      if(this.props.sort)     l.sort();
      this.props.onChange(l);

      this.setState({
        newString: "",
      });
    }


    componentDidUpdate(prevProps: IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.list !== prevProps.list) {
        this.setState({ list: [...this.props.list] })
        this.forceUpdate();
      }
    }
  


  
    render() {

      if(this.props.list == null)
      {
        return (
          <Stack grow styles={stackStyles}>
            <Spinner size={SpinnerSize.large} label="Loading List" />
          </Stack>
        );
      }
      
      const theme: ITheme = getTheme();
      const { palette, semanticColors, fonts } = theme;

      const classNames = mergeStyleSets({
        itemCell: [
          getFocusStyle(theme, { inset: -1 }),
          {
            minHeight: 54,
            width: "100%",
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
              '&:hover': { background: palette.neutralLight },
            },
          },
        ],
        itemImage: {
          flexShrink: 0,
        },
        itemContent: {
          marginLeft: 10,
          overflow: 'hidden',
          flexGrow: 1,
        },
        itemName: [
          fonts.medium,
          {
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        ],
        itemIndex: {
          fontSize: fonts.small.fontSize,
          color: palette.neutralTertiary,
          marginBottom: 10,
        },
        chevron: {
          alignSelf: 'center',
          marginLeft: 10,
          color: palette.neutralTertiary,
          fontSize: fonts.large.fontSize,
          flexShrink: 0,
        },
      });

      const deleteIcon: IIconProps = { iconName: 'Delete' };

      const onRenderCell = (item?: string, index?: number | undefined): JSX.Element => {
        return (
          <div className={classNames.itemCell} 
              key={"K" +  index}
              id={"I" + index}
          >
            <div className={classNames.itemContent}>
              <Stack horizontal>
                <div className={classNames.itemName}>{item}</div>
              </Stack>
            </div>
            <IconButton className={classNames.chevron} iconProps={deleteIcon} onClick={() => {this.deleteItem(index || 0);}} />
          </div>
        );
      };
  
      const stackToks: IStackTokens = {
        childrenGap: '16',
      };

      return (
      <Stack grow styles={stackStyles} tokens={stackToks}>
          <Label>{this.props.title}</Label>
          <Stack horizontal tokens={stackToks} >
              <TextField name="newString"  onChange={this.handleChange} value={this.state.newString} />
              <PrimaryButton onClick={this.createNewItem} text={this.props.intl.formatMessage({ id: 'std.add' })} />
          </Stack>                    
          <List  style={{width: 300}} items={this.state.list} onRenderCell={onRenderCell} />
      </Stack>
      
        );
      };
      
}

export default injectIntl(StringListEdit);