import React, { Component } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import Moment from "react-moment";
import { injectIntl, WrappedComponentProps } from "react-intl";

import { API, APIKEY, ICertificate } from "./IVesta";
import { initializeIcons } from "@uifabric/icons";
import { Spinner } from "@fluentui/react/lib/Spinner";
import Certificate from "./Certificate";

import {
    DetailsList,
    IColumn,
    SelectionMode,
    Stack,
    IStackStyles,
    CommandBar,
    ICommandBarItemProps,
    Panel,
    PanelType,
    IconButton,
} from "office-ui-fabric-react";

const stackStyles: IStackStyles = {
    root: {
        width: "500",
    },
};

type Building = {
    name: string;
    description: string;
    validfor: bigint;
};
interface IState {
    user: string;
    selected: ICertificate;
    certificates: ICertificate[];
    isOpen: boolean;
    isCertOpen: boolean;
    selectedID: string;
    isLoading: boolean;
}
interface IProps extends WrappedComponentProps { }
class UserCertificates extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        initializeIcons();
        this.state = {
            user: "",
            selected: {} as ICertificate,
            certificates: [],
            isOpen: false,
            isCertOpen: false,
            selectedID: "",
            isLoading: false,
        };
        this.dismissPanel = this.dismissPanel.bind(this);
        this._editCertificate = this._editCertificate.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        Auth.currentSession()
            .then((session: any) => {
                this.setState({ user: session.idToken.payload.email });
                this.authenticatedrefresh();
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    authenticatedrefresh() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.state.user,
            },
        };
        this.setState({
            isLoading: true,
        });
        axios
            .post(API + "/certificate/ForUser", post)
            .then((response) => {
                this.setState({
                    certificates: response.data.certificates,
                    isLoading: false,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    private _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => { };

    private _editCertificate = (item: ICertificate): void => {
        this.setState({ isOpen: true, selected: item });
    };

    private dismissPanel() {
        this.setState({ isOpen: false });
    }

    public addNewSurvey() { }

    render() {
        const columns: IColumn[] = [
            {
                key: "name",
                name: this.props.intl.formatMessage({
                    id: "certificate.certificate",
                }),
                fieldName: "name",
                minWidth: 120,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                // isSortedDescending: false,
                // sortAscendingAriaLabel: "Sorted A to Z",
                // sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
            },
            {
                key: "client",
                name: this.props.intl.formatMessage({ id: "client.client" }),
                fieldName: "name",
                minWidth: 120,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                // isSortedDescending: false,
                // sortAscendingAriaLabel: "Sorted A to Z",
                // sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: ICertificate) => {
                    if (item && item.building && item.building.client) {
                        return item.building.client.name;
                    }
                    return "";

                },
            },
            {
                key: "address",
                name: this.props.intl.formatMessage({ id: "building.address" }),
                fieldName: "address",
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                // isSortedDescending: false,
                // sortAscendingAriaLabel: "Sorted A to Z",
                // sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: ICertificate) => {
                    var r = item.building && item.building.buildingName;
                    if (item.building && item.building.city !== "") {
                        r += (r !== "" ? ", " : "") + item.building.city;
                    }
                    if (item.building && item.building.country !== "") {
                        r += (r !== "" ? ", " : "") + item.building.country;
                    }
                    return r;
                    // return item.building.address1 + ", " + item.building.address2;
                },
            },
            {
                key: "assessorInfo",
                name: this.props.intl.formatMessage({ id: "building.assessor" }),
                fieldName: "assessorInfo.email",
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: ICertificate) => {
                    if (item && item.building && item.building.assessorInfo)
                        return item.building.assessorInfo.email;
                    else
                        return "";
                },
            },
            {
                key: "status",
                name: this.props.intl.formatMessage({ id: "survey.status" }),
                fieldName: "status",
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                // isSortedDescending: false,
                // sortAscendingAriaLabel: "Sorted A to Z",
                // sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: ICertificate) => {
                    if (item.issued) {
                        return "Certified";
                    } else if (!item.inviteIssued) {
                        return "Not started";
                    } else {
                        return "In Progress";
                    }
                    // if (item.issued) {
                    //   return (
                    //     <>
                    //       Expires On{" "}
                    //       <Moment format="ddd DD MMM YYYY HH:mm">{item.issuedOn}</Moment>
                    //     </>
                    //   );
                    // }
                    // return "In Progress";
                },
            },
            {
                key: "edit",
                name: "Edit",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any, index?: number) => {
                    return (
                        <IconButton
                            iconProps={{ iconName: "Edit" }}
                            onClick={() => this._editCertificate(item)}
                            title="Edit"
                            ariaLabel="Edit"
                        />
                    );
                },
            },
        ];

        const _items: ICommandBarItemProps[] = [
            {
                key: "newItem",
                text: this.props.intl.formatMessage({ id: "survey.add" }),
                cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
                iconProps: { iconName: "Add" },
                onClick: this.addNewSurvey,
            },
        ];

        return (
            <Stack grow styles={stackStyles}>
                <CommandBar
                    items={_items}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                />
                <>
                    {this.state.isLoading ? (
                        <Spinner />
                    ) : (
                        <DetailsList
                            items={this.state.certificates}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                        />
                    )}
                </>

                <Panel
                    isOpen={this.state.isOpen}
                    onDismiss={this.dismissPanel}
                    headerText={this.props.intl.formatMessage({
                        id: "certificate.certificate",
                    })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                    type={PanelType.extraLarge}
                >
                    <Certificate id={this.state.selected.id} user={this.state.user} />
                </Panel>
            </Stack>
        );
    }
}

export default injectIntl(UserCertificates);
