import React, { Component } from "react";
import axios from "axios";
import Moment from "react-moment";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {
    API,
    APIKEY,
    ICertificateSurvey,
    ISurveySection,
    ICertificate,
    stdDayPickerStrings,
} from "./IVesta";

import {
    Dialog,
    DialogType,
    DialogFooter,
    TextField,
    Spinner,
    SpinnerSize,
    Nav,
    INavLinkGroup,
    INavStyles,
    INavLink,
    Label,
    Stack,
    IStackStyles,
    IStackTokens,
    Text,
    Separator,
    DefaultButton,
    PrimaryButton,
    DatePicker,
    DayOfWeek,
    mergeStyleSets,
} from "office-ui-fabric-react";

import SurveySection from "./SurveySection";
import InfoIcon from "./InfoIcon";

const stackStyles: IStackStyles = {
    root: {
        width: "500",
    },
};

interface IState {
    selected: string;
    approveOpen: boolean;
    declineOpen: boolean;
    rejectOpen: boolean;
    messageOpen: boolean;
    subject: string;
    message: string;
    currentSection: any;
    currentSectionKey: any;
    survey: ICertificateSurvey;
}

interface IProps extends WrappedComponentProps {
    survey: ICertificateSurvey;
    user: string;
    userrole: string;
    onUpdate: any;
    buildingName?: string;
}
class Survey extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            survey: props.survey,
            selected: "Undefined",
            approveOpen: false,
            declineOpen: false,
            rejectOpen: false,
            messageOpen: false,
            subject: "",
            message: "",
            currentSection: props.survey.survey.sections[0],
            currentSectionKey: 0,
        };
        this._onLinkClick = this._onLinkClick.bind(this);
        this._message = this._message.bind(this);
        this._approve = this._approve.bind(this);
        this._reject = this._reject.bind(this);
        this._decline = this._decline.bind(this);
        this._approveToggle = this._approveToggle.bind(this);
        this._declineToggle = this._declineToggle.bind(this);
        this._rejectToggle = this._rejectToggle.bind(this);
        this._messageToggle = this._messageToggle.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this._handleCompletionChange = this._handleCompletionChange.bind(this);
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    onUpdate() {
        this.props.onUpdate();
        {
            const post = {
                security: {
                    APIKey: APIKEY,
                    User: this.props.user,
                },
                id: this.state.survey.certificateID,
            };
            axios
                .post(API + "/certificate/view", post)
                .then((response) => {
                    var cert = response.data.certificate as ICertificate;
                    cert.surveys.forEach((i) => {
                        if (i.id === this.state.survey.id) {
                            this.setState({ survey: i });
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    _handleChange(event: any) {
        const target = event.target;
        const value: string =
            target.type === "checkbox" ? target.checked : target.value;
        const name: string = target.name;

        switch (name) {
            case "subject":
                this.setState({ subject: value });
                break;
            case "message":
                this.setState({ message: value });
                break;
        }
    }

    _approveToggle() {
        this.setState({ approveOpen: !this.state.approveOpen });
    }

    _declineToggle() {
        this.setState({ declineOpen: !this.state.declineOpen });
    }

    _rejectToggle() {
        this.setState({ rejectOpen: !this.state.rejectOpen });
    }

    _messageToggle() {
        this.setState({ messageOpen: !this.state.messageOpen });
    }

    _message() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            surveyid: this.state.survey.id,
            certificateid: this.state.survey.certificateID,
            subject: this.state.subject,
            message: this.state.message,
        };

        axios
            .post(API + "/survey/SendMessage", post)
            .then(() => { })
            .catch(function (error) {
                console.log(error);
            });

        this.setState({ messageOpen: false, subject: "", message: "" });
    }

    _approve() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ID: this.state.survey.id,
            approve: true,
            keepanswers: true,
        };
        axios
            .post(API + "/survey/ApproveSurvey", post)
            .then(() => {
                this.setState({ approveOpen: !this.state.approveOpen });
                this.onUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _decline() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ID: this.state.survey.id,
            approve: false,
            keepanswers: true,
        };
        axios
            .post(API + "/survey/ApproveSurvey", post)
            .then(() => {
                this.setState({ declineOpen: !this.state.declineOpen });
                this.onUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _reject() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ID: this.state.survey.id,
            approve: false,
            keepanswers: false,
        };
        axios
            .post(API + "/survey/ApproveSurvey", post)
            .then(() => {
                this.setState({ rejectOpen: false });
                this.onUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _onLinkClick(event: any, item?: INavLink, index?: number) {
        if (item) {
            this.setState({
                //                currentSection: this.state.survey.sections[item.key!],
                currentSectionKey: item.key,
            });
        }
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate() { }

    refresh() { }

    _handleCompletionChange(date?: Date | null) {
        if (date !== null && date !== undefined) {
            var s = this.state.survey;
            s.expectedCompletedDate = date.toString();
            this.setState({ survey: s });
        }
    }

    render() {
        const sections = this.state.survey.survey.sections;

        if (this.state.survey == null) {
            return (
                <Stack grow styles={stackStyles}>
                    <Spinner size={SpinnerSize.large} label="Loading Survey" />
                </Stack>
            );
        }

        const navStyles: Partial<INavStyles> = {
            root: {
                width: 208,
                height: 350,
                boxSizing: "border-box",
                border: "0px",
                overflowY: "auto",
            },
        };

        const controlClass = mergeStyleSets({
            control: {
                margin: "0 0 15px 0",
                maxWidth: "300px",
            },
        });

        const navLinkGroups: INavLinkGroup[] = [
            {
                links: sections.map(function (element: any, i: number) {
                    var icon;
                    if (element.qaPass) icon = "Completed";
                    else icon = "ErrorBadge";

                    return {
                        name: element.name,
                        url: "#",
                        key: i.toString(),
                        icon: icon,
                        isExpanded: true,
                    };
                }),
            },
        ];

        const approveTokens: IStackTokens = {
            childrenGap: "20px",
        };

        const headerTokens: IStackTokens = {
            childrenGap: "20px",
        };

        var disableApprove = false;
        var approvals = <></>;

        var approveBy = <></>;
        let flag = true;
        var BreakException = {};
        try {
            if (this.state.survey.isCompleted) {
                this.state.survey.survey.sections.forEach((section) => {
                    section.questions.forEach((question) => {
                        if (!question.mustApprove) {
                            flag = false;
                        } else {
                            if (question.answer) {
                                if (question.answer.approved) {
                                    flag = false;
                                } else if (
                                    // (!question.mandatory || question.conditional) &&
                                    question.answer.na
                                ) {
                                    flag = false;
                                } else {
                                    throw BreakException;
                                }
                            } else {
                                if (question.conditional) {
                                    flag = false;
                                } else {
                                    throw BreakException;
                                }
                            }
                        }
                    });

                    if (flag) {
                        throw BreakException;
                    }
                });
            }
        } catch (ex) {
            flag = true;
        }

        // disableApprove = this.state.survey.isCompleted
        //   ? this.state.survey.survey.sections.some((section) =>
        //       section.questions.some(
        //         (question) =>
        //           question.mustApprove &&
        //           (!question.answer ||
        //             !question.answer.approved)
        //       )
        //     )
        //   : true;

        disableApprove = flag;

        if (this.state.survey.isApproved) {
            disableApprove = true;
            approvals = (
                <Stack horizontal grow>
                    <Stack.Item grow>
                        <Stack horizontal verticalAlign="center">
                            <Label>Submitted On </Label>
                            <Text variant="medium">
                                <Moment format="ddd DD MMM YYYY HH:mm">
                                    {this.state.survey.completedOn}
                                </Moment>
                            </Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack horizontal verticalAlign="center">
                            <Label>Approved On </Label>
                            <Text variant="medium">
                                <Moment format="ddd DD MMM YYYY HH:mm">
                                    {this.state.survey.approvedOn}
                                </Moment>
                            </Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow={2}>
                        <Stack horizontal verticalAlign="center">
                            <Label>Approved By </Label>
                            <Text variant="medium">
                                {this.state.survey.approvedBy.email || "Unknown"}
                            </Text>
                        </Stack>
                    </Stack.Item>
                </Stack>
            );
        } else {
            const today = this.addDays(new Date(), -1);
            const dt =
                (this.state.survey.expectedCompletedDate &&
                    new Date(this.state.survey.expectedCompletedDate)) ||
                undefined;
            approveBy = (
                <DatePicker
                    label={this.props.intl.formatMessage({
                        id: "survey.completion.date",
                    })}
                    className={controlClass.control}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={stdDayPickerStrings}
                    ariaLabel={this.props.intl.formatMessage({ id: "aria.select.date" })}
                    value={dt}
                    onSelectDate={this._handleCompletionChange}
                    minDate={today}
                />
            );
        }

        var approve;
        if (this.props.userrole === "review") {
            approve = (
                <Stack horizontal horizontalAlign="start" tokens={approveTokens}>
                    <PrimaryButton
                        disabled={disableApprove}
                        onClick={this._approveToggle}
                        text="Approve"
                    />
                    <DefaultButton onClick={this._messageToggle} text="Send a message" />
                    <DefaultButton onClick={this._declineToggle} text="Decline" />
                    <DefaultButton onClick={this._rejectToggle} text="Reject" />
                </Stack>
            );
        }

        var unit = <></>;
        if (this.state.survey.survey.completionMethod === 2) {
            unit = <Text variant="large">Desk Based Survey</Text>;
        } else {
            unit = <TextField label="Unit" readOnly value={this.state.survey.unit} />;
        }

        const approveContentProps = {
            type: DialogType.normal,
            title: "Approve Survey",
            closeButtonAriaLabel: "Close",
            subText: "Are you happy with this survey?",
        };

        const declineContentProps = {
            type: DialogType.normal,
            title: "Decline Survey",
            closeButtonAriaLabel: "Close",
            subText:
                "If you decline the survey, it will return to the ToDo list for the building manager.",
        };

        const rejectContentProps = {
            type: DialogType.normal,
            title: "Reject Survey",
            closeButtonAriaLabel: "Close",
            subText:
                "Are you sure you want to reject the entire survey? This will require the user to re-enter the entire survey.",
        };

        const messageContentProps = {
            type: DialogType.normal,
            title: "Send a message",
            closeButtonAriaLabel: "Close",
            subText: "Send a message to the building manager.",
        };

        return (
            <Stack grow styles={stackStyles}>
                <Stack tokens={headerTokens}>
                    <Stack horizontal grow verticalAlign="end">
                        <Stack.Item grow>
                            <Text variant="xxLarge">{this.props.buildingName} / {this.state.survey.survey.name}</Text>
                        </Stack.Item>
                        <InfoIcon id={this.state.survey.id} />
                    </Stack>

                    {unit}
                    {approve}
                    {approveBy}
                    {approvals}
                </Stack>
                <Separator />
                <Stack horizontal grow>
                    <Stack.Item>
                        <Nav
                            onLinkClick={this._onLinkClick}
                            selectedKey=""
                            ariaLabel="Survey Sections"
                            styles={navStyles}
                            groups={navLinkGroups}
                        />
                    </Stack.Item>
                    <Separator vertical />
                    <Stack.Item grow>
                        <SurveySection
                            certificateID={this.state.survey.certificateID || ""}
                            surveyID={this.state.survey.id}
                            user={this.props.user}
                            role={this.props.userrole}
                            section={
                                this.state.survey.survey.sections[this.state.currentSectionKey]
                            }
                            onUpdate={this.onUpdate}
                        />
                    </Stack.Item>
                </Stack>
                <Dialog
                    hidden={!this.state.approveOpen}
                    onDismiss={this._approveToggle}
                    dialogContentProps={approveContentProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this._approve} text="Approve" />
                        <DefaultButton onClick={this._approveToggle} text="Cancel" />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!this.state.declineOpen}
                    onDismiss={this._declineToggle}
                    dialogContentProps={declineContentProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this._decline} text="Decline" />
                        <DefaultButton onClick={this._declineToggle} text="Cancel" />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!this.state.rejectOpen}
                    onDismiss={this._rejectToggle}
                    dialogContentProps={rejectContentProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this._reject} text="Reject" />
                        <DefaultButton onClick={this._rejectToggle} text="Cancel" />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!this.state.messageOpen}
                    onDismiss={this._messageToggle}
                    dialogContentProps={messageContentProps}
                    maxWidth={400}
                    minWidth={400}
                >
                    <TextField
                        label="Subject"
                        value={this.state.subject}
                        name="subject"
                        onChange={this._handleChange}
                    />
                    <TextField
                        label="Message"
                        multiline
                        rows={5}
                        value={this.state.message}
                        name="message"
                        onChange={this._handleChange}
                    />
                    <DialogFooter>
                        <PrimaryButton onClick={this._message} text="Send" />
                        <DefaultButton onClick={this._messageToggle} text="Cancel" />
                    </DialogFooter>
                </Dialog>
            </Stack>
        );
    }
}

export default injectIntl(Survey);
