import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { API, APIKEY, ISurvey, IPagination } from './IVesta';
import { initializeIcons } from '@uifabric/icons';

import {
    Panel, PanelType,
    TextField,
    Dropdown, CommandBar, ICommandBarItemProps,
    DetailsList, SelectionMode, IColumn,
    DefaultButton, PrimaryButton, IButtonProps, IconButton,
    Stack, IStackTokens, IStackStyles,
    Text,
    Dialog, DialogType, DialogFooter,
    TooltipHost, ITooltipHostStyles,
    Toggle,
} from 'office-ui-fabric-react/';
import { Pagination } from '@uifabric/experiments';

import SurveyEdit from './SurveyEdit';

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const stackStyles: IStackStyles = {
    root: {
        width: "500"
    },
};

type Survey = {
    name: string,
    description: string,
    validfor: bigint
}

interface IProps extends WrappedComponentProps { }

interface IState {
    user: string,
    selected: any,
    surveys: ISurvey[],
    isOpen: boolean,
    deleteOpen: boolean,
    isEditOpen: boolean,
    newSurveyName: string,
    newSurveyDescription: string,
    newSurveyValidFor: string,
    canCreate: boolean,
    panelTitle: string,
    pagination: IPagination,
    totalCount: number,
    selectedID: string,
    PropertyTypes: string[],
    CompletionTypes: string[],
    selectedSurveyType: string,
    selectedPropertyType: string,
    selectedCompletionType: string,
    isDisplayMyoCorporate: boolean,
}

const _overflowItems: ICommandBarItemProps[] = [];

const buttonToks: IStackTokens = {
    childrenGap: '6'
};

class Surveys extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        initializeIcons();

        this.state = {
            user: "",
            selected: "Undefined",
            surveys: [],
            isOpen: false,
            deleteOpen: false,
            isEditOpen: false,
            newSurveyName: "",
            newSurveyDescription: "",
            newSurveyValidFor: "",
            canCreate: true,
            panelTitle: "",
            pagination: {
                current: 0,
                pageSize: 10,
                sortField: "",
                sortOrder: "asc",
            },
            totalCount: 0,
            selectedID: "",
            PropertyTypes: [],
            CompletionTypes: [],
            selectedSurveyType: "",
            selectedPropertyType: "",
            selectedCompletionType: "",
            isDisplayMyoCorporate: true
        };

        this.dismissPanel = this.dismissPanel.bind(this);
        this.addNewSurvey = this.addNewSurvey.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidForChange = this.handleValidForChange.bind(this);
        this.editSurvey = this.editSurvey.bind(this);
        this.copySurvey = this.copySurvey.bind(this);
        this.deleteSurvey = this.deleteSurvey.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this._onToggleDelete = this._onToggleDelete.bind(this);
        this.onDeleteSurvey = this.onDeleteSurvey.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const value: string = target.type === 'checkbox' ? target.checked : target.value;
        const name: string = target.name;

        switch (name) {
            case "newSurveyName":
                this.setState({
                    newSurveyName: value
                });
                break;
            case "newSurveyDescription":
                this.setState({
                    newSurveyDescription: value
                });
                break;
        }
    }

    handleValidForChange(event: any) {
        const target = event.target;
        const value: string = target.value;

        this.setState({
            newSurveyValidFor: value
        });
    }

    handleSurveyTypeChange = (event: any, option: any) => {
        this.setState({ selectedSurveyType: option.key });
    }

    handlePropertyTypeChange = (event: any, option: any) => {
        this.setState({ selectedPropertyType: option.key });
    }
    handleCompletionTypeChange = (event: any, option: any) => {
        this.setState({ selectedCompletionType: option.key });
    }

    private onEdit() {
        this.setState({
            isEditOpen: true,
        })
    }

    private editSurvey(item: ISurvey) {
        this.setState({ isEditOpen: true, panelTitle: "Edit Survey", selected: item.id || "" });
    }

    private copySurvey(item: ISurvey) {
        this.setState({ isOpen: true, panelTitle: "Copy Survey " + item.name, selected: item.id || "" });
    }

    private closeEdit() {

        this.setState({
            isEditOpen: false,
        });
        this.authenticatedrefresh();
    }

    private onCreate() {

        if (this.state.selected === "") {
            const post = {
                Security: {
                    APIKey: APIKEY,
                    User: this.state.user
                },
                Name: this.state.newSurveyName,
                Description: this.state.newSurveyDescription,
                Validfor: this.state.newSurveyValidFor,
                SurveyType: this.state.selectedSurveyType,
                PropertyType: this.state.selectedPropertyType,
                CompletionType: this.state.selectedCompletionType,
                IsDisplayMyoCorporate: this.state.isDisplayMyoCorporate,
            }
            axios.post(API + "/survey/Create", post)
                .then(() => {
                    this.refresh();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            const post = {
                Security: {
                    APIKey: APIKEY,
                    User: this.state.user
                },
                Name: this.state.newSurveyName,
                Description: this.state.newSurveyDescription,
                Validfor: this.state.newSurveyValidFor,
                ID: this.state.selected,
                SurveyType: this.state.selectedSurveyType,
                PropertyType: this.state.selectedPropertyType,
                CompletionType: this.state.selectedCompletionType,
                IsDisplayMyoCorporate: this.state.isDisplayMyoCorporate,
            }
            axios.post(API + "/survey/Copy", post)
                .then(() => {
                    this.refresh();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        this.setState({
            isOpen: false,
            newSurveyName: "",
            newSurveyDescription: "",
            newSurveyValidFor: "",
            selectedSurveyType: "",
            selectedPropertyType: "",
            selectedCompletionType: "",
        })
    }

    private onCancel() {
        this.setState({
            isOpen: false,
            newSurveyName: "",
            newSurveyDescription: "",
            newSurveyValidFor: "",
        })
    }

    private dismissPanel() {
        this.setState({ isOpen: false });
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        Auth.currentSession()
            .then((session: any) => {
                this.setState({ user: session.idToken.payload.email });
                this.authenticatedrefresh();
            })
            .catch(function (err) {
                console.log(err)
            });
    }

    authenticatedrefresh() {
        const params = this.state.pagination;

        const post = {
            security: {
                APIKey: APIKEY,
                User: this.state.user
            },
            current: params.current + 1,
            pagesize: params.pageSize,
            sortfield: params.sortField,
            sortorder: params.sortOrder,
        }
        axios.post(API + "/survey/search", post)
            .then(response => {
                this.setState({
                    surveys: response.data.surveys,
                    totalCount: response.data.totalCount,
                    PropertyTypes: response.data.propertyTypes,
                    CompletionTypes: response.data.completionTypes,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onPageChange(page: number) {
        var p: IPagination = Object.assign(this.state.pagination);
        p.current = page;
        this.setState({ pagination: p });
        this.authenticatedrefresh();
    }

    private addNewSurvey() {
        this.setState({ isOpen: true, panelTitle: "New Survey", selected: "" });
    }

    private deleteSurvey(id: string) {
        this.setState({ deleteOpen: true, selectedID: id })
    }

    _onToggleDelete() {
        this.setState({ deleteOpen: !this.state.deleteOpen })
    }

    handleToggleChange = (event: any, checked: any) => {
        this.setState({ isDisplayMyoCorporate: checked });
    }

    onDeleteSurvey() {
        const post = {
            Security: {
                APIKey: APIKEY,
                User: this.state.user
            },
            id: this.state.selectedID,
        }
        axios.post(API + "/survey/delete", post)
            .then(() => {
                this.setState({ deleteOpen: false });
                this.authenticatedrefresh();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {

        const { surveys } = this.state;
        const columns: IColumn[] = [
            {
                key: 'column2',
                name: this.props.intl.formatMessage({ id: 'survey.name' }),
                fieldName: 'name',
                minWidth: 150,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: this.props.intl.formatMessage({ id: 'survey.description' }),
                fieldName: 'description',
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: this.props.intl.formatMessage({ id: 'survey.type' }),
                fieldName: 'surveyType',
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: this.props.intl.formatMessage({ id: 'survey.type.property.type' }),
                fieldName: 'propertyType',
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'method',
                name: this.props.intl.formatMessage({ id: 'survey.completion.method' }),
                fieldName: 'completionMethod',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                data: 'number',
                onRender: (item: ISurvey) => {
                    var value: string = "Unspecified";
                    switch (item.completionMethod) {
                        case 1:
                            value = this.props.intl.formatMessage({ id: 'survey.completion.site' }); break;
                        case 2:
                            value = this.props.intl.formatMessage({ id: 'survey.completion.desk' }); break;
                    }
                    return <span>{value}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column6',
                name: this.props.intl.formatMessage({ id: 'survey.valid.for' }),
                fieldName: 'validFor',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                data: 'number',
                onRender: (item: ISurvey) => {
                    var value: string = this.props.intl.formatMessage({ id: 'survey.valid.for.eternal' });
                    switch (item.validFor) {
                        case 7:
                            value = this.props.intl.formatMessage({ id: 'survey.valid.for.week' }); break;
                        case 31:
                            value = this.props.intl.formatMessage({ id: 'survey.valid.for.1month' }); break;
                        case 90:
                            value = this.props.intl.formatMessage({ id: 'survey.valid.for.3months' }); break;
                        case 180:
                            value = this.props.intl.formatMessage({ id: 'survey.valid.for.6months' }); break;
                        case 365:
                            value = this.props.intl.formatMessage({ id: 'survey.valid.for.1year' }); break;
                        case 730:
                            value = this.props.intl.formatMessage({ id: 'survey.valid.for.2year' }); break;
                    }

                    return <span>{value}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column7',
                name: this.props.intl.formatMessage({ id: 'survey.used' }),
                fieldName: 'certificateCount',
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: 'number',
                isPadded: true,
                onRender: (item: ISurvey) => {
                    const tooltipId = "tt" + item.id || "";
                    return <TooltipHost
                        content={item.templateCount + " templates / " + item.certificateCount + " certificates"}
                        id={tooltipId}
                        calloutProps={{ gapSpace: 0 }}
                    >
                        <span>{item.templateCount} / {item.certificateCount}</span>
                    </TooltipHost>
                }
            },
            {
                key: 'edit',
                name: 'Edit',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: ISurvey, index?: number) => {
                    return <IconButton iconProps={{ iconName: 'Edit' }} onClick={() => this.editSurvey(item)} title="Edit" ariaLabel="Edit" />
                },
            },
            {
                key: 'copy',
                name: 'Copy',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: ISurvey, index?: number) => {
                    return <IconButton iconProps={{ iconName: 'Copy' }} onClick={() => this.copySurvey(item)} title="Copy" ariaLabel="Copy" />
                },
            },
            {
                key: 'delete',
                name: 'Delete',
                ariaLabel: '',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: ISurvey) => {
                    return <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this.deleteSurvey(item.id || "")} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
                },
            }

        ];

        const _items: ICommandBarItemProps[] = [
            {
                key: 'newItem',
                text: this.props.intl.formatMessage({ id: 'survey.new' }),
                cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
                iconProps: { iconName: 'Add' },
                onClick: this.addNewSurvey
            },
        ];

        const deleteContentProps = {
            type: DialogType.largeHeader,
            title: this.props.intl.formatMessage({ id: 'survey.delete.dialog.title' }),
            subText: this.props.intl.formatMessage({ id: 'survey.delete.dialog.text' }),
        };

        const modalProps = {
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        };

        var pageCount = Math.floor(this.state.totalCount / this.state.pagination.pageSize) + 1;

        return (
            <Stack grow styles={stackStyles}>
                <Text variant="xLarge" >Surveys</Text>
                <CommandBar
                    items={_items}
                    overflowItems={_overflowItems}
                    overflowButtonProps={overflowProps}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                />
                <Pagination
                    selectedPageIndex={this.state.pagination.current}
                    pageCount={pageCount}
                    onPageChange={this.onPageChange}
                    format="buttons"
                    firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                    previousPageIconProps={{ iconName: 'ChevronLeft' }}
                    nextPageIconProps={{ iconName: 'ChevronRight' }}
                    lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
                />

                <DetailsList selectionMode={SelectionMode.none} items={surveys} columns={columns} />

                <Panel
                    isOpen={this.state.isOpen}
                    onDismiss={this.dismissPanel}
                    headerText={this.state.panelTitle}
                    closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                >
                    <Stack>
                        <TextField name="newSurveyName" label={this.props.intl.formatMessage({ id: 'survey.name' })} onChange={this.handleChange} value={this.state.newSurveyName} />
                        <TextField name="newSurveyDescription" label={this.props.intl.formatMessage({ id: 'survey.description' })} onChange={this.handleChange} multiline value={this.state.newSurveyDescription} />
                        <Dropdown
                            onChange={this.handleValidForChange}
                            label={this.props.intl.formatMessage({ id: 'survey.valid.for' })}
                            placeholder={this.props.intl.formatMessage({ id: 'survey.valid.for.placeholder' })}
                            ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                            options={[
                                { key: 'A', text: '0', title: this.props.intl.formatMessage({ id: 'survey.valid.for.eternal' }) },
                                { key: 'B', text: '7', title: this.props.intl.formatMessage({ id: 'survey.valid.for.week' }) },
                                { key: 'C', text: '31', title: this.props.intl.formatMessage({ id: 'survey.valid.for.1month' }) },
                                { key: 'D', text: '90', title: this.props.intl.formatMessage({ id: 'survey.valid.for.3months' }) },
                                { key: 'E', text: '180', title: this.props.intl.formatMessage({ id: 'survey.valid.for.6months' }) },
                                { key: 'F', text: '365', title: this.props.intl.formatMessage({ id: 'survey.valid.for.1year' }) },
                                { key: 'G', text: '730', title: this.props.intl.formatMessage({ id: 'survey.valid.for.2year' }) },
                            ]}
                            required={true}
                        />
                        <Dropdown
                            onChange={this.handleSurveyTypeChange}
                            label={this.props.intl.formatMessage({ id: 'survey.type' })}
                            placeholder={this.props.intl.formatMessage({ id: 'survey.type.placeholder' })}
                            ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                            options={[
                                { key: 'Brand', text: 'Brand', title: this.props.intl.formatMessage({ id: 'survey.type.brand' }) },
                                { key: 'Property', text: 'Property', title: this.props.intl.formatMessage({ id: 'survey.type.property' }) },
                            ]}
                            required={true}
                        />
                        {this.state.selectedSurveyType === 'Property' && (
                            <Stack>
                                <Dropdown
                                    onChange={this.handlePropertyTypeChange}
                                    label={this.props.intl.formatMessage({ id: 'survey.type.property.type' })}
                                    placeholder={this.props.intl.formatMessage({ id: 'survey.type.placeholder' })}
                                    ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                    options={this.state.PropertyTypes.map(type => ({
                                        key: type,
                                        text: type,
                                        title: this.props.intl.formatMessage({ id: 'survey.type.property' }),
                                    }))}
                                    required={true}
                                />
                                <Dropdown
                                    onChange={this.handleCompletionTypeChange}
                                    label={this.props.intl.formatMessage({ id: 'survey.property.completion.type' })}
                                    placeholder={this.props.intl.formatMessage({ id: 'survey.type.placeholder' })}
                                    ariaLabel={this.props.intl.formatMessage({ id: 'aria.select.option' })}
                                    options={this.state.CompletionTypes.map(type => ({
                                        key: type,
                                        text: type,
                                        title: this.props.intl.formatMessage({ id: 'survey.type.property' }),
                                    }))}
                                    required={true}
                                />
                            </Stack>
                        )}
                        <Stack style={{
                            paddingTop: 10,
                        }}>
                            <Toggle
                                checked={this.state.isDisplayMyoCorporate}
                                onText={this.props.intl.formatMessage({ id: 'survey.isdisplay.myocorporate.yes' })}
                                offText={this.props.intl.formatMessage({ id: 'survey.isdisplay.myocorporate.no' })}
                                onChange={this.handleToggleChange}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign="end" tokens={buttonToks} padding="l2">
                        <DefaultButton text={this.props.intl.formatMessage({ id: 'std.cancel' })} onClick={this.onCancel} allowDisabledFocus />
                        <PrimaryButton text="Create" onClick={this.onCreate} allowDisabledFocus disabled={!this.state.canCreate} />
                    </Stack>
                </Panel>

                <Panel
                    isOpen={this.state.isEditOpen}
                    onDismiss={this.closeEdit}
                    headerText={this.props.intl.formatMessage({ id: 'survey.edit' })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                    type={PanelType.extraLarge}
                >
                    <SurveyEdit user={this.state.user} surveyID={this.state.selected} onClose={this.closeEdit} />
                </Panel>
                <Dialog
                    hidden={!this.state.deleteOpen}
                    onDismiss={this._onToggleDelete}
                    dialogContentProps={deleteContentProps}
                    modalProps={modalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => { this.onDeleteSurvey() }} text={this.props.intl.formatMessage({ id: 'std.delete' })} />
                        <DefaultButton onClick={this._onToggleDelete} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
                    </DialogFooter>
                </Dialog>
            </Stack>

        );
    };

}

export default injectIntl(Surveys);