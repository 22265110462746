import React, { Component } from 'react';
import { TagPicker,  BasePicker, ITag, IInputProps,  IBasePickerProps } from '@fluentui/react/lib/Pickers';
import {  formatEmail } from '../IVesta';
import {  Stack
} from 'office-ui-fabric-react';



interface IContactPickerProps {
    label?: string,
    name?: any,
    removeButtonAriaLabel?: string,
    onResolveSuggestions?: any,
    filterSuggestedTags?: any,
    getTextFromItem?: any,
    pickerSuggestionsProps?: any,
    onItemSelected?: any,
    selectedItems?: any,
    onChange?: any,
    onBlur?: any
    itemLimit?: number
    disabled?: boolean
    inputProps?: IInputProps
    required?: boolean,
    pickerValue?: BasePicker<ITag, IBasePickerProps<ITag>>
    onInputChange?: any
    onGetErrorMessage?: any,
    errorMessage?: string,
    showItemsOnFocus?: boolean

}

interface IState {
    alertDisplay: boolean,
    alertCss: string
}

class ContactPicker extends Component<IContactPickerProps, IState>
{
    constructor(props: any) {
        super(props);
        
        this.state = {
            alertCss: "none",
            alertDisplay: false
        }

        
    }

    filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
  
        var filter: ITag[] = [];

        if(this.props.onResolveSuggestions)
        {
           filter = this.props.onResolveSuggestions(filterText, tagList);

           if(tagList && this.props.pickerValue) 
           {
               if(tagList.length === 0){
                   this.props.pickerValue.dismissSuggestions();
               }
           }

        }

        return filter;
    };
    
    onChange = (items?: ITag[]) => 
    {
        if(this.props.onChange) 
        {
            this.props.onChange(items);
        }

        if(items)
        {

            this.setState( {alertCss: items.length === 0 ? "" : "none"} );
        }
    }

    onBlur = (html: any) =>
    {

        var email = html.target.defaultValue;

        if(this.props.onBlur) {
            this.props.onBlur(html);
        }

        this.onValidateInput(email)
    }

    onValidateInput = (input: string) =>
    {
        var state = 0;
        if(this.props.onGetErrorMessage)
        {
            var result = this.props.onGetErrorMessage(formatEmail(input));
            state = result === "" ? 0 : 2;    
        }
        this.setState( {alertCss: state === 0 ? "none" : ""} );
        return state;
    }

    onInputChange = (input: string) =>
    {

        //const el1 = document.getElementById('email')

        var ret: string = input;
        if(this.props.onInputChange){
            ret = this.props.onInputChange(input);
        }

        return ret;
    }

    onEmptyInputFocus = (selectedItems?: ITag[] | undefined): ITag[] => {

        var filter: ITag[] = [];

        if(this.props.onResolveSuggestions)
        {
           filter = this.props.onResolveSuggestions("", selectedItems);
           

        }

        return filter;
    }

    render() {

        const requiredCss = `ms-Label ${(this.props.required ? " root-353" : " root-366")}`;
        return (


            <Stack>

                {this.props.label &&
                    <label htmlFor={this.props.name} className={requiredCss}>{this.props.label}</label>                
                }

                <TagPicker
                    removeButtonAriaLabel={this.props.removeButtonAriaLabel}
                    onResolveSuggestions={this.filterSuggestedTags}
                    getTextFromItem={this.props.getTextFromItem}
                    pickerSuggestionsProps={this.props.pickerSuggestionsProps}
                    itemLimit={this.props.itemLimit}
                    disabled={this.props.disabled}
                    onItemSelected={this.props.onItemSelected}
                    selectedItems={this.props.selectedItems}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    aria-invalid={false}
                    inputProps={this.props.inputProps}
                    onValidateInput={this.onValidateInput}
                    onInputChange={this.onInputChange}
                    onEmptyInputFocus={this.onEmptyInputFocus}

                />  

                <div role="alert" style={{display: this.state.alertCss}}>   
                    <p className="ms-TextField-errorMessage errorMessage-347">{this.props.errorMessage}</p>
                </div>         

           </Stack>


        );

  }


}


export default ContactPicker

