import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { API, APIKEY, IQuestion, ISurvey, ISurveySection, IObjectAttachment, ISection, ISubSection } from './IVesta';
import {
    Dropdown, IDropdownStyles, IDropdownOption,
    TextField, ITextFieldStyles,
    Toggle, Image, ImageFit,
    DefaultButton, PrimaryButton,
    Stack, IStackStyles, Text,
    Pivot, PivotItem,
    SpinButton, ISpinButtonStyles, Position,
    ChoiceGroup, Checkbox, IconButton, IIconProps,
    MessageBarType, MessageBar
} from 'office-ui-fabric-react';

import CheckboxGroup from './CheckboxGroup';
import StringListEdit from './StringListEdit';
import { getTheme, mergeStyles } from '@fluentui/react';

const addIcon: IIconProps = { iconName: 'Add' };
const removeIcon: IIconProps = { iconName: 'Remove' };
const DEFAULT_INDEX: number = 0;
const theme = getTheme();

// Define custom styles
const borderedButtonStyles = mergeStyles({
    border: `2px solid ${theme.palette.themePrimary}`,
});

const stackStyles: IStackStyles = {
    root: {
        width: "500"
    },
};

type IState = {
    question: IQuestion,

    isopen: boolean,

    translanguage: string,
    transdescription: string,
    transguidance: string,
    transoperatorguidance: string,
    transevidenceprompt: string,
    transevidencename: string,
    transevidenceguidance: string,
    transoptions: string,

    surveys: ISurvey[],
    survey: ISurvey,
    section: ISurveySection,
    sectionkey: string,
    shouldSave: boolean,
    sections: ISection[],
    subSections: ISubSection[],
    showNotification: boolean,
    alertMessage: string,
    alertType: MessageBarType,
}

interface IProps extends WrappedComponentProps {
    question: any,
    attachments: IObjectAttachment[],
    onClose: any,
    onSave: any,
    surveySectionID?: string,
    user: string,
    currentSurvey: ISurvey,
}

class QuestionEdit extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        if (this.props.question == null) {
            this.state = {
                isopen: false,

                question: {
                    id: "",
                    type: 0,
                    description: "",
                    guidance: "",
                    gpsTagged: true,
                    gpsRingfenced: true,
                    evidencerequired: 0,
                    evidencetype: 0,
                    evidenceprompt: "",
                    evidencename: "",
                    isDisplayMyoCorporate: this.props.currentSurvey.isDisplayMyoCorporate,
                    isAnswersYes: false,
                    section: "",
                    subSection: "",
                    positiveDisplay: "",
                    negativeDisplay: "",
                    introductionToTextAnswer: "",
                    imageMyoDescription: "",
                    videoMyoDescription: "",
                    numberMyoDescription: "",
                    dateMyoDescription: "",
                    manyOfMyoDescription: "",
                    documentMyoDescription: "",
                    oneOfMyoDescription: {},
                    color: "",
                    negativeColor: "",
                } as IQuestion,

                translanguage: "fr",
                transdescription: "",
                transguidance: "",
                transoperatorguidance: "",
                transevidenceprompt: "",
                transevidencename: "",
                transevidenceguidance: "",
                transoptions: "",

                surveys: [],
                survey: {} as ISurvey,
                section: {} as ISurveySection,
                sectionkey: "",
                sections: [],
                shouldSave: false,
                subSections: [],
                showNotification: false,
                alertMessage: '',
                alertType: MessageBarType.info,
            };
        }
        else {
            var q = this.props.question as IQuestion;
            if (q.translations == null) {
                q.translations = [];
            }
            var found = q.translations.find(e => e.language === "fr");
            if (found === undefined) {
                found = {
                    language: "fr",
                    description: "",
                    assessorguidance: "",
                    operatorguidance: "",
                    evidencename: "",
                    evidenceprompt: "",
                    evidenceguidance: "",
                    options: "",
                };
            }

            if (q.section == null) {
                q.section = "";
            }

            if (q.subSection == null) {
                q.subSection = "";
            }
            if (Array.isArray(q.oneOfMyoDescription) && q.oneOfMyoDescription.length < 1) {
                q.oneOfMyoDescription = [{ 'oneOfMyoDescription0': '', color0: '' }]
            }

            this.state = {
                isopen: false,
                question: this.props.question,

                translanguage: "fr",
                transdescription: found.description,
                transguidance: found.assessorguidance,
                transoperatorguidance: found.operatorguidance,
                transevidencename: found.evidencename,
                transevidenceprompt: found.evidenceprompt,
                transevidenceguidance: found.evidenceguidance,
                transoptions: found.options,

                surveys: [],
                survey: {} as ISurvey,
                section: {} as ISurveySection,
                sectionkey: "",
                shouldSave: false,
                sections: [],
                subSections: [],
                showNotification: false,
                alertMessage: '',
                alertType: MessageBarType.info,
            };
        }

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.setlanguage = this.setlanguage.bind(this);
        this.settype = this.settype.bind(this);
        this.setsubsection = this.setsubsection.bind(this);
        this.setcolor = this.setcolor.bind(this);
        this.setnegativecolor = this.setnegativecolor.bind(this);
        this.setevidencetype = this.setevidencetype.bind(this);
        this.setevidencereq = this.setevidencereq.bind(this);
        this.setsurvey = this.setsurvey.bind(this);
        this.setsection = this.setsection.bind(this);
        this.setcondquestion = this.setcondquestion.bind(this);
        this.setattachment = this.setattachment.bind(this);
        this.handleLeawayChange = this.handleLeawayChange.bind(this);
        this.handleCheckboxGroup = this.handleCheckboxGroup.bind(this);
        this.handleChoiceGroup = this.handleChoiceGroup.bind(this);
        this.handleContactOpts = this.handleContactOpts.bind(this);
        this.handleListOpts = this.handleListOpts.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.handleAddRow = this.handleAddRow.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
    }


    handleCloseAlert = () => {
        this.setState({ showNotification: false });
    };

    displayAlert = (message: string, type = MessageBarType.info) => {
        this.setState({
            alertMessage: message,
            alertType: type,
            showNotification: true,
        });
    };

    save() {
        const { question } = this.state;
        const { currentSurvey } = this.props;
        let alertTriggered = false;

        if (question.isDisplayMyoCorporate) {
            if (!question.section || (!question.subSection && (currentSurvey.propertyType !== "Single Contained" && currentSurvey.surveyType !== "Brand"))) {
                alertTriggered = true;
            }
            if (question.section && !this.state.sections.some(sec => sec.name === question.section)) {
                alertTriggered = true;
            }

            switch (question.type) {
                case 0:
                    if (!alertTriggered) {
                        if (!question.isAnswersYes) {
                            if ((!question.negativeDisplay || !question.negativeColor) || (!question.positiveDisplay || !question.color)) {
                                alertTriggered = true;
                            }
                        } else {
                            if (!question.positiveDisplay || !question.color) {
                                alertTriggered = true;
                            }
                        }
                    }
                    break;
                case 1:
                    if (!alertTriggered && (!question.introductionToTextAnswer || !question.color)) {
                        alertTriggered = true;
                    }
                    break;
                case 12:
                    if (!alertTriggered && !question.imageMyoDescription) {
                        alertTriggered = true;
                    }
                    break;
                case 13:
                    if (!alertTriggered && !question.videoMyoDescription) {
                        alertTriggered = true;
                    }
                    break;
                case 2:
                    if (!alertTriggered && (!question.numberMyoDescription || !question.color)) {
                        alertTriggered = true;
                    }
                    break;
                case 4:
                    if (!alertTriggered && (!question.dateMyoDescription || !question.color)) {
                        alertTriggered = true;
                    }
                    break;
                case 5:
                    if (!alertTriggered) {
                        const options = question.options ? question.options.split(';') : [];
                        const answers = question.oneOfMyoDescription;
                        const adjustedAnswers = answers.filter(answer => {
                            const value = Object.values(answer)[0];
                            const color = Object.values(answer)[1];
                            return value && value.trim() !== '' && color && color.trim() !== '';
                        });

                        const optionsLength = options.length;
                        const adjustedAnswersLength = adjustedAnswers.length;

                        if (optionsLength !== adjustedAnswersLength || adjustedAnswersLength !== answers.length) {
                            alertTriggered = true;
                        }
                    }
                    break;
                case 6:
                    if (!alertTriggered && (!question.manyOfMyoDescription || !question.color)) {
                        alertTriggered = true;
                    }
                    break;
                case 14:
                    if (!alertTriggered && !question.documentMyoDescription) {
                        alertTriggered = true;
                    }
                    break;
                default:
                    break;
            }
        }
        if (alertTriggered) {
            this.displayAlert("All mandatory fields need completing", MessageBarType.error);
        }
        else {
            this.props.onSave(question);
        }
    }

    close() {

        this.props.onClose();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.refreshsurveys();
        this.getSection()
    }

    refreshsurveys() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
        }
        axios.post(API + "/survey/search", post)
            .then(response => {
                if ((this.props.question.conditionalSurveyID || "") !== "") {
                    //  Need to set condition
                    var surveys: ISurvey[] = response.data.surveys as ISurvey[];
                    var survey = surveys.find(e => e.id === this.props.question.conditionalSurveyID);
                    if (survey != null) {
                        var i = this.findSectionIndex(survey, this.props.question.conditionalQuestionID);

                        this.setState({
                            sectionkey: 'SEC' + i,
                            surveys: surveys,
                            survey: survey,
                            section: survey.sections[i],
                        });
                    }
                    else {
                        this.setState({ surveys: response.data.surveys });
                    }
                }
                else {
                    this.setState({ surveys: response.data.surveys });
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getSection() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
        }
        axios.post(API + "/survey/Section", post)
            .then(response => {
                var sections: ISection[] = response.data.section as ISection[];
                const filteredSections = this.props.currentSurvey.surveyType === "Brand"
                    ? sections.filter(section => !section.isProperty)
                    : sections.filter(section => section.isProperty);

                this.setState({ sections: filteredSections });
                if (this.state.question.section) {
                    const selectedSection = filteredSections.find((section: ISection) => section.name === this.state.question.section);
                    if (selectedSection) {
                        this.setState({ subSections: selectedSection.subsection });
                    } else {
                        this.setState({ subSections: [] });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    refreshattachments() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
        }
        axios.post(API + "/client/profile", post)
            .then(response => {
                this.setState({});
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    findSectionIndex(survey: ISurvey, questionID: string) {
        for (var i = 0; i < survey.sections.length; i++) {
            for (var j = 0; j < survey.sections[i].questions.length; j++) {
                if (survey.sections[i].questions[j].id === questionID) {
                    return i;
                }
            }
        }
        return 0;
    }

    private handleLeawayChange(value: string) {
        if (isNaN(parseInt(value))) return;
        var q = this.state.question;
        q.recentleaway = parseInt(value);
        this.setState({
            question: q,
            shouldSave: true
        });
    }

    handleCheckboxGroup(newval: string) {
        var q = this.state.question;
        q.conditionalValue = newval;
        this.setState({
            question: q,
            shouldSave: true
        });
    }

    handleChoiceGroup(option: any | undefined) {
        var q = this.state.question;
        q.conditionalValue = option.key || "";
        this.setState({
            question: q,
            shouldSave: true
        });
    }

    handleContactOpts(newval: string) {
        var q = this.state.question;
        q.options = newval;
        this.setState({
            question: q,
            shouldSave: true
        });
    }

    handleListOpts(newval: string[]) {
        var q = this.state.question;
        q.options = newval.join(";");
        this.setState({
            question: q,
            shouldSave: true
        });
    }

    handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        const { name } = target;
        const value: string = newValue !== undefined ? newValue : target.value;
        var q = this.state.question;

        if (name.startsWith("trans")) {
            if (q.translations == null) {
                q.translations = [];
            }
            var found = q.translations.find(e => e.language === this.state.translanguage);
            if (found === undefined) {
                found = {
                    language: this.state.translanguage,
                    description: "",
                    assessorguidance: "",
                    operatorguidance: "",
                    evidencename: "",
                    evidenceprompt: "",
                    evidenceguidance: "",
                    options: ""
                };
                q.translations.push(found);
            }
            switch (name) {
                case "transdescription":
                    if (found != null) found.description = value;
                    this.setState({
                        transdescription: value,
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "transguidance":
                    if (found != null) found.assessorguidance = value;
                    this.setState({
                        transguidance: value,
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "transevidencename":
                    if (found != null) found.evidencename = value;
                    this.setState({
                        transevidencename: value,
                        question: q,
                        shouldSave: true
                    });
                    break;

                case "transevidenceguidance":
                    if (found != null) found.evidenceguidance = value;
                    this.setState({
                        transevidenceguidance: value,
                        question: q,
                        shouldSave: true
                    });
                    break;

                case "transoperatorguidance":
                    if (found != null) found.operatorguidance = value;
                    this.setState({
                        transoperatorguidance: value,
                        question: q,
                        shouldSave: true
                    });
                    break;

                case "transevidenceprompt":
                    if (found != null) found.evidenceprompt = value;
                    this.setState({
                        transevidenceprompt: value,
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "transoptions":
                    if (found != null) found.options = value;
                    this.setState({
                        transoptions: value,
                        question: q,
                        shouldSave: true
                    });
                    break;
            }
        }
        else if (name.startsWith("oneOfMyoDescription")) {
            const dynamicIndex = parseInt(name.replace("oneOfMyoDescription", ""), 10);
            const updatedOneOfMyoDescription = [...this.state.question.oneOfMyoDescription];

            if (updatedOneOfMyoDescription[dynamicIndex]) {
                updatedOneOfMyoDescription[dynamicIndex] = {
                    ...updatedOneOfMyoDescription[dynamicIndex],
                    [`oneOfMyoDescription${dynamicIndex}`]: value
                };
            }
            this.setState(prevState => ({
                question: {
                    ...prevState.question,
                    oneOfMyoDescription: updatedOneOfMyoDescription,
                },
                shouldSave: true
            }), () => {
                console.log("New state after update:", this.state);
            });

            return;
        }


        else {
            switch (name) {
                case "requiredafter":
                    if (isNaN(parseInt(value))) return;
                    q.requiredafter = parseInt(value);
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "mandatorygrace":
                    if (isNaN(parseInt(value))) return;
                    q.mandatoryGracePeriod = parseInt(value);
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "validateminimum":
                    if (isNaN(parseInt(value))) return;
                    q.validationMin = parseInt(value);
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "validatemaximum":
                    if (isNaN(parseInt(value))) return;
                    q.validationMax = parseInt(value);
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "validatemessage":
                    q.validationFailMessage = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;

                case "evidencename":
                    q.evidencename = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "evidenceprompt":
                    q.evidenceprompt = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "videodescription":
                    q.videoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "videourl":
                    q.videoURL = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "evidenceguidance":
                    q.evidenceguidance = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "evidencehelpurl":
                    var v = value;
                    if (value.toUpperCase().startsWith("HTTP://")) v = v.substr(7);
                    if (value.toUpperCase().startsWith("HTTPS://")) v = v.substr(8);
                    q.evidenceHelpURL = v;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "description":
                    q.description = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "options":
                    q.options = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "externalref":
                    q.externalRef = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "guidance":
                    q.guidance = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "operatorguidance":
                    q.operatorGuidance = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "helpurl":
                    var url = value;
                    if (value.toUpperCase().startsWith("HTTP://")) url = url.substr(7);
                    if (value.toUpperCase().startsWith("HTTPS://")) url = url.substr(8);
                    q.helpURL = url;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "condvalue":
                    q.conditionalValue = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "positiveDisplay":
                    q.positiveDisplay = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "negativeDisplay":
                    q.negativeDisplay = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "introductionToTextAnswer":
                    q.introductionToTextAnswer = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "imageMyoDescription":
                    q.imageMyoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "videoMyoDescription":
                    q.videoMyoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "numberMyoDescription":
                    q.numberMyoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "dateMyoDescription":
                    q.dateMyoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "manyOfMyoDescription":
                    q.manyOfMyoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
                case "documentMyoDescription":
                    q.documentMyoDescription = value;
                    this.setState({
                        question: q,
                        shouldSave: true
                    });
                    break;
            }
        }
    }

    handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void => {
        if (!option || index === undefined) {
            return;
        }

        const { question } = this.state;
        const updatedOneOfMyoDescription = [...question.oneOfMyoDescription];

        if (updatedOneOfMyoDescription[index]) {
            updatedOneOfMyoDescription[index][`color${index}`] = option.text;
        }

        this.setState({
            question: {
                ...question,
                oneOfMyoDescription: updatedOneOfMyoDescription,
            },
            shouldSave: true
        }, () => {
            console.log("Updated state:", this.state);
        });
    }

    handleAddRow = (): void => {
        this.setState(prevState => {
            if (!prevState || !prevState.question) {
                return null;
            }
            const oneOfMyoDescription = prevState.question.oneOfMyoDescription || [];
            if (oneOfMyoDescription.length < 20) {
                const newIndex = oneOfMyoDescription.length;
                const newDescription = { [`oneOfMyoDescription${newIndex}`]: '', [`color${newIndex}`]: '' };
                const newState = {
                    question: {
                        ...prevState.question,
                        oneOfMyoDescription: [
                            ...oneOfMyoDescription,
                            newDescription,
                        ],
                    },
                    shouldSave: true
                };
                console.log("New state:", newState);
                return newState;
            } else {
                return null;
            }
        });
    };


    handleRemoveRow = (index: number): void => {
        this.setState(prevState => {
            if (!prevState || !prevState.question) {
                return null;
            }
            const updatedDescriptions = prevState.question.oneOfMyoDescription.filter((_, i) => i !== index);
            return {
                question: {
                    ...prevState.question,
                    oneOfMyoDescription: updatedDescriptions,
                },
                shouldSave: true
            };
        });
    };

    setlanguage(event: any, option?: any, index?: number) {
        var q = this.state.question;
        if (q.translations == null) {
            q.translations = [];
        }
        var found = q.translations.find(e => e.language === option.key);
        if (found === undefined) {
            found = {
                language: option.key,
                description: "",
                assessorguidance: "",
                operatorguidance: "",
                evidencename: "",
                evidenceprompt: "",
                evidenceguidance: "",
                options: ""
            };
            q.translations.push(found);
        }
        this.setState({
            translanguage: option.key,
            transdescription: found.description,
            transguidance: found.assessorguidance,
            transevidencename: found.evidencename,
            transevidenceprompt: found.evidenceprompt,
            question: q, shouldSave: true,
        });
    }

    settype(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.type = index || 0;
        this.setState({ question: q, shouldSave: true, });
        this.resetParameters();
    }

    setsubsection(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.subSection = option.text;
        this.setState({ question: q, shouldSave: true, });
    }
    setcolor(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.color = option.text;
        this.setState({ question: q, shouldSave: true, });
    }
    setnegativecolor(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.negativeColor = option.text;
        this.setState({ question: q, shouldSave: true, });
    }

    setevidencereq(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.evidencerequired = index || 0;
        this.setState({ question: q, shouldSave: true, });
    }

    setsurvey(event: any, option?: any, index?: number) {
        var q = this.state.question;

        q.conditionalSurveyID = this.state.surveys[index || 0].id || "";
        var s = this.state.surveys[index || 0];
        this.setState({ survey: s, question: q, shouldSave: true, });
    }

    setsection(event: any, option?: any, index?: number) {
        var sec = this.state.survey.sections[index || 0];
        this.setState({ section: sec, sectionkey: option.key });
    }

    setcondquestion(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.conditionalQuestionID = option.key;
        this.setState({ question: q, shouldSave: true, });
    }

    setevidencetype(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.evidencetype = index || 0;
        this.setState({ question: q, shouldSave: true, });
    }

    setattachment(event: any, option?: any, index?: number) {
        var q = this.state.question;
        q.options = this.props.attachments[index || 0].blobname;
        this.setState({ question: q, shouldSave: true, });
    }

    handleToggleChange = (event: any, checked: any) => {
        var q = this.state.question;
        q.isDisplayMyoCorporate = !q.isDisplayMyoCorporate;
        this.resetParameters();
        this.setState({ question: q, shouldSave: true, });
    }

    resetParameters = () => {
        const { question } = this.state;

        if (question.type === 0) {
            question.introductionToTextAnswer = '';
            question.imageMyoDescription = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            if (question.isAnswersYes) {
                question.negativeDisplay = '';
                question.negativeColor = '';
            }
            this.setState({ question: question, shouldSave: true, });
        } else if (question.type === 1) {
            question.isAnswersYes = false;
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.imageMyoDescription = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        } else if (question.type === 12) {
            question.isAnswersYes = false;
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.videoMyoDescription = '';
            question.introductionToTextAnswer = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        } else if (question.type === 13) {
            question.isAnswersYes = false;
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.imageMyoDescription = '';
            question.introductionToTextAnswer = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }
        else if (question.type === 2) {
            question.isAnswersYes = false;
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.imageMyoDescription = '';
            question.introductionToTextAnswer = '';
            question.videoMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }

        else if (question.type === 4) {
            question.isAnswersYes = false;
            question.section = '';
            question.subSection = '';
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.introductionToTextAnswer = '';
            question.imageMyoDescription = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }
        else if (question.type === 5) {
            question.isAnswersYes = false;
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.imageMyoDescription = '';
            question.introductionToTextAnswer = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [{ 'oneOfMyoDescription0': '', color0: '' }];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }

        else if (question.type === 6) {
            question.isAnswersYes = false;
            question.section = '';
            question.subSection = '';
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.introductionToTextAnswer = '';
            question.imageMyoDescription = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }
        else if (question.type === 14) {
            question.isAnswersYes = false;
            question.section = '';
            question.subSection = '';
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.introductionToTextAnswer = '';
            question.imageMyoDescription = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }

        if (!question.isDisplayMyoCorporate) {
            question.isAnswersYes = false;
            question.section = '';
            question.subSection = '';
            question.positiveDisplay = '';
            question.negativeDisplay = '';
            question.color = '';
            question.negativeColor = '';
            question.introductionToTextAnswer = '';
            question.imageMyoDescription = '';
            question.videoMyoDescription = '';
            question.numberMyoDescription = '';
            question.dateMyoDescription = '';
            question.oneOfMyoDescription = [];
            question.manyOfMyoDescription = '';
            question.documentMyoDescription = '';
            this.setState({ question: question, shouldSave: true, });
        }
    };

    render() {
        const { question } = this.state;
        const addButtonDisabled = question.oneOfMyoDescription && question.oneOfMyoDescription.length >= 20;
        const { alertMessage, alertType, showNotification } = this.state;

        if (this.props.question == null) {
            return (<Text variant="medium"><FormattedMessage id="question.select" /></Text>)
        }

        const narrownum: Partial<ISpinButtonStyles> = { root: { width: '200px', }, };

        var questiontypes: IDropdownOption[] = [
            { key: 0, text: this.props.intl.formatMessage({ id: 'question.type.0.yn' }) },
            { key: 1, text: this.props.intl.formatMessage({ id: 'question.type.1.text' }) },
            { key: 2, text: this.props.intl.formatMessage({ id: 'question.type.2.number' }) },
            { key: 3, text: this.props.intl.formatMessage({ id: 'question.type.3.currency' }) },
            { key: 4, text: this.props.intl.formatMessage({ id: 'question.type.4.date' }) },
            { key: 5, text: this.props.intl.formatMessage({ id: 'question.type.5.oneof' }) },
            { key: 6, text: this.props.intl.formatMessage({ id: 'question.type.6.manyof' }) },
            { key: 7, text: this.props.intl.formatMessage({ id: 'question.type.7.rate' }) },
            { key: 8, text: this.props.intl.formatMessage({ id: 'question.type.8.read' }) },
            { key: 9, text: this.props.intl.formatMessage({ id: 'question.type.9.sign' }) },
            { key: 10, text: this.props.intl.formatMessage({ id: 'question.type.10.contact' }) },
            { key: 11, text: this.props.intl.formatMessage({ id: 'question.type.11.list' }) },
            { key: 12, text: this.props.intl.formatMessage({ id: 'question.type.12.image' }) },
            { key: 13, text: this.props.intl.formatMessage({ id: 'question.type.13.video' }) },
            { key: 14, text: this.props.intl.formatMessage({ id: 'question.type.14.document' }) },
            { key: 15, text: this.props.intl.formatMessage({ id: 'question.type.15.address' }) },
            { key: 16, text: this.props.intl.formatMessage({ id: 'question.type.16.unit' }) },
        ];

        var languages: IDropdownOption[] = [
            { key: "fr", text: this.props.intl.formatMessage({ id: 'std.language.fr' }) },
            { key: "de", text: this.props.intl.formatMessage({ id: 'std.language.de' }) },
            { key: "es", text: this.props.intl.formatMessage({ id: 'std.language.es' }) },
            { key: "it", text: this.props.intl.formatMessage({ id: 'std.language.it' }) },
        ];

        var evidencerequirements: IDropdownOption[] = [
            { key: 0, text: this.props.intl.formatMessage({ id: 'question.evidence.0.never' }) },
            { key: 1, text: this.props.intl.formatMessage({ id: 'question.evidence.1.always' }) },
            { key: 2, text: this.props.intl.formatMessage({ id: 'question.evidence.2.ony' }) },
            { key: 3, text: this.props.intl.formatMessage({ id: 'question.evidence.3.onn' }) },
        ];

        if (this.state.question.type !== 0) {
            evidencerequirements = [
                { key: 0, text: this.props.intl.formatMessage({ id: 'question.evidence.0.never' }) },
                { key: 1, text: this.props.intl.formatMessage({ id: 'question.evidence.1.always' }) },
            ];
        }
        if (this.state.question.type === 8 || this.state.question.type > 9) {
            evidencerequirements = [
                { key: 0, text: this.props.intl.formatMessage({ id: 'question.evidence.0.never' }) },
            ];
        }

        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
        };

        var opts;
        var validationSwitch = <></>;
        var validationOptions = <></>;

        switch (this.state.question.type) {
            case 1:
                opts = <TextField name="options" label={this.props.intl.formatMessage({ id: 'question.placeholder' })} onChange={this.handleChange} value={this.state.question.options} />
                validationSwitch = <Toggle label={this.props.intl.formatMessage({ id: 'question.validate.help' })} checked={this.state.question.validate} onText={this.props.intl.formatMessage({ id: 'question.validate.on' })} offText={this.props.intl.formatMessage({ id: 'question.validate.off' })} onChange={() => { var q = this.state.question; q.validate = !q.validate; this.setState({ question: q, shouldSave: true }) }} />;
                if (this.state.question.validate || false) {
                    validationOptions =
                        <>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextField name="validateminimum" label={this.props.intl.formatMessage({ id: 'question.validate.min.text' })} onChange={this.handleChange} value={this.state.question.validationMin.toString()} />
                                <TextField name="validatemaximum" label={this.props.intl.formatMessage({ id: 'question.validate.max.text' })} onChange={this.handleChange} value={this.state.question.validationMax.toString()} />
                            </Stack>
                            <TextField name="validatemessage" label={this.props.intl.formatMessage({ id: 'question.validate.message' })} onChange={this.handleChange} value={this.state.question.validationFailMessage} />
                        </>;
                }
                break;
            case 2:
                validationSwitch = <Toggle label={this.props.intl.formatMessage({ id: 'question.validate.help' })} checked={this.state.question.validate} onText={this.props.intl.formatMessage({ id: 'question.validate.on' })} offText={this.props.intl.formatMessage({ id: 'question.validate.off' })} onChange={() => { var q = this.state.question; q.validate = !q.validate; this.setState({ question: q, shouldSave: true }) }} />;
                if (this.state.question.validate || false) {
                    validationOptions =
                        <>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextField name="validateminimum" label={this.props.intl.formatMessage({ id: 'question.validate.min.number' })} onChange={this.handleChange} value={this.state.question.validationMin.toString()} />
                                <TextField name="validatemaximum" label={this.props.intl.formatMessage({ id: 'question.validate.max.number' })} onChange={this.handleChange} value={this.state.question.validationMax.toString()} />
                            </Stack>
                            <TextField name="validatemessage" label={this.props.intl.formatMessage({ id: 'question.validate.message' })} onChange={this.handleChange} value={this.state.question.validationFailMessage} />
                        </>;
                }
                break;
            case 5:
            case 6:
                opts = <TextField name="options" label={this.props.intl.formatMessage({ id: 'question.options' })} onChange={this.handleChange} value={this.state.question.options} />
                break;
            case 8:
                const attachments: IDropdownOption[] = this.props.attachments.map((a, i) => {
                    return {
                        key: a.blobname,
                        text: a.name
                    } as IDropdownOption;
                });
                opts = <>
                    <Dropdown
                        placeholder={this.props.intl.formatMessage({ id: 'question.attachment' })}
                        label={this.props.intl.formatMessage({ id: 'question.attachment.label' })}
                        options={attachments}
                        styles={dropdownStyles}
                        defaultSelectedKey={this.state.question.options}
                        onChange={this.setattachment}
                    />
                </>
                break;
            case 10:
                opts = <Stack>
                    <CheckboxGroup value={this.state.question.options} options={"First Name;Last Name;Phone Number"} onChange={this.handleContactOpts} label="What do you want included?" />
                    <Checkbox label="Email Address" key="email" checked={true} disabled />
                </Stack>
                break;
            case 11:
                opts = <Stack>
                    <StringListEdit title="Options" onChange={this.handleListOpts} list={this.state.question.options.split(";")} />
                </Stack>
                break;
            case 12:
                opts = <Stack>
                    <Toggle checked={this.state.question.gpsTagged} onText={this.props.intl.formatMessage({ id: 'question.evidence.gps.on' })} offText={this.props.intl.formatMessage({ id: 'question.evidence.gps.off' })} onChange={() => { var q = this.state.question; q.gpsTagged = !q.gpsTagged; this.setState({ question: q, shouldSave: true }) }} />
                    <Toggle label={this.props.intl.formatMessage({ id: 'question.evidence.ringfenced' })} checked={this.state.question.gpsRingfenced} disabled={!this.state.question.gpsTagged} onText="Yes" offText="No" onChange={() => { var q = this.state.question; q.gpsRingfenced = !q.gpsRingfenced; this.setState({ question: q, shouldSave: true }) }} />
                    <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                        <Toggle label={this.props.intl.formatMessage({ id: 'question.evidence.recent' })} checked={this.state.question.recentPicture} onText="Yes" offText="No" onChange={() => { var q = this.state.question; q.recentPicture = !q.recentPicture; this.setState({ question: q, shouldSave: true }) }} />
                        <SpinButton label={this.props.intl.formatMessage({ id: 'question.evidence.leaway' })} disabled={!this.state.question.recentPicture} labelPosition={Position.top} value={(this.state.question.recentleaway || 7).toString()} styles={{ root: { width: '250px', }, }} onValidate={this.handleLeawayChange} min={0} max={90} />
                    </Stack>
                </Stack>
                break;
            case 13:
                opts = <Stack>
                </Stack>
                break;
        }

        var evidencetypes: IDropdownOption[] = [
            { key: 0, text: this.props.intl.formatMessage({ id: 'question.evidencetype.0.none' }) },
            { key: 1, text: this.props.intl.formatMessage({ id: 'question.evidencetype.1.image' }) },
            { key: 2, text: this.props.intl.formatMessage({ id: 'question.evidencetype.2.video' }) },
            { key: 3, text: this.props.intl.formatMessage({ id: 'question.evidencetype.3.document' }) },
        ];

        const allsurveys: IDropdownOption[] = this.state.surveys.map(function (element: any, index: number, items: ISurvey[]) {
            return ({ key: element.id, text: element.name });
        });

        const allsec: IDropdownOption[] = this.state.sections.map(function (element: any, index: number, items: ISection[]) {
            return ({ key: element.name, text: element.name });
        });

        let allsubsec: IDropdownOption[] = [];
        if (this.state.subSections && this.state.subSections.length > 0) {
            allsubsec = this.state.subSections.map((element: any) => {
                return { key: element.name, text: element.name };
            });
        }

        var allsections: IDropdownOption[] = [];
        if (this.state.survey.sections != null) {
            allsections = this.state.survey.sections.map(function (element: any, index: number, items: ISurveySection[]) {
                return ({ key: "SEC" + index, text: element.name });
            });
        }

        var allquestions: IDropdownOption[] = [];
        if (this.state.section.questions != null) {
            allquestions = this.state.section.questions.map(function (element) {
                return ({ key: element.id || "", text: element.description, data: { type: element.type } });
            });
        }

        let allColors: IDropdownOption[] = [
            { key: 'Positive', text: 'Positive' },
            { key: 'Negative', text: 'Negative' },
            { key: 'Neutral', text: 'Neutral' }
        ];

        const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 100 } };

        var evidence = <></>;

        if (this.state.question.evidencerequired && this.state.question.type !== 12 && this.state.question.type !== 13) {
            var evidencefields;
            switch (this.state.question.evidencetype) {
                case 1:
                    evidencefields = <Stack>
                        <Toggle checked={this.state.question.gpsTagged} onText={this.props.intl.formatMessage({ id: 'question.evidence.gps.on' })} offText={this.props.intl.formatMessage({ id: 'question.evidence.gps.off' })} onChange={() => { var q = this.state.question; q.gpsTagged = !q.gpsTagged; this.setState({ question: q, shouldSave: true }) }} />
                        <Toggle label={this.props.intl.formatMessage({ id: 'question.evidence.ringfenced' })} checked={this.state.question.gpsRingfenced} disabled={!this.state.question.gpsTagged} onText="Yes" offText="No" onChange={() => { var q = this.state.question; q.gpsRingfenced = !q.gpsRingfenced; this.setState({ question: q, shouldSave: true }) }} />
                        <Toggle label={this.props.intl.formatMessage({ id: 'question.evidence.recent' })} checked={this.state.question.recentPicture} onText="Yes" offText="No" onChange={() => { var q = this.state.question; q.recentPicture = !q.recentPicture; this.setState({ question: q, shouldSave: true }) }} />
                        <SpinButton label={this.props.intl.formatMessage({ id: 'question.evidence.leaway' })} disabled={!this.state.question.recentPicture} labelPosition={Position.top} value={(this.state.question.recentleaway || 7).toString()} styles={narrownum} onValidate={this.handleLeawayChange} min={1} max={90} />
                    </Stack>
                    break;
            }
            evidence =
                <Stack>
                    <Stack horizontal tokens={{ childrenGap: '10' }}>
                        <Stack.Item grow >
                            <TextField name="requiredafter" styles={narrowTextFieldStyles} label={this.props.intl.formatMessage({ id: 'question.evidence.requiredafter' })} onChange={this.handleChange} value={this.state.question.requiredafter.toString()} />
                            <Dropdown
                                placeholder={this.props.intl.formatMessage({ id: 'question.evidencetype.placeholder' })}
                                label={this.props.intl.formatMessage({ id: 'question.evidencetype' })}
                                options={evidencetypes}
                                styles={dropdownStyles}
                                defaultSelectedKey={this.state.question.evidencetype}
                                onChange={this.setevidencetype}
                            />
                        </Stack.Item>
                        <Stack.Item grow >
                            <TextField name="evidenceprompt" label={this.props.intl.formatMessage({ id: 'question.evidence.prompt' })} onChange={this.handleChange} value={this.state.question.evidenceprompt} />
                            <TextField name="evidencename" label={this.props.intl.formatMessage({ id: 'question.evidence.name' })} onChange={this.handleChange} multiline value={this.state.question.evidencename} />
                            <TextField name="evidenceguidance" label={this.props.intl.formatMessage({ id: 'question.evidence.guidance' })} onChange={this.handleChange} multiline value={this.state.question.evidenceguidance} />
                            <TextField name="evidencehelpurl" prefix="https://" label={this.props.intl.formatMessage({ id: 'question.help.url' })} onChange={this.handleChange} value={this.state.question.evidenceHelpURL} />
                        </Stack.Item>
                    </Stack>
                    {evidencefields}
                </Stack>;
        }

        var condValue = <></>;
        if (this.state.question.conditional) {
            var id = this.state.question.conditionalQuestionID;
            if (this.state.section != null) {
                if (this.state.section.questions != null) {
                    var q = this.state.section.questions.find(i => { return i.id === id });
                    if (q != null) {
                        switch (q.type) {
                            case 0:
                                condValue = <Toggle
                                    label={this.props.intl.formatMessage({ id: 'question.conditional.bool' })}
                                    checked={this.state.question.conditionalValue === "True"}
                                    onText={this.props.intl.formatMessage({ id: 'question.conditional.bool.true' })}
                                    offText={this.props.intl.formatMessage({ id: 'question.conditional.bool.false' })}
                                    onChange={() => {
                                        var q = this.state.question;
                                        q.conditionalValue = (q.conditionalValue === "True") ? "False" : "True";
                                        this.setState({ question: q, shouldSave: true })
                                    }}
                                />
                                break;
                            case 5: // One of
                                const options = q.options.split(";")
                                    .map((s, i) => {
                                        return {
                                            key: i.toString(),
                                            text: s,
                                        }
                                    });

                                condValue = <ChoiceGroup selectedKey={this.state.question.conditionalValue}
                                    options={options}
                                    onChange={(e, i) => this.handleChoiceGroup(i)}
                                    label="Pick one"
                                    required={true} />;
                                break;
                            case 6:   //  Many of
                                condValue = <CheckboxGroup value={this.state.question.conditionalValue} options={q.options} onChange={this.handleCheckboxGroup} label="Pick at least one" />;
                                //condValue = <TextField name="condvalue" label={this.props.intl.formatMessage({ id: 'question.conditional.value' })} onChange={this.handleChange} value={this.state.question.conditionalValue} />;
                                break;
                        }
                    }

                }

            }
        }

        const optionRender = (option: IDropdownOption | any): JSX.Element => {
            if (typeof option == 'undefined') {
                return <></>
            }
            if (typeof option.data == 'undefined') {
                return <div><span>{option.text}</span></div>
            }
            return (
                <Stack horizontal>
                    <Image src={"/images/icons/questiontypes/" + option.data.type + ".png"} width={24} height={24} imageFit={ImageFit.cover} />
                    <Text style={{ marginLeft: 10 }} block nowrap>{option.text}</Text>
                </Stack>
            );
        };

        const onRenderTitle = (options: IDropdownOption[] | any): JSX.Element => {
            const option = options[0];
            if (typeof option == 'undefined') {
                return <></>
            }
            if (typeof option.data == 'undefined') {
                return <div><span>{option.text}</span></div>
            }
            return (
                <Stack horizontal>
                    <Image src={"/images/icons/questiontypes/" + option.data.type + ".png"} width={24} height={24} imageFit={ImageFit.cover} />
                    <Text style={{ marginLeft: 10 }} block nowrap>{option.text}</Text>
                </Stack>
            );
        };

        const changeSection = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
            if (option && option.key) {
                const selectedSection = this.state.sections.find((section: ISection) => section.name === option.key);
                var q = this.state.question;
                q.section = option.text;
                if (selectedSection) {
                    this.setState({ subSections: selectedSection.subsection, shouldSave: true });
                }
            }
        };

        return (

            <Stack grow styles={stackStyles}>
                {
                    showNotification && (
                        <MessageBar messageBarType={alertType} isMultiline={false} onDismiss={this.handleCloseAlert}>
                            {alertMessage}
                        </MessageBar>
                    )
                }

                <Stack horizontal tokens={{ childrenGap: '6', padding: "16px" }} >
                    <PrimaryButton onClick={this.save} text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} />
                    <DefaultButton onClick={this.close} text={this.state.shouldSave ? this.props.intl.formatMessage({ id: 'std.cancel' }) : this.props.intl.formatMessage({ id: 'std.close' })} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        placeholder={this.props.intl.formatMessage({ id: 'question.type.placeholder' })}
                        label={this.props.intl.formatMessage({ id: 'question.type' })}
                        options={questiontypes}
                        styles={dropdownStyles}
                        defaultSelectedKey={this.state.question.type}
                        onChange={this.settype}
                    />
                    <Toggle checked={this.state.question.mustApprove} label={this.props.intl.formatMessage({ id: 'question.mustapprove' })} onText={this.props.intl.formatMessage({ id: 'question.mustapprove.on' })} offText={this.props.intl.formatMessage({ id: 'question.mustapprove.off' })} onChange={() => { var q = this.state.question; q.mustApprove = !q.mustApprove; this.setState({ question: q, shouldSave: true }) }} />

                </Stack>
                <Stack horizontal tokens={{ childrenGap: '10' }}>
                    <Stack.Item grow >
                        <TextField name="description" multiline rows={3} label={this.props.intl.formatMessage({ id: 'question.description' })} onChange={this.handleChange} value={this.state.question.description} />
                        <TextField name="externalref" label={this.props.intl.formatMessage({ id: 'question.external.reference' })} onChange={this.handleChange} value={this.state.question.externalRef} />
                        {opts}
                        <Stack horizontal tokens={{ childrenGap: '10' }}>
                            {validationSwitch}
                        </Stack>
                        {validationOptions}
                        {this.props.currentSurvey.isDisplayMyoCorporate &&
                            (question.type === 0 || question.type === 1 || question.type === 2 || question.type === 4 || question.type === 5 || question.type === 6 || question.type === 12 || question.type === 13 || question.type === 14) && (
                                <Stack>
                                    <Stack horizontal>
                                        <Stack.Item grow>
                                            <Toggle
                                                checked={this.state.question.isDisplayMyoCorporate}
                                                label={this.props.intl.formatMessage({ id: 'question.isDisplayMyoCorporate' })}
                                                onText={this.props.intl.formatMessage({ id: 'question.isDisplayMyoCorporate.on' })}
                                                offText={this.props.intl.formatMessage({ id: 'question.isDisplayMyoCorporate.off' })}
                                                onChange={this.handleToggleChange}
                                            />
                                        </Stack.Item>
                                        {(this.state.question.isDisplayMyoCorporate && this.state.question.type == 0) && (
                                            <Stack.Item grow>
                                                <Toggle
                                                    checked={this.state.question.isAnswersYes}
                                                    label={this.props.intl.formatMessage({ id: 'question.isAnswersYes' })}
                                                    onText={this.props.intl.formatMessage({ id: 'question.isAnswersYes.on' })}
                                                    offText={this.props.intl.formatMessage({ id: 'question.isAnswersYes.off' })}
                                                    onChange={() => {
                                                        var q = this.state.question;
                                                        q.isAnswersYes = !q.isAnswersYes;
                                                        this.setState({ question: q, shouldSave: true })
                                                        this.resetParameters();
                                                    }}
                                                />
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                    {this.state.question.isDisplayMyoCorporate && (

                                        <Stack>
                                            <Stack horizontal>
                                                <Stack.Item grow>
                                                    <Dropdown
                                                        placeholder={this.props.intl.formatMessage({ id: 'question.section.placeholder' })}
                                                        label={this.props.intl.formatMessage({ id: 'question.section' })}
                                                        options={allsec}
                                                        styles={dropdownStyles}
                                                        onChange={changeSection}
                                                        selectedKey={this.state.question.section}
                                                    />
                                                </Stack.Item>
                                                {(this.props.currentSurvey && (this.props.currentSurvey.propertyType !== "Single Contained" && this.props.currentSurvey.surveyType !== "Brand")) && (
                                                    <Stack.Item grow>
                                                        <Dropdown
                                                            placeholder={this.props.intl.formatMessage({ id: 'question.subSection.placeholder' })}
                                                            label={this.props.intl.formatMessage({ id: 'question.subSection' })}
                                                            options={allsubsec}
                                                            styles={dropdownStyles}
                                                            onChange={this.setsubsection}
                                                            defaultSelectedKey={this.state.question.subSection}
                                                        />
                                                    </Stack.Item>
                                                )}
                                            </Stack>
                                            {this.state.question.type == 0 && (
                                                <Stack>
                                                    <Stack horizontal>
                                                        <Stack.Item grow className='dropdown-sec'>
                                                            <TextField name="positiveDisplay" label={this.props.intl.formatMessage({ id: 'question.positiveDisplay' })} onChange={this.handleChange} value={this.state.question.positiveDisplay} />
                                                            <Dropdown
                                                                placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                                label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                                options={allColors}
                                                                styles={dropdownStyles}
                                                                onChange={this.setcolor}
                                                                defaultSelectedKey={this.state.question.color}
                                                            />
                                                        </Stack.Item>
                                                    </Stack>
                                                    {!this.state.question.isAnswersYes && (
                                                        <Stack horizontal>
                                                            <Stack.Item grow className='dropdown-sec'>
                                                                <TextField name="negativeDisplay" label={this.props.intl.formatMessage({ id: 'question.negativeDisplay' })} onChange={this.handleChange} value={this.state.question.negativeDisplay} />
                                                                <Dropdown
                                                                    placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                                    label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                                    options={allColors}
                                                                    styles={dropdownStyles}
                                                                    onChange={this.setnegativecolor}
                                                                    defaultSelectedKey={this.state.question.negativeColor}
                                                                />
                                                            </Stack.Item>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            )}
                                            {this.state.question.type == 1 && (

                                                <Stack horizontal>
                                                    <Stack.Item grow className='dropdown-sec'>
                                                        <TextField name="introductionToTextAnswer" label={this.props.intl.formatMessage({ id: 'question.introductionToTextAnswer' })} onChange={this.handleChange} value={this.state.question.introductionToTextAnswer} />
                                                        <Dropdown
                                                            placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                            label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                            options={allColors}
                                                            styles={dropdownStyles}
                                                            onChange={this.setcolor}
                                                            defaultSelectedKey={this.state.question.color}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            )}
                                            {this.state.question.type == 12 && (
                                                <Stack horizontal>
                                                    <Stack.Item grow>
                                                        <TextField name="imageMyoDescription" label={this.props.intl.formatMessage({ id: 'question.imageMyoDescription' })} onChange={this.handleChange} value={this.state.question.imageMyoDescription} />
                                                    </Stack.Item>
                                                </Stack>
                                            )}
                                            {this.state.question.type == 13 && (
                                                <Stack horizontal>
                                                    <Stack.Item grow>
                                                        <TextField name="videoMyoDescription" label={this.props.intl.formatMessage({ id: 'question.videoMyoDescription' })} onChange={this.handleChange} value={this.state.question.videoMyoDescription} />
                                                    </Stack.Item>
                                                </Stack>
                                            )}
                                            {this.state.question.type == 2 && (
                                                <Stack horizontal>
                                                    <Stack.Item grow className='dropdown-sec'>
                                                        <TextField name="numberMyoDescription" label={this.props.intl.formatMessage({ id: 'question.numberMyoDescription' })} onChange={this.handleChange} value={this.state.question.numberMyoDescription} />
                                                        <Dropdown
                                                            placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                            label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                            options={allColors}
                                                            styles={dropdownStyles}
                                                            onChange={this.setcolor}
                                                            defaultSelectedKey={this.state.question.color}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            )}
                                            {this.state.question.type == 4 && (
                                                <Stack horizontal>
                                                    <Stack.Item grow className='dropdown-sec'>
                                                        <TextField name="dateMyoDescription" label={this.props.intl.formatMessage({ id: 'question.dateMyoDescription' })} onChange={this.handleChange} value={this.state.question.dateMyoDescription} />
                                                        <Dropdown
                                                            placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                            label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                            options={allColors}
                                                            styles={dropdownStyles}
                                                            onChange={this.setcolor}
                                                            defaultSelectedKey={this.state.question.color}
                                                        />
                                                    </Stack.Item>

                                                </Stack>
                                            )}

                                            {this.state.question.type == 5 && (
                                                <Stack>
                                                    {(Array.isArray(this.state.question.oneOfMyoDescription) && this.state.question.oneOfMyoDescription.length > 0) &&
                                                        this.state.question.oneOfMyoDescription.map((description, index) => (
                                                            <Stack key={index}>
                                                                <Stack.Item grow >
                                                                    <Stack.Item className='dropdown-sec'>
                                                                        <TextField
                                                                            name={`oneOfMyoDescription${index}`}
                                                                            label={`Answer ${index + 1}`}
                                                                            onChange={this.handleChange}
                                                                            value={description[`oneOfMyoDescription${index}`]}
                                                                        />
                                                                        <Dropdown
                                                                            placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                                            label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                                            options={allColors}
                                                                            styles={dropdownStyles}
                                                                            onChange={(event, option) => this.handleDropdownChange(event, option, index)}
                                                                            defaultSelectedKey={description[`color${index}`]}
                                                                        />
                                                                        {index == 0 && (
                                                                            <IconButton
                                                                                iconProps={addIcon}
                                                                                aria-label="Add"
                                                                                className='mt-45'
                                                                                title="Add"
                                                                                onClick={this.handleAddRow}
                                                                                disabled={addButtonDisabled}
                                                                                styles={{ root: borderedButtonStyles }}
                                                                            />
                                                                        ) || (
                                                                                <IconButton
                                                                                    iconProps={removeIcon}
                                                                                    title="Remove"
                                                                                    className='mt-45'
                                                                                    ariaLabel="Remove"
                                                                                    onClick={() => this.handleRemoveRow(index)}
                                                                                    styles={{ root: borderedButtonStyles }}
                                                                                    disabled={index == 0}
                                                                                />
                                                                            )}
                                                                    </Stack.Item>
                                                                </Stack.Item>
                                                            </Stack>
                                                        ))}
                                                </Stack>

                                            )}
                                            {this.state.question.type == 6 && (
                                                <Stack>
                                                    <Stack horizontal>
                                                        <Stack.Item grow styles={{ root: { display: 'flex', alignItems: 'flex-end' } }} className='dropdown-sec'>
                                                            <TextField
                                                                name="manyOfMyoDescription"
                                                                label={this.props.intl.formatMessage({ id: 'question.manyOfMyoDescription' })}
                                                                onChange={this.handleChange}
                                                                value={this.state.question.manyOfMyoDescription}
                                                            />
                                                            <Dropdown
                                                                placeholder={this.props.intl.formatMessage({ id: 'question.color.placeholder' })}
                                                                label={this.props.intl.formatMessage({ id: 'question.color' })}
                                                                options={allColors}
                                                                styles={dropdownStyles}
                                                                onChange={this.setcolor}
                                                                defaultSelectedKey={this.state.question.color}
                                                            />
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack>
                                            )}
                                            {this.state.question.type == 14 && (
                                                <Stack>
                                                    <Stack horizontal>
                                                        <Stack.Item grow styles={{ root: { display: 'flex', alignItems: 'flex-end' } }}>
                                                            <Stack styles={{ root: { width: '100%', marginRight: '10px' } }}>
                                                                <TextField
                                                                    name="documentMyoDescription"
                                                                    label={this.props.intl.formatMessage({ id: 'question.documentMyoDescription' })}
                                                                    onChange={this.handleChange}
                                                                    value={this.state.question.documentMyoDescription}
                                                                />
                                                            </Stack>
                                                        </Stack.Item>
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Stack>
                                    )}
                                </Stack>
                            )}
                    </Stack.Item>
                    <Stack.Item grow >
                        <TextField name="guidance" multiline label={this.props.intl.formatMessage({ id: 'question.guidance' })} onChange={this.handleChange} value={this.state.question.guidance} />
                        <TextField name="operatorguidance" label={this.props.intl.formatMessage({ id: 'question.operator.guidance' })} onChange={this.handleChange} multiline value={this.state.question.operatorGuidance} />
                        <TextField name="helpurl" prefix="https://" label={this.props.intl.formatMessage({ id: 'question.help.url' })} onChange={this.handleChange} value={this.state.question.helpURL} />
                        <TextField name="videodescription" label={this.props.intl.formatMessage({ id: 'question.video.description' })} onChange={this.handleChange} multiline value={this.state.question.videoDescription} />
                        <TextField name="videourl" prefix="https://" label={this.props.intl.formatMessage({ id: 'question.video.url' })} onChange={this.handleChange} value={this.state.question.videoURL} />
                    </Stack.Item>
                </Stack >
                <Stack horizontal tokens={{ childrenGap: '10' }}>
                    <Toggle label={this.props.intl.formatMessage({ id: 'question.premium.help' })} disabled={this.state.question.mandatory} checked={this.state.question.premium} onText={this.props.intl.formatMessage({ id: 'question.premium.on' })} offText={this.props.intl.formatMessage({ id: 'question.premium.off' })} onChange={() => { var q = this.state.question; q.premium = !q.premium; this.setState({ question: q, shouldSave: true }) }} />
                    <Toggle label={this.props.intl.formatMessage({ id: 'question.mandatory.help' })} checked={this.state.question.mandatory} onText={this.props.intl.formatMessage({ id: 'question.mandatory.on' })} offText={this.props.intl.formatMessage({ id: 'question.mandatory.off' })} onChange={() => { var q = this.state.question; q.mandatory = !q.mandatory; this.setState({ question: q, shouldSave: true }) }} />
                    <TextField name="mandatorygrace" styles={narrowTextFieldStyles}
                        label={this.props.intl.formatMessage({ id: 'question.mandatory.grace' })}
                        description={this.props.intl.formatMessage({ id: 'question.mandatory.help.extra' })}
                        onChange={this.handleChange} value={this.state.question.mandatoryGracePeriod.toString()} disabled={!this.state.question.mandatory} />
                </Stack>
                <Pivot>
                    <PivotItem headerText={this.props.intl.formatMessage({ id: 'question.conditional.title' })} headerButtonProps={{ 'data-order': 2, 'data-title': 'My Files Title', }}>
                        <Toggle label={this.props.intl.formatMessage({ id: 'question.conditional' })}
                            checked={this.state.question.conditional}
                            onText={this.props.intl.formatMessage({ id: 'question.conditional.on' })}
                            offText={this.props.intl.formatMessage({ id: 'question.conditional.off' })}
                            onChange={() => { var q = this.state.question; q.conditional = !q.conditional; this.setState({ question: q, shouldSave: true }) }} />
                        <Dropdown
                            placeholder={this.props.intl.formatMessage({ id: 'survey.survey' })}
                            label={this.props.intl.formatMessage({ id: 'survey.survey' })}
                            options={allsurveys}
                            styles={{ dropdown: { width: 300 } }}
                            selectedKey={this.state.survey.id}
                            onChange={this.setsurvey}
                            disabled={!this.state.question.conditional}
                        />
                        <Dropdown
                            placeholder={this.props.intl.formatMessage({ id: 'survey.section' })}
                            label={this.props.intl.formatMessage({ id: 'survey.section' })}
                            options={allsections}
                            styles={{ dropdown: { width: 300 } }}
                            selectedKey={this.state.sectionkey}
                            onChange={this.setsection}
                            disabled={!this.state.question.conditional}
                        />
                        <Dropdown
                            placeholder={this.props.intl.formatMessage({ id: 'question.question' })}
                            label={this.props.intl.formatMessage({ id: 'question.question' })}
                            options={allquestions}
                            styles={{ dropdown: { width: 500 } }}
                            onRenderTitle={onRenderTitle}
                            onRenderOption={optionRender}
                            selectedKey={this.state.question.conditionalQuestionID}
                            onChange={this.setcondquestion}
                            disabled={!this.state.question.conditional}
                        />
                        {condValue}
                    </PivotItem>

                    <PivotItem headerText={this.props.intl.formatMessage({ id: 'question.translations' })} headerButtonProps={{ 'data-order': 2, 'data-title': 'My Files Title', }}>
                        <Dropdown
                            placeholder={this.props.intl.formatMessage({ id: 'std.language' })}
                            label={this.props.intl.formatMessage({ id: 'std.language' })}
                            options={languages}
                            styles={dropdownStyles}
                            selectedKey={this.state.translanguage}
                            onChange={this.setlanguage}
                        />
                        <Stack horizontal tokens={{ childrenGap: '10' }}>
                            <Stack.Item grow >
                                <TextField name="transdescription" multiline rows={3} label={this.props.intl.formatMessage({ id: 'question.description' })} onChange={this.handleChange} value={this.state.transdescription} />
                                <TextField name="transevidencename" label={this.props.intl.formatMessage({ id: 'question.evidence.name' })} onChange={this.handleChange} multiline value={this.state.transevidencename} />
                                <TextField name="transevidenceprompt" label={this.props.intl.formatMessage({ id: 'question.evidence.prompt' })} onChange={this.handleChange} multiline value={this.state.transevidenceprompt} />
                            </Stack.Item>
                            <Stack.Item grow >
                                <TextField name="transguidance" multiline rows={3} label={this.props.intl.formatMessage({ id: 'question.guidance' })} onChange={this.handleChange} value={this.state.transguidance} />
                                <TextField name="transoperatorguidance" multiline rows={3} label={this.props.intl.formatMessage({ id: 'question.operator.guidance' })} onChange={this.handleChange} value={this.state.transoperatorguidance} />
                                <TextField name="transevidenceguidance" multiline rows={3} label={this.props.intl.formatMessage({ id: 'question.evidence.guidance' })} onChange={this.handleChange} value={this.state.transevidenceguidance} />
                            </Stack.Item>
                        </Stack>

                        <TextField name="transoptions" label={this.props.intl.formatMessage({ id: 'question.options' })} onChange={this.handleChange} multiline value={this.state.transoptions} />
                    </PivotItem>
                </Pivot>
            </Stack >


        );
    };
}

export default injectIntl(QuestionEdit);
