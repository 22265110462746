import React, { Component } from "react";
import axios from "axios";
import Moment from "react-moment";
import {
    injectIntl,
    WrappedComponentProps,
    FormattedMessage,
} from "react-intl";

import {
    API,
    APIKEY,
    ICertificateSurvey,
    ISurveyItem,
    ICertificate,
    stdDayPickerStrings,
    IReportReference,
} from "./IVesta";

import {
    Image,
    Spinner,
    SpinnerSize,
    CommandBar,
    ICommandBarItemProps,
    Stack,
    IStackStyles,
    IStackTokens,
    Icon,
    IIconProps,
    Panel,
    PanelType,
    DefaultButton,
    PrimaryButton,
    IconButton,
    Dropdown,
    IDropdownStyles,
    IDropdownOption,
    Dialog,
    DialogType,
    DialogFooter,
    DetailsList,
    IColumn,
    SelectionMode,
    TextField,
    DatePicker,
    DayOfWeek,
    mergeStyles,
    mergeStyleSets,
    Text,
    Pivot,
    PivotItem,
    Toggle,
} from "office-ui-fabric-react";

import Survey from "./Survey";
import ReportView from "./ReportView";
import UD from "./Ud";

const stackStyles: IStackStyles = {
    root: {
        width: "500",
    },
};

interface IState {
    user: string;
    selectedSurvey: any;
    isOpen: boolean;
    id: string;
    certificate: ICertificate;
    reports: IReportReference[];
    isNewSurveyOpen: boolean;
    surveys: ISurveyItem[];
    newSurveyID: string;
    deleteSurveyID: string;
    selectedID: string;
    approveOpen: boolean;
    declineOpen: boolean;
    rejectOpen: boolean;
    deleteOpen: boolean;
    revokeOpen: boolean;
    messageOpen: boolean;
    subject: string;
    message: string;
    expiresOn: Date;
    maxExpiresOn: Date;
    canIssue: boolean;
    canComplete: boolean;
}

interface IProps extends WrappedComponentProps {
    id: any;
    user: string;
    onUpdate?: any;
    buildingName?: string;
}

class Certificate extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            user: this.props.user || "",
            selectedSurvey: "Undefined",
            isOpen: false,
            id: this.props.id,
            certificate: {} as ICertificate,
            reports: [],
            isNewSurveyOpen: false,
            surveys: [],
            newSurveyID: "",
            deleteSurveyID: "",
            selectedID: "",
            subject: "",
            message: "",
            deleteOpen: false,
            revokeOpen: false,
            approveOpen: false,
            declineOpen: false,
            rejectOpen: false,
            messageOpen: false,
            expiresOn: new Date(),
            maxExpiresOn: new Date(),
            canIssue: false,
            canComplete: false,
        };

        this.refresh = this.refresh.bind(this);
        this.editSurvey = this.editSurvey.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.closeNewSurvey = this.closeNewSurvey.bind(this);
        this.onCreateNewSurvey = this.onCreateNewSurvey.bind(this);
        this.refreshsurveys = this.refreshsurveys.bind(this);
        this.setnewsurvey = this.setnewsurvey.bind(this);
        this.addNewSurvey = this.addNewSurvey.bind(this);
        this._ItemChanged = this._ItemChanged.bind(this);
        this.onDeleteSurvey = this.onDeleteSurvey.bind(this);
        this._deleteToggle = this._deleteToggle.bind(this);
        this._revokeToggle = this._revokeToggle.bind(this);
        this._issueToggle = this._issueToggle.bind(this);
        this._declineToggle = this._declineToggle.bind(this);
        this._rejectToggle = this._rejectToggle.bind(this);
        this._messageToggle = this._messageToggle.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._message = this._message.bind(this);
        this._issue = this._issue.bind(this);
        this._issuepremium = this._issuepremium.bind(this);
        this._doissue = this._doissue.bind(this);
        this._revoke = this._revoke.bind(this);
        this._handleExpiredChange = this._handleExpiredChange.bind(this);
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    _onDelete(id: string) {
        this.setState({ deleteOpen: true, deleteSurveyID: id });
    }

    _deleteToggle() {
        this.setState({ deleteOpen: !this.state.deleteOpen });
    }

    _revokeToggle() {
        this.setState({ revokeOpen: !this.state.revokeOpen });
    }

    _issueToggle() {
        if (!this.state.approveOpen) {
            //  Opening, so calculate vlaidity date...
            var days = 1000;

            this.state.certificate.surveys.forEach((cert) => {
                if (cert.survey.validFor < days && cert.survey.validFor > 0) {
                    days = cert.survey.validFor;
                }
            });
            var d = this.addDays(new Date(), days);

            this.setState({ approveOpen: true, expiresOn: d, maxExpiresOn: d });
        } else {
            this.setState({ approveOpen: false });
        }
    }

    _declineToggle() {
        this.setState({ declineOpen: !this.state.declineOpen });
    }

    _rejectToggle() {
        this.setState({ rejectOpen: !this.state.rejectOpen });
    }

    _messageToggle() {
        this.setState({ messageOpen: !this.state.messageOpen });
    }

    private dismissPanel() {
        this.setState({ isOpen: false });
    }

    _handleChange(event: any) {
        const target = event.target;
        const value: string =
            target.type === "checkbox" ? target.checked : target.value;
        const name: string = target.name;

        switch (name) {
            case "subject":
                this.setState({ subject: value });
                break;
            case "message":
                this.setState({ message: value });
                break;
        }
    }

    _handleExpiredChange(date?: Date | null) {
        if (date !== null && date !== undefined) {
            this.setState({ expiresOn: date });
        }
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id });
        }
    }

    editSurvey(item: ICertificateSurvey) {
        this.setState({
            isOpen: true,
            selectedSurvey: item,
        });
    }

    refresh() {
        const post = {
            security: {
                APIKey: APIKEY,
                user: this.props.user,
            },
            id: this.props.id,
        };
        axios
            .post(API + "/certificate/view", post)
            .then((response) => {
                var issuable = true;
                var completable = true;
                var cert = response.data.certificate as ICertificate;
                cert.surveys.forEach((i) => {
                    if (!i.isApproved) issuable = false;
                });
                cert.surveys.forEach((i) => {
                    if (!i.isCompleted) completable = false;
                });
                this.setState({
                    certificate: response.data.certificate,
                    reports: response.data.reports,
                    canIssue: issuable,
                    canComplete: completable,
                });
                this.refreshsurveys();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    refreshsurveys() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.state.user,
            },
        };

        axios
            .post(API + "/survey/search", post)
            .then((response) => {
                this.setState({ surveys: response.data.surveys });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _message() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            surveyid: null,
            certificateid: this.state.certificate.id,
            subject: this.state.subject,
            message: this.state.message,
        };

        axios
            .post(API + "/certificate/SendMessage", post)
            .then(() => { })
            .catch(function (error) {
                console.log(error);
            });

        this.setState({ messageOpen: false, subject: "", message: "" });
    }

    _issue() {
        this._doissue(true, false);
    }

    _issuepremium() {
        this._doissue(true, true);
    }

    _revoke() {
        this._doissue(false, false);
    }

    _doissue(action: boolean, premium: boolean) {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            ID: this.state.certificate.id,
            Issue: action,
            ExpiresOn: this.state.expiresOn,
            premium: premium,
        };
        axios
            .post(API + "/certificate/issue", post)
            .then(() => {
                this.refresh();
            })
            .catch(function (error) {
                console.log(error);
            });

        if (this.props.onUpdate != null) {
            this.props.onUpdate();
        }
        this.setState({ approveOpen: false, revokeOpen: false });
    }

    public setnewsurvey(event: any, option?: any, index?: number) {
        this.setState({ newSurveyID: this.state.surveys[index!].id || "" });
    }

    public addNewSurvey() {
        this.setState({ isNewSurveyOpen: true, newSurveyID: "" });
    }

    public onCreateNewSurvey() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            certificateID: this.state.certificate.id,
            surveyID: this.state.newSurveyID,
            approve: true,
        };
        axios
            .post(API + "/certificate/addsurvey", post)
            .then(() => {
                this.setState({ isNewSurveyOpen: false });
                this.refresh();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    public onDeleteSurvey() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            certificateID: this.state.certificate.id,
            certificateSurveyID: this.state.deleteSurveyID,
            approve: true,
        };
        axios
            .post(API + "/certificate/deletesurvey", post)
            .then(() => {
                this.setState({ deleteOpen: false });
                this.refresh();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    private closeNewSurvey() {
        this.setState({ isNewSurveyOpen: false });
    }

    private _ItemChanged = (item: any, index: any): void => {
        var items = this.state.certificate.surveys;
        var survey = items[index];
        this.setState({
            isOpen: true,
            selectedSurvey: { survey },
        });
    };

    render() {
        var image;
        if (this.state.certificate == null) {
            return (
                <Stack grow styles={stackStyles}>
                    <Spinner size={SpinnerSize.large} label="Loading Certificate" />
                </Stack>
            );
        }

        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: "Add a new survey",
            subText: "Please select a survey to add.",
        };
        const modalProps = {
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        };
        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
        };

        const sectionStackTokens: IStackTokens = { childrenGap: 20 };
        const iconClass = mergeStyles({
            fontSize: 20,
            height: 20,
            width: 20,
            margin: "5px",
        });

        const stackClass = mergeStyles({
            width: "100%",
        });

        const _items: ICommandBarItemProps[] = [
            {
                key: "newItem",
                text: "Add Survey",
                cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
                iconProps: { iconName: "Add" },
                onClick: this.addNewSurvey,
            },
        ];

        const allsurveys: IDropdownOption[] = this.state.surveys.map(function (
            element: any
        ) {
            return { key: element.id, text: element.name };
        });

        const deleteIcon: IIconProps = { iconName: "Delete" };
        const editIcon: IIconProps = { iconName: "View" };

        const columns: IColumn[] = [
            {
                key: "statusicon",
                name: "",
                minWidth: 16,
                maxWidth: 16,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                onRender: (item: any) => {
                    if (item.isApproved)
                        return <Icon iconName="Certificate" className={iconClass} />;
                    else {
                        if (item.isCompleted)
                            return <Icon iconName="SkypeCheck" className={iconClass} />;
                        else if (item.isStarted)
                            return (
                                <Icon
                                    iconName="ProgressRingDots"
                                    className={iconClass}
                                    id={item.id}
                                />
                            );
                    }

                    return <Icon iconName="Page" className={iconClass} />;
                },
            },
            {
                key: "name",
                name: "Survey",
                minWidth: 0,
                maxWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: any) => {
                    return item.survey.name;
                },
            },
            {
                key: "kind",
                name: "Completion Method",
                ariaLabel: "",
                fieldName: "id",
                isRowHeader: true,
                minWidth: 150,
                maxWidth: 250,
                onRender: (item: any) => {
                    switch (item.survey.completionMethod) {
                        case 1:
                            return "On Site";
                        case 2:
                            return "Desk based";
                        default:
                            return "Not Specified";
                    }
                },
            },
            {
                key: "status",
                name: "Current Status",
                ariaLabel: "",
                fieldName: "id",
                isRowHeader: true,
                minWidth: 250,
                maxWidth: 250,
                onRender: (item: any) => {
                    if (item.isApproved) return "Approved";
                    else {
                        if (item.isCompleted) return "Completed - awaiting approval";
                        else if (item.isStarted) return "In Progress";
                    }

                    return "Not yet started";
                },
            },
            {
                key: "edit",
                name: "Edit",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: ICertificateSurvey, index?: number) => {
                    return (
                        <IconButton
                            iconProps={editIcon}
                            onClick={() => this.editSurvey(item)}
                            title="Edit"
                            ariaLabel="Delete"
                        />
                    );
                },
            },
            {
                key: "delete",
                name: "Delete",
                ariaLabel: "",
                isIconOnly: true,
                fieldName: "id",
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: ICertificateSurvey) => {
                    return (
                        <IconButton
                            iconProps={deleteIcon}
                            onClick={() => this._onDelete(item.id)}
                            title="Delete"
                            ariaLabel="Delete"
                            disabled={item.isStarted}
                        />
                    );
                },
            },
        ];

        const controlClass = mergeStyleSets({
            control: {
                margin: "0 0 15px 0",
                maxWidth: "300px",
            },
        });

        const approveTokens: IStackTokens = {
            childrenGap: "20px",
        };

        const issueContentProps = {
            type: DialogType.normal,
            title: this.props.intl.formatMessage({
                id: "certificate.issue.dialog.title",
            }),
            closeButtonAriaLabel: this.props.intl.formatMessage({ id: "aria.close" }),
            subText: this.props.intl.formatMessage({
                id: "certificate.issue.dialog.text",
            }),
        };

        const messageContentProps = {
            type: DialogType.normal,
            title: this.props.intl.formatMessage({
                id: "std.send.message.dialog.title",
            }),
            closeButtonAriaLabel: this.props.intl.formatMessage({ id: "aria.close" }),
            subText: this.props.intl.formatMessage({
                id: "std.send.message.dialog.text",
            }),
        };

        const deleteContentProps = {
            type: DialogType.normal,
            title: this.props.intl.formatMessage({
                id: "certificate.remove.survey.dialog.title",
            }),
            closeButtonAriaLabel: this.props.intl.formatMessage({ id: "aria.close" }),
            subText: this.props.intl.formatMessage({
                id: "certificate.remove.survey.dialog.text",
            }),
        };

        const revokeContentProps = {
            type: DialogType.normal,
            title: this.props.intl.formatMessage({
                id: "certificate.revoke.dialog.title",
            }),
            closeButtonAriaLabel: this.props.intl.formatMessage({ id: "aria.close" }),
            subText: this.props.intl.formatMessage({
                id: "certificate.revoke.dialog.text",
            }),
        };

        if (this.state.certificate.issued) {
            if (this.state.certificate.premium)
                image = <Image src="/images/certplus.png" width={120} height={120} />;
            else image = <Image src="/images/cert.png" width={120} height={120} />;
        } else image = <Image src="/images/nocert.png" width={120} height={120} />;

        var surveyList;
        if (this.state.certificate.surveys !== undefined) {
            surveyList = (
                <DetailsList
                    items={this.state.certificate.surveys}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />
            );
        }

        var actionbutton = <></>;
        var issuedon = <></>;
        var commandbar = <></>;

        if (this.state.certificate.issued) {
            actionbutton = (
                <Stack horizontal horizontalAlign="start" tokens={approveTokens}>
                    <PrimaryButton
                        onClick={this._revokeToggle}
                        text={this.props.intl.formatMessage({ id: "certificate.revoke" })}
                    />
                    <DefaultButton
                        onClick={this._messageToggle}
                        text={this.props.intl.formatMessage({ id: "std.send.message" })}
                    />
                </Stack>
            );

            var kind = "Standard";
            if (this.state.certificate.premium) kind = "Premium";

            issuedon = (
                <Stack
                    horizontal
                    horizontalAlign="start"
                    verticalAlign="center"
                    tokens={approveTokens}
                >
                    <Text variant={"mediumPlus"} nowrap block>
                        {kind}{" "}
                    </Text>
                    <Text variant={"mediumPlus"} nowrap block>
                        <FormattedMessage id="certificate.certificate.issuedon" />{" "}
                    </Text>
                    <Text variant={"medium"} nowrap block>
                        <Moment format="ddd DD MMM YYYY HH:mm">
                            {this.state.certificate.issuedOn}
                        </Moment>
                    </Text>
                    <Text variant={"mediumPlus"} nowrap block>
                        <FormattedMessage id="std.by" />
                    </Text>
                    <Text variant={"medium"} nowrap block>
                        {this.state.certificate.issuedUser &&
                            this.state.certificate.issuedUser.email}
                    </Text>
                    <Text variant={"mediumPlus"} nowrap block>
                        <FormattedMessage id="certificate.expireson" />
                    </Text>
                    <Text variant={"medium"} nowrap block>
                        <Moment format="ddd DD MMM YYYY">
                            {this.state.certificate.expiresOn}
                        </Moment>
                    </Text>
                </Stack>
            );
        } else {
            actionbutton = (
                <Stack horizontal horizontalAlign="start" tokens={approveTokens}>
                    <PrimaryButton
                        disabled={!this.state.canIssue}
                        onClick={this._issueToggle}
                        text={this.props.intl.formatMessage({ id: "certificate.issue" })}
                    />
                    <DefaultButton
                        onClick={this._messageToggle}
                        text={this.props.intl.formatMessage({ id: "std.send.message" })}
                    />
                    <DefaultButton
                        disabled={!this.state.canComplete}
                        onClick={this._declineToggle}
                        text={this.props.intl.formatMessage({ id: "certificate.decline" })}
                    />
                </Stack>
            );
            commandbar = (
                <CommandBar
                    items={_items}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                />
            );
        }

        const today = this.addDays(new Date(), -1);
        return (
            <>
                <Stack grow styles={stackStyles} horizontalAlign="center">
                    {image}
                    {actionbutton}
                    {issuedon}
                </Stack>
                <Pivot>
                    <PivotItem
                        headerText={this.props.intl.formatMessage({
                            id: "certificate.surveys",
                        })}
                    >
                        <Stack tokens={sectionStackTokens} grow className={stackClass}>
                            {commandbar}
                            {surveyList}
                        </Stack>
                    </PivotItem>
                    <PivotItem
                        headerText={this.props.intl.formatMessage({ id: "certificate.ud" })}
                    >
                        <UD user={this.props.user} ud={this.state.certificate.ud} />
                    </PivotItem>
                    <PivotItem
                        headerText={this.props.intl.formatMessage({
                            id: "certificate.reports",
                        })}
                    >
                        <ReportView
                            certificateID={this.state.certificate.id}
                            user={this.props.user}
                            reports={this.state.reports}
                        />
                    </PivotItem>
                </Pivot>

                <Panel
                    isOpen={this.state.isOpen}
                    onDismiss={this.dismissPanel}
                    headerText={this.props.intl.formatMessage({ id: "survey.review" })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({
                        id: "aria.close",
                    })}
                    type={PanelType.large}
                >


                    <Survey
                        user={this.props.user}
                        onUpdate={this.refresh}
                        survey={this.state.selectedSurvey}
                        userrole="review"
                        buildingName={this.props.buildingName}
                    />
                </Panel>
                <Dialog
                    hidden={!this.state.isNewSurveyOpen}
                    onDismiss={this.closeNewSurvey}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                >
                    <Dropdown
                        placeholder={this.props.intl.formatMessage({
                            id: "certificate.select.survey",
                        })}
                        label={this.props.intl.formatMessage({ id: "survey.surveys" })}
                        options={allsurveys}
                        styles={dropdownStyles}
                        selectedKey={this.state.newSurveyID}
                        onChange={this.setnewsurvey}
                    />

                    <DialogFooter>
                        <PrimaryButton
                            onClick={this.onCreateNewSurvey}
                            text={this.props.intl.formatMessage({ id: "std.save" })}
                        />
                        <DefaultButton
                            onClick={this.closeNewSurvey}
                            text={this.props.intl.formatMessage({ id: "std.cancel" })}
                        />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!this.state.messageOpen}
                    onDismiss={this._messageToggle}
                    dialogContentProps={messageContentProps}
                    maxWidth={400}
                    minWidth={400}
                >
                    <TextField
                        label="Subject"
                        value={this.state.subject}
                        name="subject"
                        onChange={this._handleChange}
                    />
                    <TextField
                        label="Message"
                        multiline
                        rows={5}
                        value={this.state.message}
                        name="message"
                        onChange={this._handleChange}
                    />
                    <DialogFooter>
                        <PrimaryButton onClick={this._message} text="Send" />
                        <DefaultButton onClick={this._messageToggle} text="Cancel" />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    maxWidth={400}
                    minWidth={400}
                    hidden={!this.state.approveOpen}
                    onDismiss={this._issueToggle}
                    dialogContentProps={issueContentProps}
                >
                    <DatePicker
                        label={this.props.intl.formatMessage({
                            id: "certificate.expireson.long",
                        })}
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={stdDayPickerStrings}
                        placeholder={this.props.intl.formatMessage({
                            id: "certificate.select.expiration",
                        })}
                        ariaLabel={this.props.intl.formatMessage({
                            id: "aria.select.date",
                        })}
                        value={this.state.expiresOn}
                        onSelectDate={this._handleExpiredChange}
                        maxDate={this.state.maxExpiresOn}
                        minDate={today}
                    />

                    <DialogFooter>
                        <PrimaryButton
                            onClick={this._issuepremium}
                            text={this.props.intl.formatMessage({
                                id: "certificate.issue.premium",
                            })}
                        />
                        <PrimaryButton
                            onClick={this._issue}
                            text={this.props.intl.formatMessage({
                                id: "certificate.issue.standard",
                            })}
                        />
                        <DefaultButton
                            onClick={this._issueToggle}
                            text={this.props.intl.formatMessage({ id: "std.cancel" })}
                        />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!this.state.deleteOpen}
                    onDismiss={this._deleteToggle}
                    dialogContentProps={deleteContentProps}
                    maxWidth={400}
                    minWidth={400}
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={this.onDeleteSurvey}
                            text={this.props.intl.formatMessage({ id: "std.delete" })}
                        />
                        <DefaultButton
                            onClick={this._deleteToggle}
                            text={this.props.intl.formatMessage({ id: "std.cancel" })}
                        />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={!this.state.revokeOpen}
                    onDismiss={this._revokeToggle}
                    dialogContentProps={revokeContentProps}
                    maxWidth={400}
                    minWidth={400}
                >
                    <DialogFooter>
                        <PrimaryButton
                            onClick={this._revoke}
                            text={this.props.intl.formatMessage({ id: "certificate.revoke" })}
                        />
                        <DefaultButton
                            onClick={this._revokeToggle}
                            text={this.props.intl.formatMessage({ id: "std.cancel" })}
                        />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }
}

export default injectIntl(Certificate);
