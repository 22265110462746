import React from 'react';
import { FormattedMessage  } from 'react-intl';

import { NavLink as RouterNavLink } from 'react-router-dom';
import { LinkContainer   } from 'react-router-bootstrap';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav, 
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
} from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.css';

import SignoutButton  from './SignoutButton'

interface IProps   {
  isAuthenticated: boolean
  authButtonMethod: any
  user: any
}

interface IState {
  isOpen: boolean
}

function UserAvatar(props: any) {
  // If a user avatar is available, return an img tag with the pic
  if (props.user.avatar) {
    return <img
      src={props.user.avatar} alt="user"
      className="rounded-circle align-self-center mr-2"
      style={{ width: '32px' }}></img>;
  }

  // No avatar available, return a default icon
  return <i
    className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
    style={{ width: '32px' }}></i>;
}

function AuthNavItem(props: any) {
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.isAuthenticated) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret>
          <UserAvatar user={props.user} />
        </DropdownToggle>
        <DropdownMenu right>
          <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
          <p className="dropdown-item-text text-muted mb-0">{props.user.email}</p>
          <DropdownItem divider />
          <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  // Not authenticated, return a sign in link
  return (
    <NavItem>
      <SignoutButton />
    </NavItem>
  );
}

export default class NavBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    // Only show calendar nav item if logged in
    let adminmenu = null;

    if (true) {

      adminmenu = (

        <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
        <FormattedMessage id="menu.administration"/>
        </DropdownToggle>
        <DropdownMenu right>
          <LinkContainer  to="/Client" exact>
            <DropdownItem>
                <FormattedMessage id="menu.clients"/>
            </DropdownItem>
          </LinkContainer>
          <LinkContainer  to="/Survey" exact>
            <DropdownItem>
            <FormattedMessage id="menu.surveys"/>
            </DropdownItem>
          </LinkContainer>
          <LinkContainer  to="/Report" exact>
            <DropdownItem>
            <FormattedMessage id="menu.reports"/>
            </DropdownItem>
          </LinkContainer>
          <LinkContainer  to="/CertificateTemplate" exact>
            <DropdownItem>
            <FormattedMessage id="menu.templates"/>
            </DropdownItem>
          </LinkContainer>
          <LinkContainer  to="/Attachments" exact>
            <DropdownItem>
            <FormattedMessage id="menu.attachments"/>
            </DropdownItem>
          </LinkContainer>
          <LinkContainer  to="/User" exact>
            <DropdownItem>
            <FormattedMessage id="menu.users"/>
            </DropdownItem>
          </LinkContainer>
          <LinkContainer  to="/Profile" exact>
            <DropdownItem>
            <FormattedMessage id="menu.profile"/>
            </DropdownItem>
          </LinkContainer>
       </DropdownMenu>
      </UncontrolledDropdown>


      );
    }

    return (
      <div>
        <Navbar color="dark" dark expand="md" fixed="top">
          <Container>
            <NavbarBrand href="/">MYSASURE</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <RouterNavLink to="/" className="nav-link" exact><FormattedMessage id="menu.home"/></RouterNavLink>
                </NavItem>
                <NavItem>
                    <RouterNavLink to="/ToDo" className="nav-link" exact><FormattedMessage id="menu.todo"/></RouterNavLink>
                </NavItem>
                <NavItem>
                    <RouterNavLink to="/Messages" className="nav-link" exact><FormattedMessage id="menu.messages"/></RouterNavLink>
                </NavItem>
                <NavItem>
                    <RouterNavLink to="/Buildings" className="nav-link" exact><FormattedMessage id="menu.buildings"/></RouterNavLink>
                </NavItem>
                <NavItem>
                    <RouterNavLink to="/Certificates" className="nav-link" exact><FormattedMessage id="menu.certificates"/></RouterNavLink>
                </NavItem>
                {adminmenu}
              </Nav>
              <Nav className="justify-content-end" navbar>
                <AuthNavItem
                  isAuthenticated={this.props.isAuthenticated}
                  authButtonMethod={this.props.authButtonMethod}
                  user={this.props.user}
               />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
