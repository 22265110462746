import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps } from 'react-intl';


import {    
          TooltipHost, 
          mergeStyles, mergeStyleSets, FontIcon, 
} from 'office-ui-fabric-react';

interface IState  {
  isOpen: boolean,
}


interface IProps extends WrappedComponentProps {
  id: string
  externalID?: string
}


export class InfoIcon extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        
        this.state = {
          isOpen: false     ,
        };

        this.mouseclick = this.mouseclick.bind(this);
        
    }




    mouseclick()
    {
      navigator.clipboard.writeText(this.props.id);
    }  

    render() {
        const iconClass = mergeStyles({
          fontSize: 24,
          height: 24,
          width: 24,
          margin: '0 25px',
        });

        const classNames = mergeStyleSets({
          grey: [{ color: 'Gainsboro' }, iconClass],
        });

        return (
              <TooltipHost
                    content={this.props.id}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={"InfoToolTip" + this.props.id}
                    calloutProps={ { gapSpace: 0 } }
              >
                <FontIcon iconName="InfoSolid" onClick={this.mouseclick} className={classNames.grey}/>
              </TooltipHost>

        );
      };
      
}

export default injectIntl(InfoIcon);