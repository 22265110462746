import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import {IAttachmentCategory} from './IVesta';

import {  
          TextField,
          DefaultButton, PrimaryButton, IconButton, 
          CommandButton, IIconProps,
          Dialog, DialogType, DialogFooter,
          Spinner, SpinnerSize,
          Toggle,
          Stack, IStackStyles, IStackTokens, 
          getRTL,
          Icon,
          mergeStyleSets,
          List,
          ITheme, getTheme, getFocusStyle,
} from 'office-ui-fabric-react';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


type IState = {
    isopen: boolean,
    selected: any,
    list: IAttachmentCategory[],

    newString: string,

    currentValueIndex: number,

}

interface IProps  extends WrappedComponentProps {
  list: IAttachmentCategory[],
  onChange: any,
}


class CategoryListEdit extends Component<IProps,IState> {

    private dragdrop : number = 0;

    constructor(props: any) {
        super(props);
        

        this.state = {
          isopen: false,
          selected: null  ,
          newString: "",

          currentValueIndex: -1,

          list: [...this.props.list],

        };

        this.close = this.close.bind(this);
        this.onCreateCategory = this.onCreateCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.createNewItem = this.createNewItem.bind(this);
        this.onSave = this.onSave.bind(this);
        this.savecategory = this.savecategory.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this); 
        this.handleDragStart = this.handleDragStart.bind(this); 
        this.onToggle = this.onToggle.bind(this); 
    }



    private handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
        switch(name)
        {
          case "newString":
            this.setState({
                newString: value
            });
            break;
        }
    }
  
    componentDidMount()
    {
      this.refresh();
    }

    
    componentDidUpdate(prevProps: IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.list !== prevProps.list) {
        this.setState({ list: [...this.props.list] })
        this.forceUpdate();
      }
    }
  

    private onToggle(index:number)
    {
      var l : IAttachmentCategory[] = Object.assign(this.state.list);
      l[index].mandatory = !l[index].mandatory;
      this.setState({ list: l });
      this.props.onChange(l);
    }

    private deleteCategory(index: number)
    {
      var l = Object.assign(this.state.list);
      l.splice(index,1);
      this.setState({ list: l });
      this.props.onChange(l);
    }
  
    private createNewItem()
    {
      this.setState({
        currentValueIndex: -1,
        isopen: true,
      });
    }

    private savecategory(cat : IAttachmentCategory)
    {
      if(this.state.currentValueIndex < 0)    return;

      var l = Object.assign(this.state.list);
      l[this.state.currentValueIndex] = cat;

      this.setState({
        list: l,
      });
      this.forceUpdate();
    }

    private onCreateCategory() 
    {
      var l = Object.assign(this.state.list);

      if(this.state.currentValueIndex < 0)
      {
        var cat : IAttachmentCategory = {
          name: this.state.newString,
          mandatory: false,
        }
        l.push(cat);
      }
      else
      {
        l[this.state.currentValueIndex].name = this.state.newString;
      }

      this.setState({
        isopen: false,
        list: l,
        newString: "",
      });

      this.props.onChange(l);
      this.forceUpdate();
  }


    private close()
    {
        this.setState({isopen: false});
    }

  
    private refresh()
    {
    }

    private selectCategory(ev: number)
    {
      this.setState({
        newString: this.state.list[ev].name,
        currentValueIndex: ev,
        isopen: true
      });
    }

    private onSave()
    {
      this.props.onChange();
    }

    handleDragEnter(e: any)  {
      e.preventDefault();
      e.stopPropagation();
    }

    handleDragLeave(e: any)  {
      e.preventDefault();
      e.stopPropagation();
    }

    handleDragStart(e: any)  {
      this.dragdrop = parseInt(e.currentTarget.id.substring(1));
      e.stopPropagation();
    }

    handleDragOver(e: any) {
      e.dataTransfer.dropEffect = 'move';
      e.preventDefault();
      e.stopPropagation();
    }

    handleDrop(e: any) {
      var i = parseInt(e.currentTarget.id.substring(1));
      if(i !== this.dragdrop)
      {
        var l  = Object.assign(this.state.list);
        var cat : IAttachmentCategory;
        if(i === 0)
        {
          //    Move to the first item
          cat =  l.splice(this.dragdrop,1)[0];
          l.splice(0, 0, cat);
        }
        else
        {
          //    Move to the first item
          cat =  l.splice(this.dragdrop,1)[0];
          if(this.dragdrop > i)   l.splice(i, 0, cat);
          else                    l.splice(i-1, 0, cat);
        }
        this.setState({
          list: l,
        });
        this.props.onChange(l);
      }
      e.preventDefault();
      e.stopPropagation();
    };
  
    render() {
      if(this.state.list == null)
      {
        return (
          <Stack grow styles={stackStyles}>
            <Spinner size={SpinnerSize.large} label="Loading List" />
          </Stack>
        );
      }
      
      const theme: ITheme = getTheme();
      const { palette, semanticColors, fonts } = theme;

      const classNames = mergeStyleSets({
        itemCell: [
          getFocusStyle(theme, { inset: -1 }),
          {
            minHeight: 54,
            width: "100%",
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
              '&:hover': { background: palette.neutralLight },
            },
          },
        ],
        itemImage: {
          flexShrink: 0,
        },
        itemContent: {
          marginLeft: 10,
          overflow: 'hidden',
          flexGrow: 1,
        },
        itemName: [
          fonts.large,
          {
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        ],
        itemIndex: {
          fontSize: fonts.small.fontSize,
          color: palette.neutralTertiary,
          marginBottom: 10,
        },
        chevron: {
          alignSelf: 'center',
          marginLeft: 10,
          color: palette.neutralTertiary,
          fontSize: fonts.large.fontSize,
          flexShrink: 0,
        },
      });

      const deleteIcon: IIconProps = { iconName: 'Delete' };
      const addIcon: IIconProps = { iconName: 'Add' };

      const onRenderCell = (item?: IAttachmentCategory, index?: number | undefined): JSX.Element => {
        var i : IAttachmentCategory = item || {} as IAttachmentCategory;
        return (
          <div className={classNames.itemCell} 
              key={"K" + i.name + index}
              id={"I" + index}
              onDrop={this.handleDrop}
              onDragStart={this.handleDragStart}
              onDragOver={this.handleDragOver}
              onDragEnter={this.handleDragEnter}
              onDragLeave={this.handleDragLeave}
              draggable={true} data-is-focusable={true} 
              >
            <div className={classNames.itemContent}>
              <Stack horizontal>
                <Toggle label={this.props.intl.formatMessage({ id: 'profile.attachment.mandatory' })} inlineLabel onText="On" offText="Off" checked={i.mandatory} onChange={()=>{this.onToggle(index || 0);}} />
                <div className={classNames.itemName}>{i.name}</div>
              </Stack>
            </div>
            <IconButton className={classNames.chevron} iconProps={deleteIcon} onClick={() => {this.deleteCategory(index || 0);}} />
            <Icon onClick={()=>{ this.selectCategory(index || 0);}} className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
          </div>
        );
      };
  
      const dialogContentProps = {
        type: DialogType.largeHeader,
        title: this.props.intl.formatMessage({ id: 'survey.section.new.title' }),
        subText: this.props.intl.formatMessage({ id: 'survey.section.new.help' }),
      };
      const modelProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
      };      
      const stackToks: IStackTokens = {
        childrenGap: '16'
      };

      return (
      <Stack grow styles={stackStyles} tokens={stackToks}>
          <Stack horizontal tokens={stackToks}>
              <Stack grow>
                <Stack horizontal >
                  <CommandButton iconProps={addIcon} text={this.props.intl.formatMessage({ id: 'category.new' })} onClick={this.createNewItem}  />
                </Stack>                    
                <List items={this.state.list} onRenderCell={onRenderCell} />
              </Stack>
          </Stack>

          <Dialog
              hidden={!this.state.isopen}
              onDismiss={this.close}
              dialogContentProps={dialogContentProps}
              modalProps={modelProps}
          >
              <TextField name="newString" label={this.props.intl.formatMessage({ id: 'category.name' })} onChange={this.handleChange} value={this.state.newString} />
              <DialogFooter>
                  <PrimaryButton onClick={this.onCreateCategory} text={this.props.intl.formatMessage({ id: 'std.save' })} />
                  <DefaultButton onClick={this.close} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
              </DialogFooter>
          </Dialog>            
        </Stack>
      
        );
      };
      
}

export default injectIntl(CategoryListEdit);