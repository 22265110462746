import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { API, APIKEY, ICertificateTemplate, ITemplateSurvey } from './IVesta';

import {  
          Stack, IStackStyles, IStackTokens, TextField, Toggle,
          DefaultButton, PrimaryButton, Separator,
} from 'office-ui-fabric-react';

import TemplateSurveys from './TemplateSurveys';
import InfoIcon from './InfoIcon';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};



interface IState {
    selected: string,
    isOpen: boolean,
    template: ICertificateTemplate,
    shouldSave: boolean,
  }
  
  
  interface IProps extends WrappedComponentProps {
    user: string,
    id: any
    onClose?: any
  }


class CertificateTemplate extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        
        this.state = {
          selected:"Undefined"  ,
          isOpen: false     ,
          template: { 
            id: "",
            name: "",
            description: "",
            surveys: []
          } as ICertificateTemplate,
          shouldSave: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.SurveysChanged = this.SurveysChanged.bind(this);
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
        var sec;
        switch(name)
        {
        case "name":
              sec = this.state.template;
              sec.name = value;
              this.setState({
                template: sec,
                shouldSave: true,
              });
              break;
          case "description":
            sec = this.state.template;
            sec.description = value;
            this.setState({
              template: sec,
              shouldSave: true,
            });
            break;
        }
      }

    componentDidMount()
    {
      this.refresh();
    }

  
    refresh()
    {
        if(this.props.id === "")   
        {
          this.setState({
            template: {
              name: "",
              description: "",
            } as ICertificateTemplate, 
            shouldSave: false,
          });
          return;
        } 

        const post = {
          security: {
            APIKey: APIKEY,
            user: this.props.user,
          },
          id: this.props.id
        }
        axios.post(API + "/certificate/template/Get",post)
          .then(response => {
            this.setState({
                template: response.data.template, 
                shouldSave: false,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  

    public SurveysChanged(newItems: ITemplateSurvey[])
    {
      var t = this.state.template;
      t.surveys = newItems;
      this.setState({
        template: t, 
        shouldSave: true,
      });
    }

    save()
    {
        if(this.props.id === "")
        {
            const post = {
                Security: {
                  APIKey: APIKEY,
                  User: this.props.user
                },
                template: this.state.template,
              }
              axios.post(API + "/certificate/template/create",post)
                .then(() => {
                        this.props.onClose();
                })
                .catch(function (error) {
                      console.log(error);
                });                  
        }
        else
        {
            const post = {
                Security: {
                  APIKey: APIKEY,
                  User: this.props.user,
                },
                template: this.state.template
              }
              axios.post(API + "/certificate/template/update",post)
                .then(() => {
                        this.props.onClose();
                })
                .catch(function (error) {
                      console.log(error);
                });                  
        }
    }

    close()
    {
        this.props.onClose();
    }

    render() {

          const stackToks: IStackTokens = {
              childrenGap: '6'
          };


          var completedate;
          if(this.state.template.useExpectedCompletedDate)
          {
            completedate = <TextField
                              style={{width: 200}} 
                              label={this.props.intl.formatMessage({ id: 'template.completion.days' })} 
                              name="expected" 
                              value={(this.state.template.expectedCompletionPeriod || 30).toString()}  
                              onChange={this.handleChange}/>;

          }
            
          return (
            <Stack grow styles={stackStyles}>
              <Stack>

                <Stack horizontal grow verticalAlign="end">
                  <Stack.Item grow >
                    <TextField label={this.props.intl.formatMessage({ id: 'template.name' })} value={this.state.template.name}  name="name" onChange={this.handleChange}/>
                  </Stack.Item>
                  <InfoIcon id={this.state.template.id} />
                </Stack>


                <TextField label={this.props.intl.formatMessage({ id: 'template.description' })} value={this.state.template.description}  name="description" onChange={this.handleChange}/>
                <Stack horizontal>
                  <Stack style={{paddingTop:35}}>
                    <Toggle checked={this.state.template.useExpectedCompletedDate} onText={this.props.intl.formatMessage({ id: 'survey.use.completion.date' })} offText={this.props.intl.formatMessage({ id: 'survey.dontuse.completion.date' })} onChange={()=>{var s = this.state.template; s.useExpectedCompletedDate = !s.useExpectedCompletedDate; this.setState({template: s, shouldSave: true})}} />
                  </Stack>
                  {completedate}
                </Stack>
                <Toggle label={this.props.intl.formatMessage({ id: 'template.external' })} 
                   checked={this.state.template.external}
                   onText={this.props.intl.formatMessage({ id: 'std.yes' })}
                   offText={this.props.intl.formatMessage({ id: 'std.no' })}
                   onChange={()=>{var s = this.state.template; s.external = !s.external; this.setState({template: s, shouldSave: true})}} 
                />
                <Stack horizontal tokens={stackToks} padding="16px">
                    <PrimaryButton onClick={this.save} text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} />
                    <DefaultButton onClick={this.close} text={this.state.shouldSave ? this.props.intl.formatMessage({ id: 'std.cancel' }) : this.props.intl.formatMessage({ id: 'std.close' })} />
                </Stack>
              </Stack>
              <Separator />
              <TemplateSurveys user={this.props.user} surveys={this.state.template.surveys} onChange={this.SurveysChanged}  />
            </Stack>
        );
      };
      
}

export default injectIntl(CertificateTemplate);