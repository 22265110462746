import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Moment from 'react-moment';
import { injectIntl,  WrappedComponentProps, FormattedMessage  } from 'react-intl';


import {API, APIKEY, IMessage} from './IVesta'

import { initializeIcons } from '@uifabric/icons';

import {
  DetailsList,
  SelectionMode,
  IColumn,
  Stack, IStackStyles, Text,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow
} from 'office-ui-fabric-react';


import Message from './Message';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};



interface IState {
  user: string,
  messages: IMessage[],
  message: IMessage,
  canCreate: boolean,
  open: boolean,
}
  
interface IProps extends WrappedComponentProps {
  client: string
}

class Messages extends Component<IProps,IState> {

  constructor(props: IProps) {
        super(props);
        initializeIcons();

        this.state = {
          user: "",
          messages: [],
          message: {} as IMessage,
          open: false,
          canCreate: true,            
        };
      
        this.onClose = this.onClose.bind(this);
        this._MessageChanged = this._MessageChanged.bind(this);
    }

    private onClose()
    {
      this.setState({
        open: false,
      })
    }

    private _MessageChanged = (item: IMessage, index? :number): void => {
        this.setState({open: true, message: item});
    }; 


    componentDidMount()
    {
      this.refresh();
    }
  
    refresh()
    {
      Auth.currentSession()
          .then((session: any) => { 
            this.setState({ user: session.idToken.payload.email});
            this.authenticatedrefresh(session.idToken.payload.email);
          })
          .catch( function(err) {
            console.log(err);
          });
    }

    authenticatedrefresh(u: string)
    {
      
      const post = {
        security: {
          APIKey: APIKEY,
          User: u
        },
        messageFor: 3
      }

      var api = API + "/message/forUser";
      api = API + "/message/GetMessages"

      axios.post(api,post)
        .then(response => {
          this.setState({ 
            messages: response.data.messages,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    private _onColumnClick = (a: any, b: any): void => {
    
    };
    
    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
          if(props.item.read === false){
              customStyles.root = { backgroundColor: '#e5e5e5' };
          }
        return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };

      render() {
        const msgColumns: IColumn[] = [
          {
              key: 'column1',
              name: this.props.intl.formatMessage({ id: 'message.to' }),
              fieldName: 'displayToEmail',
             
              minWidth: 100,
              maxWidth: 150,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'column2',
              name: this.props.intl.formatMessage({ id: 'message.subject' }),
              fieldName: 'subject',
              minWidth: 0,
              maxWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,  
            },
            {
              key: 'column3',
              name: this.props.intl.formatMessage({ id: 'message.type' }),
              fieldName: 'type',
              minWidth: 0,
              maxWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,  
            },
            {
              key: 'column5',
              name: this.props.intl.formatMessage({ id: 'message.datesent' }),
              fieldName: 'dateSent',
              minWidth: 140,
              maxWidth: 200,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
              onRender: (item: IMessage) => {
                return <Moment format="ddd DD MMM YYYY HH:mm">{item.dateSent}</Moment>;
              },
            },
            {
                key: 'column6',
                name: this.props.intl.formatMessage({ id: 'message.dateread' }),
                fieldName: 'dateRead',
                minWidth: 140,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IMessage) => {
                    if(item.read)   return <Moment format="ddd DD MMM YYYY HH:mm">{item.dateRead}</Moment>;
                    return this.props.intl.formatMessage({ id: 'message.notread' });
                },
              },
          ];


        var msg;
        if(this.state.messages === undefined)
        {
          return "Internal Error";
        }
        if(this.state.messages.length === 0)
        {
          msg = <Text variant="large" ><FormattedMessage id="message.nomessages"/></Text>;
        }
        else
        {
          msg = <DetailsList className="MessagesDetailsList" selectionMode={SelectionMode.none} items={this.state.messages} onActiveItemChanged={this._MessageChanged} columns={msgColumns}
          onRenderRow={this._onRenderRow} />;
        }

          return (
            <Stack grow styles={stackStyles}>
                <Text variant="xLarge" ><FormattedMessage id="message.messages"/></Text>
                {msg}
                <Message nolinks user={this.state.user} visible={this.state.open} onClose={this.onClose} message={this.state.message} />
            </Stack>
        );
      };
      
}

export default injectIntl(Messages);