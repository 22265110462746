import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import './App.css';

import {  IUD,  } from './IVesta';


import { 
            Text, 
            Stack, IStackStyles,
            DefaultPalette,
} from 'office-ui-fabric-react';

type IState = {
}
  
interface IProps extends WrappedComponentProps {
    user: string,
    ud: IUD[],
    nolinks?: boolean,
}


class UD extends Component<IProps,IState>
{
    constructor(props: any) {
        super(props);
        
        this.state = {
        };

    }

    _handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
  
        switch(name)
        {
          case "reply":
            this.setState({ replyText: value });
            break;
        }
    }


    componentDidMount()
    {
        this.refresh();
    }

    componentDidUpdate(prevProps: IProps)
    {
        if (this.props.ud !== prevProps.ud) {
            this.refresh();
        }
    }

    refresh()
    {      
    }

    onUpdate()
    {
    }  

     
    render() 
    {
        if(this.props.ud == null)
        {
            return (<Text variant="large" >{this.props.intl.formatMessage({ id: 'ud.none' })}</Text>);
        }

        const itemStyles: IStackStyles = {
            root: {
                backgroundColor: DefaultPalette.themeDarker,
                color: DefaultPalette.white,
                paddingTop: 5,
                paddingBottom: 5,
                margin: 5,
            }
          };
          
          const stackStyles: IStackStyles = {
            root: {
                paddingTop: 10,
                paddingBottom: 10,
            }
          };

          const fieldStyles: IStackStyles = {
            root: {
                verticalAlign: "middle",
            }
          };

          const udf = this.props.ud.map(i => {
            return (
            <Stack key={i.id} horizontal tokens={{childrenGap: 5, }} styles={stackStyles}>
                <Stack.Item grow={1} styles={itemStyles}>
                    {i.id}
                </Stack.Item>
                <Stack.Item grow={4} styles={fieldStyles}>
                    {i.value}
                </Stack.Item>
            </Stack>
            );
        });

        return (
            <Stack>
                {udf}
            </Stack>);
    }      
}

export default injectIntl(UD);
