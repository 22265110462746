import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import {
    Stack, IStackStyles, Text, Separator,
} from 'office-ui-fabric-react';

import SurveyQuestion from './SurveyQuestion';
import { ISurveySection } from './IVesta';


const stackStyles: IStackStyles = {
    root: {
        width: "500"
    },
};

type IState = {
}

interface IProps extends WrappedComponentProps {
    certificateID: string,
    surveyID: string,
    section: ISurveySection,
    role: string,
    user: string,
    onUpdate: any,
}


class SurveySection extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
        };
    }


    componentDidMount() {
        this.refresh();
    }


    refresh() {
    }


    render() {
        var questions;

        var err = <></>;
        if (this.props.section.validationError !== "") {
            err = <Stack>
                <Text style={{ color: "red" }} >{this.props.section.validationError}</Text>
            </Stack>;
        }

        if (this.props.section == null) {
            return (<Text variant="medium"><FormattedMessage id="survey.section.select" /></Text>)
        }

        const role = this.props.role;
        const update = this.props.onUpdate;
        const user = this.props.user;
        const surveyID = this.props.surveyID;
        const certificateID = this.props.certificateID;

        questions = this.props.section.questions.map(function (element: any) {
            return (
                <SurveyQuestion
                    certificateID={certificateID}
                    surveyID={surveyID}
                    question={element}
                    user={user}
                    role={role}
                    key={element.id}
                    onUpdate={update}
                />
            );
        });

        return (
            <Stack grow styles={stackStyles}>
                <Stack>
                    <Text variant="xLarge">{this.props.section.description}</Text>
                    {err}
                    <Separator />
                    {questions}
                </Stack>
            </Stack>

        );
    };

}

export default injectIntl(SurveySection);