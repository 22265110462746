import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import {API, APIKEY, IReport, IReportSection, IReportLine, IObjectAttachment, } from './IVesta';

import {  Nav, INavLinkGroup, INavStyles, INavLink, Label,
          TextField,
          DefaultButton, PrimaryButton, IconButton, ActionButton,
          CommandButton, IIconProps,
          Dialog, DialogType, DialogFooter,
          Image, ImageFit,
          Panel, PanelType,
          Spinner, SpinnerSize,
          Stack, IStackStyles, IStackTokens, Separator,
          getRTL,
          Icon,
          mergeStyleSets,
          List,
          ITheme, getTheme, getFocusStyle,
} from 'office-ui-fabric-react';

import ReportLineEdit from './ReportLineEdit';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


type IState = {
    isopen: boolean,
    selected: any,
    isNewSectionOpen: boolean,
    report: IReport,

    currentSection: IReportSection,
    currentSectionIndex: number,

    newSectionName: string,
    newSectionDescription: string,

    currentLine: any,
    currentLineIndex: number,

    attachments: IObjectAttachment[],

    shouldSave: boolean,
}

interface IProps  extends WrappedComponentProps {
  user: string,
  reportID: string,
  onClose: any,
}


class ReportEdit extends Component<IProps,IState> {

    private dragdrop : number = 0;



    constructor(props: any) {
        super(props);
        
        this.state = {
          isopen: false,
          selected: null  ,
          isNewSectionOpen: false     ,
          newSectionName: "",
          newSectionDescription: "",

          currentSection: {name: "", description: "",  lines: []},
          currentSectionIndex: -1,

          currentLine: null,
          currentLineIndex: -1,

          report: { name: "", description: "", sections: [],  },

          attachments: [],

          shouldSave: false,
        };

        this.createNewSection = this.createNewSection.bind(this);
        this.closeNewSection = this.closeNewSection.bind(this);
        this.onCreateNewSection = this.onCreateNewSection.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectSection = this.selectSection.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.selectLine = this.selectLine.bind(this);
        this.deleteLine = this.deleteLine.bind(this);
        this.createNewLine = this.createNewLine.bind(this);
        this.onSave = this.onSave.bind(this);
        this.saveLine = this.saveLine.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this); 
        this.handleDragStart = this.handleDragStart.bind(this); 
        this.deleteSection = this.deleteSection.bind(this); 
    }

    private deleteSection()
    {
      var survey = this.state.report;
      survey.sections.splice(this.state.currentSectionIndex,1);
      this.setState({ 
        report: survey, 
        shouldSave: true, 
        currentSection: 
                {
                  name: "", 
                  description: "", 
                  lines: []
                },
        currentSectionIndex: -1,
      });
    }



    private dismissPanel()
    {
        this.setState({isopen: false});
    }  

    private handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
        var s;
        switch(name)
        {
          case "description":
            s = this.state.report;
            s.description = value;
            this.setState({report: s,shouldSave: true});
            break;
          case "name":
            s = this.state.report;
            s.name = value;
            this.setState({report: s,shouldSave: true});
            break;
          case "newSectionName":
            this.setState({
                newSectionName: value
            });
            break;
          case "newSectionDescription":
            this.setState({
                newSectionDescription: value
            });
            break;
          case "currentSectionName":
            s = this.state.currentSection;
            s.name = value;
            this.setState({
              currentSection: s,
              shouldSave: true,
            });
            break;
          case "currentSectionDescription":
            s = this.state.currentSection;
            s.description = value;
            this.setState({
              currentSection: s,
              shouldSave: true,
            });
            break;
        }
    }
  
    componentDidMount()
    {
      this.refresh();
    }

    private deleteLine(index: number)
    {
      var survey = this.state.report;
      survey.sections[this.state.currentSectionIndex].lines.splice(index,1);
      this.setState({ report: survey, shouldSave: true, });
    }
  
    private createNewLine()
    {
      if(this.state.currentSectionIndex < 0)    return;
      var survey = this.state.report;
      var q : IReportLine = {
        description: "",
        type: 0,
        options: "",
        surveyID: "",
        questionID: "",
        font: {
          typeface: "",
          size: 12,
          color: "#000000",
          bold: false,
          italic: false,
          underline: false,
        },
      }

      var i =  survey.sections[this.state.currentSectionIndex].lines.length;
      survey.sections[this.state.currentSectionIndex].lines.push(q);

      this.setState({
        report: survey,
        currentSection: survey.sections[this.state.currentSectionIndex],
        currentLine: q,
        currentLineIndex: i,
        isopen: true,
        shouldSave: true,
      });
    }


    private createNewSection()
    {
        this.setState({isNewSectionOpen: true});
    }

    private saveLine(line : IReportLine)
    {
      if(this.state.currentSectionIndex < 0)    return;
      if(this.state.currentLineIndex < 0)    return;

      var report: IReport = Object.assign(this.state.report);
      var lines = [...report.sections[this.state.currentSectionIndex].lines];
      lines[this.state.currentLineIndex] = line;
      report.sections[this.state.currentSectionIndex].lines = lines;

      this.setState({
        report: report,
        currentSection: report.sections[this.state.currentSectionIndex],
        shouldSave: true,
      });
      this.forceUpdate();
    }


    private onCreateNewSection() 
    {
      var section : IReportSection = {
        name: this.state.newSectionName,
        description: this.state.newSectionDescription,
        lines: []
      }
      this.state.report.sections.push(section);
      this.setState({
        isNewSectionOpen: false,
        newSectionName: "",
        newSectionDescription: "",
        shouldSave: true,
      })
  }


    private closeNewSection()
    {
        this.setState({isNewSectionOpen: false});
    }

  
    private refresh()
    {
      const post = {
        security: {
          APIKey: APIKEY,
          User: this.props.user,
        },
        id: this.props.reportID
      }
      axios.post(API + "/report/Edit",post)
        .then(response => {
          var sec = response.data.report.sections[0];
          this.setState({ 
            report: response.data.report, 
            currentSection: sec, 
            currentSectionIndex: 0 }); 
          })
        .catch(function (error) {
          console.log(error);
        });
    }

    private selectLine(ev: number)
    {
      this.setState({
        currentLine: this.state.currentSection.lines[ev],
        currentLineIndex: ev,
        isopen: true
      });

    }


  
    private selectSection(event: any,item?: INavLink,index?: number)
    {
        var i = (item !== undefined) ? parseInt(item.key!) :  0;
        var sec = this.state.report.sections[i];
        this.setState({
          currentSection: sec, 
          currentSectionIndex: i,
        }); 
    }

    private onSave()
    {
      const post = {
        Security: {
          APIKey: APIKEY,
          User: this.props.user
        },
        report: this.state.report,
      }
      axios.post(API + "/report/Save",post)
        .then(() => {
          this.setState({shouldSave: false});
        })
        .catch(function (error) {
              console.log(error);
        });
    }

    handleDragEnter(e: any)  {
      e.preventDefault();
      e.stopPropagation();
    }

    handleDragLeave(e: any)  {
      e.preventDefault();
      e.stopPropagation();
    }

    handleDragStart(e: any)  {
      this.dragdrop = parseInt(e.currentTarget.id.substring(1));
      e.stopPropagation();
    }

    handleDragOver(e: any) {
      e.dataTransfer.dropEffect = 'move';
      e.preventDefault();
      e.stopPropagation();
    }

    handleDrop(e: any) {
      var i = parseInt(e.currentTarget.id.substring(1));
      if(i !== this.dragdrop)
      {
        var report = Object.assign(this.state.report);
        var lines  = [...report.sections[this.state.currentSectionIndex].lines];
        var q;
        if(i === 0)
        {
          //    Move to the first item
          q =  lines.splice(this.dragdrop,1)[0];
          lines.splice(0, 0, q);
          report.sections[this.state.currentSectionIndex].lines = lines;
        }
        else
        {
          //    Move to the first item
          q =  lines.splice(this.dragdrop,1)[0];
          if(this.dragdrop > i)   lines.splice(i, 0, q);
          else                    lines.splice(i-1, 0, q);
          report.sections[this.state.currentSectionIndex].lines = lines;
        }
        this.setState({
          report: report,
          currentSection: report.sections[this.state.currentSectionIndex],
          shouldSave: true,
        });
      }
      e.preventDefault();
      e.stopPropagation();
    };
  
    render() {

      if(this.state.report == null)
      {
        return (
          <Stack grow styles={stackStyles}>
            <Spinner size={SpinnerSize.large} label="Loading Survey" />
          </Stack>
        );
      }
      
      const theme: ITheme = getTheme();
      const { palette, semanticColors, fonts } = theme;

      const classNames = mergeStyleSets({
        itemCell: [
          getFocusStyle(theme, { inset: -1 }),
          {
            minHeight: 54,
            padding: 10,
            width: "100%",
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
              '&:hover': { background: palette.neutralLight },
            },
          },
        ],
        itemImage: {
          flexShrink: 0,
        },
        itemContent: {
          marginLeft: 10,
          overflow: 'hidden',
          flexGrow: 1,
        },
        itemName: [
          fonts.large,
          {
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        ],
        itemIndex: {
          fontSize: fonts.small.fontSize,
          color: palette.neutralTertiary,
          marginBottom: 10,
        },
        chevron: {
          alignSelf: 'center',
          marginLeft: 10,
          color: palette.neutralTertiary,
          fontSize: fonts.large.fontSize,
          flexShrink: 0,
        },
      });

      const deleteIcon: IIconProps = { iconName: 'Delete' };

      const onRenderCell = (item?: IReportLine, index?: number | undefined): JSX.Element => {
        var i : IReportLine = item || { } as IReportLine;
        var desc = "";
        if(item != null)
        {
          switch(item.type)
          {
            case 0: case 1:
              desc = item.options;
              break;
            case 2:
              desc = "Image";
              break;
            case 3:
              desc = "Separator";
              break;
            case 4:
              desc = "Question";
              break;
          }
        }
        return (
          <div className={classNames.itemCell} 
              id={"I" + index}
              onDrop={this.handleDrop}
              onDragStart={this.handleDragStart}
              onDragOver={this.handleDragOver}
              onDragEnter={this.handleDragEnter}
              onDragLeave={this.handleDragLeave}
              draggable={true} data-is-focusable={true} 
              >
            <Image className={classNames.itemImage} src={"/images/icons/linetypes/" + i.type + ".png"} width={50} height={50} imageFit={ImageFit.cover} />
            <div className={classNames.itemContent}>
              <div className={classNames.itemName}>{desc}</div>
                <div className={classNames.itemIndex}>
                </div>
            </div>
            <IconButton className={classNames.chevron} iconProps={deleteIcon} onClick={() => {this.deleteLine(index || 0);}} />
            <Icon onClick={()=>{ this.selectLine(index || 0);}} className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
          </div>
        );
      };
  
      const dialogContentProps = {
        type: DialogType.largeHeader,
        title: this.props.intl.formatMessage({ id: 'survey.section.new.title' }),
        subText: this.props.intl.formatMessage({ id: 'survey.section.new.help' }),
      };
      const modelProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
      };

      
      const stackToks: IStackTokens = {
        childrenGap: '16'
      };

      const addIcon: IIconProps = { iconName: 'Add' };

      var lines = <Stack></Stack>;

      if(this.state.currentSection != null) 
      {
        var q = this.state.currentSection.lines;

        lines = 
        <Stack>
          <CommandButton iconProps={addIcon} text={this.props.intl.formatMessage({ id: 'report.new.line' })} onClick={this.createNewLine}  />
          <List items={q} onRenderCell={onRenderCell} />
          <Panel
                isOpen={this.state.isopen}
                onDismiss={this.dismissPanel}
                headerText={this.props.intl.formatMessage({ id: 'line.edit.header' })}
                closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                type={PanelType.large}

                // Stretch panel content to fill the available height so the footer is positioned
                // at the bottom of the page
                //  isFooterAtBottom={true}
                >
                  <ReportLineEdit 
                      user={this.props.user} 
                      onSave={this.saveLine} 
                      line={this.state.currentLine} 
                      attachments={this.state.attachments} 
                      surveySectionID={this.state.currentSection.id} 
                      onClose={this.dismissPanel}
                  />
                  </Panel>
        </Stack>;


      }

      const navStyles: Partial<INavStyles> = {
        root: {
          width: 208,
          height: 350,
          boxSizing: 'border-box',
          border: '0px',
          overflowY: 'auto',
        },
      };
      
      const navLinkGroups: INavLinkGroup[] = [
        {
          links: this.state.report.sections.map(function(element: any,i: number)  {
            var icon;
              if(element.qaPass)      icon="Completed";
              else                    icon="ErrorBadge";
              
            return({
              name: element.name,
              url: '#',
              key: i.toString(),
              icon: icon,
              isExpanded: true,
            } as INavLink );
          })
        }]
        
          return (
            <Stack grow styles={stackStyles} tokens={stackToks}>
                <Stack>
                    <TextField label={this.props.intl.formatMessage({ id: 'report.name' })} name="name" value={this.state.report.name} onChange={this.handleChange}/>
                    <TextField label={this.props.intl.formatMessage({ id: 'report.description' })} name="description" value={this.state.report.description}  onChange={this.handleChange}/>
                </Stack>
                <Stack tokens={stackToks} horizontal >
                    <PrimaryButton onClick={this.onSave} disabled={!this.state.shouldSave} text={this.props.intl.formatMessage({ id: 'std.save' })} />
                    <DefaultButton onClick={this.props.onClose} text={this.state.shouldSave ? this.props.intl.formatMessage({ id: 'std.cancel' }) : this.props.intl.formatMessage({ id: 'std.close' })} />
                </Stack>
                <Separator />
                <Stack horizontal tokens={stackToks}>
                    <Stack>
                      <CommandButton iconProps={addIcon} text={this.props.intl.formatMessage({ id: 'survey.section.new' })} onClick={this.createNewSection}  />
                      <Nav
                          onLinkClick={this.selectSection}
                          selectedKey=""
                          ariaLabel={this.props.intl.formatMessage({ id: 'survey.sections' })}
                          styles={navStyles}
                          groups={navLinkGroups}
                        />

                    </Stack>
                    <Stack grow>
                      <TextField name="currentSectionName" label={this.props.intl.formatMessage({ id: 'survey.section.name' })} onChange={this.handleChange} value={this.state.currentSection.name} />
                      <TextField name="currentSectionDescription" label={this.props.intl.formatMessage({ id: 'survey.section.description' })}  onChange={this.handleChange} multiline value={this.state.currentSection.description} />
                      <ActionButton onClick={this.deleteSection} disabled={this.state.currentSection.lines.length > 0} iconProps={deleteIcon}>Delete Section</ActionButton>
                      <Separator />
                      <Label>Lines</Label>
                      {lines}
                    </Stack>
                </Stack>

                <Dialog
                    hidden={!this.state.isNewSectionOpen}
                    onDismiss={this.closeNewSection}
                    dialogContentProps={dialogContentProps}
                    modalProps={modelProps}
                >
                    <TextField name="newSectionName" label={this.props.intl.formatMessage({ id: 'survey.section.name' })} onChange={this.handleChange} value={this.state.newSectionName} />
                    <TextField name="newSectionDescription" label={this.props.intl.formatMessage({ id: 'survey.section.description' })}  onChange={this.handleChange} multiline value={this.state.newSectionDescription} />
                    <DialogFooter>
                        <PrimaryButton onClick={this.onCreateNewSection} text={this.props.intl.formatMessage({ id: 'std.save' })} />
                        <DefaultButton onClick={this.closeNewSection} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
                    </DialogFooter>
                </Dialog>            
        </Stack>
      
        );
      };
      
}

export default injectIntl(ReportEdit);