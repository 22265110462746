import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import { MAPSKEY } from './IVesta';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';

import {    getTheme, FontWeights, Text,
          Stack, IStackTokens, 
          DefaultButton, PrimaryButton, 
          mergeStyles, mergeStyleSets, FontIcon, Modal,
} from 'office-ui-fabric-react';


const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    padding: '0px 0px 14px 0px',
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '8px 24px 24px 24px',
    overflowY: 'hidden',
    width: 640,
    height: 480,
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});


interface IState  {
  isOpen: boolean,
  longitude: number,
  latitude: number,
}


interface IProps extends WrappedComponentProps {
  user: string
  onChange?: any
  latitude: number
  longitude: number
}


export class LocationIcon extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        
        this.state = {
          isOpen: false     ,
          longitude: this.props.latitude,
          latitude: this.props.longitude,
        };

        this.dismissDialog = this.dismissDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.mouseclick = this.mouseclick.bind(this);
        this.dblclick = this.dblclick.bind(this);
        this.dosave = this.dosave.bind(this);
        
    }

    dosave()
    {
      if(this.props.onChange == null) return;
      this.props.onChange(this.state.latitude,this.state.longitude)
      this.dismissDialog();
    }

    dblclick(e: any)
    {
      e.domEvent.cancelBubble = true;
      this.setState({  latitude: e.latLng.lat(), longitude: e.latLng.lng()})
    }

    private dismissDialog()
    {
        this.setState({isOpen: false});
    }  

    private openDialog()
    {
        this.setState({isOpen: true});
    }  



    componentDidUpdate(prevProps: IProps) {
        if (prevProps.latitude !== this.props.latitude) {
            this.setState({ latitude: this.props.latitude});
        }
        if (prevProps.longitude !== this.props.longitude) {
            this.setState({ longitude: this.props.longitude});
        }
      }
      

    mouseclick(e: any)
    {
    }  

    render() {
        const iconClass = mergeStyles({
          fontSize: 32,
          height: 32,
          width: 32,
          margin: '0 25px',
        });

        const classNames = mergeStyleSets({
          greenYellow: [{ color: 'greenyellow' }, iconClass],
          black: [{ color: 'black' }, iconClass],
        });

        console.log("LocationIcon");
        console.log(this.state);
      
        var iconColor;
        var zoom = 18;

        if(this.state.latitude === 0 && this.state.longitude === 0)
        {
          iconColor = classNames.black;
          zoom = 1;
        }
        else  iconColor = classNames.greenYellow;
          
        const footerTokens: IStackTokens = {  childrenGap: '20px' };
        const containerStyle = { width: '600px', height: '440px' };

        return (
              <Stack>
                  <FontIcon iconName="Globe" onClick={this.openDialog} className={iconColor}/>
                  <Modal
                      isOpen={this.state.isOpen}
                      onDismiss={this.dismissDialog}
                      isBlocking={false}
                      containerClassName={contentStyles.container}
                  >
                      <Stack>
                          <Text className={contentStyles.header} variant="xLarge">Location</Text>
                          <Stack horizontalAlign="center">
                              <div className={contentStyles.body}>
                              <LoadScript
                                  googleMapsApiKey={MAPSKEY}
                              >
                                  <GoogleMap
                                  mapContainerStyle={containerStyle}
                                  center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                  zoom={zoom}
                                  onDblClick={this.dblclick}
                                  options={{disableDoubleClickZoom: false}}
                                  >
                                      <Marker
                                          position={{ lat: this.state.latitude, lng: this.state.longitude }}
                                      />
                                  </GoogleMap>
                              </LoadScript>




                              </div>
                          </Stack>
                          <Stack horizontal horizontalAlign="center" tokens={footerTokens}>
                              <PrimaryButton onClick={this.dosave} text={this.props.intl.formatMessage({ id: 'std.accept' })} />
                              <DefaultButton onClick={this.dismissDialog} text={this.props.intl.formatMessage({ id: 'std.reject' })} />
                          </Stack>
                      </Stack>
                  </Modal>
              </Stack>
        );
      };
      
}

export default injectIntl(LocationIcon);