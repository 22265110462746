import React, { Component } from "react";
import axios from "axios";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Moment from "moment";
import ReactPlayer from "react-player/lazy";

import { API, APIKEY, IQuestion, stdDayPickerStrings } from "./IVesta";

import {
    Stack,
    IStackStyles,
    IStackItemStyles,
    getTheme,
    FontIcon,
    Text,
    List,
    mergeStyles,
    mergeStyleSets,
    Separator,
    Dialog,
    DialogFooter,
    DialogType,
    Spinner,
    SpinnerSize,
    Rating,
    TextField,
    Image,
    ImageFit,
    Modal,
    Pivot,
    PivotItem,
    FontWeights,
    PivotLinkSize,
    DefaultButton,
    PrimaryButton,
    Checkbox,
    DatePicker,
    DayOfWeek,
} from "office-ui-fabric-react";

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        width: "750px",
        paddingBottom: "20px",
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
    body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
});

const stackStyles: IStackStyles = {
    root: {
        width: "500",
    },
};

type IState = {
    dialogOpen: boolean;
    feedbackOpen: boolean;
    expires: boolean;
    expiresOn: Date;
    reason: string;
};

interface IProps extends WrappedComponentProps {
    certificateID: string;
    surveyID: string;
    question: IQuestion;
    role: string;
    user: string;
    onUpdate?: any;
}

class SurveyQuestion extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        var d = this.addDays(new Date(), 356);
        var e = false;

        this.state = {
            dialogOpen: false,
            feedbackOpen: false,
            reason: "",
            expires: e,
            expiresOn: d,
        };

        this._onToggleDialog = this._onToggleDialog.bind(this);
        this._onToggleFeedback = this._onToggleFeedback.bind(this);
        this._handleExpiredChange = this._handleExpiredChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._decline = this._decline.bind(this);
        this._approve = this._approve.bind(this);
    }

    _handleChange(event: any) {
        const target = event.target;
        const value: any =
            target.type === "checkbox" ? target.checked : target.value;
        const name: string = target.name;

        switch (name) {
            case "reason":
                this.setState({ reason: value });
                break;
            case "expires":
                this.setState({ expires: value });
                break;
        }
    }

    _handleExpiredChange(date?: Date | null) {
        if (date !== null && date !== undefined) {
            this.setState({ expiresOn: date });
        }
    }
    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }
    _onToggleDialog() {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    }

    _onToggleFeedback() {
        this.setState({ feedbackOpen: !this.state.feedbackOpen });
    }

    _approve() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            questionid: this.props.question.id,
            surveyid: this.props.surveyID,
            certificateid: this.props.certificateID,
            approve: true,
            expires: this.state.expires,
            expiresOn: this.state.expiresOn,
        };

        axios
            .post(API + "/survey/ApproveAnswer", post)
            .then(() => {
                if (this.props.onUpdate != null) {
                    this.setState({ dialogOpen: false });
                    this.props.onUpdate();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _decline() {
        const post = {
            security: {
                APIKey: APIKEY,
                User: this.props.user,
            },
            questionid: this.props.question.id,
            surveyid: this.props.surveyID,
            certificateid: this.props.certificateID,
            reason: this.state.reason,
            approve: false,
        };
        axios
            .post(API + "/survey/ApproveAnswer", post)
            .then(() => {
                this.setState({ dialogOpen: false });
                if (this.props.onUpdate != null) this.props.onUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        if (this.props.question == null) {
            return (
                <Stack grow styles={stackStyles}>
                    <Spinner size={SpinnerSize.large} label="Loading Question" />
                </Stack>
            );
        }

        var textColor;
        if (this.props.question.conditionalDisabled) {
            textColor = { color: "LightGray" };
        }

        const stackItemStyles: IStackItemStyles = {
            root: {
                alignItems: "center",
                display: "flex",
                height: 50,
                justifyContent: "flex-start",
            },
        };
        const answerStackItemStyles: IStackItemStyles = {
            root: {
                alignItems: "start",
                display: "flex",
                height: 50,
                width: 150,
                justifyContent: "flex-start",
            },
        };
        const dialogContentProps = {
            type: DialogType.largeHeader,
            title: this.props.intl.formatMessage({
                id: "evidence.reject.dialog.title",
            }),
            subText: this.props.intl.formatMessage({
                id: "evidence.reject.dialog.text",
            }),
        };
        const modalProps = {
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
        };

        const iconClass = mergeStyles({
            fontSize: 25,
            height: 25,
            width: 25,
            margin: "0 25px",
        });

        const downloadiconClass = mergeStyles({
            fontSize: 64,
            height: 100,
            width: 100,
            paddingBottom: "155px",
        });

        const classNames = mergeStyleSets({
            approved: [{ color: "green", cursor: "pointer" }, iconClass],
            rejected: [{ color: "red", cursor: "pointer" }, iconClass],
            notapproved: [{ color: "black", cursor: "pointer" }, iconClass],
            downloadPDF: [{ color: "black", cursor: "pointer" }, downloadiconClass],
        });

        var answer;
        var evidencedisplay;
        var approval = (
            <>
                <DefaultButton
                    text={this.props.intl.formatMessage({ id: "evidence.accept" })}
                    disabled
                />
                <DefaultButton
                    text={this.props.intl.formatMessage({ id: "evidence.reject" })}
                    disabled
                />
            </>
        );
        var evidenceurl = "";

        if (this.props.question.answer == null) {
            answer = (
                <Text variant="small">
                    <i>Not Yet Answered</i>
                </Text>
            );
        } else if (this.props.question.answer.na) {
            answer = (
                <Text variant="small">
                    <i>Not Applicable</i>
                </Text>
            );
            textColor = { color: "LightGray" };
        } else {
            if (this.props.question.answer.evidence != null) {
                evidenceurl = this.props.question.answer.evidence.url;
            }
            var ans = this.props.question.answer.answer;
            answer = <Text variant="large">{ans}</Text>;
            if (this.props.question.mustApprove) {
                if (this.props.question.answer.approved) {
                    approval = (
                        <>
                            <PrimaryButton
                                iconProps={{ iconName: "CheckMark" }}
                                text={this.props.intl.formatMessage({
                                    id: "evidence.accepted",
                                })}
                                onClick={this._approve}
                            />
                            <DefaultButton
                                text={this.props.intl.formatMessage({ id: "evidence.reject" })}
                                onClick={this._decline}
                            />
                        </>
                    );
                } else {
                    approval = (
                        <>
                            <DefaultButton
                                text={this.props.intl.formatMessage({ id: "evidence.accept" })}
                                onClick={this._approve}
                            />
                            <DefaultButton
                                text={this.props.intl.formatMessage({ id: "evidence.reject" })}
                                onClick={this._decline}
                            />
                        </>
                    );
                }
            }
            switch (this.props.question.type) {
                case 0:
                    if (ans === "True") answer = <Text variant="large">Yes</Text>;
                    else answer = <Text variant="large">No</Text>;
                    break;
                // case 1:
                //   answer = <Text variant="large">{this.props.question.answer.answerLocal}</Text>
                //   break;
                case 3: //  Currency
                    if (this.props.question.answer.answer == null) {
                        answer = <></>;
                    } else {
                        const parts = this.props.question.answer.answer.split(";");
                        const num = parseFloat(parts[1]);
                        answer = (
                            <Text variant="large">
                                ({parts[0]}) {num.toFixed(2)}
                            </Text>
                        );
                    }
                    break;
                case 4: // Date
                    var d = Moment(this.props.question.answer.answer);
                    answer = <Text variant="large">{d.format("LL")}</Text>;
                    break;
                case 5: //  OneOf
                    const opts = this.props.question.options.split(";");
                    let answerIndex = parseInt(this.props.question.answer.answer);
                    let answerText = this.props.question.answer.answer.toLowerCase().trim();

                    if (isNaN(answerIndex)) {
                        answerIndex = opts.findIndex(option => option.toLowerCase().trim() === answerText);
                    }
                    answer = (
                        <Text variant="large">
                            {opts[answerIndex] || this.props.question.answer.answer}
                        </Text>
                    );
                    break;
                case 6: //  ManyOf
                    const cks = this.props.question.answer.answer.split(";");
                    const out = cks.map((i) => {
                        return this.props.question.options.split(";")[parseInt(i)];
                    });
                    answer = <Text variant="large">{out.join(",")}</Text>;
                    break;
                case 7: // Rating
                    answer = (
                        <Rating
                            min={1}
                            max={5}
                            readOnly
                            rating={parseInt(this.props.question.answer.answer)}
                        />
                    );
                    break;
                case 8: // ReadDoc
                    break;
                case 9: // Sign
                    break;
                case 10: // Contact List
                case 11: // Item List
                    if (this.props.question.answer.approved) {
                        answer = (
                            <FontIcon
                                iconName="list"
                                onClick={this._onToggleDialog}
                                className={classNames.approved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    iconProps={{ iconName: "CheckMark" }}
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accepted",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    } else {
                        answer = (
                            <FontIcon
                                iconName="list"
                                onClick={this._onToggleDialog}
                                className={classNames.notapproved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accept",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    }
                    const recs = ans.split("\n");
                    evidencedisplay = (
                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "question.type.11.list",
                            })}
                        >
                            <Stack style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <List
                                    items={recs}
                                    onRenderCell={(item: string | undefined, index?: number) => {
                                        var i = (item || "").split(";");
                                        return (
                                            <Stack horizontal>
                                                {i.map((c) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                borderRight: "1px solid silver",
                                                            }}
                                                        >
                                                            <Text>{c}</Text>
                                                        </div>
                                                    );
                                                })}
                                            </Stack>
                                        );
                                    }}
                                />
                            </Stack>
                        </PivotItem>
                    );
                    break;
                case 12: // Image
                    if (this.props.question.answer.approved) {
                        answer = (
                            <FontIcon
                                iconName="pictureFill"
                                onClick={this._onToggleDialog}
                                className={classNames.approved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    iconProps={{ iconName: "CheckMark" }}
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accepted",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    } else {
                        answer = (
                            <FontIcon
                                iconName="pictureFill"
                                onClick={this._onToggleDialog}
                                className={classNames.notapproved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accept",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    }
                    evidencedisplay = (
                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "evidence.image",
                            })}
                        >
                            <Image
                                src={evidenceurl}
                                imageFit={ImageFit.contain}
                                width={640}
                                height={480}
                                className={contentStyles.body}
                            />
                        </PivotItem>
                    );

                    break;
                case 13: // Video
                    if (this.props.question.answer.approved) {
                        answer = (
                            <FontIcon
                                iconName="video"
                                onClick={this._onToggleDialog}
                                className={classNames.approved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    iconProps={{ iconName: "CheckMark" }}
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accepted",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    } else {
                        answer = (
                            <FontIcon
                                iconName="video"
                                onClick={this._onToggleDialog}
                                className={classNames.notapproved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accept",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    }
                    evidencedisplay = (
                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "evidence.image",
                            })}
                        >
                            <ReactPlayer
                                url={evidenceurl}
                                width={640}
                                height={480}
                                controls={true}
                                className={contentStyles.body}
                            />
                        </PivotItem>
                    );
                    break;
                case 14: //  Document
                    answer = (
                        <a href={evidenceurl} target="_blank" download>
                            <FontIcon iconName="document" className={classNames.approved} />
                        </a>
                    );
                    break;
                case 15: //  Address
                    if (this.props.question.answer.approved) {
                        answer = (
                            <FontIcon
                                iconName="mapPin"
                                onClick={this._onToggleDialog}
                                className={classNames.approved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    iconProps={{ iconName: "CheckMark" }}
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accepted",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    } else {
                        answer = (
                            <FontIcon
                                iconName="mapPin"
                                onClick={this._onToggleDialog}
                                className={classNames.notapproved}
                            />
                        );
                        approval = (
                            <>
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accept",
                                    })}
                                />
                                <DefaultButton
                                    disabled
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                />
                            </>
                        );
                    }
                    var lines = ans.split("\n").map((l) => {
                        return <Text variant="mediumPlus">{l}</Text>;
                    });
                    evidencedisplay = (
                        <PivotItem
                            headerText={this.props.intl.formatMessage({
                                id: "evidence.address",
                            })}
                        >
                            <Stack style={{ paddingTop: 20, paddingBottom: 20 }}>
                                {lines}
                            </Stack>
                        </PivotItem>
                    );
                    break;
            }
        }
        const controlClass = mergeStyleSets({
            control: {
                margin: "0 0 15px 0",
                maxWidth: "300px",
            },
        });
        var gps;
        var canApprove;
        if (this.props.role === "review") {
            var btns = <></>;
            if (this.props.question.mustApprove) {
                if (this.props.question.answer) {
                    if (this.props.question.answer.approved) {
                        btns = (
                            <>
                                <PrimaryButton
                                    iconProps={{ iconName: "CheckMark" }}
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accept",
                                    })}
                                    onClick={this._approve}
                                />
                                <DefaultButton
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                    onClick={this._onToggleFeedback}
                                />
                            </>
                        );
                    } else {
                        btns = (
                            <>
                                <DefaultButton
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.accept",
                                    })}
                                    onClick={this._approve}
                                />
                                <DefaultButton
                                    text={this.props.intl.formatMessage({
                                        id: "evidence.reject",
                                    })}
                                    onClick={this._onToggleFeedback}
                                />
                            </>
                        );
                    }
                }
                canApprove = (
                    <Stack
                        horizontal
                        horizontalAlign="center"
                        tokens={{ childrenGap: "20px" }}
                    >
                        <Stack horizontal horizontalAlign="center" verticalAlign="center">
                            <Checkbox
                                label={this.props.intl.formatMessage({
                                    id: "evidence.expires",
                                })}
                                name="expires"
                                checked={this.state.expires}
                                onChange={this._handleChange}
                            />
                        </Stack>
                        <DatePicker
                            className={controlClass.control}
                            firstDayOfWeek={DayOfWeek.Monday}
                            strings={stdDayPickerStrings}
                            placeholder={this.props.intl.formatMessage({
                                id: "evidence.expirationdate",
                            })}
                            ariaLabel={this.props.intl.formatMessage({
                                id: "aria.select.date",
                            })}
                            value={this.state.expiresOn}
                            disabled={!this.state.expires}
                            onSelectDate={this._handleExpiredChange}
                        />
                        {btns}
                    </Stack>
                );
            } else {
                btns = (
                    <>
                        <DefaultButton
                            disabled
                            text={this.props.intl.formatMessage({ id: "evidence.accept" })}
                            onClick={this._approve}
                        />
                        <DefaultButton
                            disabled
                            text={this.props.intl.formatMessage({ id: "evidence.reject" })}
                            onClick={this._onToggleFeedback}
                        />
                    </>
                );
                canApprove = (
                    <Stack
                        horizontal
                        horizontalAlign="center"
                        tokens={{ childrenGap: "20px" }}
                    >
                        <Stack horizontal horizontalAlign="center" verticalAlign="center">
                            <Checkbox
                                disabled
                                label={this.props.intl.formatMessage({
                                    id: "evidence.expires",
                                })}
                                name="expires"
                                checked={this.state.expires}
                                onChange={this._handleChange}
                            />
                        </Stack>
                        <DatePicker
                            className={controlClass.control}
                            firstDayOfWeek={DayOfWeek.Monday}
                            strings={stdDayPickerStrings}
                            placeholder={this.props.intl.formatMessage({
                                id: "evidence.expirationdate",
                            })}
                            ariaLabel={this.props.intl.formatMessage({
                                id: "aria.select.date",
                            })}
                            value={this.state.expiresOn}
                            disabled={true}
                            onSelectDate={this._handleExpiredChange}
                        />
                        {btns}
                    </Stack>
                );
            }
        }

        return (
            <>
                <Stack grow styles={stackStyles}>
                    <Stack horizontal style={textColor} grow tokens={{ childrenGap: 20 }}>
                        <Stack.Item grow={3} styles={stackItemStyles}>
                            <Stack>
                                <Text variant="medium">{this.props.question.description}</Text>
                                <Text style={{ color: "Red" }} variant="medium">
                                    {this.props.question.validationError}
                                </Text>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item styles={answerStackItemStyles}>{answer}</Stack.Item>
                        <Stack
                            tokens={{ childrenGap: 5 }}
                            horizontal
                            style={{ color: "black" }}
                        >
                            {approval}
                        </Stack>
                    </Stack>
                    <Text variant="small" style={{ color: "silver" }}>
                        {this.props.question.guidance}
                    </Text>
                    <Separator />
                </Stack>
                <Modal
                    isOpen={this.state.dialogOpen}
                    onDismiss={this._onToggleDialog}
                    containerClassName={contentStyles.container}
                >
                    <Stack>
                        <Stack horizontalAlign="center">
                            <Pivot aria-label="Options" linkSize={PivotLinkSize.large}>
                                {evidencedisplay}
                                {gps}
                            </Pivot>
                        </Stack>
                        <Stack style={{ paddingTop: 10 }}>{canApprove}</Stack>
                    </Stack>
                </Modal>
                <Dialog
                    hidden={!this.state.feedbackOpen}
                    onDismiss={this._onToggleFeedback}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                >
                    <TextField
                        label={this.props.intl.formatMessage({
                            id: "evidence.reject.reason",
                        })}
                        multiline
                        rows={3}
                        value={this.state.reason}
                        name="reason"
                        onChange={this._handleChange}
                    />

                    <DialogFooter>
                        <PrimaryButton
                            onClick={this._decline}
                            text={this.props.intl.formatMessage({ id: "evidence.reject" })}
                        />
                        <DefaultButton
                            onClick={this._onToggleFeedback}
                            text={this.props.intl.formatMessage({ id: "std.cancel" })}
                        />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }
}

export default injectIntl(SurveyQuestion);
