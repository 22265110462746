import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import {  
          Spinner, SpinnerSize,
          Stack, IStackStyles, IStackTokens, Label,
          Checkbox,
} from 'office-ui-fabric-react';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


type IState = {
    newString: string,
    checked: boolean[],
    options: string[],
}

interface IProps  extends WrappedComponentProps {
  options: string,
  value: string,
  label: string,
  onChange: (value: string) => void,
}


class CheckboxGroup extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);
        
        var opts = this.props.options.split(";");
        var ckd: boolean[] = [];
        opts.forEach((v,i) => { ckd[i]=false});
        this.props.value.split(";").forEach((v,i) => {
          ckd[parseInt(v)] = true;
        });

        this.state = {
          newString: "",
          checked: ckd,
          options: opts,
        };

        this.handleChange = this.handleChange.bind(this);
    }



    private handleChange(toggle: number)
    {
      var checked = this.state.checked;
      checked[toggle] = !checked[toggle];

      var val: string = "";
      checked.forEach((v,i) => {
        if(val !== "")   val += ";"
        val += ((v)? i.toString() : "")
      });
      this.props.onChange(val);
    }
  
  
    componentDidUpdate(prevProps: IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.options !== prevProps.options || 
          this.props.value !== prevProps.value) {
        var opts = this.props.options.split(";");
        var ckd: boolean[] = [];
        opts.forEach((v,i) => { ckd[i]=false});
        this.props.value.split(";").forEach((v,i) => {
          ckd[parseInt(v)] = true;
        });

        this.setState({
          checked: ckd,
          options: opts,
        });

        this.forceUpdate();
      }
    }
  

    render() {
      if(this.props.options == null)
      {
        return (
          <Stack grow styles={stackStyles}>
            <Spinner size={SpinnerSize.large} label="Loading List" />
          </Stack>
        );
      }

      const boxes = this.state.options.map((s,i) => {
        return <Checkbox label={s} key={i} checked={this.state.checked[i]} onChange={()=>this.handleChange(i)}/>;
      });
      
      const stackToks: IStackTokens = {
//        childrenGap: '16',
      };

      return (
      <Stack grow styles={stackStyles} tokens={stackToks}>
          <Label>{this.props.label}</Label>
          {boxes}
      </Stack>
      
        );
      };
      
}

export default injectIntl(CheckboxGroup);