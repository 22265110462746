import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps, FormattedMessage  } from 'react-intl';

import { ISurveyItem, ITemplateSurvey } from './IVesta';

import {  
          Stack, IStackStyles, IStackTokens, 
          TextField, Toggle,
          Text, DefaultButton, PrimaryButton, 
          Separator,
          Dropdown, IDropdownStyles, IDropdownOption,
} from 'office-ui-fabric-react';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

interface IState {
    selected: string,
    isOpen: boolean,
    percent: number,
    id: string,
    shouldSave: boolean,
}


interface IProps extends WrappedComponentProps {
  user: string,
  onClose?: any
  surveys: ISurveyItem [],
  templateSurvey: ITemplateSurvey,
  onChange: any,
}


class TemplateCertificate extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);
                
        this.state = {
          selected:"Undefined"  ,
          isOpen: false     ,
          percent: this.props.templateSurvey.unitpercent,
          id: this.props.templateSurvey.surveyID,
          shouldSave: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
    }

    handleChange(event: any){
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      const name : string = target.name;

      switch(name)
      {
        case "unitpercent":
          this.setState({
            percent: parseInt(value),
            shouldSave: true,
          });
          break;
      }
    }

    componentDidUpdate(prevProps: IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.templateSurvey !== prevProps.templateSurvey) {
        this.setState({
          percent: this.props.templateSurvey.unitpercent,
          id: this.props.templateSurvey.surveyID,
        });
      }
    }
  
    save()
    {
      var t: ITemplateSurvey = {
        unitpercent : this.state.percent,
        surveyID: this.state.id,
        surveyDescription: "",
        surveyName: "",
      }
      this.props.onChange(t);
      this.setState({shouldSave: false,})
    }

    close()
    {
        this.props.onClose();
    }

    setsurvey(event: any,option?: any,index?: number)
    {
      this.setState({id: this.props.surveys[index!].id || "", shouldSave: true,});
    }

  render() {
        const stackToks: IStackTokens = {childrenGap: '6'};

        const allsurveys: IDropdownOption[] = this.props.surveys.map(function(element: ISurveyItem)  {
          return({ key: element.id, text: element.name });
        });
  
        const dropdownStyles: Partial<IDropdownStyles> = {
          dropdown: { width: 300 },
        };
  
      return (
        <Stack grow styles={stackStyles}>
          <Stack>
          <Dropdown
                  placeholder={this.props.intl.formatMessage({ id: 'survey.placeholder' })}
                  label={this.props.intl.formatMessage({ id: 'survey.surveys' })}
                  options={allsurveys}
                  styles={dropdownStyles}
                  selectedKey={this.state.id}
                  onChange={this.setsurvey}
              />

            <TextField label="Percentage of Units" name="unitpercent" onChange={this.handleChange}  value={this.state.percent.toString()}  />
            <Text variant="small" nowrap block>
              <FormattedMessage id="template.unitpc.help"/> 
            </Text>
            <Stack horizontal tokens={stackToks} padding="16px">
                <PrimaryButton onClick={this.save} text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave}/>
                <DefaultButton onClick={this.close} text={this.state.shouldSave ? this.props.intl.formatMessage({ id: 'std.cancel' }) : this.props.intl.formatMessage({ id: 'std.close' })} />
            </Stack>
          </Stack>
          <Separator />
        </Stack>
    );
  };
      
}

export default injectIntl(TemplateCertificate);