import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps } from 'react-intl';

import { IReportReference } from './IVesta';

import {  
          Stack, IStackStyles, IStackTokens, 
           PrimaryButton, 
          Separator,
          Dropdown, IDropdownOption,
} from 'office-ui-fabric-react';

import ReportViewer from './ReportViewer';



const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

interface IState {
    selected: string,
    view: string,
    isOpen: boolean,
}


interface IProps extends WrappedComponentProps {
  user: string,
  reports: IReportReference[],
  certificateID: string,
}


class ReportView extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);
                
        this.state = {
          selected:""  ,
          view:""  ,
          isOpen: false     ,
        };

        this.handleChange = this.handleChange.bind(this);
        this.setReport = this.setReport.bind(this);
        this.view = this.view.bind(this);
    }

    handleChange(event: any){
      const target = event.target;
      const name : string = target.name;

      switch(name)
      {
        // case "unitpercent":
        //   this.setState({
        //     percent: parseInt(value),
        //     shouldSave: true,
        //   });
        //   break;
      }
    }

    view()
    {
      this.setState({ view: this.state.selected });
    }

    setReport(event: any,option?: any,index?: number)
    {
      this.setState({selected: this.props.reports[index!].id || "", });
    }

  render() {
        const stackToks: IStackTokens = {childrenGap: '6'};

        const allReports: IDropdownOption[] = this.props.reports.map(function(element: IReportReference)  {
          return({ key: element.id, text: element.name });
        });

        var rep;
        if(this.state.view !== "")
        {
          rep = <ReportViewer user={this.props.user} reportID={this.state.selected} certificateID={this.props.certificateID} />
        }
  
  
      return (
        <Stack grow styles={stackStyles}>
          <Stack>
          <Dropdown
                  placeholder={this.props.intl.formatMessage({ id: 'report.name.placeholder' })}
                  label={this.props.intl.formatMessage({ id: 'report.name' })}
                  options={allReports}
                  styles={{ dropdown: { width: 300 } }}
                  selectedKey={this.state.selected}
                  onChange={this.setReport}
              />
            <Stack horizontal tokens={stackToks} padding="16px">
                <PrimaryButton onClick={this.view} text={this.props.intl.formatMessage({ id: 'std.view' })} disabled={this.state.selected === ""}/>
            </Stack>
          </Stack>
          <Separator />
          {rep}
        </Stack>
    );
  };
      
}

export default injectIntl(ReportView);