import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { API, APIKEY, IUser } from './IVesta';

import {
    CommandBar, ICommandBarItemProps,
    DetailsList, IColumn, IDetailsListProps, IDetailsRowStyles, DetailsRow,
    SelectionMode,
    Panel, PanelType,
    Stack, IStackStyles,
    IconButton
} from 'office-ui-fabric-react';

import MCPUser from './MCPUser';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

  
interface IProps extends WrappedComponentProps {
  user: string
  clientID: string
}

interface IState  {
  selected: string,
  panelTitle: string,
  users: IUser[],
  isOpen: boolean,
  isCertOpen: boolean,
  selectedID: string
}

class MCPUsers extends Component<IProps,IState> {

  constructor(props: IProps) {
      super(props);

      this.state = {
        selected:"Undefined"  ,
        panelTitle:"Undefined"  ,
        users: [ ],
        isOpen: false,
        isCertOpen: false,
        selectedID: ""     
      };

      this.dismissPanel = this.dismissPanel.bind(this);
      this.newuser = this.newuser.bind(this);
      this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount()
  {
    this.refresh();
  }

  refresh()
  {
    this.authenticatedrefresh();
  }

  authenticatedrefresh()
  {
    
    const post = {
      security: {
        APIKey: APIKEY,
        User: this.props.user
      },
      clientID: this.props.clientID
    }

    console.log("MCPUser:authenticatedrefresh")
    console.log(this.props);
    console.log(post);

    axios.post(API + "/user/MCP/Search",post)
      .then(response => {
          this.setState({ users: response.data.users
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  private editUser(item: IUser,index?: number)
  {
      this.setState({isOpen: true, panelTitle: this.props.intl.formatMessage({ id: 'user.edit' }),selectedID: item.id || ""  });
  }  

  deleteUser(id: IUser)
  {

  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
  };      
    
  private dismissPanel()
  {
      this.setState({isOpen: false});
      this.refresh();
  }
  
  private newuser()
  {
    this.setState({isOpen: true, panelTitle: this.props.intl.formatMessage({ id: 'user.create' }), selectedID: ""});
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (!props.item.active) {
        customStyles.root = { backgroundColor: "Gainsboro" };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  render() {        
    const columns: IColumn[] = [
      {
        key: 'email',
        name: this.props.intl.formatMessage({ id: 'user.email' }),
        fieldName: 'email',
        minWidth: 0,
        maxWidth: 0,
        currentWidth: 0,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'role',
        name: this.props.intl.formatMessage({ id: 'user.role' }),
        fieldName: 'role',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'edit',
        name: 'Edit',
        isIconOnly: true,
        fieldName: 'id',
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: any,index?:number) => {
          return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.editUser(item,index)} title="Edit" ariaLabel="Edit"  />
        },
      },
      {
        key: 'delete',
        name: 'Delete',
        ariaLabel: '',
        isIconOnly: true,
        fieldName: 'id',
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: any) => {
          return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteUser(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
        },
      }
    ] ;

    const _items: ICommandBarItemProps[] = [
      {
        key: 'newItem',
        text: this.props.intl.formatMessage({ id: 'user.add' }),
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'Add' },
        onClick: () => this.newuser(),
      },
    ];
    
    return (
      <Stack grow styles={stackStyles}>
          <CommandBar
              items={_items}
              ariaLabel="Use left and right arrow keys to navigate between commands"
          />
          <DetailsList 
              items={this.state.users} 
              columns={columns} 
              selectionMode={SelectionMode.none}
              onRenderRow={this._onRenderRow}
          />
          <Panel
              isOpen={this.state.isOpen}
              onDismiss={this.dismissPanel}
              headerText={this.state.panelTitle}
              closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
              type={PanelType.large}
              >
                <MCPUser clientID={this.props.clientID} id={this.state.selectedID} user={this.props.user} onClose={this.dismissPanel} />
          </Panel>

    </Stack>
    );
  };  
}

export default injectIntl(MCPUsers);