import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps, } from 'react-intl';

import { API, APIKEY, IClient } from './IVesta';

import ClientAttachments from './ClientAttachments';

type IState = {
  client: IClient,
  user: string,
}

interface IProps extends WrappedComponentProps {
}
  
class Client0Attachments extends Component<IProps,IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
          user: "",
          client: {} as IClient,
        };
    }

    componentDidMount()
    {
      this.refresh();
    }
   
    refresh()
    {
      Auth.currentSession()
          .then((session: any) => { 
            this.setState({ user: session.idToken.payload.email});
            this.authenticatedrefresh();
          })
          .catch( function(err) {
            console.log(err)
      });
    }

    authenticatedrefresh()
    {
      
      const post  = { 
          security: {
            APIKey: APIKEY,
            User: this.state.user
          },
      }

      var url = "/client/profile";
      axios.post(API + url,post)
        .then(response => {
            this.setState({ client: response.data.client });
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  render() {
    if((this.state.client.id || "") === "")
    {
      return <></>
    }
    return (
      <ClientAttachments onChange={this.authenticatedrefresh} attachments={this.state.client.attachments} categories={this.state.client.settings.myAttachmentCategories} client={this.state.client} user={this.state.user} />
    );
  };      
}

export default injectIntl(Client0Attachments);