import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import './App.css';

import { IFont, } from './IVesta';


import { Dropdown, IDropdownOption,
            TextField, 
            Toggle,    
            ColorPicker, IColor,
            Text, 
            Stack, 
} from 'office-ui-fabric-react';

type IState = {
    open: boolean,
    isEditOpen: boolean,
    reply: boolean,
    replyText: string,
}
  
interface IProps extends WrappedComponentProps {
    font: IFont,
    onChange: (font: IFont) => void;
}

const typefaces = [
    "Times New Roman",
    "Arial",
    "Courier New"
]

class Font extends Component<IProps,IState>
{
    constructor(props: any) {
        super(props);
        
        this.state = {
          open: false,
          isEditOpen: false,
          reply: false,
          replyText: "",
        };

        this._handleChange = this._handleChange.bind(this);
        this.setTypeface = this.setTypeface.bind(this);
    }

    _handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
  
        var font = this.props.font;
        switch(name)
        {
          case "size":
            font.size = parseFloat(value);
            break;
        }
    }

    private onToggle(name:string)
    {
        var font = this.props.font;

        switch(name)
        {
          case "bold":
            font.bold = !font.bold;
            break;
        case "italic":
            font.italic = !font.italic;
            break;
        case "underline":
            font.underline = !font.underline;
            break;
      }
      this.props.onChange(font);
    }

    updateColor(ev: any, colorObj: IColor)
    {
        var font = this.props.font;
        font.color = colorObj.str;
        this.props.onChange(font);
    }

    setTypeface(event: any,option?: any,index?: number)
    {
        var font = this.props.font;
        font.typeface = (index || 0).toString();
        this.props.onChange(font);
    }

    render() 
    {
        var faces: IDropdownOption[] = typefaces.map((t,i) => {
            return {
                key: i.toString(),
                text: t,
            }
        }) 

        var weight="Normal";
        if(this.props.font.bold)        weight="bold";


        var style = "normal" ;
        if(this.props.font.italic)        weight="italic";

        var face =  "Times New Roman";
        switch(this.props.font.typeface)
        {
            case "1":
                face = "Arial"
                break;
            case "2":
                face = "Courier New"
                break;
        }

  
        const textStyles: any = {
            root: {
              typeFace: `${face}`,
              fontStyle: {style},
              fontSize: `${this.props.font.size}px`,
              fontWeight: `${weight}`,
              color: `${this.props.font.color}`,
            },
          };
        

        return (
            <Stack horizontal  tokens={{childrenGap: '10', padding: "16px", }}>
                <Stack>
                    <Dropdown
                        placeholder={this.props.intl.formatMessage({ id: 'font.typeface.placeholder' })}
                        label={this.props.intl.formatMessage({ id: 'font.typeface' })}
                        options={faces}
                        styles={{ dropdown: { width: 300 } }}
                        defaultSelectedKey={this.props.font.typeface.toString()}
                        onChange={this.setTypeface}
                    />
                    <TextField name="size" label={this.props.intl.formatMessage({ id: 'font.size' })} onChange={this._handleChange} defaultValue={this.props.font.size.toString()} />
                    <Toggle label={this.props.intl.formatMessage({ id: 'font.bold' })} inlineLabel onText="On" offText="Off" checked={this.props.font.bold} onChange={()=>{this.onToggle("bold");}} />
                    <Toggle label={this.props.intl.formatMessage({ id: 'font.italic' })} inlineLabel onText="On" offText="Off" checked={this.props.font.italic} onChange={()=>{this.onToggle("italic");}} />
                    <Toggle label={this.props.intl.formatMessage({ id: 'font.underline' })} inlineLabel onText="On" offText="Off" checked={this.props.font.underline} onChange={()=>{this.onToggle("underline");}} />
                </Stack>
                <Stack horizontalAlign="center" verticalAlign="center" styles={{
                        root: {
                            width: "300px", 
                            height: "300px", 
                            border: "1px",
                            borderStyle: "dashed",
                            borderColor: "#1010ff",
                        }}
                        }>
                    <Text styles={textStyles}>ABcd123</Text>
                </Stack>
                <Stack>
                    <ColorPicker color={this.props.font.color}
                                 onChange={this.updateColor} />
                </Stack>
            </Stack>
        );
    }      
}

export default injectIntl(Font);
