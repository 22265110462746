import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {
  API,
  APIKEY,
  ITemplateItem,
  IClient,
  IAttachmentCategory,
  formatEmail,
  getPhone,
  getEmailErrorMessage,
  getMandatoryErrorMessage,
} from "./IVesta";

import {
  TextField,
  DefaultButton,
  PrimaryButton,
  Separator,
  Pivot,
  PivotItem,
  Stack,
  IStackStyles,
  MessageBar,
  MessageBarType,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
} from "office-ui-fabric-react";

import BuildingsList from "./BuildingsList";
import CertificatesList from "./CertificatesList";
import ClientAttachments from "./ClientAttachments";
import ClientStorage from "./ClientStorage";
import UD from "./Ud";
import InfoIcon from "./InfoIcon";
import {
  BasePicker,
  ITag,
  IInputProps,
  IBasePickerSuggestionsProps,
  IBasePickerProps,
} from "@fluentui/react/lib/Pickers";
import { IStackItemStyles } from "@fluentui/react/lib/Stack";
import ContactPicker from "./components/ContactPicker";

const stackItemStyles: IStackItemStyles = {
  root: {
    paddingTop: 5,
    color: "#323130",
  },
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {};
var picker: BasePicker<ITag, IBasePickerProps<ITag>>;

interface IProps extends WrappedComponentProps {
  id?: any;
  onClose?: any;
  showMessage: any;
  contactData: any;
}

interface IState {
  user: string;
  selected: string;
  isOpen: boolean;
  id: any;
  client: IClient;
  types: string[];
  users: IDropdownOption[];
  templates: ITemplateItem[];
  shouldSave: boolean;
  showSuccess: boolean;
  showError: boolean;
  message: string;
  categories: IAttachmentCategory[];
  contactList: ITag[];
}

class Client extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      user: "",
      selected: "Undefined",
      isOpen: false,
      client: { name: "", contact: "", phone: "" } as IClient,
      types: [],
      users: [],
      categories: [],
      id: props.id,
      templates: [],
      shouldSave: false,
      showSuccess: false,
      showError: false,
      message: "",
      contactList: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
    this.dismissDialog = this.dismissDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.refreshtemplates = this.refreshtemplates.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.settype = this.settype.bind(this);
    this.setassessor = this.setassessor.bind(this);
    this.bindContactList = this.bindContactList.bind(this);

    this.handlePickerChange = this.handlePickerChange.bind(this);
    this.handlePickerBlur = this.handlePickerBlur.bind(this);
    this.getDefaultItem = this.getDefaultItem.bind(this);
    this.filterSelectedTags = this.filterSelectedTags.bind(this);
    this.mapContact = this.mapContact.bind(this);
  }

  // picker

  listContainsTagList = (tag: ITag | undefined, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tag
      ? tagList.some((compareTag) => compareTag.key === tag.key)
      : null;
  };

  filterSuggestedTags = (
    filterText: string,
    tagList: ITag[] | undefined
  ): ITag[] => {
    return filterText
      ? this.state.contactList.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 &&
            !this.listContainsTagList(tag, tagList)
        )
      : [];
  };

  filterSelectedTags = (filterText: string, tagList: ITag[]): ITag[] => {
    return filterText
      ? this.state.contactList.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
        )
      : [];
  };

  getTextFromItem = (item: ITag) => {
    return item.name;
  };

  onItemSelected(item: ITag | undefined): ITag | PromiseLike<ITag> | null {
    if (picker && item && this.listContainsTagList(item, picker.items)) {
      return null;
    }
    return item ? item : null;
  }

  getDefaultItem(): ITag[] | undefined {
    var ret: ITag[] = [];
    if (this.state.client.contactInfo) {
      if (this.state.client.contactInfo.email !== "") {
        ret.push({
          key: this.state.client.contactInfo.email,
          name: this.state.client.contactInfo.email,
        });
      }
    }
    return ret;
  }

  inputProps: IInputProps = {
    onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
      this.handlePickerBlur(ev),
  };

  handlePickerBlur(html: any) {
    var client = this.state.client;
    var email = html.target.defaultValue;

    if (!email) return;

    email = formatEmail(email ? email : "");

    var contact = this.findConttact(email);

    client.contactInfo.email = email;

    if (contact) {
      client.contactID = contact.id;
      client.phone = getPhone(contact.telephones);
      client.contactInfo.notifyPinDetails = contact.name;
    } else {
      client.contactID = "";
      client.phone = "";
      client.contactInfo.name = "";
      client.contact = "";
    }

    this.setState({ client: client, shouldSave: true });
  }

  findConttact(email: string) {
    if (this.props.contactData.contacts == null) return;
    for (var i = 0; i < this.props.contactData.contacts.length; i++) {
      if (email === this.props.contactData.contacts[i].email) {
        return this.props.contactData.contacts[i];
      }
    }

    return null;
  }

  handlePickerChange(items?: ITag[]) {
    var client = this.state.client;
    const len = items ? items.length : 0;
    if (len === 0) {
      client.contactInfo.email = "";
      this.setState({ client: client, shouldSave: true });
      return;
    }

    client.contactInfo.email = formatEmail(items ? items[0].name : "");
    client.email = client.contactInfo.email;
    const key = items ? items[0].key : 0;
    const _opt = this.props.contactData.contacts[key];
    client.contactInfo.name = _opt.name;
    client.contact = _opt.name;
    client.phone = getPhone(_opt.telephones);
    //client.contactInfo.telephones = _opt.telephones
    client.contactID = _opt.id;
    this.setState({ client: client, shouldSave: true });
  }

  // picker

  private dismissDialog() {
    this.setState({ isOpen: false });
  }

  private openDialog() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.refresh();
  }

  bindContactList() {
    const _options: ITag[] = [];
    for (var i = 0; i < this.props.contactData.contacts.length; i++) {
      _options.push({
        key: `${i}`,
        name: `${this.props.contactData.contacts[i].email} (${this.props.contactData.contacts[i].name})`,
      });
    }

    this.setState({ ...this.state, contactList: _options });
  }

  refresh() {
    Auth.currentSession()
      .then((session: any) => {
        this.setState({ user: session.idToken.payload.email });
        this.authenticatedrefresh(session.idToken.payload.email);
        this.bindContactList();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  authenticatedrefresh(user: string) {
    if (this.props.id === "") {
      var s: IClient = {
        id: "",
        contactID: "",
        contactInfo: {
          id: "",
          parentID: "",
          name: "",
          email: "",
        },
        name: "",
        contact: "",
        email: "",
        phone: "",
        parentID: "",
        externalID: "",
        type: "",
        assessor: "",
        settings: {
          clientTypes: [],
          myAttachmentCategories: [],
          myClientAttachmentCategories: [],
          myBuildingAttachmentCategories: [],
        },
        managedExternally: false,
        ud: [],
      };
      this.setState({
        client: s,
        types: this.props.contactData.clientTypes,
      });
      return;
    }
    const post = {
      security: {
        APIKey: APIKEY,
        User: user,
      },
      id: this.state.id,
    };

    axios
      .post(API + "/user/Search", post)
      .then((response) => {
        const items = response.data.users;
        var table: any = [];
        items.forEach(function (element: any) {
          table.push({
            key: element.id,
            text: element.email,
          });
        });

        this.setState({ users: table });

        this.bindContactList();
      })
      .catch(function (error) {});

    axios
      .post(API + "/client/Edit", post)
      .then((response) => {
        if (response.data.status.errorCode > 0) {
          this.showMessage(true, response.data.status.message);
        } else {
          this.setState({
            client: response.data.client,
            types: response.data.clientTypes,
            categories: response.data.attachmentCategories,
          });
          this.mapContact(this.state.client);
          this.refreshtemplates();
          this.forceUpdate();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  mapContact(client: IClient) {
    client.email = client.contactInfo.email;
    client.contact = client.contactInfo.name;
    this.setState({ client: client });
  }

  refreshtemplates() {
    const post = {
      security: {
        APIKey: APIKEY,
        User: this.state.user,
      },
    };

    axios
      .post(API + "/certificate/template/search", post)
      .then((response) => {
        this.setState({ templates: response.data.templates });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange(event: any) {
    const target = event.target;
    const value: string =
      target.type === "checkbox" ? target.checked : target.value;
    const name: string = target.name;

    var client = this.state.client;
    switch (name) {
      case "name":
        client.name = value;
        break;
      case "contact":
        client.contact = value;
        client.contactInfo.name = value;
        break;
      case "email":
        client.email = value;
        client.contactInfo.email = value;
        break;
      case "phone":
        client.phone = value;
        break;
    }
    this.setState({ client: client, shouldSave: true });
  }

  save() {
    if (this.props.id === "") {
      const post = {
        Security: {
          APIKey: APIKEY,
          User: this.state.user,
        },
        client: this.state.client,
      };
      axios
        .post(API + "/client/create", post)
        .then((response) => {
          if (response.data.status.errorCode > 0) {
            this.props.showMessage(true, response.data.status.message);
          } else {
            this.setState({ shouldSave: false });
            this.props.onClose();
            this.props.showMessage(
              false,
              this.props.intl.formatMessage({ id: "client.created" })
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const post = {
        Security: {
          APIKey: APIKEY,
          User: this.state.user,
        },
        client: this.state.client,
      };
      axios
        .post(API + "/client/update", post)
        .then((response) => {
          if (response.data.status.errorCode > 0) {
            this.props.showMessage(true, response.data.status.message);
          } else {
            this.setState({ shouldSave: false });
            this.props.onClose();
            this.props.showMessage(
              false,
              this.props.intl.formatMessage({ id: "client.saved" })
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  close() {
    this.props.onClose();
  }

  private showMessage(error: boolean, message: string) {
    if (error) this.setState({ showError: true, message: message });
    else this.setState({ showSuccess: true, message: message });
  }

  settype(ev: any, option?: IDropdownOption) {
    var client = this.state.client;
    if (option === undefined) return;
    client.type = option.text;
    this.setState({ client: client, shouldSave: true });
  }

  setassessor(ev: any, option?: any) {
    // this.setState({ assessor: option.key || "", shouldSave: true });
    var client = this.state.client;
    if (option === undefined) return;
    client.assessor = option.key;
    this.setState({ client: client, shouldSave: true });
  }

  render() {
    const stackStyles: IStackStyles = {
      root: {
        width: "500",
      },
    };

    var buildings;
    if (this.state.user !== "") {
      buildings = (
        <BuildingsList
          showMessage={this.showMessage}
          user={this.state.user}
          client={this.state.id}
          contactData={this.props.contactData}
          addBuilding={this.openDialog}
        />
      );
    }

    var msg;
    if (this.state.showSuccess) {
      msg = (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => {
            this.setState({ showSuccess: false });
          }}
          dismissButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
        >
          {this.state.message}
        </MessageBar>
      );
      setTimeout(() => this.setState({ showSuccess: false }), 3000);
    }
    if (this.state.showError) {
      msg = (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => {
            this.setState({ showError: false });
          }}
          dismissButtonAriaLabel={this.props.intl.formatMessage({
            id: "aria.close",
          })}
        >
          {this.state.message}
        </MessageBar>
      );
    }

    var attachicon = "";
    this.state.categories.forEach((cat, index) => {
      if (cat.mandatory) {
        if (this.state.client.attachments == null) {
          attachicon = "AlertSolid";
        } else {
          if (
            !this.state.client.attachments.some((a) => a.category === cat.name)
          ) {
            attachicon = "AlertSolid";
          }
        }
      }
    });

    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdown: { width: 300 },
    };
    var types: any = [];
    if (this.state.types != null) {
      types = this.state.types.map(function (element: string) {
        return { key: element, text: element };
      });
    }

    return (
      <Stack grow styles={stackStyles}>
        {msg}
        <Stack>
          <Stack horizontal grow verticalAlign="end">
            <Stack.Item grow>
              <TextField
                label={this.props.intl.formatMessage({ id: "client.name" })}
                value={this.state.client.name || ""}
                name="name"
                onChange={this.handleChange}
                required={true}
                onGetErrorMessage={() =>
                  getMandatoryErrorMessage(
                    this.state.client.name,
                    "Name",
                    this.props.intl.formatMessage({
                      id: "validation.error.client.name",
                    })
                  )
                }
              />
            </Stack.Item>
            <InfoIcon id={this.state.client.id} />
          </Stack>

          <Stack styles={stackItemStyles}>
            <ContactPicker
              inputProps={{ id: "email" }}
              label={this.props.intl.formatMessage({ id: "client.email" })}
              removeButtonAriaLabel="Remove"
              onResolveSuggestions={this.filterSuggestedTags}
              getTextFromItem={this.getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              itemLimit={1}
              disabled={false}
              onItemSelected={this.onItemSelected}
              selectedItems={this.getDefaultItem()}
              onChange={this.handlePickerChange}
              onBlur={this.handlePickerBlur}
              required={true}
              pickerValue={picker}
              onGetErrorMessage={getEmailErrorMessage}
              errorMessage={this.props.intl.formatMessage({
                id: "validation.error.email",
              })}
            />
          </Stack>
          <TextField
            label={this.props.intl.formatMessage({ id: "client.contact" })}
            value={this.state.client.contact || ""}
            name="contact"
            onChange={this.handleChange}
            required={true}
            onGetErrorMessage={(value) =>
              getMandatoryErrorMessage(
                value,
                this.props.intl.formatMessage({ id: "client.contact" })
              )
            }
          />
          <TextField
            label={this.props.intl.formatMessage({ id: "client.phone" })}
            value={this.state.client.phone || ""}
            name="phone"
            onChange={this.handleChange}
          />
          <Dropdown
            label={this.props.intl.formatMessage({ id: "client.type" })}
            options={types}
            styles={dropdownStyles}
            selectedKey={this.state.client.type}
            onChange={this.settype}
          />
          <Dropdown
            label={this.props.intl.formatMessage({ id: "client.assessor" })}
            options={this.state.users}
            styles={dropdownStyles}
            selectedKey={this.state.client.assessor}
            onChange={this.setassessor}
            required={true}
            errorMessage={getMandatoryErrorMessage(
              this.state.client.assessor,
              "Assessor",
              this.props.intl.formatMessage({
                id: "validation.error.client.assessor",
              })
            )}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 6, padding: 16 }}>
          <PrimaryButton
            onClick={this.save}
            text={this.props.intl.formatMessage({ id: "std.save" })}
            disabled={!this.state.shouldSave}
          />
          <DefaultButton
            onClick={this.close}
            text={this.props.intl.formatMessage({ id: "std.cancel" })}
          />
        </Stack>
        <Separator />
        <Pivot aria-label="Building Details">
          <PivotItem
            headerText={this.props.intl.formatMessage({
              id: "client.buildings",
            })}
          >
            {buildings}
          </PivotItem>

          <PivotItem
            headerText={this.props.intl.formatMessage({
              id: "client.certificates",
            })}
          >
            <CertificatesList
              user={this.state.user}
              clientID={this.props.id || ""}
            />
          </PivotItem>

          <PivotItem
            headerText={this.props.intl.formatMessage({
              id: "client.attachments",
            })}
            itemIcon={attachicon}
          >
            <ClientAttachments
              onChange={() => this.authenticatedrefresh(this.state.user)}
              categories={this.state.categories}
              attachments={this.state.client.attachments}
              user={this.state.user}
              client={this.state.client}
            />
          </PivotItem>

          <PivotItem
            headerText={this.props.intl.formatMessage({ id: "client.ud" })}
          >
            <UD user={this.state.user} ud={this.state.client.ud} />
          </PivotItem>

          <PivotItem
            headerText={this.props.intl.formatMessage({ id: "client.storage" })}
          >
            <ClientStorage
              user={this.state.user}
              clientid={this.state.client.id}
            />
          </PivotItem>
        </Pivot>
      </Stack>
    );
  }
}

export default injectIntl(Client);
