import React, { Component } from 'react';
import { Auth } from 'aws-amplify';


import {  
          Stack, IStackStyles, IStackTokens, 
          TextField,
          PrimaryButton,
} from 'office-ui-fabric-react';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};



type IState = {
    selected: string,
    isOpen: boolean,
    id: any,
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    password2: string,
  }
  
  
  interface IProps  {
    id?: any
    onClose?: any
  }


class Building extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        
        this.state = {
          selected:"Undefined"  ,
          isOpen: false     ,
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          password2: "",
          id: props.id
        };

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
  
        switch(name)
        {
          case "firstname":
            this.setState({
              firstname: value
            });
            break;
        }
      }

    componentDidMount()
    {
      this.refresh();
    }
  
  
    refresh()
    {
    }
  

    save()
    {
        Auth.signUp({
            username: this.state.email,
            password: this.state.password,
            attributes: {
              email: this.state.email,
              name: this.state.firstname + " " + this.state.lastname,
                }
            })
            .then(() => {
            })
            .catch(() => {
            });
    }

    close()
    {
        this.props.onClose();
    }

    render() {

          const stackToks: IStackTokens = {
              childrenGap: '6'
          };


          
          

          return (
            <Stack grow styles={stackStyles}>
              <Stack>
                <TextField label="First Name" value={this.state.firstname}  name="firstname" onChange={this.handleChange}/>
                <TextField label="Last Name" value={this.state.lastname}  name="lastname" onChange={this.handleChange}/>
                <TextField label="Email" value={this.state.email}  name="email" onChange={this.handleChange}/>
                <TextField label="Password" value={this.state.password}  name="password" onChange={this.handleChange}/>
                <TextField label="Reenter Password" value={this.state.password2}  name="password2" onChange={this.handleChange}/>
                <Stack horizontal tokens={stackToks} padding="16px">
                    <PrimaryButton onClick={this.save} text="Register" />
                </Stack>
              </Stack>
            </Stack>
        );
      };
      
}

export default (Building);