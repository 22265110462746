import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import './App.css';
import { API, APIKEY, IMessage, ICertificateSurvey, } from './IVesta';
import {
    Panel, PanelType,
    TextField, 
    DefaultButton, PrimaryButton, ActionButton,
    Stack, IStackTokens,
} from 'office-ui-fabric-react';
import Survey from './Survey';

type IState = {
    open: boolean,
    isEditOpen: boolean,
    survey: ICertificateSurvey,
    reply: boolean,
    replyText: string,
}

interface IProps extends WrappedComponentProps {
    user: string,
    message: IMessage,
    visible: boolean,
    onClose: any,
    nolinks?: boolean,
}

class Message extends Component<IProps, IState>
{
    constructor(props: any) {
        super(props);

        this.state = {
            open: false,
            isEditOpen: false,
            reply: false,
            replyText: "",
            survey: {} as ICertificateSurvey,
        };

        this._reply = this._reply.bind(this);
        this._doReply = this._doReply.bind(this);
        this._dontReply = this._dontReply.bind(this);
        this._closeEdit = this._closeEdit.bind(this);
        this._openEdit = this._openEdit.bind(this);
        this._handleChange = this._handleChange.bind(this);
    }

    _handleChange(event: any) {
        const target = event.target;
        const value: string = target.type === 'checkbox' ? target.checked : target.value;
        const name: string = target.name;

        switch (name) {
            case "reply":
                this.setState({ replyText: value });
                break;
        }
    }

    private _closeEdit() {
        this.setState({ isEditOpen: false });
    }

    private _openEdit() {
        this.setState({ isEditOpen: true });
    }

    _reply() {
        const post = {
            security: {
                APIKey: APIKEY,
                user: this.props.user,
            },
            messageid: this.props.message.id,
            reply: this.state.replyText,
        }
        axios.post(API + "/message/Reply", post)
            .then(response => {
            })
            .catch(function (error) {
                console.log(error);
            });

        this.setState({ reply: false, replyText: "" })

    }

    _read() {

        if (!this.props.message.id) return;

        const post = {
            security: {
                APIKey: APIKEY,
                user: this.props.user,
            },
            messageid: this.props.message.id,
        }
        axios.post(API + "/message/Read", post)
            .then(response => {
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _doReply() {
        this.setState({ reply: true })
    }

    _dontReply() {
        this.setState({ reply: false, replyText: "" })
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.message !== prevProps.message) {
            this.refresh();
        }
    }

    refresh() {
        if (!this.props.message) return;

        if (!this.props.message.read) {
            this._read();
        }
        if (this.props.message.certificate === undefined) {
            return
        }

        if (this.props.message.certificate.surveys != null) {
            var s = this.props.message.certificate.surveys.find(e => e.id === this.props.message.surveyID);
            this.setState({ survey: s || {} as ICertificateSurvey });
        }
    }

    onUpdate() {}

    render() {
        var evidence;
        var reply;

        if (this.props.message === undefined) // || this.state.survey === undefined)
        {
            return <></>
        }
        if (this.props.message.certificate === undefined) // || this.state.survey.survey === undefined)
        {
            return <></>
        }

        // if (this.props.message.blobname != null && !this.state.reply) {
        //     evidence = <Image src={this.props.message.url} width={700} />;
        // }
        if (this.state.reply) {
            reply = <>
                <TextField multiline rows={6} value={this.state.replyText} name="reply" onChange={this._handleChange} /><br />
                <DefaultButton type="primary" onClick={this._reply} style={{ marginRight: 8, marginTop: 8 }} text={this.props.intl.formatMessage({ id: 'message.send' })} />
                <DefaultButton onClick={this._dontReply} style={{ marginRight: 8, marginTop: 8 }} text={this.props.intl.formatMessage({ id: 'message.cancel' })} />
            </>
        }

        var from = <></>;
        if (this.props.message.userFrom == null) {
            from = <TextField label={this.props.intl.formatMessage({ id: 'message.from.manager' })} readOnly defaultValue={this.props.message.contactFrom.email} />;
        }
        else {
            from = <TextField label={this.props.intl.formatMessage({ id: 'message.from.assessor' })} readOnly defaultValue={this.props.message.userFrom.email} />;
        }

        const d = new Date(this.props.message.dateSent);
        const dateSent = d.toLocaleString();

        const stackToks: IStackTokens = {
            childrenGap: '6'
        };

        var survey;
        if (this.props.nolinks) {
            if (this.state.survey.survey)
                survey = survey = this.state.survey.survey.name;
        }
        else {
            survey = <ActionButton onClick={this._openEdit}>{this.state.survey.survey.name}</ActionButton>
        }

        return (
            <>
                <Panel
                    isOpen={this.props.visible}
                    onDismiss={this.props.onClose}
                    headerText={this.props.intl.formatMessage({ id: 'message.message' })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                    type={PanelType.extraLarge}
                >
                    <Stack horizontal tokens={stackToks} padding="16px">
                        <PrimaryButton onClick={this._doReply} ><FormattedMessage id="message.reply" /></PrimaryButton>
                        <DefaultButton onClick={this.props.onClose}><FormattedMessage id="message.cancel" /></DefaultButton>
                    </Stack>
                    {from}

                    {survey &&
                        <>{survey} for survey </>
                    }

                    {!survey &&
                        <>Certificate </>
                    }

                    {this.props.message.certificate.name || ""}
                    {this.props.message.certificate.building &&
                        <> for building  {this.props.message.certificate.building.buildingName || ""} {this.props.message.certificate.building.address1 || ""} {this.props.message.certificate.building.address2 || ""}<br /></>
                    }
                    <TextField label={this.props.intl.formatMessage({ id: 'message.datesent' })} readOnly defaultValue={dateSent} />
                    <TextField label={this.props.intl.formatMessage({ id: 'message.subject' })} readOnly defaultValue={this.props.message.subject} />
                    <TextField label={this.props.intl.formatMessage({ id: 'message.sentmessage' })} readOnly multiline rows={6} defaultValue={this.props.message.fromMessage} />
                    {/* <TextField label={this.props.intl.formatMessage({ id: 'message.tomessage' })} readOnly multiline rows={6} defaultValue={this.props.message.message} /> */}

                    {((this.props.message && this.props.message.messageHistory) && this.props.message.messageHistory.length > 0) &&
            <div className='message-history'>
                {this.props.message.messageHistory.map((val, index) => {
                  if(!val.isReceived) {
                    return <div className='from-history'>
                      <div className="from-chat">
                      <div className='from-message'>{val.message}</div>
                      <div className="from-date"><Moment format="ddd DD MMM YYYY HH:mm">{val.dateSent}</Moment></div>
                    </div>
                    </div>;
                  }else{
                    return <div className='to-history'>
                      <div className="to-chat">
                      <div className='from-message'>{val.message}</div>
                      <div className='from-date'><Moment format="ddd DD MMM YYYY HH:mm">{val.dateSent}</Moment></div>
                    </div>
                    </div>;
                  }
                })}
            </div>
          }

                    <br />
                    {evidence}
                    <br />
                    {reply}
                </Panel>
                <Panel
                    isOpen={this.state.isEditOpen}
                    onDismiss={this._closeEdit}
                    headerText={this.props.intl.formatMessage({ id: 'survey.reviewsurvey' })}
                    closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                    type={PanelType.extraLarge}
                >
                    <Survey user={this.props.user} userrole="review" onUpdate={this.onUpdate} survey={this.state.survey} />
                </Panel>
            </>);
    }
}

export default injectIntl(Message);
