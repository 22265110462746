import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { API, APIKEY, ISurvey, IPagination, IReport } from './IVesta';

import { initializeIcons } from '@uifabric/icons';

import {
  Panel, PanelType,
  TextField,
  CommandBar, ICommandBarItemProps,
  DetailsList, SelectionMode, IColumn,
  DefaultButton, PrimaryButton, IButtonProps, IconButton,
  Stack, IStackTokens, IStackStyles,
  Text,
} from 'office-ui-fabric-react/';

import { Pagination } from '@uifabric/experiments';

import ReportEdit from './ReportEdit';

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


type Survey = {
  name: string,
  description: string,
  validfor: bigint
}

interface IProps extends WrappedComponentProps {
}


interface IState {
    user: string,
    selected: any,
    reports: ISurvey[],
    isOpen: boolean,
    isEditOpen: boolean,
    newName: string,
    newDescription: string,
    newSurveyValidFor: string,
    canCreate: boolean,
    panelTitle: string,
    pagination: IPagination,
    totalCount: number,
}


const _overflowItems: ICommandBarItemProps[] = [
];
  

const buttonToks: IStackTokens = {
  childrenGap: '6'
};

class Reports extends Component<IProps,IState> {
  constructor(props: IProps) {
        super(props);
        initializeIcons();


        this.state = {
          user: "",
          selected:"Undefined"  ,
          reports: [],
          isOpen: false,
          isEditOpen: false,
          newName: "",
          newDescription: "",
          newSurveyValidFor: "",
          canCreate: true,       
          panelTitle: "",     
          pagination: {
            current: 0,
            pageSize: 10,
            sortField: "",
            sortOrder: "asc",
          },
          totalCount: 0,

        };
      
        this.dismissPanel = this.dismissPanel.bind(this);
        this.addNewReport = this.addNewReport.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidForChange = this.handleValidForChange.bind(this);
        this.editReport = this.editReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.onPageChange = this.onPageChange.bind(this);

    }

    handleChange(event: any){
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      const name : string = target.name;

      switch(name)
      {
        case "newName":
          this.setState({
            newName: value
          });
          break;
        case "newDescription":
          this.setState({
            newDescription: value
          });
          break;
      
      }
    }
  
    handleValidForChange(event: any){
      const target = event.target;
      const value : string = target.value;

      this.setState({
        newSurveyValidFor: value
      });
    }
  

    private onEdit() {
        this.setState({
          isEditOpen: true,
        })
    }

    private editReport(item: IReport)
    {
        this.setState({isEditOpen: true, panelTitle: "Edit Report",selected: item.id || ""  });
    }  
  
    private deleteReport()
    {
  
    }
  
  
    private closeEdit() {

      this.setState({
        isEditOpen: false,
      });
      this.authenticatedrefresh();
  }

  private onCreate() {

      const post = {
        Security: {
          APIKey: APIKEY,
          User: this.state.user
        },
        Name: this.state.newName,
        Description: this.state.newDescription,
        Validfor: this.state.newSurveyValidFor
      }
      axios.post(API + "/report/Create",post)
        .then(() => {
                this.refresh();
        })
        .catch(function (error) {
              console.log(error);
        });

        this.setState({
          isOpen: false,
          newName: "",
          newDescription: "",
          newSurveyValidFor: "",
        })
    }

    private onCancel()
    {
      this.setState({
        isOpen: false,
        newName: "",
        newDescription: "",
        newSurveyValidFor: "",
      })
    }

    private dismissPanel()
    {
        this.setState({isOpen: false});
    }  

    componentDidMount()
    {
      this.refresh();
    }
  
    refresh()
    {
      Auth.currentSession()
          .then((session: any) => { 
            this.setState({ user: session.idToken.payload.email});
            this.authenticatedrefresh();
          })
          .catch( function(err) {
            console.log(err)
      });
    }

    authenticatedrefresh()
    {
      const params = this.state.pagination;

      const post = {
        security: {
          APIKey: APIKEY,
          User: this.state.user
        },
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }
      axios.post(API + "/report/search",post) 
        .then(response => {
          this.setState({ reports: response.data.reports,
                          totalCount: response.data.totalCount,
                        });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  

    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }
    
    private addNewReport()
    {
      this.setState({isOpen: true});
    }
      
    render() {

      const columns: IColumn[] = [
          {
            key: 'column2',
            name: this.props.intl.formatMessage({ id: 'report.name' }),
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column3',
            name: this.props.intl.formatMessage({ id: 'report.description' }),
            fieldName: 'description',
            minWidth: 0,
            maxWidth: 0,
            currentWidth: 0,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
          },
        {
          key: 'edit',
          name: 'Edit',
          isIconOnly: true,
          fieldName: 'id',
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: IReport,index?:number) => {
            return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.editReport(item)} title="Edit" ariaLabel="Edit"  />
          },
        },
        {
          key: 'delete',
          name: 'Delete',
          ariaLabel: '',
          isIconOnly: true,
          fieldName: 'id',
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: IReport) => {
            return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteReport()} title="Delete" ariaLabel="Delete"  />
          },
        }

      ];

      const _items: ICommandBarItemProps[] = [
        {
          key: 'newItem',
          text: this.props.intl.formatMessage({ id: 'report.new' }),
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'Add' },
          onClick: this.addNewReport
        },
      ];
    
      var pageCount = Math.floor(this.state.totalCount / this.state.pagination.pageSize) + 1;


          return (
            <Stack grow styles={stackStyles}>
              <Text variant="xLarge" >Reports</Text>
              <CommandBar
                items={_items}
                overflowItems={_overflowItems}
                overflowButtonProps={overflowProps}
                ariaLabel="Use left and right arrow keys to navigate between commands"
              />
              <Pagination
                selectedPageIndex={this.state.pagination.current}
                pageCount={pageCount}
                onPageChange={this.onPageChange}
                format="buttons"
                firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                previousPageIconProps={{ iconName: 'ChevronLeft' }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
              />

              <DetailsList selectionMode={SelectionMode.none} items={this.state.reports} columns={columns}/>


              <Panel
                isOpen={this.state.isOpen}
                onDismiss={this.dismissPanel}
                headerText={this.props.intl.formatMessage({ id: 'report.new' })}
                closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                >
                  <Stack>
                    <TextField name="newName" label={this.props.intl.formatMessage({ id: 'report.name' })} onChange={this.handleChange} value={this.state.newName} />
                    <TextField name="newDescription" label={this.props.intl.formatMessage({ id: 'report.description' })}  onChange={this.handleChange} multiline value={this.state.newDescription} />

                  </Stack>
                  <Stack horizontal horizontalAlign="end" tokens={buttonToks} padding="l2">
                    <DefaultButton text={this.props.intl.formatMessage({ id: 'std.cancel' })} onClick={this.onCancel} allowDisabledFocus />
                    <PrimaryButton text="Create" onClick={this.onCreate} allowDisabledFocus disabled={!this.state.canCreate} />
                  </Stack>
              </Panel>

            <Panel
                isOpen={this.state.isEditOpen}
                onDismiss={this.closeEdit}
                headerText={this.props.intl.formatMessage({ id: 'report.edit' })}
                closeButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
                type={PanelType.extraLarge}
            >
              <ReportEdit user={this.state.user} reportID={this.state.selected} onClose={this.closeEdit} /> 
            </Panel>
          </Stack>
      
        );
      };
      
}

export default injectIntl(Reports);