import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { FluentCustomizations } from '@uifabric/fluent-theme';
import { Customizer, mergeStyles } from 'office-ui-fabric-react';
import * as serviceWorker from './serviceWorker';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import {IntlProvider} from 'react-intl';

import English from './lang/en.json';

const locale = navigator.language;

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

initializeIcons();

ReactDOM.render(
  <Customizer {...FluentCustomizations}>
  <IntlProvider locale ={locale} messages={English}>
    <App />
  </IntlProvider>
  </Customizer>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
