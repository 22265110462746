import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { API, APIKEY, IClient, IPagination } from './IVesta';


import {
  DetailsList,  IColumn,
  SelectionMode,
  Stack, IStackStyles, 
  SearchBox,
  MessageBar, MessageBarType,
  DefaultButton, IconButton,
  Dropdown,  IDropdownStyles
} from 'office-ui-fabric-react';

import { Pagination } from '@uifabric/experiments';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


interface IState  {
    selected: string,
    clients: IClient[],
    types: string[],
    type: number,
    typefind: string,
    selectedID: string,
    filter: string,
    showSuccess: boolean,
    showError: boolean,
    message: string,
    pagination: IPagination,
    totalCount: number,
  }

  interface IProps extends WrappedComponentProps {
    clientSelected: (client: IClient) => void,
    user: string,
  }
  
  
  
class ClientList extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
          selected:"Undefined"  ,
          clients: []       ,
          types: ["(Any)"],
          type: -1,
          typefind: "",
          selectedID: "",
          filter: "",     
          showSuccess: false,
          showError: false,
          message: "",
          pagination: {
            current: 0,
            pageSize: 10,
            sortField: "",
            sortOrder: "asc",
          },
          totalCount: 0,
        };

        this.filter = this.filter.bind(this);
        this.refresh = this.refresh.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.settype = this.settype.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.selectClient = this.selectClient.bind(this);
    }

    private selectClient(item: IClient,index?: number)
    {
      this.props.clientSelected(item);
    }  


    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.refresh();
    }


    private filter(value: any)
    {
      this.setState({ filter: value });
//      this.filterval = value;
//      if(value == "")   this.setState({ filteredbuildings:this.state.buildings });
//      else              this.setState({ filteredbuildings:this.state.buildings.filter(this.match,this)});      
    }



    componentDidMount()
    {
      this.refresh();
    }
  
    
    refresh()
    {
      const params = this.state.pagination;

      const post = {
        security: {
          APIKey: APIKEY,
          User: this.props.user
        },
        type: this.state.typefind,
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      axios.post(API + "/client/Search",post)
        .then(response => {
            if(response.data.status.errorCode > 0)
            {
              this.showMessage(true,response.data.status.message);
            }
            else
            {
              this.setState({ 
                clients: response.data.clients, 
                totalCount: response.data.totalCount, 
                types:[ "(Any)", ...response.data.clientTypes]  
              });
            }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    };      
      


    private showMessage(error:boolean, message: string )
    {
      if(error)   this.setState({showError: true, message: message})
      else        this.setState({showSuccess: true, message: message})
    }

    settype(ev: any,option?: any)
    {
      var index = parseInt(option.key || "0");
      this.setState({type: index - 1, typefind: (index===0) ? "" : option.text});
    }


    render() {
        const { clients } = this.state;
        const columns: IColumn[] = [
            {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'client.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSorted: true,
              isSortedDescending: false,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'type',
              name: this.props.intl.formatMessage({ id: 'client.type' }),
              fieldName: 'type',
              minWidth: 150,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'contact',
              name: this.props.intl.formatMessage({ id: 'client.contact' }),
              fieldName: 'contact',
              minWidth: 150,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'phone',
              name: this.props.intl.formatMessage({ id: 'client.phone' }),
              fieldName: 'phone',
              minWidth: 150,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'email',
              name: this.props.intl.formatMessage({ id: 'client.email' }),
              fieldName: 'email',
              minWidth: 150,
              maxWidth: 250,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'edit',
              name: 'Edit',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any,index?:number) => {
                return <IconButton iconProps={{ iconName: 'Checkmark' }} onClick={()=>this.selectClient(item,index)} title="Edit" ariaLabel="Edit" />
              },
            },
  
          ];

          var msg;
          if(this.state.showSuccess)
          {
            msg = <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
              onDismiss={()=>{this.setState({showSuccess: false})}}
              dismissButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
            >
              {this.state.message}
            </MessageBar>;
            setTimeout(() => this.setState({showSuccess: false}), 3000);
          }
          if(this.state.showError)
          {
            msg = <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              onDismiss={()=>{this.setState({showError: false})}}
              dismissButtonAriaLabel={this.props.intl.formatMessage({ id: 'aria.close' })}
            >
              {this.state.message}
            </MessageBar>;
          }
          const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 },};
  
          const types = this.state.types.map(function(element: string,index: number )  {
            return { key: index, text: element }; 
          });

          var pageCount = Math.floor(this.state.totalCount / this.state.pagination.pageSize) + 1;

        return (
          <Stack grow styles={stackStyles}>
            {msg}
            <Stack horizontal tokens={{childrenGap: '6'}}>
              <Stack grow>
                <SearchBox placeholder="Filter Clients" iconProps={{ iconName: 'Filter' }} onChange={this.filter} value={this.state.filter} />
                <Dropdown
                    label={this.props.intl.formatMessage({ id: 'client.type' })}
                    options={types}
                    styles={dropdownStyles} 
                    selectedKey={this.state.type + 1}
                    onChange={this.settype}
                />
              </Stack>
              <Stack>
                <DefaultButton
                  toggle
                  text="Search"
                  iconProps={{ iconName: 'Search' }}
                  onClick={this.refresh}
                />
              </Stack>
            </Stack>
            <Pagination
                selectedPageIndex={this.state.pagination.current}
                pageCount={pageCount}
                onPageChange={this.onPageChange}
                format="buttons"
                firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                previousPageIconProps={{ iconName: 'ChevronLeft' }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
              />

            <DetailsList  items={clients} 
                          columns={columns} 
                          selectionMode={SelectionMode.none}
            />

          </Stack>
        );
      };
      
}

export default injectIntl(ClientList);