import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {API, APIKEY, IReport, IReportSection,  IObjectAttachment, } from './IVesta';

import {  
          Spinner, SpinnerSize,
          Stack, IStackStyles, IStackTokens, Separator,
          Text,
} from 'office-ui-fabric-react';

import ReportLineView from './ReportLineView';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};


type IState = {
    isopen: boolean,
    selected: any,
    isNewSectionOpen: boolean,
    report: IReport,

    currentSection: IReportSection,
    currentSectionIndex: number,

    newSectionName: string,
    newSectionDescription: string,

    currentLine: any,
    currentLineIndex: number,

    attachments: IObjectAttachment[],

    shouldSave: boolean,
}

interface IProps  extends WrappedComponentProps {
  user: string,
  reportID: string,
  certificateID: string,
}


class ReportViewer extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);
        
        this.state = {
          isopen: false,
          selected: null  ,
          isNewSectionOpen: false     ,
          newSectionName: "",
          newSectionDescription: "",

          currentSection: {name: "", description: "",  lines: []},
          currentSectionIndex: -1,

          currentLine: null,
          currentLineIndex: -1,

          report: { name: "", description: "", sections: [],  },

          attachments: [],

          shouldSave: false,
        };

        this.dismissPanel = this.dismissPanel.bind(this);
    }



    private dismissPanel()
    {
        this.setState({isopen: false});
    }  

  
    componentDidMount()
    {
      this.refresh();
    }

  
    private refresh()
    {
      const post = {
        security: {
          APIKey: APIKEY,
          User: this.props.user,
        },
        id: this.props.reportID,
        certificateid: this.props.certificateID
      }
      axios.post(API + "/report/View",post)
        .then(response => {
          this.setState({ 
            report: response.data.report, 
          }); 
        })
        .catch(function (error) {
          console.log(error);
        });
    }



  
    render() {

      if(this.state.report == null)
      {
        return (
          <Stack grow styles={stackStyles}>
            <Spinner size={SpinnerSize.large} label="Loading Survey" />
          </Stack>
        );
      }
      
      const stackToks: IStackTokens = {
        childrenGap: '16'
      };

      var lines: any = [];

      this.state.report.sections.forEach(section => {
        section.lines.forEach(line => {
          lines.push(<ReportLineView line={line}/>)
        });
      });



        
          return (
            <Stack grow styles={stackStyles} tokens={stackToks}>
                <Stack>
                    <Text>{this.state.report.name}</Text> 
                </Stack>
                <Separator />
                <Stack horizontal tokens={stackToks}>
                    <Stack>
                      {lines}
                    </Stack>
                </Stack>

        </Stack>
      
        );
      };
      
}

export default injectIntl(ReportViewer);