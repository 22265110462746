import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps, FormattedMessage  } from 'react-intl';

import {API, APIKEY, IReportLine, IFont, IQuestion, ISurvey, ISurveySection, IObjectAttachment, } from './IVesta';
import {  Dropdown, IDropdownStyles, IDropdownOption,
          TextField, 
          DefaultButton, PrimaryButton, 
          Stack, IStackStyles, Text,
          Checkbox
} from 'office-ui-fabric-react';

import CheckboxGroup from './CheckboxGroup';
import Font from './Font';


const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

type IState = {

    line: IReportLine,

    isopen: boolean,


    surveys: ISurvey[],
    survey: ISurvey,
    section: ISurveySection,
    sectionkey: string,

    shouldSave: boolean,
  }
  
  interface IProps extends WrappedComponentProps {
    line : IReportLine,
    attachments: IObjectAttachment[],
    onClose: any,
    onSave: any,
    surveySectionID?: string,
    user: string,
  }


class ReportLineEdit extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        if(this.props.line == null)
        {
            this.state = {
                isopen: false,

                line: {
                  type: 0,
                  description: "",
                } as IReportLine,



                surveys: [] ,
                survey: {} as ISurvey,
                section: {
                  questions: [] as IQuestion[]
                } as ISurveySection,
                sectionkey: "",

                shouldSave: false,
            };
        }
        else
        {
          this.state = {
              isopen: false,

              line: this.props.line,

              surveys: [],
              survey: {
                sections: [] as ISurveySection[] 
                } as ISurvey,
              section: {} as ISurveySection,
              sectionkey: "",

              shouldSave: false,
            };
        }

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.settype = this.settype.bind(this);
        this.setsurvey = this.setsurvey.bind(this);
        this.setsection = this.setsection.bind(this);
        this.setquestion = this.setquestion.bind(this);
        this.handleContactOpts = this.handleContactOpts.bind(this);
        this.handleFontChange = this.handleFontChange.bind(this);

    }

    save()
    {
        this.props.onSave(this.state.line);
        this.props.onClose();
    }

    close()
    {
        this.props.onClose();
    }


    componentDidMount()
    {
      this.refresh();
    }
  
  
    refresh()
    {
      this.refreshsurveys();
    }

    refreshsurveys()
    {
      
      const post  = { 
          security: {
            APIKey: APIKEY,
            User: this.props.user,
          },
      }
      axios.post(API + "/survey/search",post) 
        .then(response => {
            if((this.props.line.surveyID || "") !== "")
            {
              //  Need to set condition
              var surveys : ISurvey[] = response.data.surveys as ISurvey[];
              var survey  = surveys.find(e => e.id === this.props.line.surveyID);
              if( survey != null)
              {
              var i = this.findSectionIndex(survey,this.props.line.questionID);

                this.setState({
                  sectionkey: 'SEC'+i,
                  surveys: surveys ,
                  survey: survey,
                  section: survey.sections[i],
                });
              }
              else
              {
                this.setState({ surveys: response.data.surveys });
              }
            }
            else 
            {
              this.setState({ surveys: response.data.surveys });
            }
            
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    refreshattachments()
    {
      
      const post  = { 
          security: {
            APIKey: APIKEY,
            User: this.props.user,
          },
      }
      axios.post(API + "/client/profile",post) 
        .then(response => {
          this.setState({});
        })
        .catch(function (error) {
          console.log(error);
        });
    }


    findSectionIndex(survey: ISurvey,questionID: string)
    {
      for(var i = 0; i < survey.sections.length; i++)
      {
        for(var j = 0; j < survey.sections[i].questions.length; j++)
        {
          if(survey.sections[i].questions[j].id === questionID)
          {
            return i;
          }
        }  
      }
      return 0;
    }
  
 
    handleContactOpts(newval: string)
    {
      var q = this.state.line;
      q.options = newval;
      this.setState({
        line: q,
        shouldSave: true
      });
    }

    handleFontChange(value: IFont)
    {
      var line =Object.assign(this.state.line);
      line.font = value;
      this.setState({line: line, shouldSave: true });
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var q = this.state.line;

          switch(name)
          {
            case "description":
              q.description = value;
              this.setState({
                line: q,
                shouldSave: true
              });
              break;
            case "options":
              q.options = value;
              this.setState({
                line: q,
                shouldSave: true
              });
              break;
            }
      }

      settype(event: any,option?: any,index?: number)
      {
        var q = this.state.line;
        q.type = index || 0;
        this.setState({line: q, shouldSave: true,});
      }

      setsurvey(event: any,option?: any,index?: number)
      {
        var l = this.state.line;
        var s = this.state.surveys[index || 0];
        l.surveyID = s.id || "";
        this.setState({survey: s, line: l,  shouldSave: true,});
      }

      setsection(event: any,option?: any,index?: number)
      {
        var sec = this.state.survey.sections[index || 0];
        this.setState({section: sec, sectionkey: option.key});
      }

      setquestion(event: any,option?: any,index?: number)
      {
        var l = this.state.line;
        l.questionID = this.state.section.questions[index || 0].id || "";
        this.setState({line: l, shouldSave: true,});
      }

      render() {


      
      if(this.props.line == null)
      {
        return(<Text variant="medium"><FormattedMessage id="question.select"/></Text>) 
      }

      var questiontypes: IDropdownOption[] = [
          {   key: 0, text: this.props.intl.formatMessage({ id: 'line.type.0.header' }) },
          {   key: 1, text: this.props.intl.formatMessage({ id: 'line.type.1.text' }) },
          {   key: 2, text: this.props.intl.formatMessage({ id: 'line.type.2.image' }) },
          {   key: 3, text: this.props.intl.formatMessage({ id: 'line.type.3.separator' }) },
          {   key: 4, text: this.props.intl.formatMessage({ id: 'line.type.4.answer' }) },
        ];

        const dropdownStyles: Partial<IDropdownStyles> = {
          dropdown: { width: 300 },
        };


        var opts;
        switch(this.state.line.type)
        {
          case 0: case 1:
            opts = <>
                <TextField name="options" multiline rows={4} label={this.props.intl.formatMessage({ id: 'line.text' })} onChange={this.handleChange} value={this.state.line.options} />
                <Font font={this.state.line.font} onChange={this.handleFontChange} />
            </>
            break;
          case 4:
            const surveys : IDropdownOption[] = this.state.surveys.map((a,i) => 
              {return {
                  key: a.id, 
                  text: a.name  
                  } as IDropdownOption;
                });
            var sections: IDropdownOption[] = [];
            var questions: IDropdownOption[] = [];
            if(this.state.survey != null)
            {
              sections  = this.state.survey.sections.map((a,i) => 
              {return {
                key: a.id, 
                text: a.name  
                } as IDropdownOption;
              });
              if(this.state.section != null)
              {
                if(this.state.section.questions != null)
                {
                  questions  = this.state.section.questions.map((a,i) => 
                  {return {
                      key: a.id, 
                      text: a.description 
                      } as IDropdownOption;
                    });    
                }
              }
            }
            opts = 
              <Stack horizontal tokens={{
                childrenGap: '6',
                padding: "16px",
                }}>
                  <Stack>
                    <Dropdown
                          placeholder={this.props.intl.formatMessage({ id: 'line.survey' })}
                          label={this.props.intl.formatMessage({ id: 'line.survey' })}
                          options={surveys}
                          styles={dropdownStyles}
                          defaultSelectedKey={this.state.line.surveyID}
                          onChange={this.setsurvey}
                    />
                    <Dropdown
                          placeholder={this.props.intl.formatMessage({ id: 'line.section' })}
                          label={this.props.intl.formatMessage({ id: 'line.section' })}
                          options={sections}
                          styles={dropdownStyles}
                          defaultSelectedKey={this.state.section.id}
                          onChange={this.setsection}
                    />
                    <Dropdown
                          placeholder={this.props.intl.formatMessage({ id: 'line.question' })}
                          label={this.props.intl.formatMessage({ id: 'line.question' })}
                          options={questions}
                          styles={dropdownStyles}
                          defaultSelectedKey={this.state.line.questionID}
                          onChange={this.setquestion}
                    />
                  </Stack>
                  <Stack>
                    <Font font={this.state.line.font} onChange={this.handleFontChange} />
                  </Stack>
              </Stack> 
            break;
          case 10:
            opts =  <Stack>
                      <CheckboxGroup value={this.state.line.options} options={"First Name;Last Name;Phone Number"} onChange={this.handleContactOpts} label="What do you want included?"  />
                      <Checkbox label="Email Address" key="email" checked={true} disabled />
                    </Stack>
            break;

        }


        
        return (
          <Stack grow styles={stackStyles}>
              <Stack horizontal tokens={{childrenGap: '6', padding: "16px"}} >
                  <PrimaryButton onClick={this.save} text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave}/>
                  <DefaultButton onClick={this.close} text={this.state.shouldSave ? this.props.intl.formatMessage({ id: 'std.cancel' }) : this.props.intl.formatMessage({ id: 'std.close' })} />
              </Stack>
              <Dropdown
                  placeholder={this.props.intl.formatMessage({ id: 'question.type.placeholder' })}
                  label={this.props.intl.formatMessage({ id: 'question.type' })}
                  options={questiontypes}
                  styles={dropdownStyles}
                  defaultSelectedKey={this.state.line.type}
                  onChange={this.settype}
              />
              <Stack horizontal tokens={{childrenGap: '10'}}>
                <Stack.Item grow >
                  <TextField name="description" label={this.props.intl.formatMessage({ id: 'line.description' })} onChange={this.handleChange} value={this.state.line.description} />
                </Stack.Item>
              </Stack>
              {opts}
        </Stack>
      
        );
      };
      
}

export default injectIntl(ReportLineEdit);