import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps, } from 'react-intl';

import { API, APIKEY, IClient, IAttachment, IAttachmentCategory, IObjectAttachment } from './IVesta';

import {
  DetailsList,  IColumn, SelectionMode,
  DefaultButton, PrimaryButton, IconButton,
  TextField,
  FontIcon,
  Stack, IStackStyles, CommandBar, ICommandBarItemProps, IStackTokens,
  Text, 
  Dialog, DialogType, DialogFooter,
  Label,
  Dropdown, IDropdownOption, IDropdownStyles,
  mergeStyles, mergeStyleSets

} from 'office-ui-fabric-react';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

const iconClass = mergeStyles({
  fontSize: 18,
  height: 18,
  width: 18,
});

const classNames = mergeStyleSets({
  green: [{ color: 'darkgreen' }, iconClass],
  red: [{ color: 'darkred' }, iconClass],
});


type IState = {
  filter: string,
  currentCategory: number,
  currentAttachments: IObjectAttachment[],
  categories: IAttachmentCategory[],
  isopen: boolean,
  canadd: boolean,
  filename: string,
  newcat: number,
  file: any,
  deleteOpen: boolean,
  deleteBlob: string,
}

interface IProps extends WrappedComponentProps {
  client: IClient,
  user: string,
  onChange: () => void,
  categories: IAttachmentCategory[],
  attachments?: IObjectAttachment[],
}
  
  
  
class ClientAttachments extends Component<IProps,IState> {

    constructor(props: IProps) {
        super(props);


        var attach: IObjectAttachment[] = [];
        if(this.props.attachments != null)
        {
          attach = this.props.attachments.filter(a => a.category === this.props.categories[0].name);
        }

        var cats = [...this.props.categories];
        cats.push({ name: "Undefined", mandatory: false } as IAttachmentCategory)

        this.state = {
          filter: "",
          currentCategory: 0,     
          currentAttachments: attach,
          categories: cats,
          isopen: false,
          filename: "",
          canadd: false,
          newcat: 0,
          file: null,
          deleteOpen: false,
          deleteBlob: "",
        };

        this.dismissPanel = this.dismissPanel.bind(this);
        this.addNewFile = this.addNewFile.bind(this);
        this.deleteAttachment = this.deleteAttachment.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.setnewcat = this.setnewcat.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.toggleDelete = this.toggleDelete.bind(this);
    }

    private toggleDelete()
    {
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    private close()
    {
        this.setState({isopen: false});
    }

    private open()
    {
        this.setState({isopen: true});
    }

    private onUpload()
    {
      var formData = new FormData();
      formData.append("APIKey",APIKEY || "");
      formData.append("User",this.props.user);
      formData.append("clientID",this.props.client.id);
      formData.append("name",this.state.filename);
      formData.append("category",this.props.categories[this.state.newcat].name);
      formData.append("file", this.state.file);

      axios.post(API + "/client/addattachment",formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
          if(response.data.status.errorCode > 0)
          {
          }
          else
          {
            this.setState({isopen: false, newcat: 0, filename: "", canadd: false, file: null});
            this.props.onChange();
          }
        })
        .catch(function (error) {
              console.log(error);
        });                  


        this.setState({isopen: false});
    }

    private handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
        switch(name)
        {
          case "filename":
            this.setState({
                filename: value
            });
            break;
        }
    }

    selectCategory(c: number)
    {
      var attach = this.state.currentAttachments;
      if(this.props.attachments != null)  
      {
        if(c > (this.props.categories.length -1))
        {
          attach = this.props.attachments.filter(a => !this.props.categories.some(c => c.name === a.category));
        }
        else
        {
          attach = this.props.attachments.filter(a => a.category === this.props.categories[c].name);
        }
        console.log(attach);
      };

      this.setState({ currentCategory: c, currentAttachments: attach })
    }

    setnewcat(e: any,opt: any, index: number | undefined)
    {
      this.setState({ newcat: index || 0 })
      console.log(e);
    }

    componentDidMount()
    {
      this.refresh();
    }
  
    componentDidUpdate(prevProps: IProps) {
      // Typical usage (don't forget to compare props):
      if (this.props.attachments !== prevProps.attachments) {
        var attach: IObjectAttachment[] = [];
        if(this.props.attachments != null)
        {
          attach = this.props.attachments.filter(a => a.category === this.props.categories[this.state.currentCategory].name);
        }

        this.setState({
          currentAttachments: attach,
        });

      }
    }
    
    public filterval: string = "";

   
    refresh()
    {
      
    }




    private _onColumnClick = (): void => {    };            
    private dismissPanel()
    {
        this.setState({isopen: false});
    }  

    addNewFile()
    {
      this.setState({isopen: true});

    }

    onDelete(blobname: string)
    {
      this.setState({deleteBlob: blobname, deleteOpen: true,})
    }

    deleteAttachment()
    {
      const post = {
        Security: {
          APIKey: APIKEY,
          User: this.props.user
        },
        clientid: this.props.client.id,
        blobname: this.state.deleteBlob,
      }
      axios.post(API + "/client/deleteattachment",post)
        .then(response => {
            this.setState({deleteOpen: false, deleteBlob: ""})
            this.props.onChange();
        })
        .catch(function (error) {
              console.log(error);
        });                  
    }

    changeHandler(event: any)
    {
      var name = this.state.filename;
      if(name==="")
      {
        name=event.target.files[0].name;
      }
      this.setState({ filename: name, file: event.target.files[0], canadd: true, });
    };
  


    render() {

      const _items: ICommandBarItemProps[] = [
          {
            key: 'newItem',
            text: this.props.intl.formatMessage({ id: 'attachment.add' }),
            cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
            iconProps: { iconName: 'Add' },
            onClick: this.addNewFile,
          },
        ];


        const catcolumns: IColumn[] = [
          {
            key: 'Name',
            name: this.props.intl.formatMessage({ id: 'attachment.category' }),
            fieldName: 'name',
            minWidth: 0,
            maxWidth: 0,
            currentWidth: 0,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: IAttachment,index?: number) => {
              var text = <>{item.name}</>;
              if((index || 0) === this.state.currentCategory)
              {
                text = <b>{item.name}</b>;
              }
              return <div onClick={()=>{this.selectCategory(index || 0)}}>{text}</div>;
            },
          },
          {
            key: 'edit',
            name: 'Edit',
            ariaLabel: '',
            isIconOnly: true,
            fieldName: 'id',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IAttachmentCategory,index?: number) => {
              if(!item.mandatory)   return <FontIcon iconName="StatusCircleRing" className={classNames.green} />;
              if(this.props.attachments == null)
              { 
                return <FontIcon iconName="AlertSolid" className={classNames.red} />
              }

              if(this.props.attachments.find(a => a.category === item.name) === undefined)
              {
                return <FontIcon iconName="AlertSolid" className={classNames.red} />
              }
              return <FontIcon iconName="CompletedSolid" className={classNames.green} />
            },
          },
        ];


        const columns: IColumn[] = [
          {
            key: 'Name',
            name: this.props.intl.formatMessage({ id: 'attachment.name' }),
            fieldName: 'address',
            minWidth: 0,
            maxWidth: 0,
            currentWidth: 0,
            isRowHeader: true,
            isResizable: true,
            onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
            onRender: (item: IAttachment) => {
              return item.name;
            },
          },
          {
            key: 'download',
            name: 'Download',
            ariaLabel: '',
            isIconOnly: true,
            fieldName: 'id',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item: any) => {
              return <IconButton iconProps={{ iconName: 'CloudDownload' }}  title="Edit" ariaLabel="Delete" disabled={!item.canDelete} />
            },
          },
          {
            key: 'edit',
            name: 'Edit',
            ariaLabel: '',
            isIconOnly: true,
            fieldName: 'id',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item: any) => {
              return <IconButton iconProps={{ iconName: 'Edit' }}  title="Edit" ariaLabel="Delete" disabled={!item.canDelete} />
            },
          },
          {
            key: 'delete',
            name: 'Delete',
            ariaLabel: '',
            isIconOnly: true,
            fieldName: 'id',
            minWidth: 16,
            maxWidth: 16,
            onRender: (item: IObjectAttachment) => {
              return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.onDelete(item.blobname)} title="Delete" ariaLabel="Delete"  />
            },
        },
    
        ];

        var title = "";

        const innerStackTokens: IStackTokens = {
          childrenGap: 5,
          padding: 10,
        };

        const dialogContentProps = {
          type: DialogType.largeHeader,
          title: this.props.intl.formatMessage({ id: 'attachment.new.title' }),
          subText: this.props.intl.formatMessage({ id: 'attachment.new.help' }),
        };
        const modalProps = {
          isBlocking: false,
          styles: { main: { maxWidth: 450 } },
        };      
        const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 },};
  
        const deleteContentProps = {
          type: DialogType.largeHeader,
          title: this.props.intl.formatMessage({ id: 'attachment.delete.dialog.title' }),
          subText: this.props.intl.formatMessage({ id: 'attachment.delete.dialog.text' }),
        };

        
      return (
        <Stack grow styles={stackStyles}>
          <Stack horizontal grow verticalAlign="center">
              <Text variant="xLarge">{title}</Text>
              <Stack grow>
                <CommandBar items={_items} />
              </Stack>
          </Stack>
          <Stack horizontal tokens={innerStackTokens}>
            <Stack grow > 
              <DetailsList 
                items={this.state.categories}
                columns={catcolumns} 
                selectionMode={SelectionMode.none}
            />
            </Stack>
            <Stack grow={5}>
                <DetailsList  items={this.state.currentAttachments || []} 
                  columns={columns} 
                  selectionMode={SelectionMode.none}
                />
            </Stack>
          </Stack>
          <Dialog
              hidden={!this.state.isopen}
              onDismiss={this.close}
              dialogContentProps={dialogContentProps}
              modalProps={modalProps}
          >
              <TextField name="filename" label={this.props.intl.formatMessage({ id: 'attachment.name' })} onChange={this.handleChange} value={this.state.filename} />
              <Dropdown
                    placeholder={this.props.intl.formatMessage({ id: 'attachment.category' })}
                    label={this.props.intl.formatMessage({ id: 'attachment.category' })}
                    options={this.props.categories.map((c,i) => { return { key: i || 0, text: c.name  } as IDropdownOption })}
                    styles={dropdownStyles} 
                    selectedKey={this.state.newcat}
                    onChange={this.setnewcat}
                />
              <Label>File to Attach</Label>
              <input type="file" name="file" onChange={this.changeHandler} />
              <DialogFooter>
                  <PrimaryButton disabled={!this.state.canadd} onClick={this.onUpload} text={this.props.intl.formatMessage({ id: 'std.save' })} />
                  <DefaultButton onClick={this.close} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
              </DialogFooter>
          </Dialog>            
          <Dialog
              hidden={!this.state.deleteOpen}
              onDismiss={this.toggleDelete}
              dialogContentProps={deleteContentProps}
              modalProps={modalProps}
          >
              <DialogFooter>
                  <PrimaryButton onClick={() => {this.deleteAttachment()}} text={this.props.intl.formatMessage({ id: 'std.delete' })} />
                  <DefaultButton onClick={this.toggleDelete} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
              </DialogFooter>
          </Dialog>            

        </Stack>
      );
    };      
}

export default injectIntl(ClientAttachments);