import React, { Component } from 'react';
import axios from 'axios';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { API, APIKEY, getEmailErrorMessage } from './IVesta';

import {  
          Stack, IStackTokens, 
          TextField,
          Toggle,
          Dropdown, IDropdownOption, IDropdownStyles,
          DefaultButton, PrimaryButton, 
} from 'office-ui-fabric-react';

interface IState  {
    id: string,
    email: string,
    role: string,
    active: boolean,
    shouldSave: boolean,
}
  
interface IProps extends WrappedComponentProps {
    id: string
    user: string
    onClose?: any
}

  

class User extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);
        
        this.state = {
          email: "",
          role: "",
          active: false,
          id: props.id,
          shouldSave: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.setrole = this.setrole.bind(this);
        this.setactive = this.setactive.bind(this);
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
  
        switch(name)
        {
          case "email":
            const s = getEmailErrorMessage(value) === "";
            this.setState({
              email: value,
              shouldSave: s,
            });
            break;
        }
      }

    setrole(event: any,option?: any,index?: number)
    {
      const s = getEmailErrorMessage(this.state.email) === "";
      this.setState({role: option.key || "", shouldSave: s,});
    }

    setactive(ev: React.MouseEvent<HTMLElement>, checked?: boolean) 
    {
      const s = getEmailErrorMessage(this.state.email) === "";
      this.setState({active: checked || false, shouldSave: s,});
    }

    componentDidMount()
    {
      this.refresh();
    }
  
    refresh()
    {
      if(this.props.id === "")
      {
        this.setState({
          email: "", 
          role: "", 
          active: true,
        });
        return;
      } 
      
      const post = {
        security: {
          APIKey: APIKEY,
          User: this.props.user
        },
        id: this.state.id
      }
  
      axios.post(API + "/user/edit",post)
        .then(response => {
            const item = response.data.user;
            this.setState({
                email: item.email, 
                role: item.role, 
                active: item.active,
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  
    save()
    {
        if(this.props.id === "")
        {
            const post = {
                Security: {
                  APIKey: APIKEY,
                  User: this.props.user
                },
                email: this.state.email,
                role: this.state.role,
                active: this.state.active,
              }
              axios.post(API + "/user/create",post)
                .then(response => {
                  this.props.onClose();
                })
                .catch(function (error) {
                      console.log(error);
                });                  
        }
        else
        {
            const post = {
                Security: {
                  APIKey: APIKEY,
                  User: this.props.user
                },
                id: this.state.id,
                email: this.state.email,
                role: this.state.role,
                active: this.state.active,
              }
              axios.post(API + "/user/update",post)
                .then(response => {
                        this.props.onClose();
                })
                .catch(function (error) {
                      console.log(error);
                });                  
        }
    }

    close()
    {
        this.props.onClose();
    }


    render() {
          const roles: IDropdownOption[] = [
            { key: 'admin', text: this.props.intl.formatMessage({ id: 'user.role.administrator' }) },
            { key: 'assessor', text: this.props.intl.formatMessage({ id: 'user.role.assessor' }) },
          ];
    
          const stackToks: IStackTokens = {
              childrenGap: '6'
          };
          
          const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },
          };

          return (
            <Stack grow>
              <Stack>
                <TextField label={this.props.intl.formatMessage({ id: 'user.email' })} value={this.state.email}  name="email"  onGetErrorMessage={getEmailErrorMessage} onChange={this.handleChange}/>
                <Dropdown
                    placeholder={this.props.intl.formatMessage({ id: 'user.role.placeholder' })}
                    label={this.props.intl.formatMessage({ id: 'user.role' })}
                    options={roles}
                    styles={dropdownStyles}
                    selectedKey={this.state.role}
                    onChange={this.setrole}
                />                
                <Toggle label={this.props.intl.formatMessage({ id: 'user.login.enable' })} checked={this.state.active} onText="Yes" offText="No" onChange={this.setactive} />
                <Stack horizontal tokens={stackToks} padding="16px">
                    <PrimaryButton onClick={this.save} text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave}/>
                    <DefaultButton onClick={this.close} text={this.props.intl.formatMessage({ id: 'std.cancel' })} />
                </Stack>
              </Stack>
            </Stack>
        );
      };
      
}

export default injectIntl(User);