import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps, FormattedMessage  } from 'react-intl';

import { IReportLine,  ISurvey, ISurveySection,  } from './IVesta';
import {   
          Stack, IStackStyles, Text,
          Separator
} from 'office-ui-fabric-react';

const stackStyles: IStackStyles = {
  root: {
    width: "500"
  },
};

type IState = {
  }
  
  interface IProps extends WrappedComponentProps {
    line : IReportLine,
  }


class ReportLineView extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        if(this.props.line == null)
        {
            this.state = {
            };
        }
        else
        {
          this.state = {
              isopen: false,

              line: this.props.line,

              surveys: [],
              survey: {
                sections: [] as ISurveySection[] 
                } as ISurvey,
              section: {} as ISurveySection,
              sectionkey: "",

              shouldSave: false,
            };
        }
    }



    componentDidMount()
    {
      this.refresh();
    }
  
  
    refresh()
    {
    }

    render() {
      if(this.props.line == null)
      {
        return(<Text variant="medium"><FormattedMessage id="question.select"/></Text>) 
      }

      var opts;
      switch(this.props.line.type)
      {
        case 0: case 1:
          opts = <Text>{this.props.line.options}</Text>
          break;
        case 3:
          opts = <Separator />
          break;
        case 4:
          if(this.props.line.answer != null && this.props.line.question != null)
          {
            opts = 
              <Stack>
                <Text variant="small">{this.props.line.question.description} </Text>
                <Stack horizontalAlign="end">
                  <Text>{this.props.line.answer.answer} </Text>
                </Stack>
              </Stack>;
          }
          break;

      }

      return (
        <Stack grow styles={stackStyles}>
            {opts}
      </Stack>
    
      );
    };
      
}

export default injectIntl(ReportLineView);