import React from 'react';
import {
  Jumbotron
} from 'reactstrap';

import {
  Stack, Image, ImageFit
} from 'office-ui-fabric-react';


interface WelcomeProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface WelcomeState {
  isOpen: boolean;
}


export default class Welcome extends React.Component<WelcomeProps, WelcomeState> {
  render() {
    return (
      <Jumbotron>
        <Stack horizontal>
          <Image  src="/images/y.png" width="100" height="100" />
          <Stack>
            <h1>My Digital Audit</h1>
            <p className="lead">
              Allowing you to turbo charge your surveying, assessing and evidence gathering.
            </p>
          </Stack>
        </Stack>
      </Jumbotron>
    );
  }
}
